import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'
import { BatchBurndownChartContainer } from './BatchBurndownChartContainer'
import { ContentCard } from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import {
  displayErrorModal,
  FUEL_TYPE_NAMES,
  FuelType as FuelTypeEnum,
} from '../../../../utils'
import {
  BatchBurndownByFuelTypeSeries,
  UserEntriesByFuelTypeSeries,
} from './models'
import { mapBatchBurndownByFueltypes, mapUserEntriesByFuelType } from './utils'
import FuelAndStockModal from '../../../../pages/ActivityLogPage/FuelAndStock/FuelAndStockModal'

type Props = {
  imoNo: string
  useApi?: boolean // True if the feature component should call the api for data, otherwise sent in through props
  burndown?: Performance.FuelOilStock.BurndownModel | null
}

const FUEL_TYPES_ARR = [
  FuelTypeEnum.HS,
  FuelTypeEnum.VLS,
  FuelTypeEnum.ULS,
  FuelTypeEnum.MDO,
  FuelTypeEnum.MM,
]

interface initialZoomInterface {
  startValue: number
  endValue: number
}

export const BatchBurndown = ({ imoNo, useApi, burndown }: Props) => {
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<Set<FuelTypeEnum>>(
    new Set(FUEL_TYPES_ARR),
  )
  const [burndownByFuelType, setBurndownByFuelType] =
    useState<BatchBurndownByFuelTypeSeries>()
  const [userEntriesByFuelType, setUserEntriesByFuelType] =
    useState<UserEntriesByFuelTypeSeries>()
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  const [period, setPeriod] = useState<Performance.Common.Period>()
  const [initialZoom, setInitialZoom] = useState<initialZoomInterface>({
    startValue: moment.utc().subtract(14, 'd').valueOf(),
    endValue: moment.utc().valueOf(),
  })

  const mapAndSetBurndownData = useCallback(
    (burndown?: Performance.FuelOilStock.BurndownModel | null) => {
      if (burndown) {
        setBurndownByFuelType(mapBatchBurndownByFueltypes(burndown))
        setOpenWindow(burndown.openWindow)
        setUserEntriesByFuelType(mapUserEntriesByFuelType(burndown))
        setPeriod(burndown.period)
      } else {
        setNoData()
      }
    },
    [],
  )

  const getStockStatusWithBurndown = useCallback(
    (imoNo: string) => {
      const includeBurndown = true
      PerformanceApi.getStockStatus(imoNo, includeBurndown)
        .then((stockStatusResponse) =>
          mapAndSetBurndownData(stockStatusResponse?.burndown),
        )
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get stock status',
            message: e.message,
          }).then(setNoData),
        )
    },
    [mapAndSetBurndownData],
  )

  const setNoData = () => {
    const empty_Data = { HS: [], VLS: [], ULS: [], MDO: [], MM: [] }
    setBurndownByFuelType(empty_Data as BatchBurndownByFuelTypeSeries)
    setUserEntriesByFuelType(empty_Data as UserEntriesByFuelTypeSeries)
  }

  useEffect(() => {
    if (!useApi) mapAndSetBurndownData(burndown)
  }, [useApi, imoNo, burndown, mapAndSetBurndownData])

  useEffect(() => {
    if (useApi) getStockStatusWithBurndown(imoNo)
  }, [imoNo, useApi, getStockStatusWithBurndown])

  const handleCheckboxChange = (fuelType: FuelTypeEnum) => {
    setSelectedFuelTypes((prev) => {
      const newSelected = new Set(prev)
      newSelected.has(fuelType)
        ? newSelected.delete(fuelType)
        : newSelected.add(fuelType)
      return newSelected
    })
  }

  const combinedBurndownSeries = Array.from(selectedFuelTypes).flatMap(
    (fuelType) => burndownByFuelType?.[FUEL_TYPE_NAMES[fuelType]] || [],
  )

  const combinedUserEntriesSeries = Array.from(selectedFuelTypes).flatMap(
    (fuelType) => userEntriesByFuelType?.[FUEL_TYPE_NAMES[fuelType]] || [],
  )

  return (
    <ContentCard
      id='batch-burndown'
      title='Batch burndown'
      helpTextKey='stock/batch-burndown'
      headerChildren={<FuelAndStockModal />}
    >
      <CheckboxWrapper>
        {FUEL_TYPES_ARR.map((fuelType) => (
          <McCheckbox
            key={fuelType}
            label={FUEL_TYPE_NAMES[fuelType]}
            checked={selectedFuelTypes.has(fuelType)}
            change={() => handleCheckboxChange(fuelType)}
          />
        ))}
      </CheckboxWrapper>
      <BatchBurndownChartContainer
        batchBurndownSeries={combinedBurndownSeries}
        userEntriesSeries={combinedUserEntriesSeries}
        initialZoom={initialZoom}
        setInitialZoom={setInitialZoom}
        openWindow={openWindow}
        period={period}
        id={Array.from(selectedFuelTypes)
          .map((fuelType) => FUEL_TYPE_NAMES[fuelType])
          .join(', ')}
      />
    </ContentCard>
  )
}

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-right: 20px;
`
