import { useContext, useState } from 'react'

import { BatchSelections } from './BatchSelections'
import { BatchTransferModal } from '../BatchTransferModal'
import type { FuelTypeQuantity } from '../../../../stock-management/models'
import { Performance } from '../../../../../api-models'
import * as PerformanceAPI from '../../../../../services/performance'
import { VesselPageContext } from '../../../../../contexts'
import { displayErrorModal, FuelType } from '../../../../../utils'

type Props = {
  fuelTypeQuantities: Array<FuelTypeQuantity>
  batchSelections: Array<Performance.FuelOilStock.CurrentBatchSelectionResponse> | null
  onSelectionsUpdate?: () => void
}

export const BatchSelectionsContainer = ({
  fuelTypeQuantities,
  batchSelections,
  onSelectionsUpdate,
}: Props) => {
  const vesselPageContext = useContext(VesselPageContext)
  const imoNo = vesselPageContext.imoNo!

  //Batch selection state
  const [fuelTypeForBatchSelection, setFuelTypeForBatchSelection] =
    useState<FuelType>()

  const showErrorWithTitle = (statusText) => (error) =>
    displayErrorModal({
      statusText,
      message: error.message,
    })

  const submitBatchTransfer = (
    batchSelection: Performance.FuelOilStock.BatchSelection,
  ) => {
    return PerformanceAPI.postBatchSelection(imoNo, batchSelection)
      .then(() => {
        onEntryUpdates()
        setFuelTypeForBatchSelection(undefined)
      })
      .catch(showErrorWithTitle('Could not create batch selection'))
  }

  const onEntryUpdates = () => {
    if (onSelectionsUpdate) onSelectionsUpdate()
  }

  const _uniqueFuelTypesOnboard = Array.from(
    new Set(
      fuelTypeQuantities
        .filter(({ quantity }) => quantity !== undefined)
        .map(({ fuelType }) => fuelType),
    ),
  )

  return (
    <>
      <BatchSelections
        onClick={(fuelType) => setFuelTypeForBatchSelection(fuelType)}
        fuelTypes={_uniqueFuelTypesOnboard}
        batchSelections={batchSelections}
      />
      {batchSelections && fuelTypeForBatchSelection && (
        <BatchTransferModal
          fuelTypeForBatchSelection={fuelTypeForBatchSelection}
          currentlySelectedBatch={batchSelections.find(
            (selection) => selection.fuelType === fuelTypeForBatchSelection,
          )}
          closeHandler={() => setFuelTypeForBatchSelection(undefined)}
          submit={(batchSelection) => {
            return submitBatchTransfer(batchSelection)
          }}
        />
      )}
    </>
  )
}
