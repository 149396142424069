import { useEffect, useMemo } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { ThemeProvider } from 'styled-components'

import { WindowContextProvider } from './contexts'
import Routes from './routes'
import { GlobalStyles, lightTheme } from './theme_new'
import { getApplicationConfig, isShoreContext } from './utils'
import * as analytics from './utils/analytics'

const faro = analytics.initializeRum()

const ShoreApp = () => {
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance, inProgress } = useMsal()

  const loginRequest = useMemo(
    () => ({
      account: accounts[0],
      scopes: [(window as any).config.azureAd.scope],
      extraQueryParameters: {
        domain_hint: (window as any).config.azureAd.domain,
      },
    }),
    [accounts],
  )

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      void instance.loginRedirect(loginRequest)
    }
  }, [inProgress, instance, isAuthenticated, loginRequest])

  if (!isAuthenticated) {
    return <div>Please wait until we sign you in</div>
  }

  if (loginRequest.account !== undefined) {
    faro?.api.setUser({
      username: loginRequest.account.username,
    })
  }

  return <Routes />
}

const VesselApp = () => {
  const { currentVesselImo } = getApplicationConfig()

  faro?.api.setUser({
    username: currentVesselImo,
    attributes: {
      imoNo: currentVesselImo,
    },
  })

  return <Routes />
}

const App = () => (
  <WindowContextProvider>
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      {isShoreContext() ? <ShoreApp /> : <VesselApp />}
    </ThemeProvider>
  </WindowContextProvider>
)

export default App
