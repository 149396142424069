import { FormElements } from '../../../commons'

type Props = {
  imoNo?: string
  flowMeterScenario?: string
  vesselName?: string
}

export function SystemInfoTable(props: Props) {
  return (
    <FormElements.ReadonlyTable>
      <tbody>
        <tr>
          <td>
            <FormElements.Label>Vessel name</FormElements.Label>
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>{props.vesselName}</td>
        </tr>
        <tr>
          <td>
            <FormElements.Label>IMO</FormElements.Label>
          </td>
          <td>{props.imoNo}</td>
        </tr>
        <tr>
          <td>
            <FormElements.Label>Flow meter scenario</FormElements.Label>
          </td>
          <td>{props.flowMeterScenario ? props.flowMeterScenario : 'N/A'}</td>
        </tr>
      </tbody>
    </FormElements.ReadonlyTable>
  )
}
