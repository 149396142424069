import { useContext, useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { McLabel } from '@maersk-global/mds-react-wrapper'

import { calculateLcvForBioFuel } from './Bunkering.utils'
import { type BunkeringFormValues } from './BunkeringForm.types'
import { LabReportWrapper, TagGroupWrapper } from './BunkeringForm.styles'
import { Column, ContentWrapper, resolveNotesHeight } from '../styles'
import { McTagGroup } from '../McTagGroup'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import * as PerformanceAPI from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { displayErrorModal, FuelType } from '../../../../utils'
import { Loading } from '../../../../commons'
import { fuelTypeOptions, fuelTypeSubOptions } from '../../models'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getFuelGradeOption } from '../../utils'
import { getTerminalOption } from '../../../hybrid-data-collector/reports/reports.utils'
import {
  FormInputDateTime,
  FormTextArea,
  FormTypeahead,
  InputNumber,
  InputText,
} from '../../../../components/formik'

type Props = {
  disabled: boolean
  isLabReportReadonly?: boolean
  isBaseFormReadonly: boolean
  disableOpenWindowValidation?: boolean
  fuelGrades?: Array<MasterDataApi.Common.FuelGrade>
  isFuelGradesSuccess: boolean
}

export const BunkeringFormFields = ({
  disabled,
  isLabReportReadonly,
  isBaseFormReadonly,
  disableOpenWindowValidation,
  fuelGrades,
  isFuelGradesSuccess,
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()
  const imoNo = useContext(VesselPageContext).imoNo!
  const { initialValues, values, setFieldValue } =
    useFormikContext<BunkeringFormValues>()
  const { fuelType } = values
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const [calculatedLcvForBioFuel, setCalculatedLcvForBioFuel] = useState(false)
  useEffect(() => {
    if (disableOpenWindowValidation) {
      setOpenWindow({
        period: {
          from: moment.utc().subtract(2, 'y').toString(),
          to: moment.utc().toString(),
          totalMinutes: moment
            .utc()
            .diff(moment.utc().subtract(2, 'y'), 'minutes'),
        },
      })
    } else {
      PerformanceAPI.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo, disableOpenWindowValidation])

  useEffect(() => {
    const fuelType = values?.fuelType
    const fieldName = 'isDistillate'
    if (fuelType === FuelType.HS) void setFieldValue(fieldName, false)
    if (fuelType === FuelType.MDO) void setFieldValue(fieldName, true)
  }, [fuelType, setFieldValue, values])

  const activeFuelGradeOptions = useMemo(() => {
    if (!isFuelGradesSuccess) return []
    return fuelGrades!.filter((fg) => fg.data.isActive).map(getFuelGradeOption)
  }, [fuelGrades, isFuelGradesSuccess])

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []

    const result = terminals
      .filter((t) => t.data.isActive)
      .map(getTerminalOption)

    if (
      initialValues.portCode &&
      !result.find((t) => t.value === initialValues.portCode)
    ) {
      // Workaround: Add the port code from the initial values if it is not in the list
      result.push({
        label: initialValues.portCode,
        value: initialValues.portCode,
      })
    }

    return result
  }, [terminals, isTerminalsSuccess, initialValues.portCode])

  if (!openWindow) {
    return <Loading width='unset' />
  }

  const handleBioPercentageChange = (value: number | null) => {
    if (value === null) {
      void setFieldValue('lcv', null)
      setCalculatedLcvForBioFuel(false)
      return
    }

    const fuelGrade = fuelGrades?.find(
      (fg) => fg.data.code === values.fuelGrade,
    )
    if (fuelGrade?.data.isBiofuel) {
      const lcv = calculateLcvForBioFuel(fuelGrade.data.fuelType, value)
      void setFieldValue('lcv', lcv)
      setCalculatedLcvForBioFuel(true)
    }
  }

  const handleFuelGradeChange = (value: string | number) => {
    const fuelGrade = fuelGrades?.find((fg) => fg.data.code === value)
    void setFieldValue('fuelGrade', value)
    void setFieldValue('fuelType', fuelGrade?.data.fuelType ?? 0)
    void setFieldValue('isDistillate', fuelGrade?.data.refinementType === 1)
    void setFieldValue('lcv', '')
    void setFieldValue('bioPercentage', 0)
    setCalculatedLcvForBioFuel(false)
  }

  return (
    <>
      <ContentWrapper>
        <Column>
          <FormInputDateTime
            name='timestamp'
            label='Time of bunkering'
            min={moment.utc(openWindow.period.from)}
            max={moment.utc(openWindow.period.to)}
            passThroughMoment
            disabled={isBaseFormReadonly || disabled}
          />
          <FormTypeahead
            id='fuelGrade'
            name='fuelGrade'
            label='Fuel grade'
            placeholder='Type to search'
            options={activeFuelGradeOptions}
            onChange={handleFuelGradeChange}
            disabled={isBaseFormReadonly || disabled}
          />
          <McLabel fit={windowSize} label='Fuel type' />
          <TagGroupWrapper>
            <McTagGroup name='fuelType' options={fuelTypeOptions} />
          </TagGroupWrapper>
          {values.fuelType !== 0 && values.fuelType !== FuelType.MM && (
            <>
              <McLabel
                fit={windowSize}
                label={`Type of ${FuelType[values.fuelType]}`}
              />
              <TagGroupWrapper>
                <McTagGroup
                  name='isDistillate'
                  options={fuelTypeSubOptions[values.fuelType]}
                />
              </TagGroupWrapper>
            </>
          )}
          {(fuelGrades?.find((fg) => fg.data.code === values.fuelGrade)?.data
            .isBiofuel ||
            values.bioPercentage > 0) && (
            <InputNumber
              label='Percentage bio'
              name='bioPercentage'
              unit='%mass'
              onBlur={handleBioPercentageChange}
              disabled={isBaseFormReadonly || disabled}
            />
          )}

          <FormTypeahead
            id='portCode'
            name='portCode'
            label='Bunker port code'
            placeholder='Type to search'
            options={activeTerminalOptions}
            disabled={isBaseFormReadonly || disabled}
          />
        </Column>
        <Column>
          <InputText
            label='Order ID (Nomination Number)'
            name='orderId'
            placeholder='Enter the 5-digit order ID'
            disabled={isBaseFormReadonly || disabled}
          />
          <InputNumber
            label='Quantity ordered'
            name='quantityOrdered'
            unit='MT'
            disabled={isBaseFormReadonly || disabled}
          />
          <InputNumber
            label='Quantity received according to C/E'
            name='quantityPerChiefEngineer'
            unit='MT'
            disabled={isBaseFormReadonly || disabled}
          />
          <InputNumber
            label='Quantity agreed with supplier (BDN)'
            name='quantityAgreed'
            unit='MT'
            disabled={isBaseFormReadonly || disabled}
          />
        </Column>
        <Column>
          <FormTextArea
            id='notes'
            name='notes'
            label='Notes'
            height={resolveNotesHeight(windowSize)}
            rows={6}
            maxlength={1000}
            disabled={isBaseFormReadonly || disabled}
          />
        </Column>
      </ContentWrapper>
      <LabReportWrapper>
        <h4>Lab report</h4>
        <hr />
        <ContentWrapper>
          <Column>
            <InputText
              label='Marpol seal'
              name='marpolSeal'
              disabled={isLabReportReadonly || disabled}
            />
            <InputNumber
              label='Density 15'
              name='density15'
              unit='kg/m³'
              disabled={isLabReportReadonly || disabled}
            />
            <InputNumber
              label='LCV'
              name='lcv'
              unit='KJ/kg'
              disabled={
                isLabReportReadonly || disabled || calculatedLcvForBioFuel
              }
            />
          </Column>
          <Column>
            <InputNumber
              label='Water'
              name='water'
              unit='%vol.'
              disabled={isLabReportReadonly || disabled}
            />
            <InputNumber
              label='Ash'
              name='ash'
              unit='%mass'
              disabled={isLabReportReadonly || disabled}
            />
            <InputNumber
              label='Sulphur'
              name='sulphur'
              unit='%mass'
              disabled={isLabReportReadonly || disabled}
            />
          </Column>
          <Column />
        </ContentWrapper>
      </LabReportWrapper>
    </>
  )
}
