import type { EChartsOption, EChartsType } from 'echarts/types/dist/shared'
import * as echarts from 'echarts/core'
import { BarChart } from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import {
  AXIS_LABEL,
  AXIS_TICK,
  TEXT_STYLE,
  TOOLTIP,
} from '../../../../EngineTestReportPage/Common/ChartConfig'
import { formatValue } from '../../../../../utils'

echarts.use([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  UniversalTransition,
])

export const initChart = (domId: string) => {
  const chartDom = document.getElementById(domId)

  if (!chartDom) {
    console.error(`Chart DOM element with ID '${domId}' not found`)
    return
  }

  const options: EChartsOption = {
    barCategoryGap: '35%',
    barGap: 0,
    grid: {
      containLabel: true,
      left: 32,
    },
    // @ts-ignore
    tooltip: {
      ...TOOLTIP,
      axisPointer: {
        type: 'shadow',
      },
      order: 'valueAsc',
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      top: 'middle',
      right: 0,
      padding: [32, 32],
      icon: 'circle',
      itemGap: 16,
      itemWidth: 16,
      itemHeight: 16,
      textStyle: TEXT_STYLE,
    },
    xAxis: {
      type: 'category',
      name: '% MCR',
      axisLabel: AXIS_LABEL,
      nameTextStyle: AXIS_LABEL,
      axisTick: AXIS_TICK,
    },
    yAxis: {
      type: 'value',
      name: 'Running hours',
      nameTextStyle: AXIS_LABEL,
      axisLabel: AXIS_LABEL,
    },
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)
  return chart
}

export const updateChart = (
  data: Array<BalrogApi.LoadProfile>,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  const dataset = resolveDataset(data)

  let maxNameLength = 0
  dataset.forEach((row) => {
    const name = row[0].toString()
    if (name.length > maxNameLength) {
      maxNameLength = name.length
    }
  })

  chart.setOption({
    grid: {
      right: maxNameLength > 18 ? 312 : 248,
    },
    dataset: {
      source: dataset,
    },
    xAxis: [{ type: 'category', gridIndex: 0 }],
    series: [
      ...dataset.slice(1, dataset.length).map((row) => ({
        type: 'bar',
        seriesLayoutBy: 'row',
        emphasis: {
          focus: 'series',
        },
      })),
    ],
  })
}

const resolveDataset = (
  data: Array<BalrogApi.LoadProfile>,
): Array<Array<string | number>> => {
  const columns = new Set<string>(['Vessel']) // ['Vessel', '0-10', '10-20', ..., '80-90']
  const vesselLoadProfile: Record<number, Array<number>> = {} // { '[VESSEL NAME]': [-0.4, 0.3, -0.2] }

  data.forEach((entry) => {
    entry.loadProfile.forEach((loadProfile) => {
      columns.add(`${loadProfile.loadStart}-${loadProfile.loadEnd}`)

      if (!(entry.vesselName in vesselLoadProfile)) {
        vesselLoadProfile[entry.vesselName] = []
      }

      vesselLoadProfile[entry.vesselName].push(
        formatValue(loadProfile.runtime, 2),
      )
    })
  })

  // ["[VESSEL NAME]", -0.4, 0.3, -0.2]
  const rows = Object.entries(vesselLoadProfile).map(([imo, values]) => [
    imo,
    ...values,
  ])

  return [Array.from(columns), ...rows]
}
