import { Modal } from '../../../../commons'
import { type BunkeringFormValues } from '../bunkering/BunkeringForm.types'
import { BunkeringFormInitialRob } from './BunkeringFormInitialRob'
import type { FormBatch, InitialRobBatch } from '../../models'

type Props = {
  batch?: FormBatch
  batches: Array<InitialRobBatch>
  closeHandler: (refresh?: boolean) => void
  submitBunkering: (values: BunkeringFormValues, batchId?: string) => void
  deleteBunkering: (batchId: string) => void
}

export const BunkeringModalInitialRob = ({
  closeHandler,
  batch,
  batches,
  submitBunkering,
  deleteBunkering,
}: Props) => (
  <Modal
    title='Add new bunkered batch'
    visible
    closeHandler={closeHandler}
    minWidth='880px'
  >
    <BunkeringFormInitialRob
      closeHandler={closeHandler}
      batches={batches}
      batch={batch}
      submitBunkering={submitBunkering}
      deleteBunkering={deleteBunkering}
    />
  </Modal>
)
