import styled from 'styled-components'

import type { TWindowSize } from '../../../../theme_new/styled'

export const BatchGroup = styled.div`
  margin-bottom: 24px;
`

export const BatchFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 8px;

  > mc-input,
  mc-select {
    flex-grow: 1;
  }
`

export const resolveModalHeight = (
  batchCount: number,
  windowSize: TWindowSize,
): `${number}px` => {
  const sizeHeights = { small: 328, medium: 376, large: 424 }
  const batchHeights = { small: 136, medium: 152, large: 160 }

  let height = sizeHeights[windowSize] + batchCount * batchHeights[windowSize]

  return `${height}px`
}

export const resolveModalWidth = (windowSize: TWindowSize): `${number}px` => {
  const sizeWidths = { small: 1000, medium: 1120, large: 1192 }
  return `${sizeWidths[windowSize]}px`
}
