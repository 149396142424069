import styled from 'styled-components'
import { McLabel } from '@maersk-global/mds-react-wrapper'

import type { TWindowSize } from '../../../../theme_new/styled'

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  grid-template-areas: 'left-col right-col';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  padding: 0 16px;
`

export const Column = styled.section`
  > div,
  mc-select,
  mc-button,
  mc-textarea {
    margin-bottom: 20px;
    max-width: 600px;
  }

  > mc-select,
  mc-textarea {
    display: block;
  }
`

export const ColumnLeft = styled(Column)`
  grid-area: left-col;
`

export const ColumnRight = styled(Column)`
  grid-area: right-col;
`

export const ConsumptionField = styled.div`
  display: flex;
`

export const ConsumptionPerHourLabel = styled(McLabel)<{
  fit: TWindowSize
}>`
  margin: ${({ fit }) =>
    fit === 'small'
      ? '30px 0 0 12px'
      : fit === 'medium'
      ? '36px 0 0 16px'
      : '40px 0 0 16px'};
`

export const Header = styled.h5`
  border-bottom: 2px solid #63b2d4;
  margin: 4px 0 16px !important;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  margin: 0 0 10px !important;
`

export const Loading = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
`

export const ButtonSpacer = styled.div`
  margin: 0 10px !important;
`

export const Heading = styled.span`
  display: flex;
  align-items: center;
`
