/**
 * Represents a data point with a value, an override status, and an optional external source.
 *
 * @template T - The type of the value.
 */
export class DataPoint<T> {
  /**
   * The value of the data point.
   */
  value: T | null

  /**
   * Indicates whether the data point is overridden.
   * @default false
   */
  isOverridden: boolean = false

  /**
   * The external source of the data point, if any. Should only ever be created by Gandalf API.
   * @default null
   */
  readonly externalSource: GandalfApi.ExternalSource<T> | null = null

  /**
   * Creates an instance of DataPoint with the given value.
   * Properties `isOverridden` and `externalSource` are initialized to their default values of `false` and `null`, respectively.
   *
   * @param value - The value of the data point.
   */
  constructor(value: T) {
    this.value = value
  }
}
