import { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import { validationSchema } from './BunkeringForm.validation'
import { defaultInitialValues } from './BunkeringForm.utils'
import { resolveModalHeight } from './BunkeringForm.styles'
import { type BunkeringFormValues } from './BunkeringForm.types'
import { BunkeringFormFields } from './BunkeringFormFields'
import { Heading } from '../styles'
import {
  generateDisplayName,
  mapBatchToBunkeringFormValues,
  mapBunkeringFormValuesToBatch,
} from '../../utils'
import { HelpText } from '../../../help-texts'
import { Performance } from '../../../../api-models'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../../utils'
import { useFuelGrades } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getBunkeredBatch } from '../../../../services/performance'

type Props = {
  entryId?: string
  closeHandler: (refresh?: boolean) => void
}

export const BunkeringForm = ({ closeHandler, entryId }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!
  const { data: fuelGrades, isSuccess: isFuelGradesSuccess } = useFuelGrades()
  const [didTrySubmit, setDidTrySubmit] = useState(false)
  const [batch, setBatch] =
    useState<Performance.FuelOilStock.BunkeredBatchResponse>()

  const isEditMode = !!batch
  const submitText = isEditMode ? 'Save changes' : 'Add new bunkering'
  const disabled =
    isShoreContext() || (!!batch && batch.readonly && batch.labReportReadonly)
  const isDeleteForbidden = isShoreContext() || (!!batch && batch.readonly)

  const initialValues = batch
    ? mapBatchToBunkeringFormValues(batch)
    : defaultInitialValues

  const submitBunkering = (
    values: BunkeringFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const batchRequestBody = mapBunkeringFormValuesToBatch(values)
    const request = batch
      ? PerformanceApi.putBunkeredBatch(imoNo, batchRequestBody, batch.id)
      : PerformanceApi.postBunkeredBatch(imoNo, batchRequestBody)
    request
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  const deleteBunkering = (batchId, setSubmitting) => {
    setSubmitting(true)
    PerformanceApi.deleteBunkeredBatch(imoNo, batchId)
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  const _fit = windowSize === 'large' ? 'medium' : windowSize

  useEffect(() => {
    if (!entryId) return
    getBunkeredBatch(imoNo, entryId).then(setBatch).catch(displayErrorModal)
  }, [entryId, imoNo])

  // If an entry ID is provided, we need to wait for the batch to be fetched
  if (!!entryId && !batch) {
    return
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (!values.displayName.trim()) {
          values.displayName = generateDisplayName(
            values.fuelType,
            values.isDistillate,
            values.quantityPerChiefEngineer,
            values.portCode,
            values.timestamp?.toISOString(),
          )
        }
        submitBunkering(values, setSubmitting)
      }}
      validationSchema={validationSchema(fuelGrades ?? [])}
      validateOnChange={didTrySubmit}
    >
      {({ isSubmitting, setSubmitting, isValid, values }) => {
        const _fuelGrade = fuelGrades?.find(
          (fg) => fg.data.code === values.fuelGrade,
        )

        return (
          <Form>
            <McModal
              fit={_fit}
              open
              height={resolveModalHeight(_fuelGrade, windowSize)}
              width='1040px'
              closed={() => closeHandler()}
            >
              <Heading slot='heading'>
                {disabled
                  ? 'Bunkered batch details'
                  : !!batch
                  ? 'Edit bunkered batch'
                  : 'Add new bunkered batch'}
                <HelpText textKey='stock/bunkering' />
              </Heading>
              <BunkeringFormFields
                disabled={disabled}
                isBaseFormReadonly={!!batch?.readonly}
                isLabReportReadonly={batch?.labReportReadonly}
                fuelGrades={fuelGrades}
                isFuelGradesSuccess={isFuelGradesSuccess}
              />
              {!!batch && (
                <McButton
                  fit={_fit}
                  slot='secondaryAction'
                  label='Delete'
                  variant='plain'
                  type='button'
                  disabled={isSubmitting || isDeleteForbidden}
                  click={() => deleteBunkering(batch.id, setSubmitting)}
                />
              )}
              <McButton
                fit={_fit}
                slot='secondaryAction'
                label='Cancel'
                appearance='neutral'
                type='button'
                click={() => closeHandler()}
              />
              <McButton
                fit={_fit}
                slot='primaryAction'
                label={submitText}
                appearance='primary'
                disabled={isSubmitting || disabled || !isValid}
                type='submit'
                click={() => setDidTrySubmit(true)}
              />
            </McModal>
          </Form>
        )
      }}
    </Formik>
  )
}
