/**
 * @see Papi.FuelConsumption.BatchIndicatorType
 */
export enum BatchIndicatorType {
  Batch = 1,
  Blend = 2,
  Error = 3,
}

/**
 * @see Papi.FuelConsumption.BatchType
 */
export enum BatchType {
  Bunkered = 10,
  Mixed = 11,
}

export const DataPointIds = {
  Ae: {
    1: {
      RunningHours: 82,
    },
    2: {
      RunningHours: 102,
    },
    3: {
      RunningHours: 122,
    },
    4: {
      RunningHours: 142,
    },
  },
}
