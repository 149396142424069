import { useState, useEffect, useContext, useCallback } from 'react'

import { ContentCard, Loading } from '../../../commons'
import type { Performance } from '../../../api-models'
import * as PerformanceAPI from '../../../services/performance'
import { VesselPageContext } from '../../../contexts'
import { FuelLineChangeModal } from './fuel-type-selection/FuelLineChangeModal'
import { displayErrorModal } from '../../../utils'
import styled, { grey, media, md, lg, sm } from '../../../theme'
import { FuelConsumers } from './consumer-selection/FuelConsumers'
import FuelAndStockModal from '../../../pages/ActivityLogPage/FuelAndStock/FuelAndStockModal'

const InfoTextWrapper = styled.span`
  color: ${grey[700]};
  font-size: 14px;
  margin-bottom: 16px;
`

const ContentWrapper = styled.div`
  ${media.sm`padding: ${sm.CONTENT_CARD_HEADER_PADDING}`}
  ${media.lg`padding: ${lg.CONTENT_CARD_HEADER_PADDING}`}
  ${media.md`padding: ${md.CONTENT_CARD_HEADER_PADDING}`}
  padding-top: 0 !important;
  display: flex;
  flex-direction: column;
`

const ConsumerCardsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  gap: 16px;
  min-height: 150px;
`

type Props = {
  batches: Array<Performance.FuelOilStock.BatchResponse>
  onSelectionChange?: () => void
}

export const ConsumerSelection = ({ batches, onSelectionChange }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!

  const [isLoadingData, setIsLoadingData] = useState(false)
  const [fuelTypeSelections, setFuelTypeSelections] =
    useState<Array<Performance.FDL.FuelTypeSelectionResponse>>()
  // Fuel type selection state
  const [showFuelLineChangeModal, setShowFuelLineChangeModal] = useState(false)
  const [selectedFuelLine, setSelectedFuelLine] = useState<number>()

  const showErrorWithTitle = (statusText: string) => (error: any) => {
    return displayErrorModal({
      statusText,
      message: error.message,
    })
  }

  const getCurrentFuelTypeSelections = useCallback(() => {
    setIsLoadingData(true)
    PerformanceAPI.getCurrentFuelTypeSelections(imoNo)
      .catch(showErrorWithTitle('Could not get current fuel type selections'))
      .then(setFuelTypeSelections)
      .finally(() => setIsLoadingData(false))
  }, [imoNo])

  useEffect(() => {
    if (imoNo) {
      getCurrentFuelTypeSelections()
    }
  }, [imoNo, getCurrentFuelTypeSelections])

  const onEntryUpdates = () => {
    if (onSelectionChange) {
      onSelectionChange()
    }

    getCurrentFuelTypeSelections()
  }

  // Fuel type selection
  const submitFuelTypeSelection = (
    fuelTypeSelection: Performance.FDL.FuelTypeSelection,
  ) => {
    if (imoNo) {
      PerformanceAPI.postFuelTypeSelection(imoNo, fuelTypeSelection)
        .then(() => {
          setShowFuelLineChangeModal(false)
          return PerformanceAPI.getCurrentFuelTypeSelections(imoNo)
            .then((selections) => {
              setFuelTypeSelections(selections)
              onEntryUpdates()
            })
            .catch(
              showErrorWithTitle('Could not get current fuel type selections'),
            )
        })
        .catch(showErrorWithTitle('Could not create fuel type selection'))
    }
  }

  const openFuelLineChangeModal = (
    fuelLine: Performance.Status.MainEngFuelLine | Performance.Status.FuelLine,
  ) => {
    const { fuelLineType } = fuelLine
    setSelectedFuelLine(fuelLineType)
    setShowFuelLineChangeModal(true)
  }

  return (
    <ContentCard
      id='fuel-consumers'
      title='Consumers'
      width='100%'
      helpTextKey='fuel-consumption/consumers'
      headerChildren={<FuelAndStockModal />}
    >
      <ContentWrapper>
        <InfoTextWrapper>
          Below you can see which fuel types are currently selected for ME, AE
          and Boiler fuel lines.
        </InfoTextWrapper>
        <ConsumerCardsContainer>
          {isLoadingData ? (
            <Loading padding='48px 0' />
          ) : (
            <FuelConsumers
              fuelTypeSelections={fuelTypeSelections}
              onClick={openFuelLineChangeModal}
            />
          )}
        </ConsumerCardsContainer>
      </ContentWrapper>
      {showFuelLineChangeModal && selectedFuelLine && (
        <FuelLineChangeModal
          currentlySelectedFuelType={fuelTypeSelections?.find(
            (selection) => selection.fuelLineType === selectedFuelLine,
          )}
          fuelTypeSelections={fuelTypeSelections}
          batches={batches}
          fuelLineType={selectedFuelLine}
          closeHandler={() => setShowFuelLineChangeModal(false)}
          submit={submitFuelTypeSelection}
        />
      )}
    </ContentCard>
  )
}
