import { useContext } from 'react'
import { useField } from 'formik'
import { McTag } from '@maersk-global/mds-react-wrapper'

import styled, { IColorScheme, IThemeInterface } from './../../../theme'
import { WindowContext } from '../../../contexts'

export type IMcTagOption = {
  label: string
  value: number
  scheme: IColorScheme
}

type Props = {
  options: Array<IMcTagOption>
  name: string
}

const transformSchemeToTheme = (s: IColorScheme): IThemeInterface => ({
  fg: s.font,
  bg: s.fill,
  border: s.stroke,
})

const Tag = styled(McTag)`
  ::part(tag) {
    min-width: auto;
    margin-right: 8px;

    background-color: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.fg};
    border: 1px solid ${(props) => props.theme.border || 'transparent'};
    opacity: ${(props) => (props.theme === 'white' ? '0.6' : '1')};
  }
`

export const McTagGroup = ({ options, name }: Props) => {
  const { windowSize } = useContext(WindowContext)

  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name)

  return options.map((option) => (
    <Tag
      key={option.value}
      fit={windowSize}
      label={option.label}
      theme={
        option.value === field.value
          ? transformSchemeToTheme(option.scheme)
          : 'white'
      }
    />
  ))
}
