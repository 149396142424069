export const vesselPages: Array<PageData> = [
  {
    name: 'Overview',
    pagePath: '/overview',
  },
  {
    name: 'Class comparison',
    pagePath: '/class-comparison',
  },
  {
    name: 'FUEL',
    pagePath: '',
  },
  {
    name: 'Fuel consumption',
    pagePath: '/fuel-consumption',
  },
  {
    name: 'Stock management',
    pagePath: '/stock-management',
  },
  {
    name: 'Lube management',
    pagePath: '/lube-oil',
  },
  {
    name: 'DATA',
    pagePath: '',
  },
  {
    name: 'Reporting',
    pagePath: '/hdc/overview',
  },
  {
    name: 'Reporting',
    pagePath: '/hdc/report',
  },
  {
    name: 'Engine test',
    pagePath: '/engine-test',
  },
  {
    name: 'Main engine test report',
    pagePath: '/engine-test/metc/:reportId',
  },
  {
    name: 'Auxiliary engine test report',
    pagePath: '/engine-test/aetc/:reportId',
  },
  {
    name: 'Malfunctioning equipment',
    pagePath: '/mfe',
  },
  {
    name: 'Malfunctioning equipment report',
    pagePath: '/mfe/report/:reportId',
  },
  {
    name: 'Counter',
    pagePath: '/counter',
  },
  {
    name: 'Sensors',
    pagePath: '/sensors',
  },
  {
    name: 'Data points',
    pagePath: '/data-points',
  },
  {
    name: 'ENERGY',
    pagePath: '',
  },
  {
    name: 'Propulsion',
    pagePath: '/propulsion',
  },
  {
    name: 'Electrical production',
    pagePath: '/electrical-production',
  },
  {
    name: 'Auxiliary engines',
    pagePath: '/auxiliary-engines',
  },
  {
    name: 'Waste heat recovery',
    pagePath: '/waste-heat-recovery',
  },
  {
    name: 'Trim',
    pagePath: `/trim`,
  },
  {
    name: 'Activity log',
    pagePath: `/activity-log`,
  },
  {
    name: 'Energy help section',
    pagePath: `/help`,
  },
]

export const helpPages: Array<PageData> = [
  {
    name: 'Energy help section',
    pagePath: '/lube-management',
  },
  {
    name: 'Energy help section',
    pagePath: '/reporting',
  },
  {
    name: 'Topics Overview',
    pagePath: '',
  },
]
