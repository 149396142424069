import * as yup from 'yup'
import moment from 'moment'

import { commaAgnostic } from '../../../../utils'

export const castingSchema = yup.object().shape({
  timestamp: yup.string().required(),
  batches: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      displayName: yup.string().required(),
      fuel: yup.object().shape({
        type: yup.number(),
        isDistillate: yup.boolean(),
        grade: yup.string(),
        bioPercentage: yup.number(),
      }),
      fuelType: yup.number().required(),
      quantity: yup.number().required(),
      rob: yup
        .mixed()
        .transform((cv: any, ov: any) =>
          typeof cv === 'string' ? parseFloat(commaAgnostic(cv)) : cv,
        )
        .required(),
      adjustment: yup.number().required(),
    }),
  ),
  notes: yup.string(),
})

const REQUIRED = 'Please input a sounding value'

export const validationSchema = yup.object().shape({
  timestamp: yup
    .string()
    .required('Timestamp must be filled out')
    .test({
      name: 'rob',
      test: function (value) {
        const context = this.options?.context as any
        const rob = context.rob
        /* First check: Only validate if rob has been fetched.
         * Make sure query timestamp and timestamp field is the same to avoid race conditions where validation is done before new rob is fetched.
         * Second case: Sounding is not allowed if calculated ROB is more than 4 hours older than selected timestamp
         * Third case: Sounding is not permitted at all if we've tried to calculate ROB, but couldn't (hasData is false).
         */
        if (rob === undefined) {
          return true
        }
        if (moment.utc(rob.queryTimestamp).isSame(moment.utc(value))) {
          if (!rob.hasData) {
            return this.createError({
              message: 'Sounding not permitted as ROB cannot be calculated',
              path: this.path,
            })
          }
          let validateAgainst = moment.utc(value).subtract(4, 'hours')
          if (moment.utc(rob?.timestamp).isBefore(validateAgainst)) {
            return this.createError({
              message:
                'Sounding not permitted as calculated ROB is more than 4 hours old.',
              path: this.path,
            })
          }
        }
        return true
      },
    }),
  hsValues: yup.array().of(
    yup.object().shape({
      rob: yup.number().nullable().required(REQUIRED),
    }),
  ),
  vlsValues: yup.array().of(
    yup.object().shape({
      rob: yup.number().nullable().required(REQUIRED),
    }),
  ),
  ulsValues: yup.array().of(
    yup.object().shape({
      rob: yup.number().nullable().required(REQUIRED),
    }),
  ),
  mdoValues: yup.array().of(
    yup.object().shape({
      rob: yup.number().nullable().required(REQUIRED),
    }),
  ),
  mmValues: yup.array().of(
    yup.object().shape({
      rob: yup.number().nullable().required(REQUIRED),
    }),
  ),
  notes: yup.string().notRequired().max(1000, 'Max. 1000 characters'),
})
