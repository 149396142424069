import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { clearQueryParams, sortDateRange, getFiltersFromQueryParams } from '..'
import {
  Wrapper,
  StyledMcNotification,
} from '../../../components/Notifications/Notifications.styles'
import { WindowContext } from '../../../contexts'

type Props = {
  onReset?: () => void
}

const FiltersNotification = ({ onReset }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const history = useHistory()
  const filters = getFiltersFromQueryParams()

  if (!filters || !filters.dateRange || !onReset) return

  const sortedFilters = sortDateRange(
    moment.utc(filters.dateRange.from),
    moment.utc(filters.dateRange.to),
  )

  const handleClearFilters = () => {
    history.replace(clearQueryParams())
    onReset()
  }

  return (
    <Wrapper>
      <StyledMcNotification
        fit={windowSize}
        heading='Filters applied'
        appearance='info'
        icon='info-circle'
      >
        You have applied a filter for period{' '}
        <b>{moment.utc(sortedFilters.from).format('DD MMM HH:mm UTC')}</b> to{' '}
        <b>{moment.utc(sortedFilters.to).format('DD MMM HH:mm UTC')}</b>. Live
        data will not be updated in the graphs until filters have been cleared.
        <span slot='actions'>
          <span className='action' onClick={handleClearFilters}>
            Clear filters
          </span>
        </span>
      </StyledMcNotification>
    </Wrapper>
  )
}

export default FiltersNotification
