import styled from 'styled-components'
import { McTag } from '@maersk-global/mds-react-wrapper'

export const TableWrapper = styled.div`
  thead {
    th {
      cursor: default;

      &.sortable {
        cursor: pointer;

        &:hover {
          background-color: ${(props) => props.theme.colors.grey[200]};
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        td.feedback {
          filter: brightness(1.04);
        }
      }
    }

    tr,
    td {
      &.feedback {
        &--danger {
          background-color: ${(props) =>
            props.theme.colors.feedback.danger.light} !important;

          &::after {
            border-bottom-color: white !important;
          }
        }

        &:hover {
          filter: brightness(1.065) !important;
        }

        &--info {
          background-color: ${(props) =>
            props.theme.colors.feedback.info.light};
        }

        > td {
          background-color: unset;
        }
      }
    }

    tr.fuel-type {
      > td {
        background-color: unset;

        &::after {
          border-bottom-color: white !important;
        }
      }

      &--hs {
        background-color: ${(props) =>
          props.theme.colors.fuelType.hs} !important;
      }

      &--vls {
        background-color: ${(props) =>
          props.theme.colors.fuelType.vls} !important;
      }

      &--uls {
        background-color: ${(props) =>
          props.theme.colors.fuelType.uls} !important;
      }

      &--mdo {
        background-color: ${(props) =>
          props.theme.colors.fuelType.mdo} !important;
      }

      &--mm {
        background-color: ${(props) =>
          props.theme.colors.fuelType.mm} !important;
      }
    }
  }

  tbody.inert > tr {
    pointer-events: none;
  }

  tbody:not(.inert) > tr.fuel-type {
    &:hover {
      filter: brightness(1.03) !important;
    }
  }

  tbody.inert > tr {
    cursor: default;
  }
`

export const SortableHeader = styled.div`
  display: flex;
  align-items: center;

  mc-icon {
    margin-left: 8px;
  }
`

export const Header = styled.div<{ icon?: boolean }>`
  text-align: ${({ icon }) => (icon ? 'center' : 'left')};
`

export const NumericHeader = styled.div<{ leftAlignText?: boolean }>`
  margin-left: auto;
  text-align: ${(props) => (props.leftAlignText ? 'left' : 'right')};
`

export const NumericCell = styled.div<{ leftAlignText?: boolean }>`
  text-align: ${(props) => (props.leftAlignText ? 'left' : 'right')};
  font-feature-settings: 'tnum' 1;
`

export const NumericTooltipCell = styled.div<{ boldFont?: boolean }>`
  width: 100%;
  text-align: right;
  font-feature-settings: 'tnum' 1;

  font-weight: ${(props) => (props.boldFont ? 700 : 400)};
  font-size: var(--mdt-brand-font--small-font-size);
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    font-size: var(--mdt-brand-font--default-font-size);
  }
`

export const ActionCellButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue[500]};
`

export const ExpanderCell = styled.div<{ isHead: boolean }>`
  display: flex;
  margin-left: -16px;

  font-weight: ${(props) => (props.isHead ? 700 : 400)};

  mc-icon {
    &.open {
      transform: rotate(90deg);
    }

    &.hidden {
      opacity: 0;
    }
  }

  span {
    margin-left: 8px;
  }

  img {
    margin-left: 8px;
    height: 24px;
    width: 24px;
  }
`

export const IconCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

export const FilterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
  padding-right: 100px;

  > div {
    gap: 16px;
  }

  .fuel-stock-entry-type-filter {
    width: 250px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 280px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      width: 310px;
    }
  }

  .tem-advice-status-filter {
    width: 127px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 142px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      width: 157px;
    }
  }

  .tem-advice-reason-filter {
    width: 310px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 350px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      width: 370px;
    }
  }

  .it-platform-filter,
  .imo-number-filter,
  .lube-entry-type-filter {
    width: 130px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 150px;
    }
  }

  .fuel-type-filter {
    width: 100px;
  }

  .vessel-class-filter,
  .sensor-group-filter {
    width: 150px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 180px;
    }
  }

  .vessel-name-filter,
  .sensor-name-filter,
  .sensor-connector-filter {
    width: 200px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 230px;
    }
  }

  .oil-type-filter {
    width: 130px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 160px;
    }
  }

  .batch-name-filter {
    width: 250px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 280px;
    }
  }

  .select-filter {
    width: 100%;
  }
`

export const ResultsCountTag = styled(McTag)`
  position: absolute;
  right: 4px;
  bottom: 8px;
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`
