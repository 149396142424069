import styled from 'styled-components'

import { type LabReport } from './MixedBatchDetails'

type Props = {
  labReport: LabReport
}

type LabReportItem = {
  label: string
  unit: string
}

const LabReportItems: Record<keyof LabReport, LabReportItem> = {
  density15: { label: 'Density 15', unit: 'kg/m³' },
  lcv: { label: 'LCV', unit: 'KJ/kg' },
  water: { label: 'Water', unit: '%vol.' },
  ash: { label: 'Ash', unit: '%mass' },
  sulphur: { label: 'Sulphur', unit: '%mass' },
  marpolSeal: { label: 'Marpol seal', unit: '' },
} as const

const LabReportTooltip = ({ labReport }: Props) => (
  <>
    <strong>Lab report</strong>
    <hr />
    {Object.entries(labReport).map(([key, value]) => (
      <TooltipContent key={key}>
        <span>{LabReportItems[key].label}</span>
        <span>
          {value} {LabReportItems[key].unit}
        </span>
      </TooltipContent>
    ))}
  </>
)

export default LabReportTooltip

const TooltipContent = styled.div`
  > span {
    display: inline-block;

    &:first-of-type {
      min-width: 104px;
    }

    &:last-of-type {
      font-feature-settings: 'tnum' 1;
    }
  }
`
