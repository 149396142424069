import { useContext, useEffect, useState } from 'react'
import { Field, type FieldProps, useFormikContext } from 'formik'
import moment from 'moment'
import {
  McButton,
  McError,
  McNotification,
} from '@maersk-global/mds-react-wrapper'

import { type LossFormValues } from './LossModal'
import BatchSelect from '../BatchSelect'
import { StockRobNotifications } from '../StockRobNotifications'
import {
  Column,
  ContentWrapper,
  QuantityInputWrapper,
  resolveNotesHeight,
} from '../styles'
import { type Option } from '../../hooks'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import { Loading } from '../../../../commons'
import {
  displayErrorModal,
  formatValue,
  FUEL_TYPE_NAMES,
  FuelType,
} from '../../../../utils'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { type Performance } from '../../../../api-models'
import * as PerformanceApi from '../../../../services/performance'
import { STOCK_LOSS_REASON_OPTIONS } from '../../../../utils/constants'
import {
  FormInputDateTime,
  FormTextArea,
  InputNumber,
  InputSelect,
} from '../../../../components/formik'

type Props = {
  disabled: boolean
  loss?: Performance.FuelOilStock.LossEntry
  onClose: () => void
  onDidTrySubmit: () => void
  batchOptions?: Array<Option>
  openWindow?: Performance.FuelOilStock.OpenWindow
  deleteHandler: () => void
}

const LossForm = ({
  disabled,
  loss,
  onClose,
  onDidTrySubmit,
  batchOptions,
  openWindow,
  deleteHandler,
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!

  const { dirty, isSubmitting, isValid, setFieldValue, values, errors } =
    useFormikContext<LossFormValues>()

  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const [currentBatchRob, setCurrentBatchRob] = useState<number | null>()
  const [lossToOptions, setLossToOptions] = useState<Array<Option>>()
  const [batchOptionsAtTimeOfLoss, setBatchOptionsAtTimeOfLoss] =
    useState<Array<Option>>()
  const [shouldShowCalculator, setShouldShowCalculator] = useState(false)

  useEffect(() => {
    if (values.timestamp) {
      PerformanceApi.getRob(imoNo, moment.utc(values.timestamp).toISOString())
        .then(setRob)
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message: 'Could not receive remaining on board at time of loss',
          }),
        )
      if (!disabled) {
        void setFieldValue('batchId', '')
        void setFieldValue('destinationBatchId', '')
        void setFieldValue('quantity', '')
        void setFieldValue('notes', '')
        void setFieldValue('reasonCode', '')
      }
    }
  }, [values.timestamp, imoNo, setFieldValue])

  // This effect is used to update the 'loss to' options based on the
  // 'loss from' options and the selected batch.
  useEffect(() => {
    if (!batchOptionsAtTimeOfLoss) {
      return
    }

    let isLossFromMethanol: boolean | undefined
    if (values.batchId) {
      const selectedFromBatch = batchOptions?.find(
        (o) => o.value === values.batchId,
      )

      isLossFromMethanol =
        selectedFromBatch?.fuelType === FUEL_TYPE_NAMES[FuelType.MM]
    }

    let newLossToOptions: Array<Option>
    if (isLossFromMethanol === true) {
      // Restrict 'loss to' options to methanol only
      newLossToOptions = batchOptionsAtTimeOfLoss.filter(
        (o) => o.fuelType === FUEL_TYPE_NAMES[FuelType.MM],
      )
      if (!disabled && values.destinationBatchId) {
        const selectedToBatch = lossToOptions?.find(
          (x) => x.value === values.destinationBatchId,
        )
        if (selectedToBatch?.fuelType !== FUEL_TYPE_NAMES[FuelType.MM]) {
          void setFieldValue('destinationBatchId', '')
        }
      }
    } else if (isLossFromMethanol === false) {
      newLossToOptions = batchOptionsAtTimeOfLoss.filter(
        (o) => o.fuelType !== FUEL_TYPE_NAMES[FuelType.MM],
      )
    } else {
      newLossToOptions = batchOptionsAtTimeOfLoss
    }

    // Disable the batch from which the loss is made
    newLossToOptions = newLossToOptions.map((o) => ({
      ...o,
      isDisabled: o.value === values.batchId,
    }))

    setLossToOptions([
      ...newLossToOptions,
      {
        label: 'Waste',
        value: 'waste',
        fuelType: '',
      },
    ])
  }, [batchOptionsAtTimeOfLoss, values.batchId])

  useEffect(() => {
    if (!batchOptions) {
      return
    }

    let newLossFromOptions: Array<Option>
    /*
     * If Stock is not in an operational state, rob returns no data.
     * Last known batches on board must then be fetched from batch endpoint
     */
    if (rob?.hasData) {
      const batchIdsAtTimeOfLoss = rob?.batchQuantities
        .filter(({ quantity }) => quantity > 0)
        .map((x) => x.batch.id)

      newLossFromOptions = batchOptions.filter(({ value }) =>
        batchIdsAtTimeOfLoss?.includes(value),
      )
    } else {
      newLossFromOptions = batchOptions
    }

    setBatchOptionsAtTimeOfLoss(newLossFromOptions)
  }, [rob, batchOptions])

  useEffect(() => {
    if (rob?.hasData && values.batchId) {
      const { batchQuantities } = rob
      const newRob = batchQuantities.find(
        (x) => x.batch.id === values.batchId,
      )?.quantity
      if (typeof newRob === 'number') {
        setCurrentBatchRob(newRob)
      } else {
        setCurrentBatchRob(null)
      }
    } else {
      setCurrentBatchRob(null)
    }
  }, [values.batchId, rob])

  if (!batchOptions || !openWindow) {
    return <Loading />
  }

  const _fit = windowSize === 'large' ? 'medium' : windowSize

  return (
    <>
      <StockRobNotifications rob={rob} />
      <McNotification
        fit={windowSize}
        appearance='info'
        icon='info-circle'
        body='Please note that sludge is automatically deducted from your consumption, so you do not need to, under normal circumstances, add it as a loss.'
        style={{ marginBottom: '16px' }}
      />
      <ContentWrapper>
        <Column>
          <FormInputDateTime
            name='timestamp'
            label='Time of loss UTC'
            min={moment.utc(openWindow.period.from)}
            max={moment.utc(openWindow.period.to)}
            passThroughMoment
            disabled={disabled}
          />
          <InputSelect
            name='reasonCode'
            label='Reason for loss'
            disabled={disabled}
            options={STOCK_LOSS_REASON_OPTIONS}
            optionswidth='auto'
          />
        </Column>
        <Column>
          <BatchSelect
            name='batchId'
            label='Loss from'
            options={batchOptionsAtTimeOfLoss}
            placeholder='Select loss from'
            disabled={disabled}
            hint={
              currentBatchRob
                ? `${formatValue(currentBatchRob, 3)} MT at time of loss`
                : undefined
            }
          />
          <BatchSelect
            name='destinationBatchId'
            label='Loss to'
            options={lossToOptions}
            placeholder='Select loss to'
            disabled={disabled}
          />
          <QuantityInputWrapper>
            <Field
              name='quantity'
              validate={(value: number | null) => {
                if (
                  value !== null &&
                  currentBatchRob &&
                  value > currentBatchRob
                ) {
                  return `Cannot exceed quantity at time of loss (${formatValue(
                    currentBatchRob,
                    3,
                  )} MT)`
                }
              }}
            >
              {(props: FieldProps) => (
                <InputNumber
                  label='Quantity'
                  name={props.field.name}
                  unit='MT'
                  disabled={disabled}
                />
              )}
            </Field>
            <div>
              <McButton
                fit={windowSize}
                hiddenlabel
                icon='calculator'
                click={() => setShouldShowCalculator(true)}
                variant='plain'
              />
              {/* This McError element aligns the McButton vertically in case
                the quantity input field has an error. */}
              <McError invalid={!!errors['quantity']} errormessage='&nbsp;' />
            </div>
          </QuantityInputWrapper>
          {shouldShowCalculator && (
            <VolumeToMassCalculator
              onCancel={() => setShouldShowCalculator(false)}
              onClose={() => setShouldShowCalculator(false)}
              onSave={(totalSum: string) => {
                let quantity: number | string
                if (totalSum === '-' || isNaN(+totalSum) || disabled) {
                  quantity = ''
                } else {
                  quantity = parseFloat(totalSum)
                }
                void setFieldValue('quantity', quantity)
                setShouldShowCalculator(false)
              }}
            />
          )}
        </Column>
        <Column>
          <FormTextArea
            id='notes'
            name='notes'
            label='Notes'
            height={resolveNotesHeight(windowSize)}
            maxlength={1000}
            disabled={disabled}
          />
        </Column>
      </ContentWrapper>
      {loss && (
        <McButton
          fit={windowSize}
          slot='primaryAction'
          label='Close'
          type='button'
          click={onClose}
        />
      )}
      {loss && !loss.readonly && (
        <McButton
          fit={_fit}
          slot='secondaryAction'
          label='Delete'
          variant='plain'
          appearance='error'
          type='button'
          disabled={isSubmitting}
          click={deleteHandler}
        />
      )}
      {!loss && (
        <>
          <McButton
            fit={_fit}
            slot='secondaryAction'
            label='Cancel'
            appearance='neutral'
            type='button'
            click={onClose}
          />
          <McButton
            fit={_fit}
            slot='primaryAction'
            label='Add new loss'
            disabled={isSubmitting || disabled || !isValid || !dirty}
            type='submit'
            click={onDidTrySubmit}
          />
        </>
      )}
    </>
  )
}

export default LossForm
