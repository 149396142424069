import React, { FC, useState, useEffect, useContext, useCallback } from 'react'
import { Performance } from '../../../../api-models'
import { VesselPageContext } from '../../../../contexts'
import { FuelType, FuelLineType } from '../../../../utils/models'
import { FuelConsumerCard } from './ConsumerCard'

interface FuelConsumersProps {
  fuelTypeSelections?: Performance.FDL.FuelTypeSelectionResponse[]
  onClick: (
    fuelLine: Performance.Status.MainEngFuelLine | Performance.Status.FuelLine,
  ) => void
}

export const FuelConsumers: FC<FuelConsumersProps> = ({
  fuelTypeSelections,
  onClick,
}) => {
  const vesselPageContext = useContext(VesselPageContext)
  const { vesselStatus, configuration } = vesselPageContext

  const [mainEngFuelLine, setMainEngFuelLine] =
    useState<Performance.Status.MainEngFuelLine>()
  const [mainEngSecondaryFuelLine, setMainSecondaryEngFuelLine] =
    useState<Performance.Status.MainEngFuelLine>()
  const [auxEngFuelLines, setAuxEngFuelLine] =
    useState<Performance.Status.FuelLine[]>()
  const [boilerFuelLines, setBoilerFuelLine] =
    useState<Performance.Status.FuelLine[]>()

  const getSelectionsForEngine = useCallback(
    (fuelLinesForEngine, consumptionForEngine) =>
      fuelLinesForEngine.map((fuelLineType) => {
        // Getting fuel type selection for current fuel line type
        const selectionForCurrentFuelLine = fuelTypeSelections?.find(
          (selection) => selection.fuelLineType === fuelLineType,
        )
        // Fuel lines AEMDO and BoilerMDO can only consume fuel type MDO and we dont show them in this component at all.
        // For all others get fuel type from current selection
        const fuelType = [FuelLineType.AEMDO, FuelLineType.BoilerMDO].includes(
          fuelLineType,
        )
          ? FuelType.MDO
          : selectionForCurrentFuelLine?.fuelType ?? -1
        // Getting consumption for current fuel line type
        const consumptionForCurrentFuelLine = consumptionForEngine?.find(
          (consumption) => consumption.fuelLineType === fuelLineType,
        )

        return {
          fuelType,
          fuelLineType,
          consumption: consumptionForCurrentFuelLine?.consumption,
        }
      }),
    [fuelTypeSelections],
  )

  useEffect(() => {
    if (configuration?.fuelLineTypes) {
      const fuelLineTypesForCurrentVessel = configuration.fuelLineTypes

      // Main engine is handled separately, because the data structure is different there
      const FUEL_LINE_TYPES_BY_ENGINE = {
        AUXILLARY: [
          FuelLineType.AEMain,
          FuelLineType.AESecondary,
          FuelLineType.AEMDO,
        ],
        BOILER: [FuelLineType.BoilerMain, FuelLineType.BoilerMDO],
      }
      // Main engine
      const selectionForCurrentFuelLine = fuelTypeSelections?.filter(
        (selection) =>
          selection.fuelLineType === FuelLineType.MEMain ||
          selection.fuelLineType === FuelLineType.MESecondary,
      )

      const consumptionForMainFuelLine =
        vesselStatus?.fuelConsumption?.mainEngFuelLines?.find(
          (fuelLine) => fuelLine.fuelLineType === FuelLineType.MEMain,
        )

      const meMainFuelLine = selectionForCurrentFuelLine?.find(
        (fuelLine) => fuelLine.fuelLineType === FuelLineType.MEMain,
      )
      const mainEngFuelLine = {
        fuelType: meMainFuelLine?.fuelType ?? -1,
        fuelLineType: FuelLineType.MEMain,
        consumption: consumptionForMainFuelLine?.consumption || null,
        consumption1: consumptionForMainFuelLine?.consumption1 || null,
        consumption2: consumptionForMainFuelLine?.consumption2 || null,
      }

      const consumptionForMESecondaryFuelLine =
        vesselStatus?.fuelConsumption?.mainEngFuelLines?.find(
          (fuelLine) => fuelLine.fuelLineType === FuelLineType.MESecondary,
        )

      const meSecondaryFuelLine = selectionForCurrentFuelLine?.find(
        (fuelLine) => fuelLine.fuelLineType === FuelLineType.MESecondary,
      )
      const mainEngSecondaryFuelLine = {
        fuelType: meSecondaryFuelLine?.fuelType ?? -1,
        fuelLineType: FuelLineType.MESecondary,
        consumption: consumptionForMESecondaryFuelLine?.consumption || null,
        consumption1: consumptionForMESecondaryFuelLine?.consumption1 || null,
        consumption2: consumptionForMESecondaryFuelLine?.consumption2 || null,
      }

      // Aux Engs
      const auxEngFuelLinesOnBoard = FUEL_LINE_TYPES_BY_ENGINE.AUXILLARY.filter(
        (fuelLine) => fuelLineTypesForCurrentVessel.includes(fuelLine),
      )

      const auxEngFuelLines = getSelectionsForEngine(
        auxEngFuelLinesOnBoard,
        vesselStatus?.fuelConsumption?.auxEngFuelLines,
      )
      // Boiler
      const boilerFuelLinesOnBoard = FUEL_LINE_TYPES_BY_ENGINE.BOILER.filter(
        (fuelLine) => fuelLineTypesForCurrentVessel.includes(fuelLine),
      )
      const boilerFuelLines = getSelectionsForEngine(
        boilerFuelLinesOnBoard,
        vesselStatus?.fuelConsumption?.boilerFuelLines,
      )

      setMainEngFuelLine(mainEngFuelLine)
      setMainSecondaryEngFuelLine(mainEngSecondaryFuelLine)
      setAuxEngFuelLine(auxEngFuelLines)
      setBoilerFuelLine(boilerFuelLines)
    }
  }, [vesselStatus, configuration, fuelTypeSelections, getSelectionsForEngine])

  return (
    <>
      {mainEngFuelLine && (
        <FuelConsumerCard
          fuelLine={mainEngFuelLine}
          hasMainEngSecondaryFuelLine={
            configuration?.hasMainEngSecondaryFuelLine ?? false
          }
          onClick={onClick}
        ></FuelConsumerCard>
      )}

      {configuration?.hasMainEngSecondaryFuelLine &&
        mainEngSecondaryFuelLine && (
          <FuelConsumerCard
            fuelLine={mainEngSecondaryFuelLine}
            hasMainEngSecondaryFuelLine={
              configuration?.hasMainEngSecondaryFuelLine ?? false
            }
            onClick={onClick}
          ></FuelConsumerCard>
        )}

      {auxEngFuelLines?.map((fuelLine) => (
        <FuelConsumerCard
          key={`fuelLine-${fuelLine.fuelLineType}`}
          hasMainEngSecondaryFuelLine={
            configuration?.hasMainEngSecondaryFuelLine ?? false
          }
          onClick={onClick}
          fuelLine={fuelLine}
        />
      ))}
      {boilerFuelLines?.map((fuelLine) => (
        <FuelConsumerCard
          key={`fuelLine-${fuelLine.fuelLineType}`}
          hasMainEngSecondaryFuelLine={
            configuration?.hasMainEngSecondaryFuelLine ?? false
          }
          onClick={onClick}
          fuelLine={fuelLine}
        />
      ))}
    </>
  )
}
