import React, { useReducer, useCallback, useEffect } from 'react'
import {
  EntryType,
  UserEntryTypes,
} from '../../features/stock-management/models'
import * as PerformanceApi from '../../services/performance'
import { IReducerState } from './stock-entry-reducer'
import { StockEntryContextReducer, StockEntryContext } from '.'
import * as Actions from './stock-entry-action-creators'
import { displayErrorModal } from '../../utils'

const DELETE_ENDPOINT_BY_USER_ENTRY_TYPE = {
  1: PerformanceApi.deleteBunkeredBatch,
  2: PerformanceApi.deleteMixedBatch,
  3: PerformanceApi.deleteRobMark,
  4: PerformanceApi.deleteSounding,
  5: PerformanceApi.deleteLoss,
  6: PerformanceApi.deleteDebunkering,
  7: PerformanceApi.deleteRobMark,
  10: PerformanceApi.deleteBatchSelection,
  20: PerformanceApi.deleteFuelTypeSelection,
  21: PerformanceApi.deleteFuelTypeSelection,
  22: PerformanceApi.deleteFuelTypeSelection,
  23: PerformanceApi.deleteFuelTypeSelection,
  24: PerformanceApi.deleteFuelTypeSelection,
  25: PerformanceApi.deleteFuelTypeSelection,
  26: PerformanceApi.deleteFuelTypeSelection,
}

const initialValues: IReducerState = {}

const StockEntryContextProvider = (props) => {
  const [state, dispatch] = useReducer(StockEntryContextReducer, initialValues)
  const imoNo = props.imoNo

  const getFuelOilUserEntries = useCallback(() => {
    return PerformanceApi.getFuelOilUserEntries(imoNo)
      .then((fuelOilUserEntries) =>
        dispatch(Actions.setFuelOilUserEntries(fuelOilUserEntries)),
      )
      .catch((e) => {
        dispatch(Actions.setFuelOilUserEntries([]))
        return Promise.reject(e)
      })
  }, [imoNo])

  const deleteEntry = useCallback(
    (userEntryTypes: UserEntryTypes, entryId: string) => {
      const deleteEndpointByType =
        DELETE_ENDPOINT_BY_USER_ENTRY_TYPE[userEntryTypes]
      if (deleteEndpointByType) {
        return deleteEndpointByType(imoNo, entryId)
          .then(getFuelOilUserEntries)
          .catch((e) =>
            displayErrorModal({
              statusText: 'Could not delete entry',
              message: e.message,
            }),
          )
      } else {
        return Promise.reject(
          new Error(`Can not delete entry of type ${userEntryTypes}`),
        )
      }
    },
    [imoNo, getFuelOilUserEntries],
  )

  const setCurrentEntry = useCallback(
    (entryType?: EntryType, entryId?: string) =>
      dispatch(Actions.setCurrentEntry(entryType, entryId)),
    [],
  )

  // Reset for vessel change (shore mode)
  useEffect(() => dispatch(Actions.setFuelOilUserEntries(undefined)), [imoNo])

  return (
    <StockEntryContext.Provider
      value={{
        imoNo,
        state,
        deleteEntry,
        setCurrentEntry,
        getFuelOilUserEntries,
      }}
    >
      {props.children}
    </StockEntryContext.Provider>
  )
}

export default StockEntryContextProvider
