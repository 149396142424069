import React, { useState } from 'react'
import { McModal, McTab, McTabBar } from '@maersk-global/mds-react-wrapper'

import GeneralOperatingConditions from './GeneralOperatingConditions'
import Cylinders from './Cylinders'
import Turbochargers from './Turbochargers'
import MainEngineEfficiency from './MainEngineEfficiency'
import TcEfficiency from './TcEfficiency'
import OperatingConditions from './OperatingConditions'
import LoadCondition from './LoadCondition'
import {
  Section,
  TabIndex,
} from '../../EngineTestReportPage/MetcReportPage/MetcReportPage.consts'
import Fuel from './Fuel'
import EnginePerformanceTestReportsTable from '../EnginePerformanceTestReportsTable/EnginePerformanceTestReportsTable'
import { useGetVesselClassMetcReports } from '../../../queries/GandalfApi/GandalfApi'
import { Loading } from '../../../commons'

type Props = {
  reportIds: Array<string> // Should only ever be of length 2
  onCompareEnd: () => void
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse
}

const EnginePerformanceTestReportsComparison = ({
  reportIds,
  onCompareEnd,
  sisterVesselsData,
}: Props) => {
  const { data: vesselClassReports, isSuccess } =
    useGetVesselClassMetcReports(reportIds)

  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.RESULTS)

  const handleTabChange = ({ detail }: CustomEvent<number>) => {
    setTabIndex(detail)
  }

  const tabItems = (reports: Array<GandalfApi.MetcReport>) => [
    {
      label: Section.GENERAL,
      content: (
        <>
          <GeneralOperatingConditions reportsCompared={reports} />
          <Fuel reportsCompared={reports} />
          <LoadCondition reportsCompared={reports} />
        </>
      ),
    },
    {
      label: Section.CYLINDERS,
      content: <Cylinders reportsCompared={reports} />,
    },
    {
      label: Section.TURBOCHARGERS,
      content: <Turbochargers reportsCompared={reports} />,
    },
    {
      label: Section.RESULTS,
      content: (
        <>
          <OperatingConditions reportsCompared={reports} />
          <MainEngineEfficiency reportsCompared={reports} />
          <TcEfficiency reportsCompared={reports} />
        </>
      ),
    },
  ]

  return (
    <McModal
      open
      heading='Engine Test Results Comparison'
      width='1240px'
      height='80vh'
      closed={onCompareEnd}
    >
      <p className='mds-table-caption'>
        Compare engine performance test results within your class of vessels.
      </p>
      {isSuccess ? (
        <McTabBar currentindex={tabIndex} tabchange={handleTabChange}>
          {tabItems(vesselClassReports).map(({ label, content }) => (
            <React.Fragment key={label}>
              <McTab slot='tab' label={label} />
              <EnginePerformanceTestReportsTable
                tabIndex={tabIndex}
                reportsCompared={vesselClassReports}
                sisterVesselsData={sisterVesselsData}
              >
                {content}
              </EnginePerformanceTestReportsTable>
            </React.Fragment>
          ))}
        </McTabBar>
      ) : (
        <Loading />
      )}
    </McModal>
  )
}

export default EnginePerformanceTestReportsComparison
