import { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import moment, { type Moment } from 'moment'
import * as yup from 'yup'
import { McModal } from '@maersk-global/mds-react-wrapper'

import DebunkeringForm from './DebunkeringForm'
import { resolveModalHeight } from './DebunkeringForm.styles'
import { Heading } from '../styles'
import { type Performance } from '../../../../api-models'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../../utils'
import { HelpText } from '../../../help-texts'

export type DebunkeringFormValues = {
  timestamp?: Moment
  batchId: string
  quantity: string
  portCode: string
  reasonCode: string
  notes: string
}

const defaultInitialValues: DebunkeringFormValues = {
  batchId: '',
  quantity: '',
  portCode: '',
  reasonCode: '',
  notes: '',
}

const REQUIRED = 'Required'

const validationSchema = yup.object().shape({
  batchId: yup.string().required(REQUIRED),
  quantity: yup.number().nullable().required(REQUIRED),
  notes: yup.string().notRequired().max(1000, 'Max. 1000 characters'),
  timestamp: yup.date().required(REQUIRED),
  reasonCode: yup.string().required(REQUIRED),
  portCode: yup.string().required(REQUIRED),
})

type Props = {
  entryId?: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const DebunkeringModal = ({ entryId, closeHandler }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!
  const [debunkering, setDebunkering] =
    useState<Performance.FuelOilStock.DebunkeringEntry>()
  const [initialValues, setInitialValues] =
    useState<DebunkeringFormValues>(defaultInitialValues)
  const disabled =
    isShoreContext() || (!!debunkering && debunkering.readonly) || !!entryId
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const [didTrySubmit, setDidTrySubmit] = useState(false)

  const submitDebunkering = (
    values: DebunkeringFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const debunkeringRequestBody: Performance.FuelOilStock.Debunkering = {
      ...values,
      timestamp: values.timestamp ? values.timestamp.toISOString() : '',
    }
    const request = debunkering
      ? PerformanceApi.putDebunkering(
          imoNo,
          debunkeringRequestBody,
          debunkering.id,
        )
      : PerformanceApi.postDebunkering(imoNo, debunkeringRequestBody)

    request
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  const deleteDebunkering = (
    debunkeringId: string,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.deleteDebunkering(imoNo, debunkeringId)
      .then(() => closeHandler(true))
      .catch((e) => {
        if (setSubmitting) setSubmitting(false)
        void displayErrorModal({
          statusText: 'Could not delete debunkering',
          message: e.message,
        })
      })
  }

  useEffect(() => {
    if (!entryId) {
      return
    }

    PerformanceApi.getDebunkering(imoNo, entryId)
      .then(setDebunkering)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get debunkering',
          message: e.message,
        }),
      )
  }, [entryId, imoNo])

  useEffect(() => {
    if (debunkering) {
      setInitialValues({
        batchId: debunkering.batchId,
        quantity: debunkering.quantity,
        portCode: debunkering.portCode,
        reasonCode: debunkering.reasonCode,
        notes: debunkering.notes,
        timestamp: moment(debunkering.timestamp),
      })
    } else {
      setInitialValues(defaultInitialValues)
    }
  }, [debunkering, imoNo])

  useEffect(() => {
    if (imoNo) {
      PerformanceApi.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time of loss interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo])

  if (entryId && !debunkering) {
    return
  }

  const _fit = windowSize === 'large' ? 'medium' : windowSize

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) =>
        submitDebunkering(values, setSubmitting)
      }
      validationSchema={validationSchema}
      validateOnChange={didTrySubmit}
      enableReinitialize
    >
      <Form>
        <McModal
          fit={_fit}
          open
          width='1040px'
          closed={() => closeHandler()}
          height={resolveModalHeight(windowSize)}
        >
          <Heading slot='heading'>
            {disabled ? 'View debunkering' : 'Add debunkering'}
            <HelpText textKey='stock/debunkering' />
          </Heading>
          <DebunkeringForm
            disabled={disabled}
            debunkering={debunkering}
            onClose={closeHandler}
            deleteHandler={deleteDebunkering}
            openWindow={openWindow}
            onDidTrySubmit={() => setDidTrySubmit(true)}
          />
        </McModal>
      </Form>
    </Formik>
  )
}
