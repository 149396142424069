import * as React from 'react'
import { yellowColorScheme } from '../../../theme'
import { ChartLegendDropdown, FancyLabel, Icon } from '../../../commons'
import { mapFuelLineTypeToName } from '../../../utils/mappers/fdl4-mappers'
import { Density15TogglesModel } from './FuelConsumptionChartWrapper'

interface LegendDropdownProps {
  density15Toggles: Density15TogglesModel
  setDensity15Toggles: Function
}

export const LegendDropdown: React.FunctionComponent<LegendDropdownProps> = ({
  density15Toggles,
  setDensity15Toggles,
}) => {
  const [showMenu, setShowMenu] = React.useState(false)
  const checkedSquare = <Icon icon={`fal fa-check-square`} />
  const square = <Icon icon={`fal fa-square`} />
  return (
    <ChartLegendDropdown>
      <FancyLabel
        colorScheme={yellowColorScheme}
        value={true}
        onChange={() => setShowMenu(!showMenu)}
        style={{
          marginBottom: 'unset',
          borderWidth: '1px',
        }}
      >
        Density 15
      </FancyLabel>
      {showMenu && (
        <div id='menu'>
          <div id='menu-options'>
            {Object.keys(density15Toggles).map((key) => {
              return (
                <div
                  key={key}
                  className='menu-items'
                  onClick={() => {
                    const newDensity15Toggles = {
                      ...density15Toggles,
                      [key]: !density15Toggles[key],
                    }
                    setDensity15Toggles(newDensity15Toggles)
                  }}
                >
                  {density15Toggles[key] ? checkedSquare : square}
                  <span>{mapFuelLineTypeToName(+key)}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </ChartLegendDropdown>
  )
}
