import * as echarts from 'echarts/core'
import type { EChartsOption, EChartsType } from 'echarts/types/dist/shared'
import { BarChart } from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import {
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_TICK,
  COLOR,
  GRID,
  MARK_LINE_STYLE,
  NAME_LOCATION,
  TEXT_STYLE,
  TOOLTIP,
  X_AXIS_NAME_GAP,
} from '../../../Common/ChartConfig'
import { formatValue } from '../../../../../utils'

echarts.use([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  UniversalTransition,
])

type SeriesConfig = {
  data: Array<number | null>
  type: 'bar' | 'line'
}

export const updateChart = (
  y1: Array<number | null>,
  y2: SeriesConfig | null,
  yAvg: number | null,
  yModel: number | null,
  seriesNames: Array<string>,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  let y1Values = y1.filter((val) => val !== null) as Array<number>
  if (y1Values.length === 0) {
    y1Values = [0, 100]
  }
  let y2Values = (y2?.data ?? []).filter((val) => val !== null) as Array<number>

  const yMin = Math.min(...y1Values, ...y2Values)
  const yMax = Math.max(...y1Values, ...y2Values)

  const yAxisMin = Math.floor(Math.max(yMin - 4, 0) / 10) * 10
  const yAxisMax = Math.ceil(Math.max(yMax, yModel ?? 0) + 1)

  chart.setOption({
    yAxis: { min: yAxisMin, max: yAxisMax },
    dataset: [
      { source: y1.map((val, i) => [i + 1, val]) },
      ...(y2 ? [{ source: y2.data.map((val, i) => [i + 1, val]) }] : []),
    ],
    series: [
      {
        color: y2 ? COLOR.primary : COLOR.secondary,
        type: 'bar',
        datasetIndex: 0,
        barWidth: y2 ? '24%' : '48%',
        name: seriesNames[0],
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: MARK_LINE_STYLE,
          label: AXIS_LABEL,
          data: [
            ...(yAvg
              ? [
                  {
                    yAxis: yAvg,
                    lineStyle: { color: y2 ? COLOR.primary : COLOR.secondary },
                    label: { formatter: 'Average' },
                  },
                ]
              : []),

            ...(yModel
              ? [
                  {
                    yAxis: yModel,
                    lineStyle: { color: '#B80012' },
                    label: { formatter: 'Shop test' },
                  },
                ]
              : []),
          ],
        },
      },
      ...(y2
        ? [
            {
              color: COLOR.secondary,
              type: y2.type,
              lineStyle: { opacity: 0 },
              symbolSize: 16,
              symbol: 'diamond',
              datasetIndex: 1,
              name: seriesNames[1],
              barWidth: '24%',
            },
          ]
        : []),
    ],
  })
}

type YAxesConfig = {
  name: string
  unit: string
}

export const initMipChart = (domId: string, yAxes: Array<YAxesConfig>) => {
  const chartDom = document.getElementById(domId)

  if (!chartDom) {
    console.error(`Chart DOM element with ID '${domId}' not found`)
    return
  }

  const options: EChartsOption = {
    grid: {
      ...GRID,
      bottom: 32,
      top: 32,
      left: 40,
    },
    legend: {
      top: 0,
      right: 120,
      textStyle: TEXT_STYLE,
      itemWidth: 16,
    },
    // @ts-ignore
    tooltip: {
      ...TOOLTIP,
      formatter: (params: any) => {
        let result = `Cylinder <strong>${params[0].axisValue}</strong>`
        params.forEach((item: any, idx: number) => {
          result += `<br/>${item.marker} ${
            item.seriesName
          }: <strong style="font-variant: tabular-nums">${formatValue(
            item.data[1],
            2,
          )} ${yAxes[idx].unit}</strong>`
        })
        return result
      },
    },
    xAxis: {
      type: 'category',
      name: 'Cylinder',
      nameLocation: NAME_LOCATION,
      nameGap: X_AXIS_NAME_GAP,
      axisLabel: AXIS_LABEL,
      axisTick: AXIS_TICK,
      nameTextStyle: AXIS_LABEL,
    },
    yAxis: [
      {
        name: `${yAxes[0].name} [${yAxes[0].unit}]`,
        nameLocation: NAME_LOCATION,
        nameGap: 32,
        nameTextStyle: AXIS_LABEL,
        axisLabel: AXIS_LABEL,
        axisLine: AXIS_LINE,
        splitLine: { show: false },
      },
      {
        name: `${yAxes[2].name} [${yAxes[2].unit}]`,
        nameLocation: NAME_LOCATION,
        nameGap: 32,
        nameTextStyle: AXIS_LABEL,
        axisLabel: AXIS_LABEL,
        axisLine: AXIS_LINE,
        splitLine: { show: false },
      },
    ],
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)

  return chart
}

export const updateMipChart = (
  y1: Array<number | null>,
  y2: Array<number | null>,
  yAvg: number | null,
  seriesNames: Array<string>,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  let y1Values = y1.filter((val) => val !== null) as Array<number>
  if (y1Values.length === 0) {
    y1Values = [0, 100]
  }
  const y1Min = Math.min(...y1Values)
  const y1Max = Math.max(...y1Values)
  const y1AxisMin = Math.floor(Math.max(y1Min - 0.5, 0))
  const y1AxisMax = Math.ceil(Math.max(y1Max, 0) + 0.5)

  let y2Values = y2.filter((val) => val !== null) as Array<number>
  if (y2Values.length === 0) {
    y2Values = [y1Min, y1Max]
  }
  const y2Min = Math.min(...y2Values)
  const y2Max = Math.max(...y2Values)
  const y2AxisMin = Math.floor(Math.max(y2Min - 0.5, 0))
  const y2AxisMax = Math.ceil(Math.max(y2Max, 0) + 0.5)

  chart.setOption({
    yAxis: [
      { min: y1AxisMin, max: y1AxisMax },
      { min: y2AxisMin, max: y2AxisMax },
    ],
    dataset: [
      { source: y1.map((val, i) => [i + 1, val]) },
      { source: y1.map((_, i) => [i + 1, yAvg]) },
      { source: y2.map((val, i) => [i + 1, val]) },
    ],
    series: [
      {
        type: 'bar',
        datasetIndex: 0,
        name: seriesNames[0],
        color: COLOR.primary,
        barWidth: '48%',
      },
      {
        type: 'line',
        datasetIndex: 1,
        name: `${seriesNames[0]} avg.`,
        color: COLOR.primary,
        lineStyle: {
          type: 'dashed',
          dashOffset: 2,
        },
        itemStyle: {
          opacity: 0,
        },
      },
      {
        type: 'line',
        datasetIndex: 2,
        yAxisIndex: 1,
        name: seriesNames[1],
        color: COLOR.secondary,
        lineStyle: { opacity: 0 },
        symbolSize: 16,
        symbol: 'diamond',
      },
    ],
  })
}
