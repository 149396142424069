import { useContext, useEffect, useState } from 'react'
import {
  Field,
  FieldArray,
  type FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik'
import moment, { type Moment } from 'moment'
import { McButton, McError, McModal } from '@maersk-global/mds-react-wrapper'

import {
  BatchFieldWrapper,
  BatchGroup,
  resolveModalHeight,
  resolveModalWidth,
} from './styles'
import { validateMixedFuelTypes, validationSchema } from './validation-schemas'
import { CurrentBatchRobUpdater } from './hook-components/CurrentBatchRobUpdater'
import { VolumeToMassComponent } from './VolumeToMassComponent'
import { OpenWindowUpdater } from './hook-components/OpenWindowUpdater'
import { RobUpdater } from './hook-components/RobUpdater'
import MixWarning from './MixWarning'
import BatchSelect from '../BatchSelect'
import { StockRobNotifications } from '../StockRobNotifications'
import { Column, ContentWrapper, Heading, resolveNotesHeight } from '../styles'
import { type Option, useBatchOptions } from '../../hooks'
import { Loading } from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import {
  displayErrorModal,
  formatValue,
  isShoreContext,
} from '../../../../utils'
import { HelpText } from '../../../help-texts'
import {
  FormInputDateTime,
  FormTextArea,
  InputNumber,
} from '../../../../components/formik'

export type MixedBatch = {
  id: string
  quantity?: number
  currentRob?: number
  fuel?: Performance.FuelOilStock.Fuel
}

type FormValues = {
  timestamp: Moment | undefined
  mixedBatches: Array<MixedBatch>
  notes: string
}

const defaultInitialValues: FormValues = {
  timestamp: undefined,
  mixedBatches: [
    { id: '', quantity: undefined, currentRob: undefined },
    { id: '', quantity: undefined, currentRob: undefined },
  ],
  notes: '',
}

type Props = {
  closeHandler: (refresh?: boolean) => void
}

const MixedBatchForm = ({ closeHandler }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!

  const [didTrySubmit, setDidTrySubmit] = useState(false)
  const [shouldShowCalculator, setShouldShowCalculator] = useState(false)
  const [mixedBatchName, setMixedBatchName] = useState<string>()
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const [batchOptionsAtTimeSelected, setBatchOptionsAtTimeSelected] =
    useState<Array<Option>>()
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  const batchOptions = useBatchOptions(imoNo, true)

  useEffect(() => {
    /*
     * If Stock is not in an operational state, ROB returns no data.
     * Last known batches on board must then be fetched from batch endpoint
     */
    if (rob && rob.hasData) {
      const batchIdsAtTimeSelected = rob?.batchQuantities
        .filter(({ quantity }) => quantity > 0)
        .map((x) => x.batch.id)
      setBatchOptionsAtTimeSelected(
        batchOptions?.filter(({ value }) =>
          batchIdsAtTimeSelected?.includes(value),
        ),
      )
    } else {
      /* When ROB can't be calculated show the batches currently on board */
      setBatchOptionsAtTimeSelected(batchOptions)
    }
  }, [rob, batchOptions])

  const createNewMixedBatch = (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)

    const requestBody: Performance.FuelOilStock.MixedBatchEntryRequest = {
      timestamp: values.timestamp?.toISOString() ?? '',
      batches: values.mixedBatches.map((mixedBatch) => ({
        id: mixedBatch.id,
        quantity: mixedBatch.quantity || 0,
      })),
      notes: values.notes,
    }
    PerformanceApi.postMixedBatch(imoNo, requestBody)
      .then(() => closeHandler(true))
      .catch((e) => {
        void displayErrorModal({
          statusText: 'Could not submit mixed batch',
          message: e.message,
        })
      })
      .finally(() => setSubmitting(false))
  }

  if (!openWindow) {
    return (
      <>
        {/* The OpenWindowUpdater fetches the time interval when Stock changes are allowed to determine available dates in the datepicker */}
        <OpenWindowUpdater setOpenWindow={setOpenWindow} />
        <Loading />
      </>
    )
  }

  return (
    <Formik
      initialValues={defaultInitialValues}
      onSubmit={(values, { setSubmitting }) =>
        createNewMixedBatch(values, setSubmitting)
      }
      validationSchema={validationSchema}
      validateOnChange={didTrySubmit}
    >
      {({
        values,
        errors,
        isSubmitting,
        isValidating,
        isValid,
        dirty,
      }: FormikProps<FormValues>) => {
        const submitIsDisabled =
          isShoreContext() || isSubmitting || isValidating || !isValid || !dirty

        return (
          <Form>
            {/* The RobUpdater refreshes ROB for all the batches when timestamp is selected/changed
             * CurrentBatchRobUpdater sets the current ROB for the batch the user selected to be displayed next to the selector
             */}
            <RobUpdater setRob={setRob} />
            <CurrentBatchRobUpdater rob={rob} />

            <McModal
              fit={windowSize === 'large' ? 'medium' : windowSize}
              open
              width={resolveModalWidth(windowSize)}
              height={resolveModalHeight(
                values.mixedBatches.length,
                windowSize,
              )}
              closed={() => closeHandler()}
            >
              <Heading slot='heading'>
                Create mixed batch
                <HelpText textKey='stock/add-mixed-batch' />
              </Heading>
              <StockRobNotifications rob={rob} />
              <MixWarning name='mixedBatches' />
              <ContentWrapper>
                <Column>
                  <FormInputDateTime
                    name='timestamp'
                    label='Time of mixing UTC'
                    min={moment.utc(openWindow.period.from)}
                    max={moment.utc(openWindow.period.to)}
                    passThroughMoment
                  />
                </Column>
                <Column>
                  <FieldArray
                    name='mixedBatches'
                    render={(arrayHelpers) => (
                      <>
                        {values.mixedBatches &&
                          values.mixedBatches.length > 0 &&
                          values.mixedBatches.map((mixedBatch, idx) => (
                            <BatchGroup key={`mixedBatches-${idx}`}>
                              <BatchFieldWrapper>
                                <Field
                                  name={`mixedBatches.${idx}.id`}
                                  validate={() =>
                                    validateMixedFuelTypes(
                                      mixedBatch,
                                      values.mixedBatches,
                                    )
                                  }
                                >
                                  {(props: FieldProps) => (
                                    <BatchSelect
                                      name={props.field.name}
                                      label={`Batch ${idx + 1}`}
                                      options={batchOptionsAtTimeSelected}
                                      optionswidth='auto'
                                      placeholder='Select batch to mix'
                                      hint={
                                        typeof mixedBatch.currentRob ===
                                        'number'
                                          ? `${formatValue(
                                              mixedBatch.currentRob,
                                              3,
                                            )} MT at time of mixing`
                                          : undefined
                                      }
                                    />
                                  )}
                                </Field>
                                <div>
                                  <McButton
                                    fit={windowSize}
                                    hiddenlabel
                                    variant='plain'
                                    icon='trash'
                                    appearance='error'
                                    disabled={values.mixedBatches.length <= 2}
                                    click={() => {
                                      if (values.mixedBatches.length <= 2) {
                                        return
                                      }
                                      arrayHelpers.remove(idx)
                                    }}
                                  />
                                  {/* This McError element aligns the McButton vertically in case the batch select field has an error or hint. */}
                                  <McError
                                    invalid={
                                      !!errors.mixedBatches?.[idx]?.['id'] ||
                                      typeof mixedBatch.currentRob === 'number'
                                    }
                                    errormessage='&nbsp;'
                                  />
                                </div>
                              </BatchFieldWrapper>
                              <BatchFieldWrapper>
                                <InputNumber
                                  label='Quantity'
                                  name={`mixedBatches[${idx}].quantity`}
                                  unit='MT'
                                />
                                <div>
                                  <McButton
                                    fit={windowSize}
                                    hiddenlabel
                                    variant='plain'
                                    icon='calculator'
                                    click={() => {
                                      setMixedBatchName(`mixedBatches[${idx}]`)
                                      setShouldShowCalculator(true)
                                    }}
                                  />
                                  {/* This McError element aligns the McButton vertically in case the quantity input field has an error. */}
                                  <McError
                                    invalid={
                                      !!errors.mixedBatches?.[idx]?.['quantity']
                                    }
                                    errormessage='&nbsp;'
                                  />
                                </div>
                              </BatchFieldWrapper>
                            </BatchGroup>
                          ))}
                        <VolumeToMassComponent
                          shouldShowCalculator={shouldShowCalculator}
                          mixedBatchName={`${mixedBatchName}.quantity`}
                          closeHandler={() => {
                            setMixedBatchName(undefined)
                            setShouldShowCalculator(false)
                          }}
                        />
                        {/* User can max mix up to 4 different batches */}
                        {values.mixedBatches.length < 4 && (
                          <McButton
                            fit={windowSize}
                            label='Add batch'
                            appearance='primary'
                            icon='plus'
                            type='button'
                            click={() => {
                              arrayHelpers.push({
                                id: '',
                                quantity: undefined,
                                currentRob: undefined,
                              })
                            }}
                          />
                        )}
                      </>
                    )}
                  />
                </Column>
                <Column>
                  <FormTextArea
                    id='notes'
                    name='notes'
                    label='Notes'
                    height={resolveNotesHeight(windowSize)}
                    maxlength={1000}
                  />
                </Column>
              </ContentWrapper>
              <McButton
                fit={windowSize}
                slot='secondaryAction'
                label='Cancel'
                appearance='neutral'
                click={() => closeHandler()}
                type='button'
              />
              <McButton
                fit={windowSize}
                slot='primaryAction'
                label='Create new mixed batch'
                appearance='primary'
                disabled={submitIsDisabled}
                type='submit'
                click={() => setDidTrySubmit(true)}
              />
            </McModal>
          </Form>
        )
      }}
    </Formik>
  )
}

export default MixedBatchForm
