import { useContext, useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { utc } from 'moment'

import {
  ReportState,
  ReportType,
} from '../../queries/GandalfApi/GandalfApi.consts'
import { useGetReports } from '../../queries/GandalfApi/GandalfApi'
import {
  getCounterDataPointDifference,
  keys,
} from '../../queries/PerformanceApi/Papi'
import { DataPointIds } from '../../queries/PerformanceApi/Papi.consts'
import { VesselPageContext } from '../../contexts'

const STALE_TIME_1_HOUR = 1000 * 60 * 60

type AuxEngInstanceTestElapse = {
  elapsedRunningHoursSinceLastTest: number | null
  elapsedDaysSinceLastTest: number
  instanceNo: number
}

/**
 * Finds the latest submitted engine performance test report from the given
 * array of reports.
 *
 * @param reports - The array of engine performance test reports to process.
 * @returns The latest submitted report or `undefined` if none exists.
 */
const getLatestSubmittedReport = <T extends GandalfApi.EngineTestReport>(
  reports: Array<T>,
) => {
  const submittedReports = reports
    .filter((r) => r.reportState === ReportState.Submitted)
    .sort((a, b) => utc(b.startTimestamp).diff(utc(a.startTimestamp)))

  if (submittedReports.length === 0) {
    return
  }

  return submittedReports[0]
}

/**
 * Determines if the given array of METC reports gives rise to a warning or an
 * alert and returns the corresponding message.
 *
 * @param reports - The array of METC reports to process.
 * @returns Returns a warning or alert message if the last submitted report is
 * older than 25 or 30 days respectively. Otherwise, returns `undefined`.
 */
const getMetcNotification = (reports: Array<GandalfApi.MetcReport>) => {
  const latestReport = getLatestSubmittedReport(reports)

  if (!latestReport) {
    return
  }

  const elapsedDays = utc().diff(utc(latestReport.startTimestamp), 'days')

  if (elapsedDays < 25) {
    return
  }

  let msg = `A main engine test has not been submitted the past ${elapsedDays} days. Please plan for the next test when time and conditions permit.`

  return elapsedDays >= 30 ? { alert: msg } : { warning: msg }
}

/**
 * Arranges AETC reports into groups based on instance number.
 *
 * @param reports - The array of reports to process.
 * @returns A record of AETC reports grouped by instance number.
 */
const getAetcReportsByInstance = (reports: Array<GandalfApi.AetcReport>) => {
  return reports.reduce(
    (acc: Record<number, Array<GandalfApi.AetcReport>>, report) => {
      const instanceNo = report.data.equipment.instanceNo
      if (!acc[instanceNo]) {
        acc[instanceNo] = [report]
      } else {
        acc[instanceNo].push(report)
      }

      return acc
    },
    {},
  )
}

/**
 * Determines if the given auxiliary engine test elapse gives rise to an alert
 * and returns the corresponding message.
 *
 * @param test - An instance of {@link AuxEngInstanceTestElapse}
 * @returns An alert if:
 * - The last submitted AETC report is older than 90 days.
 * - The running hours of the auxiliary engine has increased by more than 1500
 *  hours since the last performance test.
 * - Otherwise, returns `undefined`.
 */
const getAetcNotification = (test: AuxEngInstanceTestElapse) => {
  const prompt =
    'Please plan for the next test when time and conditions permit.'

  if (
    test.elapsedRunningHoursSinceLastTest !== null &&
    test.elapsedRunningHoursSinceLastTest > 1500
  ) {
    return {
      alert: `The running hours of auxiliary engine #${test.instanceNo} has increased by ${test.elapsedRunningHoursSinceLastTest} hours since the last performance test. ${prompt}`,
    }
  }

  if (test.elapsedDaysSinceLastTest > 90) {
    return {
      alert: `A performance test for auxiliary engine #${test.instanceNo} has not been submitted the past ${test.elapsedDaysSinceLastTest} days. ${prompt}`,
    }
  }
}

/**
 * Arranges AETC notifications across all auxiliary engines into a simple array
 * of alerts.
 *
 * @param tests - An array of {@link AuxEngInstanceTestElapse}.
 * @returns An array of alerts.
 */
const getAetcNotifications = (tests: Array<AuxEngInstanceTestElapse>) => {
  const alerts: Array<string> = []
  for (const test of tests) {
    const notification = getAetcNotification(test)

    if (!notification) {
      continue
    }

    alerts.push(notification.alert)
  }

  return alerts
}

/**
 * Hook to fetch and memoize METC report messages based on IMO number.
 *
 * @returns The memoized notification object containing either a warning or an
 * alert.
 */
export const useMetcNotification = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const getReports = useGetReports<GandalfApi.Metc.ReportData>(
    imoNo,
    ReportType.Metc,
  )

  return useMemo(() => {
    if (!getReports.isSuccess) {
      return
    }
    return getMetcNotification(getReports.data.reports)
  }, [getReports.data])
}

/**
 * Hook to fetch and memoize AETC report messages based on IMO number.
 *
 * @returns The memoized array of alerts.
 */
export const useAetcNotifications = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const getReports = useGetReports<GandalfApi.Aetc.ReportData>(
    imoNo,
    ReportType.Aetc,
  )

  const reportsByInstance = getReports.isSuccess
    ? getAetcReportsByInstance(getReports.data.reports)
    : {}

  let latestSubmittedReportsByInstance: Array<GandalfApi.AetcReport> = []
  for (const reports of Object.values(reportsByInstance)) {
    const latestReport = getLatestSubmittedReport(reports)
    if (latestReport) {
      latestSubmittedReportsByInstance.push(latestReport)
    }
  }

  // Execute (in parallel) queries to get the running hour differences between
  // the last submitted report and now. Cache the results for 1 hour.
  const runningHourDifferenceQueries = useQueries({
    queries: latestSubmittedReportsByInstance.map((report) => {
      const instanceNo = report.data.equipment.instanceNo
      const reportStart = utc(report.startTimestamp)
        .startOf('hour')
        .toISOString()
      const now = utc().startOf('hour').toISOString()
      const elapsedDays = utc().diff(utc(report.startTimestamp), 'days')

      return {
        queryKey: keys.counterDataPointDifference(
          imoNo,
          reportStart,
          now,
          DataPointIds.Ae[instanceNo].RunningHours,
        ),
        queryFn: getCounterDataPointDifference,
        staleTime: STALE_TIME_1_HOUR,
        // Map the response to a more usable format for this context.
        select: (
          data: Papi.DataPointDifference.Response,
        ): AuxEngInstanceTestElapse => ({
          elapsedRunningHoursSinceLastTest: data.datapointDifference,
          elapsedDaysSinceLastTest: elapsedDays,
          instanceNo: instanceNo,
        }),
      }
    }),
  })

  return useMemo(() => {
    if (
      !getReports.isSuccess ||
      !runningHourDifferenceQueries.every((q) => q.isSuccess)
    ) {
      return
    }

    const runningHourDifferences = runningHourDifferenceQueries.map(
      (q) => q.data!,
    )

    return getAetcNotifications(runningHourDifferences)
  }, [getReports.data, runningHourDifferenceQueries.every((q) => q.isSuccess)])
}
