import * as moment from 'moment'

export type ValidatorValue = string | undefined | null | number | moment.Moment

export function formatValue(
  value: number | string | null | undefined,
  decimals: number,
  defaultValue: string = '-',
): string {
  // Parse value to a number type
  const number = parseFloat(`${value}`)

  // Return defaultValue for anything that doesn't pass the number check
  if (isNaN(number)) {
    return defaultValue
  }

  // No need to go further if the input is the requested output
  if (numberOfDecimals(number) === decimals) {
    return '' + number
  }

  if (isFinite(number)) {
    // toFixed does not round so we need to do that...
    const m = Math.pow(10, decimals)
    const roundedValue = Math.round(number * m) / m
    return roundedValue.toFixed(decimals)
  }
  return defaultValue
}

/**
 * Formats a number with a specified number of decimal places and a unit.
 *
 * @param number - The number to format. Can be `number` or `null`.
 * @param decimalPlaces - The number of decimal places to include in the formatted number.
 * @param unit - The unit to append to the formatted number.
 * @returns The formatted number with the specified unit, or '-' if the number is `null`.
 */
export function formatNumberWithUnit(
  number: number | null,
  decimalPlaces: number,
  unit: string,
): string {
  if (number === null) {
    return '-'
  }

  const factor = Math.pow(10, decimalPlaces)
  const roundedNumber = Math.round((number + Number.EPSILON) * factor) / factor

  return `${roundedNumber.toFixed(decimalPlaces)} ${unit}`
}

export function roundNumber(number: number, decimals: number) {
  const m = Math.pow(10, decimals)
  const roundedValue = Math.round((number + Number.EPSILON) * m) / m
  return parseFloat(roundedValue.toFixed(decimals))
}

export const isNumber = (value: ValidatorValue): value is number => {
  return !isNaN(parseFloat(`${value}`)) && isFinite(parseFloat(`${value}`))
}

export const kiloConverter = (value: number | null) =>
  value === null ? null : value * 1000

export const getXPercentageOfNumber: (
  number: number,
  percentage: number,
) => number = (number, percentage) => (percentage / 100) * number

/**
 * Safely calculates the percentage of a value relative to a total.
 *
 * @param value - The value to calculate the percentage for. Can be null.
 * @param total - The total value to calculate the percentage against. Can be null.
 * @returns The calculated percentage, or undefined if the input is invalid.
 */
export const calcPercentageSafely = (
  value: number | null,
  total: number | null,
): number | undefined => {
  if (value === null || total === null || total === 0) {
    return undefined
  }
  return (value / total) * 100
}

/**
 * Converts a given numeric value to a percentage. If the value is null or
 * undefined, it is simply passed through, leaving it to the caller to handle
 * such cases.
 *
 * @param value - The value to convert. Can be `number`, `null`, or `undefined`.
 * @returns The value multiplied by 100 if it is a number, otherwise null.
 */
export const toPercentage = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return null
  }

  return value * 100
}

export const findDialAngle: (value: number, maxValue: number) => number = (
  value,
  maxValue,
) => {
  let percentage = calcPercentageSafely(value, maxValue)!
  if (percentage > 100) {
    percentage = 100
  }
  return 180 - 180 * (percentage / 100)
}

export const roundToClosestTen: (value: number | string) => number = (value) =>
  Math.floor(parseFloat(`${value}`) / 10) * 10

export const defaultSortMethod: (
  a: string | null | number | undefined,
  b: string | null | number | undefined,
  desc: boolean,
) => -1 | 0 | 1 = (a, b, desc) => {
  // force null and undefined to the bottom
  const a1 = a === null || a === undefined ? null : a
  const b1 = b === null || b === undefined ? null : b

  const a2 = typeof a1 === 'string' ? a1.toLowerCase() : a1
  const b2 = typeof b1 === 'string' ? b1.toLowerCase() : b1
  if (a2 === null) {
    return 1
  } else if (b2 === null) {
    return -1
  } else if (a2 === b2) {
    return 0
  } else if (!desc) {
    return a2 < b2 ? -1 : 1
  } else if (desc) {
    return a2 < b2 ? 1 : -1
  } else {
    return 0
  }
}

export const diff: (a: number, b: number) => number = (a, b) => Math.abs(a - b)

export const numberOfDecimals = (value: number) => {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0
  }
  return 0
}

/**
 * Calculates the sum of a vector (array) of numbers, treating `null` values as zero.
 *
 * @param vector - An array of `number` or `null` values.
 * @returns The sum of the numbers in the vector, treating `null` values as zero.
 */
export const calcVectorSum = (vector: Array<number | null>): number | null => {
  return vector.reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
}

/**
 * Performs linear interpolation to estimate a value `y` at a given point `x`
 * based on two known points `(x1, y1)` and `(x2, y2)`.
 *
 * @param x1 - The x-coordinate of the first known point.
 * @param y1 - The y-coordinate of the first known point.
 * @param x2 - The x-coordinate of the second known point.
 * @param y2 - The y-coordinate of the second known point.
 * @param x  - The x-coordinate at which to estimate the y value.
 * @returns The estimated y value at the given x-coordinate if `x` lies within
 * the range of `x1` and `x2`, otherwise `undefined`.
 */
export const linearInterpolate = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x: number,
): number | undefined => {
  if (x < Math.min(x1, x2) || x > Math.max(x1, x2)) {
    return undefined
  }

  return y1 + (x - x1) * ((y2 - y1) / (x2 - x1))
}

/**
 * Finds the two adjacent points in a coordinate system given an x value.
 *
 * @param xValues - Array of all x values.
 * @param yValues - Array of all y values.
 * @param x - The x value to find the adjacent points for.
 * @returns A tuple of the two adjacent points `[x1, y1, x2, y2]` or `undefined`
 * if not found.
 */
export const findAdjacentPoints = (
  xValues: Array<number>,
  yValues: Array<number>,
  x: number,
): [number, number, number, number] | undefined => {
  if (xValues.length !== yValues.length || xValues.length < 2) {
    throw new Error(
      'Invalid input arrays. xValues and yValues must have the same length and at least 2 elements.',
    )
  }

  for (let i = 1; i < xValues.length; i++) {
    if (xValues[i - 1] <= x && x <= xValues[i]) {
      const x1 = xValues[i - 1]
      const y1 = yValues[i - 1]
      const x2 = xValues[i]
      const y2 = yValues[i]
      return [x1, y1, x2, y2]
    }
  }

  return undefined
}
