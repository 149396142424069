import {
  useAetcNotifications,
  useMetcNotification,
} from './EnginePerformanceTestNotifications.utils'
import Notifications from '../Notifications/Notifications'
import { isShoreContext } from '../../utils'

const EnginePerformanceTestNotifications = () => {
  return (
    <>
      <MetcNotification />
      {!isShoreContext() && <AetcNotifications />}
    </>
  )
}

const MetcNotification = () => {
  const metcNotification = useMetcNotification()

  if (!metcNotification) {
    return null
  }

  return (
    <Notifications
      {...(metcNotification.warning && {
        warnings: [metcNotification.warning],
      })}
      {...(metcNotification.alert && {
        alerts: [metcNotification.alert],
      })}
    />
  )
}

const AetcNotifications = () => {
  const aetcNotifications = useAetcNotifications()

  if (!aetcNotifications) {
    return null
  }

  return <Notifications alerts={aetcNotifications} />
}

export default EnginePerformanceTestNotifications
