import styled from 'styled-components'

import { FuelTypeQuantity } from './FuelTypeQuantity'
import { type StockOnBoard } from '../../models'
import {
  batchQuantitiesByDisplayOrder,
  fuelTypeByDisplayOrder,
  getBatchesFromFuelType,
} from '../../utils'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

type Props = {
  stockOnBoard?: StockOnBoard
}

export const FuelTypeBatches = ({ stockOnBoard }: Props) => (
  <Wrapper data-e2e='batches'>
    {stockOnBoard?.fuelTypeQuantities
      .filter(({ quantity }) => Boolean(quantity))
      .sort(fuelTypeByDisplayOrder)
      .map(({ fuelType }) => (
        <FuelTypeQuantity
          key={`fuel-type-${fuelType}`}
          fuelType={fuelType}
          fuelTypeBatches={getBatchesFromFuelType(
            stockOnBoard.batchQuantities.sort(batchQuantitiesByDisplayOrder),
            fuelType,
          )}
        />
      ))}
  </Wrapper>
)
