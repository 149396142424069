import { useContext } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'
import { DataPoint } from '../../../queries/GandalfApi/GandalfApi.utils'

type Props = {
  name: string
  label: string
  onChange?: () => void
}

const Checkbox = ({ name, label, onChange }: Props) => {
  const { windowSize } = useContext(WindowContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<DataPoint<boolean>>(name)

  const dataPoint = field.value
  const { externalSource: extSrc } = dataPoint

  const handleChange = async (event: CustomEvent<boolean>) => {
    const target = event.target as HTMLInputElement
    const newValue = target.checked

    dataPoint.value = newValue
    if (extSrc) {
      dataPoint.isOverridden = newValue !== extSrc.value
    }

    await helpers.setValue(dataPoint)

    // Call the onChange callback if it is present.
    onChange?.()
  }

  return (
    <Wrapper>
      <McCheckbox
        fit={windowSize}
        name={name}
        label={label}
        checked={dataPoint.value ?? undefined}
        change={handleChange}
      />
    </Wrapper>
  )
}

export default Checkbox

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
