import { BunkeringFormValues } from './BunkeringForm.types'

export const defaultInitialValues: BunkeringFormValues = {
  timestamp: undefined,
  fuelType: 0,
  isDistillate: false,
  orderId: '',
  portCode: '',
  fuelGrade: '',
  displayName: '',
  quantityOrdered: '',
  quantityAgreed: '',
  quantityPerChiefEngineer: '',
  marpolSeal: '',
  density15: '',
  lcv: '',
  water: '',
  ash: '',
  sulphur: '',
  bioPercentage: 0,
  notes: '',
}
