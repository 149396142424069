import { useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'

import { Nav } from './TopNav.styles'
import VesselOnly from './VesselOnly'
import { Dropdown } from '../../commons'
import {
  getAppBuildNumber,
  getAppVersion,
  getShortAppVersion,
  hasRole,
  isShoreContext,
  isVesselContext,
  isVesselPage,
  ROLES,
  signOut,
} from '../../utils'
import { Toolbox } from '../Toolbox'
import UtcTime from '../UtcTime'

const TopNav = () => {
  const location = useLocation()
  const params = useParams()
  // @ts-ignore
  const imoNo = params.vesselId as string

  const [showDataExportModal, setShowDataExportModal] = useState(false)
  const [showVesselSettings, setShowVesselSettings] = useState(false)

  const showExport =
    (isShoreContext() && hasRole(ROLES.SUPER_USER)) || isVesselContext()

  return (
    <Nav>
      <header
        className='logo bar-item'
        title={`Version: ${getAppVersion()}\nBuild: ${getAppBuildNumber()}`}
      >
        <NavLink
          to={
            isShoreContext()
              ? '/MaerskStarConnect/shore/vessel-overview'
              : `/MaerskStarConnect/vessel/${imoNo}/overview`
          }
        >
          <div className='mds-headline--x-small'>
            StarConnect<span className='vl'></span>
            <b>Vessel Energy</b>
            <sup>v{getShortAppVersion()}</sup>
          </div>
        </NavLink>
      </header>

      <div className='utc-time'>
        <UtcTime id='shote-utc-time' />
      </div>

      <ul className='bar-item toolpane'>
        {imoNo && (
          <li>
            <NavLink to={`/MaerskStarConnect/vessel/${imoNo}/activity-log`}>
              Activity log
            </NavLink>
          </li>
        )}
        <li id='toolbox-help'>
          <NavLink
            id='topnav-help-link'
            to={
              imoNo
                ? `/MaerskStarConnect/vessel/${imoNo}/help`
                : '/MaerskStarConnect/help'
            }
          >
            Help
          </NavLink>
        </li>
        <li id='toolbox-list'>
          <Toolbox
            imoNo={imoNo}
            isVesselSpecificPage={
              !isShoreContext() || isVesselPage(location.pathname)
            }
          />
        </li>

        <li id='toolpane-list'>
          <Dropdown triggerChildren='Settings' menuPosition={{ right: '0px' }}>
            {showExport &&
              (!isShoreContext() || isVesselPage(location.pathname)) && (
                <li
                  id='exportdata-link'
                  onClick={() => setShowDataExportModal(true)}
                >
                  Export data
                </li>
              )}
            {(!isShoreContext() || isVesselPage(location.pathname)) && (
              <li
                id='vessel-settings-link'
                onClick={() => setShowVesselSettings(true)}
              >
                Vessel settings
              </li>
            )}
            {isShoreContext() && (
              <li id='logout-link' onClick={signOut}>
                Log out
              </li>
            )}
          </Dropdown>
        </li>
      </ul>
      {imoNo && (
        <VesselOnly
          imoNo={imoNo}
          showDataExportModal={showDataExportModal}
          setShowDataExportModal={setShowDataExportModal}
          showVesselSettings={showVesselSettings}
          setShowVesselSettings={setShowVesselSettings}
        />
      )}
    </Nav>
  )
}

export default TopNav
