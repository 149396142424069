import { type Step } from 'react-joyride'
import styled from 'styled-components'

import { FUEL_TYPE_NAMES, FuelType } from '../../../../../utils'

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
`

/**
 * Note on z-index
 * the commons modal component is useing z-index 100001,
 * for obvious reasons the step options should be 100002 or higher.
 */
const defaultStepProps: Partial<Step> = {
  spotlightPadding: 0,
  disableOverlayClose: true,
  disableScrolling: true,
  disableScrollParentFix: true,
  disableBeacon: true,
  styles: {
    options: {
      zIndex: 100002,
    },
  },
}

const BatchTabelSteps: Step[] = [
  {
    target: '.entry-type',
    title: 'Edit a batch',
    content: (
      <>
        <Paragraph>
          To view or edit the details of a specific batch, click on the batch
          name in this table.
        </Paragraph>
        <Paragraph>
          If it is a new bunkering and you do not have the lab result yet, you
          can fill this in later.
        </Paragraph>
      </>
    ),
    ...defaultStepProps,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.joyride-input-rob',
    title: 'Update remaining on board',
    content: (
      <>
        <Paragraph>
          Enter the remaining quantity for the bunkered batches that are still
          onboard
        </Paragraph>
        <Paragraph>
          If you have bunkered more batches into the same tank please give the
          best guestimate for the individual batches and just make sure the
          total matches. After this initial stock setup is complete, the batches
          can be consolidated into 1 mixed batch
        </Paragraph>
      </>
    ),
    ...defaultStepProps,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
]

const BunkeringStep: Step = {
  target: '.bunkering-button',
  title: 'Missing a batch?',
  content: (
    <Paragraph>
      If a batch on board is missing in the list of batches, you can add it with
      a bunkering.
    </Paragraph>
  ),
  ...defaultStepProps,
  placement: 'bottom',
  spotlightClicks: true,
  disableOverlay: true,
  styles: {
    options: {
      zIndex: 100001,
    },
  },
}

const INFORMATION_STEPS: Step[] = [
  {
    target: '.joyride-rob-1',
    title: 'Welcome to the new stock module',
    content: (
      <>
        <Paragraph>
          We are going to guide you through the steps of registering your stock
          onboard by bunkered batch.
        </Paragraph>
        <Paragraph>
          Please have your latest sounding/ROB and bunkering information ready
          next to you.
        </Paragraph>
      </>
    ),
    placement: 'center',
    ...defaultStepProps,
  },
  {
    target: '.joyride-rob-not-now',
    title: 'Not now',
    content: (
      <Paragraph>
        You can always press “not now” and continue to use StarConnect without
        the stock module. This guide will wait until you are ready.
      </Paragraph>
    ),
    placement: 'bottom',
    ...defaultStepProps,
    disableOverlay: true,
  },
  {
    target: '.joyride-rob-3',
    title: 'Select known ROB timestamp',
    content: (
      <Paragraph>
        Please select timestamp for latest known ROB. The timestamp should be as
        close to the present time as possible.
      </Paragraph>
    ),
    placement: 'top',
    styles: {
      options: {
        zIndex: 100002,
      },
    },
    ...defaultStepProps,
    spotlightClicks: true,
    hideFooter: true,
  },
  {
    target: '.next-button',
    title: 'When you find the right timestamp',
    content: (
      <Paragraph>
        Press the next button when you are done selecting time of startup.
      </Paragraph>
    ),
    placement: 'bottom',
    ...defaultStepProps,
    spotlightClicks: true,
    hideFooter: true,
    disableOverlay: true,
  },
]

const HS_STEPS: Step[] = [
  {
    target: '.formcontent-1',
    title: 'Registering of fuel types and batches onboard.',
    content: (
      <>
        <Paragraph>
          Please enter how much is remaining of all earlier registered bunkered
          batches, at the timestamp you have just entered.
        </Paragraph>
        <Paragraph>
          Kindly note that the Very Low Sulphur (VLS) fuel has now been added in
          StarConnect. Please make sure that all bunkerings with 0.5% Sulphur
          have been transferred correctly and are listed under VLS.
        </Paragraph>
      </>
    ),
    ...defaultStepProps,
    placement: 'center',
    spotlightClicks: false,
    disableOverlay: true,
    hideBackButton: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  ...BatchTabelSteps,
  { ...BunkeringStep },
  {
    target: '.next-button',
    title: 'On to the next fuel type',
    content: (
      <Paragraph>
        Press “Next” button when you have finished registering your High Sulphur
        (HS) batches. If the batch has been consumed just leave the field blank
        . If you do not have HS onboard just press “Next”
      </Paragraph>
    ),
    placement: 'bottom',
    spotlightClicks: true,
    hideFooter: true,
    disableOverlay: true,
    ...defaultStepProps,
  },
]

const VLS_STEPS: Step[] = [
  {
    target: '.formcontent-4',
    title: 'Update Very Low Sulphur ROB',
    content: (
      <>
        <Paragraph>
          Here are the HS batches we have converted to VLS according to the
          Sulphur content you have entered in the lab reports after the
          bunkering.
        </Paragraph>
        <Paragraph>
          Press next and enter the quantities remaining onboard. If the batch
          has been consumed, just leave the field blank.
        </Paragraph>
      </>
    ),
    ...defaultStepProps,
    placement: 'center',
    hideBackButton: true,
    spotlightClicks: false,
    disableOverlay: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  ...BatchTabelSteps,
  { ...BunkeringStep },
  {
    target: '.next-button',
    title: 'Onto next the fuel type',
    content: (
      <Paragraph>
        Press the next button when you have finished registering your VLS
        batches.
      </Paragraph>
    ),
    placement: 'bottom',
    spotlightClicks: true,
    hideFooter: true,
    disableOverlay: true,
    ...defaultStepProps,
  },
]

const ULS_STEPS: Step[] = [
  {
    target: '.formcontent-2',
    title: 'Update Ultra Low Sulphur ROB',
    content: <div>Now fill out for ULS.</div>,
    ...defaultStepProps,
    placement: 'center',
    hideBackButton: true,
    spotlightClicks: false,
    disableOverlay: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  ...BatchTabelSteps,
  { ...BunkeringStep },
  {
    target: '.next-button',
    title: 'On to next fuel type',
    content: (
      <Paragraph>
        Press the next button when you have finished registering your ULS
        batches.
      </Paragraph>
    ),
    placement: 'bottom',
    spotlightClicks: true,
    hideFooter: true,
    disableOverlay: true,
    ...defaultStepProps,
  },
]

const MM_STEPS: Array<Step> = [
  {
    target: `.formcontent-${FuelType.MM}`,
    title: 'Update Methanol ROB',
    content: <div>Now fill out for {FUEL_TYPE_NAMES[FuelType.MM]}.</div>,
    ...defaultStepProps,
    placement: 'center',
    hideBackButton: true,
    spotlightClicks: false,
    disableOverlay: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  ...BatchTabelSteps,
  { ...BunkeringStep },
  {
    target: '.next-button',
    title: 'On to next fuel type',
    content: (
      <Paragraph>
        Press the next button when you have finished registering your{' '}
        {FUEL_TYPE_NAMES[FuelType.MM]} batches.
      </Paragraph>
    ),
    placement: 'bottom',
    spotlightClicks: true,
    hideFooter: true,
    disableOverlay: true,
    ...defaultStepProps,
  },
]

const MDO_STEPS: Step[] = [
  {
    target: '.formcontent-3',
    title: 'Update Marine Diesel Oil ROB',
    content: (
      <>
        <Paragraph>Now fill out for MDO.</Paragraph>
        <Paragraph>
          There has to be a MDO batch onboard with a lab report.
        </Paragraph>
        <Paragraph>
          If you have several small batches mixed from a long time ago, you can
          create a fictive batch with the same LCV as you would have put in
          MSPS. For the unknown parameters, you can put a dot.
        </Paragraph>
      </>
    ),
    ...defaultStepProps,
    placement: 'center',
    hideBackButton: true,
    disableOverlay: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  ...BatchTabelSteps,
  { ...BunkeringStep },
  {
    target: '.next-button',
    title: 'On to consumption',
    content: (
      <Paragraph>
        Press the next button when you have finished registering your MDO
        batches.
      </Paragraph>
    ),
    placement: 'bottom',
    spotlightClicks: true,
    hideFooter: true,
    disableOverlay: true,
    ...defaultStepProps,
  },
]

const CONSUMPTION_STEPS: Step[] = [
  {
    target: '.joyride-rob-consumption',
    title: 'Consumption',
    content: (
      <Paragraph>
        Now it is time to register which batch is in use at your selected time
        for startup for each fuel type.
      </Paragraph>
    ),
    ...defaultStepProps,
    placement: 'center',
    hideBackButton: true,
    disableOverlay: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.joyride-batch-selections',
    title: 'Select batches in use',
    content: (
      <>
        <Paragraph>
          Press “select batch” and choose from the list which batches are in use
          i.e., are being transferred or are in the service/settling tanks.
        </Paragraph>
        <Paragraph>
          If a fuel type is not in use, just leave it blank. You must select an
          MDO batch
        </Paragraph>
        <Paragraph>
          TIP: Not seeing any batches on the list? Please go back and check if
          a) bunkering date is AFTER the initial ROB timestamp you have entered
          or b) you are missing any Lab report data.
        </Paragraph>
      </>
    ),
    placement: 'top',
    ...defaultStepProps,
    offset: 50,
    disableOverlay: false,
    spotlightPadding: 30,
  },
  {
    target: '.joyride-fuel-type-selection',
    title: 'Select engine consumption',
    content: (
      <Paragraph>
        Select which fuel type is being burned by each consumer.
      </Paragraph>
    ),
    placement: 'top',
    ...defaultStepProps,
    offset: 70,
    disableOverlay: false,
    spotlightPadding: 30,
  },
]

const getSteps = [
  INFORMATION_STEPS,
  HS_STEPS,
  VLS_STEPS,
  ULS_STEPS,
  MDO_STEPS,
  CONSUMPTION_STEPS,
]

export default getSteps
export { MM_STEPS }
