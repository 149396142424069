import styled from 'styled-components'

export const TableContainer = styled.div`
  margin-top: 24px;
  display: flex;

  td {
    font-variant: tabular-nums;

    &.strong {
      border-top: 1px solid black;
      font-weight: 500;
    }
  }
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`

export const AverageCol = styled.col`
  min-width: 144px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 160px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 184px;
  }
`

export const ValueCol = styled.col`
  min-width: 136px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 160px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 176px;
  }
`

export const InputCol = styled.col`
  min-width: 136px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 168px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 200px;
  }
`

export const TableHeadingCol = styled.col`
  width: 70%;
`

export const TableValueCol = styled.col`
  width: 30%;
`

export const ChartsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 40px;
`

export const ChartWrapper = styled.div`
  position: relative;
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  margin: 32px 0 24px;
  height: 350px;
  width: 50%;
`

export const BatchSelectionTableCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    white-space: nowrap;
  }

  > mc-button {
    margin-left: 12px;
  }
`
