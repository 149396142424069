import * as yup from 'yup'
import { ELubeOilType } from '../types'

export const getQuantityValidationSchema = (min: number, max: number) =>
  integerValidationSchema
    .min(min, `Should be in range ${min} to ${max}`)
    .max(max, `Should be in range ${min} to ${max}`)

export const integerValidationSchema = yup
  .number()
  .nullable()
  .typeError('Should be a number')
  .integer('Should be an integer')

export const OIL_TYPE_TO_TBN_RANGE = {
  [ELubeOilType.AE]: [10, 55],
  [ELubeOilType.CLO]: [10, 175],
  [ELubeOilType.CLOAdditive]: [0, 500],
  [ELubeOilType.ME]: [0, 55],
}

export const REQUIRED_MESSAGE = 'Required'

export const timestampValidationSchema = yup
  .date()
  .nullable()
  .required(REQUIRED_MESSAGE)
  .test(
    'is-truncated',
    'Should be truncated to 10 minutes precision',
    (value: Date) => value?.getMinutes() % 10 === 0,
  )
