import { useContext } from 'react'
import styled from 'styled-components'
import { McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import { type MergedBatch } from './MixedBatchDetails'
import LabReportTooltip from './LabReportTooltip'
import { formatValue } from '../../../../utils'
import { WindowContext } from '../../../../contexts'

type Props = {
  batches: Array<MergedBatch>
}

const MixedBatchTableRows = ({ batches }: Props) => {
  const { windowSize } = useContext(WindowContext)

  if (batches.length === 0) {
    return null
  }

  return batches.map((batch, idx) => (
    <tr key={`name-${idx}`}>
      <td>
        <BatchDetailsWrapper>
          {batch.displayName}&nbsp;
          {batch.labReport && (
            <McTooltip
              appearance='inverse'
              position='top-center'
              fit={windowSize}
            >
              <McIcon size='20' icon='chemistry-beakers' slot='trigger' />
              <LabReportTooltip labReport={batch.labReport} />
            </McTooltip>
          )}
        </BatchDetailsWrapper>
      </td>
      <td>{formatValue(batch.quantity, 2)}</td>
    </tr>
  ))
}

export default MixedBatchTableRows

const BatchDetailsWrapper = styled.span`
  font-size: 14px;
  display: inline-flex;
  margin: 8px 0 8px 0;
  cursor: default;
`
