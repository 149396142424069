import { useContext, useState } from 'react'

import {
  filtersConfig,
  resolveInstanceNumbers,
  sortModelsByInstanceNumbers,
} from './AuxiliaryEnginesPage.utils'
import { VesselPageContext } from '../../contexts'
import FilterPanel, {
  type Filters,
  FiltersNotification,
  getFiltersFromQueryParams,
} from '../../features/filter'
import { AESfocChartContainer } from '../../features/auxiliary-engines'
import { ContentLayout } from '../../layout'
import {
  useStaticModels,
  useVesselStaticModelsAssignments,
} from '../../queries/MasterDataApi/MasterDataApi'
import { StaticModelType } from '../../queries/MasterDataApi/MasterDataApi.consts'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

const AuxiliaryEnginesPage = () => {
  const imoNo = useContext(VesselPageContext).imoNo!

  const [filters, setFilters] = useState<Filters | undefined>(
    getFiltersFromQueryParams,
  )

  const staticModelAssignmentsQuery =
    useVesselStaticModelsAssignments<MasterDataApi.StaticModels.AuxEngInstance>(
      +imoNo,
    )

  const auxEngAssignments =
    staticModelAssignmentsQuery?.data?.assignments?.filter(
      (assignment) => assignment.type === StaticModelType.AuxEng,
    )

  const assignedAuxEngStaticModelIds = auxEngAssignments
    ?.map((assignment) => assignment.staticModelId)
    .join(',')

  const auxEngStaticModelsQuery =
    useStaticModels<MasterDataApi.StaticModels.AuxEngStaticModelData>(
      assignedAuxEngStaticModelIds,
    )

  let staticModels: Array<AuxEngPage.StaticModel> = []

  if (auxEngStaticModelsQuery.isSuccess && auxEngAssignments) {
    staticModels = auxEngStaticModelsQuery.data
      ?.map((auxEngStaticModel) => ({
        ...auxEngStaticModel,
        instanceNumbers: resolveInstanceNumbers(
          auxEngStaticModel,
          auxEngAssignments,
        ),
      }))
      .sort(sortModelsByInstanceNumbers)
  }

  const onFiltersReset = () => setFilters(undefined)

  return (
    <ContentLayout
      header='Auxiliary engines'
      featureId='auxiliary-engines'
      hasFilter
    >
      <FiltersNotification onReset={onFiltersReset} />
      <TemNotifications />
      <PerformanceAlerts />
      <FilterPanel
        onChange={(filters) => setFilters(filters)}
        config={filtersConfig}
        value={filters}
        onReset={onFiltersReset}
      />
      <div className='full-component'>
        <AESfocChartContainer staticModels={staticModels} filters={filters} />
      </div>
    </ContentLayout>
  )
}

export default AuxiliaryEnginesPage
