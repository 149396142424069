import { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import * as S from '../overwrite-form/style'
import TotalizerModal from '../totalizer-modal'
import { postManualCorrectionRepairs } from '../../../../services/performance'
import { FuelLineDataLoss } from '../../../../api-models/performance/fuel-consumption'
import { displayErrorModal } from '../../../../utils'
import { UNITS } from '../../../../utils/constants'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { Loading } from '../../../../commons'
import { amountPerHour, zeroSecondsDate } from '../../utils/fuel-consumption'
import {
  FormInputDateTime,
  FormTextArea,
  InputNumber,
} from '../../../../components/formik'

type Props = {
  loss: FuelLineDataLoss
  refreshData: Function
  id: string
}

const FuelConsumptionDataLossForm = ({ loss, refreshData, id }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)

  const [totalizerActive, setTotalizerActive] = useState(false)

  const initialValues = {
    startTimestamp: zeroSecondsDate(loss.startTimestamp),
    endTimestamp: zeroSecondsDate(loss.endTimestamp),
    consumedMass: loss.consumedMassEstimate?.calculationSucceded
      ? loss.consumedMassEstimate.diffValue
      : null,
    notes: '',
  }

  const handleSubmit = async (values, helpers) => {
    try {
      await postManualCorrectionRepairs(imoNo, {
        ...values,
        fuelLineType: loss.fuelLineType,
      })
      refreshData()
      helpers.setSubmitting(false)
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to repair loss',
        message: err.message,
      })
    }
  }

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    startTimestamp: Yup.date().required('Required'),
    endTimestamp: Yup.date().required('Required'),
    consumedMass: Yup.string()
      .nullable()
      .required('Please provide an estimated consumed mass.'),
    notes: Yup.string().max(1000, 'Limit of 1000 characters exceeded'),
  })

  return (
    <>
      <Formik
        key={id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <S.Container>
              {isSubmitting && (
                <S.Loading>
                  <Loading />
                </S.Loading>
              )}
              <S.ColumnLeft>
                <S.Header>Data Loss period</S.Header>
                <FormInputDateTime
                  name='startTimestamp'
                  label='Data loss from'
                  passThroughMoment
                  disabled
                />
                <FormInputDateTime
                  name='endTimestamp'
                  label='Data loss to'
                  passThroughMoment
                  min={Moment.utc(loss.startTimestamp)}
                  max={Moment.utc(loss.endTimestamp)}
                  onChange={(val) =>
                    setFieldValue('endTimestamp', val.toISOString())
                  }
                />
                <FormTextArea
                  id='notes'
                  name='notes'
                  label='Comment'
                  rows={4}
                  maxlength={1000}
                />
              </S.ColumnLeft>
              <S.ColumnRight>
                <S.Header>Consumption</S.Header>
                <McButton
                  fit={windowSize}
                  type='button'
                  variant='outlined'
                  label='Totalizer at specific time'
                  icon='cog'
                  click={() => setTotalizerActive(true)}
                />
                <S.ConsumptionField>
                  <InputNumber
                    name='consumedMass'
                    label='Est. consumption'
                    unit={UNITS.METRIC_TON}
                  />
                  {!!values.consumedMass && (
                    <S.ConsumptionPerHourLabel fit={windowSize}>
                      {`${amountPerHour(
                        values.startTimestamp,
                        values.endTimestamp,
                        values.consumedMass,
                      )} MT/h`}
                    </S.ConsumptionPerHourLabel>
                  )}
                </S.ConsumptionField>
              </S.ColumnRight>
            </S.Container>
            <S.Actions>
              <McButton appearance='primary' label='Save' type='submit' />
            </S.Actions>
          </Form>
        )}
      </Formik>
      <TotalizerModal
        handleClose={() => setTotalizerActive(false)}
        visible={totalizerActive}
        timestamp={loss.endTimestamp}
      />
    </>
  )
}

export default FuelConsumptionDataLossForm
