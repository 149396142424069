import { useContext } from 'react'
import { useField } from 'formik'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../contexts'

type Props = {
  name: string
  label: string
  options: Array<{
    label: string
    value: string | number
  }>
  disabled?: boolean
  optionswidth?: 'trigger' | 'auto'
  onOptionSelected?: (value: string | number) => void
}

export const InputSelect = ({
  name,
  options,
  onOptionSelected,
  ...rest
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string | null>(name)

  const handleChange = async ({ detail }: CustomEvent) => {
    await helpers.setValue(detail.value)
    onOptionSelected?.(detail.value)
  }

  return (
    <McSelect
      fit={windowSize}
      optionselected={handleChange}
      value={field.value}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      {...rest}
    >
      {options.map((o) => (
        <McOption key={o.value} value={o.value} label={o.label} />
      ))}
    </McSelect>
  )
}
