import { useFormikContext } from 'formik'

import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'

type Props = {
  shouldShowCalculator: boolean
  mixedBatchName: string
  closeHandler: () => void
}

export const VolumeToMassComponent = ({
  shouldShowCalculator,
  mixedBatchName,
  closeHandler,
}: Props) => {
  const { setFieldValue } = useFormikContext<any>()

  if (!shouldShowCalculator) {
    return null
  }

  return (
    <VolumeToMassCalculator
      onCancel={closeHandler}
      onClose={closeHandler}
      onSave={(sum: string) => {
        let quantity: number | string
        if (sum === '-' || isNaN(+sum)) {
          quantity = ''
        } else {
          quantity = parseFloat(sum)
        }
        void setFieldValue(`${mixedBatchName}`, quantity)
        closeHandler()
      }}
    />
  )
}
