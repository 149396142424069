import { useContext } from 'react'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'

import { GroupNameItem, Wrapper } from './VesselPanel.styles'
import {
  getIsActivePageFunctionForVesselSidebar,
  getPagePath,
  isFeatureEnabled,
} from '../../../utils'
import { Icon, StatusPill } from '../../../commons'
import ErrorCount from '../../../components/ErrorCount'
import { type Sensors } from '../../../api-models/sensors'
import { AppContext } from '../../../contexts'
import { usePageProps } from '../../../routing/VesselRoutes.utils'

type Props = {
  onClick: () => void
  vesselExpanded: boolean
  active: boolean
  vesselName: string
  imoNo: number
  vesselActive: boolean
  vesselStatus: Sensors.VesselSensorsStatus
}

const VesselPanel = (props: Props) => {
  const {
    vesselStatus,
    vesselExpanded,
    active,
    vesselName,
    imoNo,
    onClick,
    vesselActive,
  } = props
  const location = useLocation()
  const routeMatch = useRouteMatch()

  const configuration = useContext(AppContext).configurations?.find(
    (config) => config.imoNo === imoNo,
  )
  const pageProps = usePageProps(imoNo.toString(), vesselExpanded)

  return (
    <Wrapper>
      {vesselActive && (
        <button
          className={`${vesselExpanded ? 'downwards' : 'right'} ${
            active ? 'active' : ''
          }`}
          onClick={onClick}
        >
          <span className={`fal fa-chevron-right`} />
          <span className='vessel-name'>{vesselName}</span>
          <div className='pills'>
            {vesselStatus && vesselStatus.sensorErrorCount > 0 && (
              <ErrorCount>{vesselStatus.sensorErrorCount}</ErrorCount>
            )}
            <span style={{ paddingLeft: '8px' }} />
            <StatusPill imoNo={imoNo} />
          </div>
        </button>
      )}
      {!vesselActive && (
        <button
          className='neglected-vessel'
          title='This vessel is not installed yet.'
        >
          <span className='vessel-name'>{vesselName}</span>
          <div className='pills'>
            <Icon icon='cv-disconnected' />
          </div>
        </button>
      )}
      {vesselExpanded && (
        <ul className='pages'>
          {pageProps.map((page, idx) => {
            const isEquipmentInstalled =
              !page.equipment || configuration?.[page.equipment].isInstalled
            if (
              page.hideInNav ||
              !isFeatureEnabled(
                getPagePath(`${routeMatch.path}/${page.pagePath}`),
              ) ||
              page.isDisabled ||
              !isEquipmentInstalled
            ) {
              return null
            }
            if (page.isGroupName) {
              return (
                <GroupNameItem key={`${page.name}-${idx}`}>
                  <span>{page.name}</span>
                </GroupNameItem>
              )
            }
            return (
              <li key={`link-${page.pagePath}`}>
                <NavLink
                  exact
                  to={`/MaerskStarConnect/vessel/${imoNo}${page.pagePath}${location.search}`}
                  isActive={getIsActivePageFunctionForVesselSidebar(
                    page.pagePath,
                    imoNo,
                    page.isExact,
                  )}
                >
                  {page.icon && <Icon icon={page.icon} />}
                  <span>{page.name}</span>
                  {page.name.toLowerCase() === 'sensors' &&
                    vesselStatus &&
                    vesselStatus.sensorErrorCount > 0 && (
                      <div className='pills'>
                        <ErrorCount>{vesselStatus.sensorErrorCount}</ErrorCount>
                      </div>
                    )}
                </NavLink>
              </li>
            )
          })}
        </ul>
      )}
    </Wrapper>
  )
}

export default VesselPanel
