import { useContext, useEffect, useMemo, useState } from 'react'
import { Field, type FieldProps, useFormikContext } from 'formik'
import moment from 'moment'
import { McButton, McError } from '@maersk-global/mds-react-wrapper'

import BatchSelect from '../BatchSelect'
import {
  Column,
  ContentWrapper,
  QuantityInputWrapper,
  resolveNotesHeight,
} from '../styles'
import { StockRobNotifications } from '../StockRobNotifications'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { Loading } from '../../../../commons'
import { displayErrorModal, formatValue } from '../../../../utils'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { type Option, useBatchOptions } from '../../hooks'
import { type DebunkeringFormValues } from './DebunkeringModal'
import { STOCK_DEBUNKERING_REASON_OPTIONS } from '../../../../utils/constants'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../../../hybrid-data-collector/reports/reports.utils'
import {
  FormInputDateTime,
  FormTextArea,
  FormTypeahead,
  InputNumber,
  InputSelect,
} from '../../../../components/formik'

type Props = {
  disabled: boolean
  debunkering?: Performance.FuelOilStock.DebunkeringEntry
  onClose: () => void
  deleteHandler: (
    debunkeringId: string,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void
  openWindow?: Performance.FuelOilStock.OpenWindow
  onDidTrySubmit: () => void
}

const DebunkeringForm = ({
  onClose,
  deleteHandler,
  openWindow,
  debunkering,
  disabled,
  onDidTrySubmit,
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!
  const batchOptions = useBatchOptions(imoNo)
  const terminalsQuery = useTerminals()

  const [currentBatchRob, setCurrentBatchRob] = useState<number | null>(null)
  const [batchOptionsAtTimeSelected, setBatchOptionsAtTimeSelected] =
    useState<Option[]>()
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const [shouldShowCalculator, setShouldShowCalculator] = useState(false)

  const {
    setFieldValue,
    initialValues,
    values,
    errors,
    isValid,
    dirty,
    isSubmitting,
    setSubmitting,
  } = useFormikContext<DebunkeringFormValues>()

  useEffect(() => {
    if (values.timestamp) {
      PerformanceApi.getRob(imoNo, moment.utc(values.timestamp).toISOString())
        .then(setRob)
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message:
              'Could not receive remaining on board at time of debunkering',
          }),
        )
      if (!disabled) {
        void setFieldValue('batchId', '')
        void setFieldValue('quantity', '')
        void setFieldValue('notes', '')
      }
    }
  }, [values.timestamp, imoNo, setFieldValue])

  useEffect(() => {
    /*
     * If Stock is not in an operational state, rob returns no data.
     * Last known batches on board must then be fetched from batch endpoint
     */
    if (rob?.hasData) {
      const batchIdsAtTimeSelected = rob?.batchQuantities
        .filter(({ quantity }) => quantity > 0)
        .map((x) => x.batch.id)
      setBatchOptionsAtTimeSelected(
        batchOptions?.filter(({ value }) =>
          batchIdsAtTimeSelected?.includes(value),
        ),
      )
    } else {
      setBatchOptionsAtTimeSelected(batchOptions)
    }
  }, [rob, batchOptions])

  useEffect(() => {
    if (rob?.hasData && values.batchId) {
      const { batchQuantities } = rob
      const newRob = batchQuantities.find(
        (x) => x.batch.id === values.batchId,
      )?.quantity
      if (typeof newRob === 'number') {
        setCurrentBatchRob(newRob)
      } else {
        setCurrentBatchRob(null)
      }
    } else {
      setCurrentBatchRob(null)
    }
  }, [values.batchId, rob])

  const activeTerminalOptions = useMemo(() => {
    if (!terminalsQuery.isSuccess) return []

    const result = terminalsQuery.data
      .filter((t) => t.data.isActive)
      .map(getTerminalOption)

    if (
      initialValues.portCode &&
      !result.find((t) => t.value === initialValues.portCode)
    ) {
      // Workaround: Add the port code from the initial values if it is not in the list
      result.push({
        label: initialValues.portCode,
        value: initialValues.portCode,
      })
    }

    return result
  }, [terminalsQuery.isSuccess, initialValues.portCode])

  if (!batchOptions || !openWindow) {
    return <Loading />
  }

  const _submitTxt = !!debunkering ? 'Save changes' : 'Add new debunkering'

  return (
    <>
      <StockRobNotifications rob={rob} />
      <ContentWrapper>
        <Column>
          <FormInputDateTime
            name='timestamp'
            label='Time of debunkering UTC'
            min={moment.utc(openWindow.period.from)}
            max={moment.utc(openWindow.period.to)}
            passThroughMoment
            disabled={disabled}
          />
          <FormTypeahead
            id='portCode'
            name='portCode'
            label='Port code'
            placeholder='Type to search'
            options={activeTerminalOptions}
            disabled={disabled}
          />
        </Column>
        <Column>
          <BatchSelect
            name='batchId'
            label='Batch debunkered'
            options={batchOptionsAtTimeSelected}
            placeholder='Select debunkering from'
            disabled={disabled}
            hint={
              currentBatchRob
                ? `${formatValue(currentBatchRob, 3)} MT at time of debunkering`
                : undefined
            }
          />

          <QuantityInputWrapper>
            <Field
              name='quantity'
              validate={(value: number | null) => {
                if (
                  value !== null &&
                  currentBatchRob !== null &&
                  value > currentBatchRob
                ) {
                  return `Cannot exceed quantity at time of debunkering (${formatValue(
                    currentBatchRob,
                    3,
                  )} MT)`
                }
              }}
            >
              {(props: FieldProps) => (
                <InputNumber
                  label='Quantity debunkered'
                  name={props.field.name}
                  unit='MT'
                  disabled={disabled}
                />
              )}
            </Field>
            <div>
              <McButton
                fit={windowSize}
                hiddenlabel
                icon='calculator'
                click={() => setShouldShowCalculator(true)}
                variant='plain'
              />
              {/* This McError element aligns the McButton vertically in case
                the quantity input field has an error. */}
              <McError invalid={!!errors['quantity']} errormessage='&nbsp;' />
            </div>
          </QuantityInputWrapper>
          {shouldShowCalculator && (
            <VolumeToMassCalculator
              onCancel={() => setShouldShowCalculator(false)}
              onClose={() => setShouldShowCalculator(false)}
              onSave={(totalSum: string) => {
                let quantity: number | string
                if (totalSum === '-' || isNaN(+totalSum) || disabled) {
                  quantity = ''
                } else {
                  quantity = parseFloat(totalSum)
                }
                void setFieldValue('quantity', quantity)
                setShouldShowCalculator(false)
              }}
            />
          )}
          <InputSelect
            name='reasonCode'
            label='Reason for debunkering'
            disabled={disabled}
            options={STOCK_DEBUNKERING_REASON_OPTIONS}
          />
        </Column>
        <Column>
          <FormTextArea
            id='notes'
            name='notes'
            label='Notes'
            height={resolveNotesHeight(windowSize)}
            maxlength={1000}
            disabled={disabled}
          />
        </Column>
      </ContentWrapper>
      {debunkering && (
        <McButton
          fit={windowSize}
          slot='primaryAction'
          label='Close'
          type='button'
          click={onClose}
        />
      )}
      {debunkering && !debunkering.readonly && (
        <McButton
          fit={windowSize}
          slot='secondaryAction'
          label='Delete'
          variant='plain'
          appearance='error'
          type='button'
          disabled={isSubmitting}
          click={() => deleteHandler(debunkering.id, setSubmitting)}
        />
      )}
      {!debunkering && (
        <>
          <McButton
            fit={windowSize}
            slot='secondaryAction'
            label='Cancel'
            appearance='neutral'
            type='button'
            click={onClose}
          />
          <McButton
            fit={windowSize}
            slot='primaryAction'
            label={_submitTxt}
            disabled={isSubmitting || disabled || !isValid || !dirty}
            type='submit'
            click={onDidTrySubmit}
          />
        </>
      )}
    </>
  )
}

export default DebunkeringForm
