import styled from 'styled-components'

import { BatchSelectionDisplay } from './BatchSelectionDisplay'
import { fuelTypeNumberByDisplayOrder } from '../../../../stock-management/utils'
import { Performance } from '../../../../../api-models'
import { Loading } from '../../../../../commons'
import { FuelType } from '../../../../../utils'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const getDisplayNameForSelectedBatch = (
  batchSelections: Array<Performance.FuelOilStock.CurrentBatchSelectionResponse>,
  fuelType: FuelType,
): string | undefined =>
  batchSelections.find((selection) => selection.fuelType === fuelType)
    ?.displayName

type Props = {
  onClick: (fuelType: FuelType) => void
  batchSelections: Array<Performance.FuelOilStock.CurrentBatchSelectionResponse> | null
  fuelTypes: Array<FuelType>
}

export const BatchSelections = ({
  onClick,
  fuelTypes,
  batchSelections,
}: Props) => {
  if (!batchSelections) {
    return <Loading width='unset' />
  }

  return (
    <Wrapper>
      {fuelTypes.sort(fuelTypeNumberByDisplayOrder).map((fuelType) => (
        <BatchSelectionDisplay
          key={`fuel-type-${fuelType}`}
          fuelType={fuelType}
          batchName={getDisplayNameForSelectedBatch(batchSelections, fuelType)}
          onClick={onClick}
        />
      ))}
    </Wrapper>
  )
}
