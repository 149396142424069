import { FuelLineType } from '../../utils'
import { Vessel, QueryResponse, Period, IStockNotification } from './common'

enum FuelConsumptionManagementState {
  OPERATIONAL = 1,
  LOST_TRACK = 2,
  START_UP_ERROR = 3,
  ERROR = 3,
}

export enum EFuelConsumptionState {
  StartUp = 1,
  Operational = 2,
  Recoverable = 4,
}

export interface Monitor extends QueryResponse {
  timestamps: number[]
  mainEngFuelLines: FuelLineConsumptionSeries[]
  auxEngFuelLines: FuelLineConsumptionSeries[]
  boilerLines: FuelLineConsumptionSeries[]
}

export interface FuelLineConsumptionSeries {
  fuelLineType: number
  fuelTypeSeries: number[]
  consumptionSeries: number[]
  consumption1Series?: number[]
  consumption2Series?: number[]
  density15Series: number[]
}

export interface BatchMonitor extends QueryResponse {
  vessel: Vessel
  hasData: boolean
  mainEngFuelLines: FuelLineBatchMonitor[]
  auxEngFuelLines: FuelLineBatchMonitor[]
  boilerLines: FuelLineBatchMonitor[]
}

export interface FuelLineBatchMonitor {
  fuelLineType: number
  indicators: Indicator[]
}

export interface Indicator {
  timestamp: Date
  type: number
  fuelType: number
  errorMessage?: string
  batch?: Batch
  fromBatch?: Batch
  toBatch?: Batch
}

export interface Batch {
  id: string
  displayName: string
  type: number
}

export interface MonitorConsolidated extends QueryResponse {
  hasData: boolean
  timestamps: number[] | null
  mainEngSeries: number[] | null
  auxEngSeries: number[] | null
  boilerSeries: number[] | null
}

export interface FuelTypeConsumptionSeries {
  fuelTypes: {
    fuelType: number
    consumption: number | null
  }[]
}

export interface FuelTypeMonitorResponse {
  vessel: Vessel
  queryPeriod: Period
  hasData: boolean
  timestamps: number[] | null
  consumptionSeries: FuelTypeConsumptionSeries[]
}

/**
 * FuelConsumptionStatusResponse
 * @description Model for GET /api/Vessel/{imoNo}/FuelConsumption/Status
 *
 * @property {string} timestamp Gets time of status
 * @property {number} state Gets the fuel consumption management state. = ['1', '2', '3', '4']
 * @property {FuelConstumptionNotification[]} notifications A collection of notifications
 * @property {string} openWindowTimestamp Gets the timestamp of the latest ROB mark, also known as open window
 * @property {boolean} hasDataLoss Indicator telling whether any data losses was found in the queried period
 * @property {FuelLineDataLoss[]} fuelLineDataLosses A collection of fuel line data losses for the data loss
 */
export interface FuelConsumptionStatusResponse {
  timestamp: string
  state: FuelConsumptionManagementState
  notifications: IStockNotification[]
  openWindowTimestamp: string
  hasDataLoss: boolean
  fuelLineDataLosses: FuelLineDataLoss[]
}

/**
 * FuelLineDataLoss
 * @description a map representing what fuel line was affected by the loss, and how much was lost (estimated).
 *
 * @property {FuelLineType} fuelLineType a enum representing a fuel line.
 * @property {boolean} hasEnded Indicates whether the data loss has ended.
 * @property {string} startTimestamp The timestamp from when the data loss began.
 * @property {string} endTimestamp The timestamp when the data loss ended and we got data back again.
 * @property {number} actualNoOLogs The actual number of logs for the fuel line.
 * @property {number} dataAvailability The data availability for the period.
 * @property {object} consumedMassEstimate The consumed mass estimate in [MT] for the period.
 */
export type FuelLineDataLoss = {
  fuelLineType: FuelLineType
  hasEnded: boolean
  startTimestamp: string
  endTimestamp: string
  actualNoOLogs: number
  dataAvailability: number
  consumedMassEstimate: FuelLineDataLossConsumedMassEstimate
}

/**
 * FuelLineDataLossConsumedMassEstimate
 * @description a map describing the estimated fuel loss based on a backend calulation.
 *
 * @property {boolean} calculationSucceded An indicator telling whether the calculation succeeded or not.
 * @property {number} diffValue The consumed mass for the period in [MT]
 * @property {number} perHourValue The average consumed mass per hour in [MT/h]
 */
export type FuelLineDataLossConsumedMassEstimate = {
  calculationSucceded: boolean
  diffValue: number
  perHourValue: number
}

/**
 * TotalizerDataPoint
 * @description A single flowmeter reading
 *
 * @property {number} dataPointId ID of the data point (flowmeter).
 * @property {string} dataPointName Name if the data point - ie. Main engine 1.
 * @property {number} dataPointValue The value of the data point reading.
 */
export type TotalizerDataPoint = {
  dataPointId: number
  dataPointName: string
  dataPointValue: number
}

/**
 * TotalizerLog
 * @description A log containing reading from the ships flowmeters
 *
 * @property {string} timestamp The timestamp of the reading (ISO date string). Timestamps are in 10 minute intervals.
 * @property {boolean} closest Indicating if this reading is the closest to the timestamp send to the totalizer endpoint.
 * @property {array} valueSeries Collection of TotalizerDataPoints containing the actual readings.
 */
export type TotalizerLog = {
  timestamp: string
  closest: boolean
  valueSeries: TotalizerDataPoint[]
}

/**
 * FuelLineTotalizer
 * @description Output from the totalizer describing fuel consumption on the various flowmeters from a specific timestamp.
 *
 * @property {boolean} hasData Does data exist near the queried timestamp.
 * @property {array} consumptionFlowMeters A list of the flowmeters available on the vessel.
 * @property {array} logs A list of flowmeter logs.
 */
export type FuelLineTotalizer = {
  hasData: boolean
  consumptionFlowMeters: { name: string }[]
  logs: TotalizerLog[]
}
