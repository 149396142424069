import { useFormikContext } from 'formik'

import OperatingConditions from './OperatingConditions'
import AdjustedToIsoConditions from './AdjustedToIsoConditions'
import KeyParameters from './KeyParameters'
import IsoCharts from './Charts/IsoCharts'
import CommentField from '../../Common/CommentField'
import KeyParamsCharts from './Charts/KeyParamsCharts'

type Props = {
  isActive: boolean
}

const ResultsTab = ({ isActive }: Props) => {
  const { values } = useFormikContext<GandalfApi.AetcReport>()
  const { data } = values

  return (
    <div slot='panel'>
      <OperatingConditions
        general={data.general}
        equipment={data.equipment}
        result={data.result}
      />
      <AdjustedToIsoConditions
        general={data.general}
        adjustedIsoConditions={data.result.adjustedIsoConditions}
      />
      <IsoCharts
        isActive={isActive}
        equipment={data.equipment}
        result={data.result}
      />
      <KeyParameters keyParams={data.result.keyParams} />
      <KeyParamsCharts
        isActive={isActive}
        operatingCondition={data.equipment.shopTest.defaultOperatingCondition}
        result={data.result}
      />
      <CommentField label='Comment' name='data.comment' />
    </div>
  )
}

export default ResultsTab
