import { FuelType } from '../../../utils'
import {
  fillOpacity,
  maritimeBlueTheme,
  purpleTheme,
  maerskBlueTheme,
  orangeTheme,
  greenTheme,
} from '../../../theme'

export const DIM_FUEL_LINE_TYPE = 0
export const DIM_START_TIME = 1
export const DIM_END_TIME = 2
export const DIM_INDICATOR_TYPE = 3
export const DIM_FUEL_TYPE = 4
export const DIM_BATCH_ID = 5
export const DIM_BATCH_NAME = 6
export const DIM_BATCH_TYPE = 7
export const DIM_FROM_BATCH_ID = 8
export const DIM_FROM_BATCH_NAME = 9
export const DIM_FROM_BATCH_TYPE = 10
export const DIM_TO_BATCH_ID = 11
export const DIM_TO_BATCH_NAME = 12
export const DIM_TO_BATCH_TYPE = 13
export const DIM_ERROR_MESSAGE = 14
export const DIMENSION_NAMES = [
  'Fuel line type',
  'Timestamp',
  'End time',
  'Indicator type',
  'Fuel type',
  'Batch id',
  'Display name',
  'Batch type',
  'From batch id',
  'From batch name',
  'From batch type',
  'To batch id',
  'To batch name',
  'To batch type',
  'Indicator error message',
]

export const THEMES_BY_FUEL_TYPE = {
  [FuelType.HS]: fillOpacity(maritimeBlueTheme, 0.2),
  [FuelType.VLS]: fillOpacity(purpleTheme, 0.2),
  [FuelType.ULS]: fillOpacity(maerskBlueTheme, 0.2),
  [FuelType.MDO]: fillOpacity(orangeTheme, 0.2),
  [FuelType.MM]: fillOpacity(greenTheme, 0.2),
}

export interface BatchMonitorSeriesMapped {
  indicators: Array<Array<string | number>>
  fuelLines: Array<number>
}

export interface ElementStyles {
  fill: string
  stroke: string
  lineWidth: number
  textFill: string
  cursor: string
}
