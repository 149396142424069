import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'
import {
  // TODO: If these end up being shared between METC and AETC, move them to a common location
  initChart,
  type ModelData,
  type TestData,
  updateChart,
} from '../../../MetcReportPage/ResultsTab/Charts/Charts.utils'
import { UNITS } from '../../../../../utils/constants'

const ISO_SFOC_CHART_ID = 'iso-sfoc-chart'
const ISO_PMAX_CHART_ID = 'iso-pmax-chart'

type Props = {
  isActive: boolean
  equipment: GandalfApi.Aetc.Equipment
  result: GandalfApi.Aetc.Result
}

const IsoCharts = ({ isActive, equipment, result }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const sfocChartRef = useRef<EChartsType>()
  const pmaxChartRef = useRef<EChartsType>()

  const operatingCondition = equipment.shopTest.defaultOperatingCondition

  const modelSfocData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.sfoc,
      name: 'SFOC ISO shop test',
    },
  ]
  const testSfocData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.adjustedIsoConditions.adjustedToIsoConditions.sfoc,
      name: 'SFOC test (ISO)',
    },
  ]

  const modelPmaxData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.pfiring,
      name: 'Pmax ISO shop test',
    },
  ]
  const testPmaxData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.adjustedIsoConditions.adjustedToIsoConditions.pmax,
      name: 'Pmax test (ISO)',
    },
  ]

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      sfocChartRef.current = initChart({
        id: ISO_SFOC_CHART_ID,
        xLabel: 'Engine load',
        xUnit: UNITS.ENGINE_LOAD,
        yLabel: 'SFOC',
        yUnit: UNITS.SFOC,
      })
      updateChart(modelSfocData, testSfocData, sfocChartRef.current)

      pmaxChartRef.current = initChart({
        id: ISO_PMAX_CHART_ID,
        xLabel: 'Engine load',
        xUnit: UNITS.ENGINE_LOAD,
        yLabel: 'Pmax',
        yUnit: UNITS.BAR,
      })
      updateChart(modelPmaxData, testPmaxData, pmaxChartRef.current)
    }, 0)

    setIsInitialized(true)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) return
    updateChart(modelSfocData, testSfocData, sfocChartRef.current)
    updateChart(modelPmaxData, testPmaxData, pmaxChartRef.current)
  }, [result.adjustedIsoConditions])

  return (
    <ChartsWrapper>
      <ChartWrapper id={ISO_SFOC_CHART_ID} />
      <ChartWrapper id={ISO_PMAX_CHART_ID} />
    </ChartsWrapper>
  )
}

export default IsoCharts
