import { useCallback, useContext, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import moment, { type Moment } from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { RecoverySoundingTable } from './RecoverySoundingTable'
import {
  FormContent,
  FormDateTimeInput,
  FormProgressBar,
  InfoBox,
  Loading,
  ModalControls,
} from '../../../../commons'
import {
  displayErrorModal,
  FuelLineType,
  FuelType,
  getClosestTenMinute,
  isShoreContext,
} from '../../../../utils'
import { Performance } from '../../../../api-models'
import styled, { info } from '../../../../theme'
import * as PerformanceAPI from '../../../../services/performance'
import { VesselPageContext } from '../../../../contexts'
import { FormConsumptionSelection } from '../form-consumption-selection/FormConsumptionSelection'
import { RecoveryAndStartupCommonRobType } from '../form-consumption-selection/FormBatchSelection'

const Wrapper = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  max-width: 800px;
`

const TimeFormContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;

  .field-label {
    font-size: 14px;
    text-align: right;
    margin-right: 8px;
  }
`

export interface RecoveryFormValues extends RecoveryAndStartupCommonRobType {
  timestamp?: Moment
  hsValues: Performance.FuelOilStock.BatchResponse[]
  vlsValues: Performance.FuelOilStock.BatchResponse[]
  ulsValues: Performance.FuelOilStock.BatchResponse[]
  mdoValues: Performance.FuelOilStock.BatchResponse[]
  consumption: {
    batchSelections: Performance.FuelOilStock.BatchSelectionResponse[]
    fuelTypeSelections: Performance.FuelOilStock.FuelTypeSelection[]
  }
}

const FORM_STEPS = ['Information', 'ROB', 'Consumption']

const FUEL_TYPE_TO_FIELD_NAME = [
  { fuelType: FuelType.HS, fieldName: 'hsValues' },
  { fuelType: FuelType.VLS, fieldName: 'vlsValues' },
  { fuelType: FuelType.ULS, fieldName: 'ulsValues' },
  { fuelType: FuelType.MDO, fieldName: 'mdoValues' },
  { fuelType: FuelType.MM, fieldName: 'mmValues' },
]

type Props = {
  recoveryAvailableFromDate?: moment.Moment | null
  closeHandler: (refresh?: boolean) => void
  batches: Performance.FuelOilStock.BatchResponse[]
}

export const RecoveryForm = ({
  recoveryAvailableFromDate,
  closeHandler,
  batches,
}: Props) => {
  const vesselPageContext = useContext(VesselPageContext)
  const imoNo = vesselPageContext.imoNo!
  const { configuration } = vesselPageContext
  const [currentStep, setCurrentStep] = useState(0)
  const isShoreMode = isShoreContext()

  const getBatchesForFuelType = useCallback(
    (fuelType) => batches.filter((batch) => batch?.fuel?.type === fuelType),
    [batches],
  )

  const initialValues: RecoveryFormValues = {
    hsValues: [],
    vlsValues: [],
    ulsValues: [],
    mdoValues: [],
    consumption: {
      batchSelections: [],
      fuelTypeSelections: [],
    },
    manualrob: {
      rob: {},
    },
  }

  const getRecoveryBatches = useCallback((values) => {
    const { hsValues, vlsValues, ulsValues, mdoValues } = values
    return [...hsValues, ...vlsValues, ...ulsValues, ...mdoValues]
  }, [])

  const mapRecoveryValuesToRecoveryRequest = (
    values: RecoveryFormValues,
  ): Performance.FuelOilStock.RecoveryRequest => {
    const recoveryBatches = getRecoveryBatches(values)
    const { batchSelections, fuelTypeSelections } = values.consumption
    const completeFuelTypeSelections = fuelTypeSelections.map((selection) => ({
      ...selection,
    }))
    ;[FuelLineType.AEMDO, FuelLineType.BoilerMDO].forEach((fuelLineType) => {
      if (configuration?.fuelLineTypes.includes(fuelLineType)) {
        completeFuelTypeSelections.push({
          fuelType: FuelType.MDO,
          fuelLineType,
        })
      }
    })
    if (configuration?.hasMainEngSecondaryFuelLine) {
      ;[FuelLineType.MEMain, FuelLineType.AEMain].forEach((fuelLineType) => {
        if (configuration.fuelLineTypes.includes(fuelLineType)) {
          completeFuelTypeSelections.push({
            fuelType: FuelType.MM,
            fuelLineType,
          })
        }
      })
    }
    const mappedBatches: Performance.FuelOilStock.RecoveryBatch[] =
      recoveryBatches.map((batch: Performance.FuelOilStock.RecoveryBatch) => {
        const batchSelection = batchSelections.find((s) => s.id === batch.id)
        return {
          id: batch.id,
          rob: (values?.manualrob?.rob && values.manualrob.rob[batch.id]) || 0,
          selected: !!batchSelection,
        }
      })
    return {
      timestamp: values.timestamp?.toISOString() ?? '',
      batches: mappedBatches,
      fuelTypeSelections: completeFuelTypeSelections,
    }
  }

  const submitRecovery = (
    values: RecoveryFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const startUpRequestBody = mapRecoveryValuesToRecoveryRequest(values)
    setSubmitting(true)
    PerformanceAPI.postRecovery(imoNo, startUpRequestBody)
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }
  if (!recoveryAvailableFromDate) {
    return <Loading width='unset' />
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) =>
        submitRecovery(values, setSubmitting)
      }
    >
      {({ isSubmitting, values, dirty, setFieldValue }) => (
        <Form>
          <FormProgressBar currentStep={currentStep} steps={FORM_STEPS}>
            <FormContent show={currentStep === 0}>
              <InfoBox theme={info} boxMargin='8px 16px' maxWidth='400px'>
                Please select a timestamp where your ROB is known and the
                batches that were in use are known. The timestamp should be as
                close to the present time as possible.
              </InfoBox>
              <TimeFormContainer>
                <span className='field-label'>Time of ROB UTC</span>
                <Field
                  name='timestamp'
                  onChange={(value: Moment) => {
                    FUEL_TYPE_TO_FIELD_NAME.forEach(({ fuelType, fieldName }) =>
                      setFieldValue(
                        fieldName,
                        getBatchesForFuelType(fuelType).filter((batch) =>
                          moment.utc(batch.timestamp).isSameOrBefore(value),
                        ),
                      ),
                    )
                  }}
                  component={(props) => (
                    <FormDateTimeInput
                      {...props}
                      minuteSpecific={false}
                      minDate={moment.utc(recoveryAvailableFromDate)}
                      maxDate={getClosestTenMinute(moment.utc())}
                    />
                  )}
                />
              </TimeFormContainer>
            </FormContent>
            <FormContent show={currentStep === 1}>
              <Wrapper>
                <InfoBox theme={info} boxMargin='8px 16px' maxWidth='580px'>
                  Enter ROB for each batch at selected timestamp
                </InfoBox>
                <RecoverySoundingTable isShoreMode={isShoreMode} />
              </Wrapper>
            </FormContent>
            <FormContent
              show={currentStep === 2}
              style={{ padding: '0px 16px 16px 16px' }}
            >
              <Wrapper>
                <InfoBox theme={info} maxWidth='800px'>
                  At the top you select which batch of each fuel type is
                  consumed. That should correspond to what you have in your your
                  service/settling tanks. Below, you select which fuel type each
                  consumer is burning.
                </InfoBox>
                <FormConsumptionSelection
                  batches={batches.filter((batch) =>
                    moment
                      .utc(batch.timestamp)
                      .isSameOrBefore(values.timestamp),
                  )}
                />
              </Wrapper>
            </FormContent>
          </FormProgressBar>
          <ModalControls>
            {currentStep === 0 ? (
              <McButton
                label='Cancel'
                appearance='neutral'
                click={() => closeHandler()}
                type='button'
              />
            ) : (
              <McButton
                label='Back'
                appearance='neutral'
                click={() => setCurrentStep(currentStep - 1)}
                type='button'
              />
            )}
            {currentStep < FORM_STEPS.length - 1 && (
              <McButton
                label='Next'
                appearance='primary'
                disabled={!values.timestamp || !dirty}
                click={() => setCurrentStep(currentStep + 1)}
                type='button'
              />
            )}
            {currentStep === FORM_STEPS.length - 1 && (
              <McButton
                label='Recover'
                appearance='primary'
                disabled={isSubmitting}
                type='submit'
              />
            )}
          </ModalControls>
        </Form>
      )}
    </Formik>
  )
}
