import { FuelType } from '../../../../utils/models/fuel-models'

export const calculateLcvForBioFuel = (
  fuelType: FuelType,
  bioPercentage: number,
): number | '' => {
  if (bioPercentage === 0 || fuelType === FuelType.MM) {
    return ''
  }

  const factors: Record<FuelType, number> = {
    [FuelType.HS]: 40290,
    [FuelType.VLS]: 41000,
    [FuelType.ULS]: 42200,
    [FuelType.MDO]: 42700,
    [FuelType.MM]: 0,
  }

  return (
    (bioPercentage / 100) * 37500 +
    (1 - bioPercentage / 100) * factors[fuelType]
  )
}
