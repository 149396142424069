import React from 'react'
import ReactDOM from 'react-dom/client'
import { MsalProvider } from '@azure/msal-react'

import 'react-datepicker/dist/react-datepicker.css'
import './global.scss'
import './assets/icons/brands'
import './assets/icons/light'
import './assets/icons/regular'
import './assets/icons/solid'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './app'
import { msalInstance } from './utils'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
)

serviceWorkerRegistration.register()
