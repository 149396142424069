import { useContext } from 'react'

import { keyParamsRanges } from '../AetcReportPage.consts'
import { TableContainer } from '../../EngineTestReportPage.styles'
import RangeValidationTag from '../../Common/RangeValidationTag'
import { formatNumberWithUnit, toPercentage } from '../../../../utils'
import { UNITS } from '../../../../utils/constants'
import { WindowContext } from '../../../../contexts'
import InfoTooltip from '../../../../components/InfoTooltip'

type Props = {
  keyParams: GandalfApi.Aetc.KeyParameters
}

const KeyParameters = ({ keyParams }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const renderRangeValidation = (x: keyof GandalfApi.Aetc.KeyParameters) => {
    if (keyParams[x].rangeValidation === null) {
      return '-'
    }

    return (
      <InfoTooltip content={`The limit is ${keyParamsRanges[x]}`}>
        <RangeValidationTag rangeValidation={keyParams[x].rangeValidation} />
      </InfoTooltip>
    )
  }

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <thead>
          <tr>
            <th>Key parameters</th>
            <th>Adjusted to ISO condition</th>
            <th>ISO model</th>
            <th>Deviation to model</th>
            <th className='mds-table__cell--text-center'>Validation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SFOC (LCV 42700 + ISO corrected)</td>
            <td>
              {formatNumberWithUnit(
                keyParams.sfoc.isoConditionAdjustedValue,
                1,
                UNITS.SFOC,
              )}
            </td>
            <td>
              {formatNumberWithUnit(keyParams.sfoc.modelValue, 1, UNITS.SFOC)}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.sfoc.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('sfoc')}
            </td>
          </tr>
          <tr>
            <td>Pmax</td>
            <td>
              {formatNumberWithUnit(
                keyParams.pmax.isoConditionAdjustedValue,
                1,
                UNITS.BAR,
              )}
            </td>
            <td>
              {formatNumberWithUnit(keyParams.pmax.modelValue, 1, UNITS.BAR)}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.pmax.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('pmax')}
            </td>
          </tr>
          <tr>
            <td>SFOC due to Pmax deviation</td>
            <td>
              {formatNumberWithUnit(
                keyParams.sfocDuePmax.isoConditionAdjustedValue,
                1,
                UNITS.SFOC,
              )}
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>-</td>
          </tr>
          <tr>
            <td>Scavenging air pressure</td>
            <td>
              {formatNumberWithUnit(
                keyParams.scavAirPres.isoConditionAdjustedValue,
                1,
                UNITS.BAR,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.scavAirPres.modelValue,
                1,
                UNITS.BAR,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.scavAirPres.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('scavAirPres')}
            </td>
          </tr>
          <tr>
            <td>Average exhaust gas outlet temperature</td>
            <td>
              {formatNumberWithUnit(
                keyParams.exhGasTempAvg.isoConditionAdjustedValue,
                1,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.exhGasTempAvg.modelValue,
                1,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.exhGasTempAvg.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('exhGasTempAvg')}
            </td>
          </tr>
          <tr>
            <td>Turbocharger inlet exhaust temperature</td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcInletExhTemp.isoConditionAdjustedValue,
                1,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcInletExhTemp.modelValue,
                1,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcInletExhTemp.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('tcInletExhTemp')}
            </td>
          </tr>
          <tr>
            <td>Turbocharger outlet exhaust temperature</td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcOutletExhTemp.isoConditionAdjustedValue,
                1,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcOutletExhTemp.modelValue,
                1,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcOutletExhTemp.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('tcOutletExhTemp')}
            </td>
          </tr>
          <tr>
            <td>Turbocharger RPM</td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcSpeed.isoConditionAdjustedValue,
                1,
                UNITS.RPM,
              )}
            </td>
            <td>
              {formatNumberWithUnit(keyParams.tcSpeed.modelValue, 1, UNITS.RPM)}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.tcSpeed.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('tcSpeed')}
            </td>
          </tr>
          <tr>
            <td>Fuel rack</td>
            <td>
              {formatNumberWithUnit(
                keyParams.fuelPumpIdx.isoConditionAdjustedValue,
                1,
                UNITS.MILLIMETER,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.fuelPumpIdx.modelValue,
                1,
                UNITS.MILLIMETER,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.fuelPumpIdx.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('fuelPumpIdx')}
            </td>
          </tr>
          <tr>
            <td>VIT actuator position</td>
            <td>
              {formatNumberWithUnit(
                keyParams.vitPos.isoConditionAdjustedValue,
                1,
                UNITS.MILLIMETER,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.vitPos.modelValue,
                1,
                UNITS.MILLIMETER,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.vitPos.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('vitPos')}
            </td>
          </tr>
          <tr>
            <td>Thermal efficiency</td>
            <td>
              <InfoTooltip
                label={`${formatNumberWithUnit(
                  toPercentage(
                    keyParams.thermalEfficiency.isoConditionAdjustedValue,
                  ),
                  1,
                  UNITS.PERCENTAGE,
                )}`}
                content='Indicated Power (Diesel Doctor) / Fuel Calorific Power'
              />
            </td>
            <td>
              <InfoTooltip
                label={`${formatNumberWithUnit(
                  toPercentage(keyParams.thermalEfficiency.modelValue),
                  1,
                  UNITS.PERCENTAGE,
                )}`}
                content='Engine Indicated Power / Fuel Calorific Power.'
              />
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.thermalEfficiency.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('thermalEfficiency')}
            </td>
          </tr>
          <tr>
            <td>Electrical efficiency</td>
            <td>
              <InfoTooltip
                label={`${formatNumberWithUnit(
                  toPercentage(
                    keyParams.electricalEfficiency.isoConditionAdjustedValue,
                  ),
                  1,
                  UNITS.PERCENTAGE,
                )}`}
                content='Electrical Power (CAMS) / Indicated Power (from Diesel Doctor, etc)'
              />
            </td>
            <td>
              <InfoTooltip
                label={`${formatNumberWithUnit(
                  toPercentage(keyParams.electricalEfficiency.modelValue),
                  1,
                  UNITS.PERCENTAGE,
                )}`}
                content='Reference value from Shop Test'
              />
            </td>
            <td>
              {formatNumberWithUnit(
                keyParams.electricalEfficiency.modelDeviation,
                1,
                UNITS.PERCENTAGE,
              )}
            </td>
            <td className='mds-table__cell--text-center'>
              {renderRangeValidation('electricalEfficiency')}
            </td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default KeyParameters
