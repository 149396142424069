import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'
import {
  initChart,
  updateDeviationChart,
} from '../../../MetcReportPage/CylinderTab/CylinderCharts/CylinderCharts.utils'
import {
  initMipChart,
  updateChart,
  updateMipChart,
} from './CylinderCharts.utils'
import { UNITS } from '../../../../../utils/constants'

const PMAX_CHART_ID = 'pmax-chart'
const PMAX_DEVIATION_CHART_ID = 'pmax-deviation-chart'
const MIP_CHART_ID = 'mip-chart'
const MIP_DEVIATION_CHART_ID = 'mip-deviation-chart'
const IP_CHART_ID = 'ip-chart'
const IP_DEVIATION_CHART_ID = 'ip-deviation-chart'
const EXH_GAS_TEMP_CHART_ID = 'exh-gas-temp-chart'
const EXH_GAS_TEMP_DEVIATION_CHART_ID = 'exh-gas-temp-deviation-chart'
const ANGLE_CHART_ID = 'angle-chart'
const FUEL_RACK_DEVIATION_CHART_ID = 'fuel-rack-deviation-chart'

type Props = {
  isActive: boolean
  cylinders: Array<GandalfApi.Aetc.Cylinder>
  result: GandalfApi.Aetc.Result
}

const CylinderCharts = ({ isActive, cylinders, result }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const pMaxChartRef = useRef<EChartsType>()
  const pMaxDeviationChartRef = useRef<EChartsType>()
  const mipChartRef = useRef<EChartsType>()
  const mipDeviationChartRef = useRef<EChartsType>()
  const ipChartRef = useRef<EChartsType>()
  const ipDeviationChartRef = useRef<EChartsType>()
  const exhGasTempChartRef = useRef<EChartsType>()
  const exhGasTempDeviationChartRef = useRef<EChartsType>()
  const angleChartRef = useRef<EChartsType>()
  const fuelRackDeviationChartRef = useRef<EChartsType>()

  const setPMaxChartsData = () => {
    const pMaxMeasured = cylinders.map((c) => c.pmax.value)
    updateChart(
      pMaxMeasured,
      null,
      result.cylinders.pmaxAvg,
      result.adjustedIsoConditions.adjustedToIsoConditions.pmax,
      ['Pmax measured'],
      pMaxChartRef.current,
    )
    updateDeviationChart(
      pMaxMeasured,
      result.cylinders.pmaxAvg,
      5,
      'Pmax deviation',
      pMaxDeviationChartRef.current,
    )
  }

  const setMipChartsData = () => {
    const mipMeasured = cylinders.map((c) => c.mip.value)
    const fuelRackMeasured = cylinders.map((c) => c.fuelRack.value)
    updateMipChart(
      mipMeasured,
      fuelRackMeasured,
      result.cylinders.mipAvg,
      ['MIP measured', 'Fuel rack'],
      mipChartRef.current,
    )
    updateDeviationChart(
      mipMeasured,
      result.cylinders.mipAvg,
      0.5,
      'MIP deviation',
      mipDeviationChartRef.current,
    )
  }

  const setIpChartsData = () => {
    const ipMeasured = cylinders.map((c) => c.ip.value)
    updateChart(
      ipMeasured,
      null,
      result.cylinders.ipAvg,
      null,
      ['Indicated Power measured'],
      ipChartRef.current,
    )
    updateDeviationChart(
      ipMeasured,
      result.cylinders.ipAvg,
      50,
      'Indicated power deviation',
      ipDeviationChartRef.current,
    )
  }

  const setExhGasTempChartsData = () => {
    const exhGasTempMeasured = cylinders.map((c) => c.exhGasTemp.value)
    updateChart(
      exhGasTempMeasured,
      null,
      result.cylinders.exhGasTempAvg,
      null,
      ['Exhaust gas temperature measured'],
      exhGasTempChartRef.current,
    )
    updateDeviationChart(
      exhGasTempMeasured,
      result.cylinders.exhGasTempAvg,
      50,
      'Exhaust gas temperature deviation',
      exhGasTempDeviationChartRef.current,
    )
  }

  const setPeakPresAngleChartData = () => {
    const peakPresAngleMeasured = cylinders.map((c) => c.peakPresAngle.value)
    const ignAngleMeasured = cylinders.map((c) => c.ignAngle.value)
    updateChart(
      peakPresAngleMeasured,
      { data: ignAngleMeasured, type: 'bar' },
      null,
      null,
      ['Angle of peak pressure', 'Ignition angle'],
      angleChartRef.current,
    )
  }

  const setFuelRackChartData = () => {
    const fuelRackMeasured = cylinders.map((c) => c.fuelRack.value)

    updateDeviationChart(
      fuelRackMeasured,
      result.cylinders.fuelRackAvg,
      2,
      'Fuel rack deviation',
      fuelRackDeviationChartRef.current,
    )
  }

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      pMaxChartRef.current = initChart(PMAX_CHART_ID, `Pmax [${UNITS.BAR}]`)
      pMaxDeviationChartRef.current = initChart(
        PMAX_DEVIATION_CHART_ID,
        `Pmax deviation [${UNITS.BAR}]`,
      )
      setPMaxChartsData()

      mipChartRef.current = initMipChart(MIP_CHART_ID, [
        { name: 'MIP', unit: UNITS.BAR },
        { name: 'MIP avg.', unit: UNITS.BAR },
        { name: 'Fuel rack', unit: UNITS.MILLIMETER },
      ])
      mipDeviationChartRef.current = initChart(
        MIP_DEVIATION_CHART_ID,
        `MIP deviation [${UNITS.BAR}]`,
      )
      setMipChartsData()

      ipChartRef.current = initChart(
        IP_CHART_ID,
        `Indicated power [${UNITS.KILO_WATT}]`,
      )
      ipDeviationChartRef.current = initChart(
        IP_DEVIATION_CHART_ID,
        `Indicated power deviation [${UNITS.KILO_WATT}]`,
      )
      setIpChartsData()

      exhGasTempChartRef.current = initChart(
        EXH_GAS_TEMP_CHART_ID,
        `Exhaust gas temperature [${UNITS.TEMPERATURE}]`,
      )
      exhGasTempDeviationChartRef.current = initChart(
        EXH_GAS_TEMP_DEVIATION_CHART_ID,
        `Exhaust gas temperature deviation [${UNITS.TEMPERATURE}]`,
      )
      setExhGasTempChartsData()

      angleChartRef.current = initChart(
        ANGLE_CHART_ID,
        `Angle of peak pressure / ignition [${UNITS.DEGREES}]`,
      )
      setPeakPresAngleChartData()

      fuelRackDeviationChartRef.current = initChart(
        FUEL_RACK_DEVIATION_CHART_ID,
        `Fuel rack deviation [${UNITS.MILLIMETER}]`,
      )
      setFuelRackChartData()

      setIsInitialized(true)
    }, 0)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) {
      return
    }

    setPMaxChartsData()
    setMipChartsData()
    setIpChartsData()
    setExhGasTempChartsData()
    setPeakPresAngleChartData()
    setFuelRackChartData()
  }, [cylinders, result])

  return (
    <ChartsWrapper>
      <ChartWrapper id={PMAX_CHART_ID} />
      <ChartWrapper id={PMAX_DEVIATION_CHART_ID} />
      <ChartWrapper id={MIP_CHART_ID} />
      <ChartWrapper id={MIP_DEVIATION_CHART_ID} />
      <ChartWrapper id={IP_CHART_ID} />
      <ChartWrapper id={IP_DEVIATION_CHART_ID} />
      <ChartWrapper id={EXH_GAS_TEMP_CHART_ID} />
      <ChartWrapper id={EXH_GAS_TEMP_DEVIATION_CHART_ID} />
      <ChartWrapper id={ANGLE_CHART_ID} />
      <ChartWrapper id={FUEL_RACK_DEVIATION_CHART_ID} />
    </ChartsWrapper>
  )
}

export default CylinderCharts
