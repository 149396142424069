import { createBrowserHistory } from 'history'
import { Route } from 'react-router-dom'
import { RumInit } from '@maersk-global/telemetry-web-sdk'
import {
  createReactRouterV5Options,
  ReactIntegration,
} from '@grafana/faro-react'

import { getApplicationConfig } from './application-utils'
import { getAppVersion } from './version-utils'

const APP_NAME = 'VesselEnergy-Prod'
const APP_VERSION = getAppVersion()
const APP_ENVIRONMENT = 'prod'
const API_KEY = getApplicationConfig().RUM_API_KEY

const history = createBrowserHistory()

export const initializeRum = () => {
  const faro = RumInit({
    app: {
      name: APP_NAME,
      version: APP_VERSION,
      environment: APP_ENVIRONMENT,
    },
    apiKey: API_KEY ?? '',
    debug: false,
    traceLog: false,
    useSendBeacon: true,
    isolate: true,
  })

  // Instrument React Router v5
  // https://github.com/grafana/faro-web-sdk/tree/main/packages/react#grafanafaro-react
  faro.instrumentations.add(
    new ReactIntegration({
      router: createReactRouterV5Options({
        // @ts-ignore
        history,
        Route,
      }),
    }),
  )

  if (!API_KEY) {
    let msg = 'RUM API key was not found.'
    if (process.env.NODE_ENV !== 'production') {
      msg += ' Pausing Faro instance to prevent auth errors being thrown.'
    }
    console.info(msg)
    faro.pause()
  }

  return faro
}
