import { getApplicationConfig } from '../utils'

export const getAppVersion = (): string => {
  return getApplicationConfig().APP_VERSION
}

export const getShortAppVersion = (): string => {
  const version = getAppVersion()
  const index = version.indexOf('-g')
  if (index === -1) {
    return version
  }

  return version.substring(0, index)
}

export const getAppBuildNumber = (): string => {
  return getApplicationConfig().APP_BUILD_NUMBER
}
