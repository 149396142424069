import styled from 'styled-components'

import type { TWindowSize } from '../../../theme_new/styled'

export const QuantityInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  > mc-input {
    flex-grow: 1;
  }
`

export const Heading = styled.span`
  display: flex;
  align-items: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    gap: 32px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    gap: 40px;
  }
`

export const Column = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex-basis: 33.33%;
`

export const resolveNotesHeight = (windowSize: TWindowSize): `${number}px` => {
  const sizeHeights = { small: 166, medium: 198, large: 222 }
  return `${sizeHeights[windowSize]}px`
}
