import { useContext } from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import { McInput, McTooltip } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'
import { DataPoint } from '../../../queries/GandalfApi/GandalfApi.utils'

type Props = {
  name: string
  unit?: string
  isIgnored?: boolean
  onBlur?: () => void
}

const NumberInputField = ({ name, unit, isIgnored, onBlur }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { initialValues } = useFormikContext<GandalfApi.MetcReport>()
  const [field, meta, helpers] = useField<DataPoint<number>>(name)

  const threshold = initialValues.data.meta.dataAvailabilityThreshold
  const dataPoint = field.value
  const { externalSource: extSrc } = dataPoint

  /**
   * Prevents changing the value of the input field by scrolling while the
   * cursor is over it.
   */
  const handleOnWheel = (event: WheelEvent) => {
    if (document.activeElement === event.target) {
      event.preventDefault()
    }
  }

  const handleChange = async (event: FocusEvent) => {
    const target = event.target as HTMLInputElement
    const stringValue = target.value as string | null
    let newValue: number | null = null
    if (stringValue !== null && stringValue !== '') {
      newValue = Number(stringValue)
    }

    if (dataPoint.value === newValue) {
      // No change. Do nothing.
      return
    }

    // Update the data point value.
    dataPoint.value = newValue

    if (
      extSrc !== null &&
      extSrc.value !== null &&
      extSrc.dataAvailability >= threshold
    ) {
      // Ext. source is present, it has a value and data is available.
      dataPoint.isOverridden = newValue !== extSrc.value
    }

    // Update the formik field value.
    await helpers.setValue(dataPoint)

    // Call the onBlur callback if it is present.
    onBlur?.()
  }

  const Input = (
    <StyledMcInput
      slot='trigger'
      type='number'
      fit={windowSize}
      name={name}
      value={dataPoint.value}
      suffix={unit}
      hiddenlabel
      icon={dataPoint.isOverridden ? 'exclamation-circle' : undefined}
      isManualInput={
        extSrc === null ||
        extSrc.value === null ||
        extSrc.dataAvailability < threshold
      }
      isIgnored={isIgnored}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      // @ts-ignore
      onwheel={handleOnWheel}
      blur={handleChange}
      data-e2e={name}
    />
  )

  return extSrc !== null && dataPoint.isOverridden ? (
    <McTooltip position='top-center' appearance='neutral-inverse'>
      {Input}
      <span>
        Original value was: {extSrc.value} {unit}
      </span>
    </McTooltip>
  ) : (
    Input
  )
}

export default NumberInputField

const StyledMcInput = styled(McInput)<{
  isManualInput?: boolean
  isIgnored?: boolean
}>`
  opacity: ${(props) => (props.isIgnored ? '0.5' : '1')};

  ::part(field),
  ::part(input) {
    background-color: ${(props) =>
      props.isManualInput
        ? 'var(--mds_brand_appearance_primary_subtle_background-color)'
        : 'initial'};
  }

  ::part(input) {
    font-variant: tabular-nums;
  }
`
