import { TabStatus } from '../EngineTestReportPage.consts'

export const AetcReportTabSearchParam = 'aetc-report-tab'

export enum TabIndex {
  GENERAL,
  CYLINDERS,
  RESULTS,
}

export enum Section {
  GENERAL = 'General',
  CYLINDERS = 'Cylinders',
  RESULTS = 'Results',
}

export const TabIndexToSection = {
  [TabIndex.GENERAL]: Section.GENERAL.toLowerCase(),
  [TabIndex.CYLINDERS]: Section.CYLINDERS.toLowerCase(),
}

export type TabsStatus = {
  [key in TabIndex]: TabStatus
}

export const keyParamsRanges: Record<
  keyof GandalfApi.Aetc.KeyParameters,
  string
> = {
  sfoc: '±5 %',
  pmax: '±5 bar',
  sfocDuePmax: '',
  scavAirPres: '±10 %',
  exhGasTempAvg: '±50 °C',
  tcInletExhTemp: '±50 °C',
  tcOutletExhTemp: '±50 °C',
  tcSpeed: '±5 %',
  fuelPumpIdx: '±2 mm',
  vitPos: '0 %',
  thermalEfficiency: '±2 %',
  electricalEfficiency: '±2 %',
}
