import moment from 'moment'
import {
  type CellContext,
  createColumnHelper,
  type FilterFn,
} from '@tanstack/react-table'
import {
  McCheckbox,
  McIcon,
  McTag,
  McTooltip,
} from '@maersk-global/mds-react-wrapper'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

import { dateFilter } from '../../ActivityLogPage/ActivityLogFilters/DateFilter/helpers'
import { type TWindowSize } from '../../../theme_new/styled'
import {
  Header,
  IconCell,
  NumericCell,
  NumericHeader,
} from '../../../components/Table/Table.styles'
import { formatValue } from '../../../utils'
import { DATE_FORMAT } from '../../../components/formik/InputDateTime/InputDateTime.utils'
import { getVesselName } from '../ClassComparisonPage.utils'
import { Performance } from '../../../api-models'
import {
  MainEngInstanceName,
  StaticModelType,
  StaticModelTypeNames,
} from '../../../queries/MasterDataApi/MasterDataApi.consts'

const columnHelper = createColumnHelper<GandalfApi.EngineTestReportSummary>()

export const getColumns = (
  windowSize: TWindowSize,
  onReportSelected: (reportId: string, isChecked: boolean) => void,
  selectedReports: Set<string>,
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse,
  vesselConfig?: Performance.VesselConfiguration.Configuration,
) => {
  const vesselNameFilterFn: FilterFn<GandalfApi.EngineTestReportSummary> = (
    row,
    columnId,
    filterStr,
  ) => {
    const vessel = getVesselName(
      +row.original.imo,
      sisterVesselsData,
    ).toLowerCase()
    return vessel.includes(filterStr.toLowerCase())
  }

  return [
    columnHelper.display({
      id: 'compare',
      header: 'Compare',
      cell: ({ row }: CellContext<GandalfApi.EngineTestReportSummary, any>) => {
        return (
          <IconCell>
            <McCheckbox
              fit={windowSize}
              hiddenlabel
              checked={selectedReports.has(row.original.id)}
              change={(event: CustomEvent<boolean>) => {
                const target = event.target as HTMLInputElement
                onReportSelected(row.original.id, target.checked)
              }}
            />
          </IconCell>
        )
      },
    }),
    columnHelper.display({
      id: 'vesselName',
      header: 'Vessel Name',
      filterFn: vesselNameFilterFn,
      cell: ({ row }: CellContext<GandalfApi.EngineTestReportSummary, any>) =>
        getVesselName(+row.original.imo, sisterVesselsData),
    }),
    columnHelper.accessor('num', {
      header: 'Report No.',
    }),
    columnHelper.accessor('start', {
      header: 'Report date (UTC)',
      cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY, HH:mm'),
      filterFn: dateFilter,
    }),
    columnHelper.display({
      id: 'duration',
      header: 'Report duration',
      cell: ({ row }: CellContext<GandalfApi.EngineTestReportSummary, any>) => {
        const start = moment.utc(row.original.start)
        const end = moment.utc(row.original.end)
        const duration = moment.duration(end.diff(start))
        return `${duration.hours()}h ${duration.minutes()}m`
      },
    }),
    columnHelper.accessor('eq', {
      header: 'Equipment',
      cell: ({ row }: CellContext<GandalfApi.EngineTestReportSummary, any>) =>
        resolveEquipmentDisplayName(
          row.original.eq,
          vesselConfig?.hasTwoMainEngines || false,
        ),
    }),
    columnHelper.accessor('load', {
      header: () => <NumericHeader>Engine load</NumericHeader>,
      cell: (info) => {
        return (
          <NumericCell>{formatValue(info.getValue(), 1) + ' %MCR'}</NumericCell>
        )
      },
    }),
    columnHelper.accessor('sfocDev', {
      header: () => <NumericHeader>SFOC deviation</NumericHeader>,
      cell: (info) => {
        if (info.getValue() === null) {
          return <NumericCell>-</NumericCell>
        }
        return (
          <NumericCell>{formatValue(info.getValue(), 1) + ' %'}</NumericCell>
        )
      },
    }),
    columnHelper.display({
      id: 'warnings',
      header: 'Warnings',
      cell: ({ row }: CellContext<GandalfApi.EngineTestReportSummary, any>) => {
        const wc = row.original.warnCount

        if (wc) {
          return (
            <IconCell>
              <McTag fit={windowSize} appearance='error'>
                &nbsp;{wc}&nbsp;
              </McTag>
            </IconCell>
          )
        }

        return (
          <IconCell>
            <McTag fit={windowSize} appearance='success'>
              &nbsp;OK&nbsp;
            </McTag>
          </IconCell>
        )
      },
    }),
    columnHelper.accessor('cmt', {
      header: () => <Header icon>Comment</Header>,
      cell: ({ row }: CellContext<GandalfApi.EngineTestReportSummary, any>) => {
        if (!row.original.cmt) return <IconCell>-</IconCell>

        return (
          <IconCell>
            <McTooltip
              width={400}
              fit={windowSize}
              appearance='inverse'
              position='top-center'
            >
              <McIcon
                slot='trigger'
                icon='comment'
                size={windowSize === 'small' ? '20' : '24'}
              />
              <span>{row.original.cmt}</span>
            </McTooltip>
          </IconCell>
        )
      },
      enableSorting: false,
    }),
  ]
}

/**
 * Resolves the display name for a given equipment string.
 *
 * @param equipment - The equipment string, where the last character is a number indicating the instance.
 * @param isTwinEngine - A flag indicating if the vessel has two main engines.
 * @returns The resolved display name for the equipment.
 */
const resolveEquipmentDisplayName = (
  equipment: string,
  isTwinEngine: boolean,
) => {
  const instance = parseInt(equipment.slice(-1), 10)
  if (isNaN(instance)) {
    console.error(
      'Unable to resolve instance number from equipment string:',
      equipment,
    )
    return '-'
  }

  const staticModelType = equipment.includes(
    StaticModelTypeNames[StaticModelType.MainEng],
  )
    ? StaticModelType.MainEng
    : StaticModelType.AuxEng

  let displayName = StaticModelTypeNames[staticModelType]
  if (staticModelType === StaticModelType.MainEng && isTwinEngine) {
    displayName += ` ${MainEngInstanceName[instance]}`
  }

  return displayName
}

export const getInitialDateRange = (): IDateRangeValue => {
  const to = moment.utc().endOf('month')
  const from = to.clone().subtract(1, 'year').startOf('month')

  return {
    from: from.format(DATE_FORMAT),
    to: to.format(DATE_FORMAT),
  }
}
