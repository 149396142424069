import { useContext } from 'react'

import { TableContainer } from '../../EngineTestReportPage.styles'
import { formatValue } from '../../../../utils'
import { WindowContext } from '../../../../contexts'

type Props = {
  general: GandalfApi.Metc.General
  adjustedIsoConditions: GandalfApi.Metc.AdjustedIsoConditions
}

const AdjustedToIsoConditions = ({ general, adjustedIsoConditions }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <thead>
          <tr>
            <th>Measurements adjusted to ISO condition</th>
            <th>Measured value</th>
            <th>Model</th>
            <th>SFOC impact</th>
            <th>Pcomp impact</th>
            <th>Pmax impact</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Barometric Pressure</td>
            <td>{general.erAmbientPres.value} mbar</td>
            <td>
              {formatValue(adjustedIsoConditions.erAmbientPres.modelValue, 0)}{' '}
              mbar
            </td>
            <td>
              {formatValue(adjustedIsoConditions.erAmbientPres.sfoc, 2)} g/kWh
            </td>
            <td>
              {formatValue(adjustedIsoConditions.erAmbientPres.pcomp, 2)} bar
            </td>
            <td>
              {formatValue(adjustedIsoConditions.erAmbientPres.pmax, 2)} bar
            </td>
          </tr>
          <tr>
            <td>Blower Inlet Temp.</td>
            <td>{general.blowerInletTemp.value} °C</td>
            <td>
              {formatValue(adjustedIsoConditions.blowerInletTemp.modelValue, 2)}{' '}
              °C
            </td>
            <td>
              {formatValue(adjustedIsoConditions.blowerInletTemp.sfoc, 2)} g/kWh
            </td>
            <td>
              {formatValue(adjustedIsoConditions.blowerInletTemp.pcomp, 2)} bar
            </td>
            <td>
              {formatValue(adjustedIsoConditions.blowerInletTemp.pmax, 2)} bar
            </td>
          </tr>
          <tr>
            <td>Scavenging Air Temp.</td>
            <td>{general.scavAirTemp.value} °C</td>
            <td>
              {formatValue(adjustedIsoConditions.scavAirTemp.modelValue, 2)} °C
            </td>
            <td>
              {formatValue(adjustedIsoConditions.scavAirTemp.sfoc, 2)} g/kWh
            </td>
            <td>
              {formatValue(adjustedIsoConditions.scavAirTemp.pcomp, 2)} bar
            </td>
            <td>
              {formatValue(adjustedIsoConditions.scavAirTemp.pmax, 2)} bar
            </td>
          </tr>
          <tr>
            <td>Back Pressure</td>
            <td>{general.turbineBackPres.value} mmH2O</td>
            <td>
              {formatValue(adjustedIsoConditions.turbineBackPres.modelValue, 2)}{' '}
              mmH2O
            </td>
            <td>-</td>
            <td>
              {formatValue(adjustedIsoConditions.turbineBackPres.pcomp, 2)} bar
            </td>
            <td>
              {formatValue(adjustedIsoConditions.turbineBackPres.pmax, 2)} bar
            </td>
          </tr>
          <tr>
            <td>Thrust Bearing Loss</td>
            <td>-</td>
            <td>
              {formatValue(
                adjustedIsoConditions.thrustBearingLoss.modelValue
                  ? adjustedIsoConditions.thrustBearingLoss.modelValue * 100
                  : null,
                0,
              )}{' '}
              %
            </td>
            <td>
              {formatValue(adjustedIsoConditions.thrustBearingLoss.sfoc, 2)}{' '}
              g/kWh
            </td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Adjusted to ISO Conditions</td>
            <td>-</td>
            <td>-</td>
            <td className='strong'>
              {formatValue(
                adjustedIsoConditions.adjustedToISOConditions.sfoc,
                2,
              )}{' '}
              g/kWh
            </td>
            <td className='strong'>
              {formatValue(
                adjustedIsoConditions.adjustedToISOConditions.pcomp,
                2,
              )}{' '}
              bar
            </td>
            <td className='strong'>
              {formatValue(
                adjustedIsoConditions.adjustedToISOConditions.pmax,
                2,
              )}{' '}
              bar
            </td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default AdjustedToIsoConditions
