import {
  MetcReportTabSearchParam,
  TabIndex,
  TabIndexToSection,
  type TabsStatus,
} from './MetcReportPage.consts'
import { TabStatus } from '../EngineTestReportPage.consts'
import { ReportState } from '../../../queries/GandalfApi/GandalfApi.consts'

/**
 * Resolves the initial tab index based on the URL search params. If the search
 * params do not contain a valid tab index, we default to the
 * <strong>General</strong> tab.
 */
export const resolveInitialTabIndex = (searchParams: URLSearchParams) => {
  const defaultTabIdx = TabIndex.GENERAL

  const initialTabIdx = parseInt(
    searchParams.get(MetcReportTabSearchParam) ?? defaultTabIdx.toString(),
  )

  const isValidTabIdx = Object.values(TabIndex).includes(initialTabIdx)

  return isValidTabIdx ? initialTabIdx : defaultTabIdx
}

/**
 * Resolves the initial status of the report tabs/sections based on the report
 * state. If the report state is `submitted`, all tabs are marked as done.
 */
export const resolveInitialTabsStatus = (
  state: GandalfApi.ReportState,
): TabsStatus => {
  return state === ReportState.Submitted
    ? getDoneTabsStatus()
    : getIndeterminateTabsStatus()
}

/**
 * Resolves the status of the report tabs/sections based on the errors returned
 * from Gandalf.
 */
export const resolveTabsStatus = (err: GandalfApi.ValidationError) => {
  const status = getDoneTabsStatus()
  err.body.errors.forEach((error) => {
    error.paths.forEach((path) => {
      if (path.startsWith(TabIndexToSection[TabIndex.GENERAL])) {
        status[TabIndex.GENERAL] = TabStatus.ERROR
      } else if (path.startsWith(TabIndexToSection[TabIndex.CYLINDERS])) {
        status[TabIndex.CYLINDERS] = TabStatus.ERROR
      } else if (path.startsWith(TabIndexToSection[TabIndex.TURBOCHARGERS])) {
        status[TabIndex.TURBOCHARGERS] = TabStatus.ERROR
      }
    })
  })

  if (err.body.errors.length > 0) {
    status[TabIndex.RESULTS] = TabStatus.ERROR
  }

  return status
}

/**
 * Yields a {@link TabsStatus} object with all tabs set to
 * {@link TabsStatus.INDETERMINATE}.
 */
export const getIndeterminateTabsStatus = (): TabsStatus => ({
  [TabIndex.GENERAL]: TabStatus.INDETERMINATE,
  [TabIndex.CYLINDERS]: TabStatus.INDETERMINATE,
  [TabIndex.TURBOCHARGERS]: TabStatus.INDETERMINATE,
  [TabIndex.RESULTS]: TabStatus.INDETERMINATE,
})

/**
 * Yields a {@link TabsStatus} object with all tabs set to
 * {@link TabsStatus.SUCCESS}.
 */
const getDoneTabsStatus = (): TabsStatus => ({
  [TabIndex.GENERAL]: TabStatus.SUCCESS,
  [TabIndex.CYLINDERS]: TabStatus.SUCCESS,
  [TabIndex.TURBOCHARGERS]: TabStatus.SUCCESS,
  [TabIndex.RESULTS]: TabStatus.SUCCESS,
})
