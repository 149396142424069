import { type CellContext, createColumnHelper } from '@tanstack/table-core'
import moment from 'moment'
import { McTag } from '@maersk-global/mds-react-wrapper'

import { dateFilter } from '../ActivityLogFilters/DateFilter/helpers'
import { type TWindowSize } from '../../../theme_new/styled'
import { DATE_TIME_FORMAT } from '../../../components/formik/InputDateTime/InputDateTime.utils'
import { NotificationState } from '../../../queries/NotificationsApi/NotificationsApi.consts'
import {
  getFirstVesselResponse,
  getLatestVesselResponseForStep,
} from '../../../components/PerformanceAlerts/Diagnostics/Diagnostics.utils'
import {
  CloseAction,
  Status,
  StatusDisplay,
  StatusTagAppearance,
} from '../../../components/PerformanceAlerts/Diagnostics/Diagnostics.consts'
import {
  getEquipmentName,
  getLatestNotificationResponse,
  getSfocStatus,
} from '../../../components/PerformanceAlerts/PerformanceAlerts.utils'
import {
  NumericCell,
  NumericHeader,
} from '../../../components/Table/Table.styles'
import { displayToast, formatValue } from '../../../utils'
import { prettyDuration } from '../../../features/hybrid-data-collector/utils'

const columnHelper = createColumnHelper<NotificationsAPI.PerformanceAlert>()

export const getColumns = (windowSize: TWindowSize, isTwinEngine: boolean) => {
  return [
    columnHelper.accessor('created_at', {
      header: 'Start',
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) => {
        const { id, created_at } = row.original
        return (
          <span
            className='copy-to-clipboard'
            onClick={async () => {
              await navigator.clipboard.writeText(JSON.stringify(row.original))
              void displayToast(
                'success',
                'Notification JSON copied to clipboard',
                id,
              )
            }}
          >
            {moment.utc(created_at).format(DATE_TIME_FORMAT)}
          </span>
        )
      },
      filterFn: dateFilter,
    }),
    columnHelper.accessor('ends_at', {
      header: 'End',
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) => {
        if (row.original.state !== NotificationState.Closed) {
          return '-'
        }

        const latestResponse = getLatestNotificationResponse(row.original)
        if (!latestResponse) {
          return '-'
        }

        return moment.utc(latestResponse.created_at).format(DATE_TIME_FORMAT)
      },
    }),
    columnHelper.display({
      id: 'duration',
      header: 'Active duration',
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) =>
        resolveActiveDuration(row.original),
    }),
    columnHelper.display({
      id: 'equipment',
      header: 'Equipment',
      filterFn: equipmentFilterFn(isTwinEngine),
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) => {
        const { type, data } = row.original
        return getEquipmentName(type, isTwinEngine ? data.instance : undefined)
      },
    }),
    columnHelper.display({
      id: 'description',
      header: 'Description',
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) => {
        return (
          <span title={row.original.data.description}>
            {getSfocStatus(row.original)}
          </span>
        )
      },
    }),
    columnHelper.accessor('data.estimatedWasteToPort', {
      header: () => <NumericHeader>Excess consumption [MT]</NumericHeader>,
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) => {
        const { data } = row.original
        let waste = '-'
        if (
          data.estimatedWasteToPort !== null &&
          data.estimatedWasteToPort > 0
        ) {
          waste = formatValue(data.estimatedWasteToPort, 2)
        }
        return <NumericCell>{waste}</NumericCell>
      },
    }),
    columnHelper.display({
      id: 'status',
      header: 'Status',
      filterFn: statusFilterFn,
      cell: ({ row }: CellContext<NotificationsAPI.PerformanceAlert, any>) => {
        const status = resolveStatus(row.original)
        return (
          <McTag
            fit={windowSize}
            style={{ textTransform: 'capitalize' }}
            appearance={StatusTagAppearance[status]}
          >
            {StatusDisplay[status]}
          </McTag>
        )
      },
    }),
  ]
}

const resolveActiveDuration = (
  alert: NotificationsAPI.PerformanceAlert,
): number | string => {
  const { created_at, notification_responses } = alert
  const firstVesselResponse = getFirstVesselResponse(notification_responses)
  if (!firstVesselResponse) return '-'

  return prettyDuration(
    moment(created_at),
    moment(firstVesselResponse.created_at),
  )
}

const resolveStatus = (
  alert: NotificationsAPI.PerformanceAlert,
): Diagnostics.Status => {
  const { state, notification_responses } = alert

  if (state === NotificationState.Active) {
    // Active: If advice has been triggered on the vessel, and yet no action taken on the vessel
    return Status.Active
  }

  const latestVesselResponse = getLatestVesselResponseForStep(
    notification_responses,
  )

  if (latestVesselResponse?.data.reason === CloseAction.Dismissed) {
    // Dismissed: If advice has been triggered, and action has been dismissed by the vessel
    return Status.Dismissed
  }

  if (state === NotificationState.Closed) {
    if (!latestVesselResponse) {
      // Timed-out: If advice has been triggered, and the advice has been closed without any action from the vessel.
      return Status.TimedOut
    }

    // Followed: 1) If advice has been triggered, 2) action has been taken, and then 3) SFOC is dropping in the awaiting action period
    return Status.Followed
  }

  // Awaiting action: if advice has been triggered, and action has been taken/accepted by the vessel
  return Status.AwaitingAction
}

const equipmentFilterFn =
  (isTwinEngine: boolean) =>
  (row: any, columnId: string, filterStr: string) => {
    const { type, data } = row.original
    const equipmentName = getEquipmentName(
      type,
      isTwinEngine ? data.instance : undefined,
    )
    return equipmentName === filterStr
  }

const statusFilterFn = (row: any, columnId: string, filterStr: string) => {
  return resolveStatus(row.original) === filterStr
}
