import { type MouseEvent, useContext, useMemo, useRef, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { McButton } from '@maersk-global/mds-react-wrapper'
import { type McListItem } from '@maersk-global/mds-components-core'

import { engineTestReportToListModel, getColumns } from './EngineTestPage.utils'
import { EngineTestReportAction as Action } from './EngineTestPage.consts'
import EngineTestFilters from './EngineTestFilters/EngineTestFilters'
import CreateEngineTestModal from './CreateEngineTestModal/CreateEngineTestModal'
import EmptyState from './EmptyState/EmptyState'

import {
  EngineTestReportTabIndex,
  EngineTestReportTabSearchParam,
} from '../EngineTestReportPage/EngineTestReportPage.consts'
import { ContentLayout } from '../../layout'
import { ContentCard, Loading } from '../../commons'
import { type routerParams } from '../../routes'
import { WindowContext } from '../../contexts'
import { useGetReports } from '../../queries/GandalfApi/GandalfApi'
import {
  ReportState,
  ReportType,
} from '../../queries/GandalfApi/GandalfApi.consts'
import Table, { type ITableRef } from '../../components/Table/Table'
import { PadContent } from '../../layout/styles'
import EnginePerformanceTestNotifications from '../../components/EnginePerformanceTestNotifications/EnginePerformanceTestNotifications'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import DeleteReportModal from '../../components/Reporting/DeleteReportModal'
import { useVesselMainEngines } from '../../queries/MasterDataApi/MasterDataApi'

const EngineTestPage = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { vesselId: imoNo } = useParams<routerParams>()
  const vesselMainEngines = useVesselMainEngines(imoNo)
  const { windowSize } = useContext(WindowContext)
  const getMetcReports = useGetReports<GandalfApi.Metc.ReportData>(
    imoNo,
    ReportType.Metc,
  )
  const getAetcReports = useGetReports<GandalfApi.Aetc.ReportData>(
    imoNo,
    ReportType.Aetc,
  )

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState<string>()
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'startTimestamp' },
  ])

  const tableRef = useRef<ITableRef>(null)

  const reports = useMemo(() => {
    if (
      !getMetcReports.isSuccess ||
      !getAetcReports.isSuccess ||
      !vesselMainEngines.isSuccess
    ) {
      return []
    }

    const isTwinMainEngine = vesselMainEngines.data.engines.length === 2

    return [
      ...getMetcReports.data.reports.map((r) =>
        engineTestReportToListModel(r, isTwinMainEngine),
      ),
      ...getAetcReports.data.reports.map((r) => engineTestReportToListModel(r)),
    ]
  }, [getMetcReports.data, getAetcReports.data, vesselMainEngines.isSuccess])

  const handleCreateReportClick = () => {
    setCreateModalOpen(true)
  }

  const handleRowClick = (
    row: Row<GandalfApi.EngineTestReport>,
    event: MouseEvent,
  ) => {
    const target = event.target as HTMLElement
    if (target.closest('mc-button, mc-list-item')) {
      /* Return if clicking on a menu button or menu item. */
      tableRef.current?.deselectRow()
      return
    }

    const { reportId, reportType, reportState } = row.original

    const tabSearchParam = EngineTestReportTabSearchParam[reportType]
    const tabIdx = EngineTestReportTabIndex[reportType][reportState]

    history.push(`${url}/${reportType}/${reportId}?${tabSearchParam}=${tabIdx}`)
  }

  const handleListClick =
    (reportId: string) =>
    ({ detail }: CustomEvent) => {
      setSelectedReportId(reportId)
      const item: McListItem = detail.item

      const reportType = reports.find(
        (report) => report.reportId === reportId,
      )?.reportType

      if (!reportType) {
        console.error('Report type could not be resolved from given report ID.')
        return
      }

      const tabSearchParam = EngineTestReportTabSearchParam[reportType]
      const tabIdx = EngineTestReportTabIndex[reportType]

      switch (item.label) {
        case Action.ViewResults:
          history.push(
            `${url}/${reportType}/${reportId}?${tabSearchParam}=${
              tabIdx[ReportState.Submitted]
            }`,
          )
          break
        case Action.EditTest:
          history.push(
            `${url}/${reportType}/${reportId}?${tabSearchParam}=${
              tabIdx[ReportState.Draft]
            }`,
          )
          break
        case Action.Delete:
          setDeleteModalOpen(true)
          break
      }
    }

  const handleDeleteModalClose = () => {
    setSelectedReportId(undefined)
    setDeleteModalOpen(false)
  }

  const handleCreateModalClose = () => {
    setCreateModalOpen(false)
  }

  const table = useReactTable({
    data: reports,
    columns: getColumns(windowSize, handleListClick),
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  if (
    !getMetcReports.isSuccess ||
    !getAetcReports.isSuccess ||
    !vesselMainEngines.isSuccess
  ) {
    return <Loading />
  }

  const selectedReport = reports.find(
    (report) => report.reportId === selectedReportId,
  )

  const renderButton = (
    <McButton
      fit={windowSize}
      label='Add Engine Test Report'
      click={handleCreateReportClick}
      data-e2e='add-engine-test-report-button'
    />
  )

  const hasReports = reports.length !== 0

  return (
    <>
      <ContentLayout header='Engine test'>
        <TemNotifications />
        <PerformanceAlerts />
        <EnginePerformanceTestNotifications />
        <ContentCard
          id='engine-test__reports-overview'
          title={hasReports ? 'Reports overview' : ''}
          additionalInfo={hasReports ? renderButton : null}
        >
          <PadContent>
            {hasReports ? (
              <>
                <EngineTestFilters table={table} />
                <Table
                  table={table}
                  onRowSelect={handleRowClick}
                  ref={tableRef}
                />
              </>
            ) : (
              <EmptyState>{renderButton}</EmptyState>
            )}
          </PadContent>
        </ContentCard>
      </ContentLayout>
      <CreateEngineTestModal
        open={createModalOpen}
        onClose={handleCreateModalClose}
      />
      {selectedReport && (
        <DeleteReportModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          imoNo={imoNo}
          report={selectedReport}
        />
      )}
    </>
  )
}

export default EngineTestPage
