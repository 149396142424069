import * as React from 'react'
import * as ECharts from 'echarts'
import * as colors from '../themes'
import moment from 'moment'
import styled from 'styled-components'
import { MeSfocVsPowerChart } from '../charts/MeSfocVsPowerChart'
import { MeSfocVsTimeChart } from '../charts/MeSfocVsTimeChart'
import { connectChartsLineScatter } from '../chartConnector'
import { Performance } from '../../../api-models'
import { Filters, filtersChanged, getDateRange } from '../../filter'
import { Chart, getDefaultFilters } from '../../../utils'
import { layoutSizes } from '../../../theme'
import {
  ChartContainer,
  ChartStencil,
  ContentCard,
  NotFound,
  VesselStatusBlock,
} from '../../../commons'
import { UNITS } from '../../../utils/constants'
import { MeSfocChartActionType, ToggleInMeSfocChart } from '../models'
import { EngineLabel } from './EngineLabel'
import { MeSfocLegend } from './MeSfocLegend'

const Wrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`

interface MeSfocChartContainerProps {
  vesselStatus?: Performance.Status.Status | null
  imoNo: string
  configuration: Performance.VesselConfiguration.Configuration
  filters?: Filters
  data?: HydraApi.Sfoc.MainEngine
  dataShopTestModels?: MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData>
  dataVesselSpecific?: HydraApi.SfocBaselineModel.BaselineModel
}

interface MeSfocChartContainerState {
  chartsMounted: boolean
  toggleInChart: ToggleInMeSfocChart
}

class MeSfocChartContainer extends React.Component<
  MeSfocChartContainerProps,
  MeSfocChartContainerState
> {
  constructor(props: MeSfocChartContainerProps) {
    super(props)
    const hasTwoMainEngines = props.configuration.hasTwoMainEngines
    this.state = {
      chartsMounted: false,
      toggleInChart: {
        ME1: !hasTwoMainEngines,
        ME2: false,
        Mix: hasTwoMainEngines,
        showShopTestCurve: false,
        showVesselSpecific: !props.configuration.hasMainEngSecondaryFuelLine,
        showStableDataPointsOnly: true,
      },
    }
  }

  componentDidUpdate(
    prevProps: MeSfocChartContainerProps,
    prevState: MeSfocChartContainerState,
  ) {
    const { filters, imoNo, data, vesselStatus, configuration } = this.props
    const { chartsMounted, toggleInChart } = this.state
    if (
      filtersChanged(prevProps.filters, filters) ||
      prevProps.imoNo !== imoNo ||
      prevProps.vesselStatus?.timestamp !== vesselStatus?.timestamp
    ) {
      this.setState({
        chartsMounted: false,
      })
    }

    if (!chartsMounted && document.getElementById('me-sfoc-vs-power-chart')) {
      this.setState({ chartsMounted: true })
    }

    if (!!data && chartsMounted && !prevState.chartsMounted) {
      const [power, time] = [
        document.getElementById('me-sfoc-vs-power-chart'),
        document.getElementById('me-power-vs-time-chart'),
      ] as Array<HTMLDivElement | null>
      if (power && time) {
        const powerChart = ECharts.getInstanceByDom(power) as Chart
        const timeChart = ECharts.getInstanceByDom(time) as Chart
        connectChartsLineScatter(timeChart, powerChart, data.timestamps)
      }
    }

    if (
      prevProps.configuration.hasMainEngSecondaryFuelLine !==
      configuration.hasMainEngSecondaryFuelLine
    ) {
      this.setState({
        toggleInChart: {
          ...toggleInChart,
          showVesselSpecific: false,
        },
      })
    }
  }

  toggleReducer = (action: MeSfocChartActionType) => {
    const { toggleInChart } = this.state
    const {
      showShopTestCurve,
      showVesselSpecific,
      ME1,
      ME2,
      Mix,
      showStableDataPointsOnly,
    } = toggleInChart
    switch (action) {
      case MeSfocChartActionType.TOGGLE_ME1:
        this.setState({
          toggleInChart: {
            ...toggleInChart,
            ME1: !ME1,
          },
        })
        break
      case MeSfocChartActionType.TOGGLE_ME2:
        this.setState({
          toggleInChart: {
            ...toggleInChart,
            ME2: !ME2,
          },
        })
        break
      case MeSfocChartActionType.TOGGLE_MIX:
        this.setState({
          toggleInChart: {
            ...toggleInChart,
            Mix: !Mix,
          },
        })
        break
      case MeSfocChartActionType.TOGGLE_SHOP_TEST:
        this.setState({
          toggleInChart: {
            ...toggleInChart,
            showShopTestCurve: !showShopTestCurve,
          },
        })
        break
      case MeSfocChartActionType.TOGGLE_VESSEL_SPECIFIC:
        this.setState({
          toggleInChart: {
            ...toggleInChart,
            showVesselSpecific: !showVesselSpecific,
          },
        })
        break
      case MeSfocChartActionType.TOGGLE_NON_STABLE_POINTS:
        this.setState({
          toggleInChart: {
            ...toggleInChart,
            showStableDataPointsOnly: !showStableDataPointsOnly,
          },
        })
        break
      default:
    }
  }

  public render() {
    const {
      filters,
      configuration,
      data,
      dataShopTestModels,
      vesselStatus,
      dataVesselSpecific,
    } = this.props
    const { toggleInChart } = this.state
    const queryFilterPeriod = getDateRange(
      getDefaultFilters(14, 'd').dateRange,
      filters,
    )
    const timeLineMinValue = moment.utc(queryFilterPeriod.from).valueOf()
    const hasVesselSpecificEnabled = !!dataVesselSpecific
    return (
      <Wrapper>
        <ContentCard
          id='sfoc_vs_power'
          title='ME SFOC vs ME Power'
          helpTextKey='propulsion/sfoc'
          width={layoutSizes.full}
          notification={
            'The content in this graph displays 90 days of data regardless of what date range you have selected'
          }
        >
          <MeSfocLegend
            toggleInChart={toggleInChart}
            toggleReducer={this.toggleReducer}
            hasVesselSpecificData={hasVesselSpecificEnabled}
          />
          <ChartContainer
            y1Label={{
              name: UNITS.SFOC,
              colorScheme: colors.normal,
            }}
            xLabel={{
              name: UNITS.ENGINE_LOAD,
              colorScheme: colors.normal,
            }}
            minHeight={350}
          >
            {!data && <ChartStencil chartType='scatter' minHeight={350} />}
            {configuration && !!data && data.hasData && (
              <MeSfocVsPowerChart
                hasTwoMainEngines={configuration.hasTwoMainEngines}
                queryPeriod={queryFilterPeriod}
                data={data}
                dataShopTestModels={dataShopTestModels}
                dataVesselSpecific={dataVesselSpecific}
                toggleInChart={toggleInChart}
                hasVesselSpecificEnabled={hasVesselSpecificEnabled}
              />
            )}
            {!!vesselStatus && !!configuration && (
              <VesselStatusBlock
                timestamp={vesselStatus.timestamp}
                lastSixHours={true}
              >
                <EngineLabel
                  title={configuration.hasTwoMainEngines ? 'Port ME' : 'ME'}
                  enginePowerStatus={
                    vesselStatus.hasData && vesselStatus.mainEng1
                      ? vesselStatus.mainEng1.sfoc
                      : undefined
                  }
                  colorScheme={colors.meEng1}
                  onChangeEngineLabel={() =>
                    this.toggleReducer(MeSfocChartActionType.TOGGLE_ME1)
                  }
                  isDisabled={toggleInChart.ME1}
                />
                {configuration.hasTwoMainEngines && (
                  <>
                    <EngineLabel
                      title='Stbd ME'
                      enginePowerStatus={
                        vesselStatus.hasData && vesselStatus.mainEng2
                          ? vesselStatus.mainEng2.sfoc
                          : undefined
                      }
                      colorScheme={colors.meEng2}
                      onChangeEngineLabel={() =>
                        this.toggleReducer(MeSfocChartActionType.TOGGLE_ME2)
                      }
                      isDisabled={toggleInChart.ME2}
                    />

                    <EngineLabel
                      title='Mix MEs'
                      enginePowerStatus={
                        vesselStatus.hasData && vesselStatus.mainEng
                          ? vesselStatus.mainEng.sfoc
                          : undefined
                      }
                      colorScheme={colors.meMix}
                      onChangeEngineLabel={() =>
                        this.toggleReducer(MeSfocChartActionType.TOGGLE_MIX)
                      }
                      isDisabled={toggleInChart.Mix}
                    />
                  </>
                )}
              </VesselStatusBlock>
            )}
          </ChartContainer>
          {!!data && !data.hasData && (
            <NotFound text='No data for the selected period found.' />
          )}
          <ChartContainer
            y1Label={{
              name: UNITS.GRAM_KILOWATT_HOUR,
              colorScheme: colors.normal,
            }}
            minHeight={250}
            hideSideContent={true}
          >
            {!data && <ChartStencil chartType='line' minHeight={350} />}

            {configuration && !!data && data.hasData && (
              <MeSfocVsTimeChart
                data={data}
                dataShopTestModels={dataShopTestModels}
                timeLineMinValue={timeLineMinValue}
                hasTwoMainEngines={configuration.hasTwoMainEngines}
                hasVesselSpecificEnabled={hasVesselSpecificEnabled}
                toggleInChart={toggleInChart}
                dataVesselSpecific={dataVesselSpecific}
              />
            )}
          </ChartContainer>
          {!!data && !data.hasData && (
            <NotFound text='No data for the selected period found.' />
          )}
        </ContentCard>
      </Wrapper>
    )
  }
}

export default MeSfocChartContainer
