import { useContext } from 'react'
import styled from 'styled-components'
import { McInput } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  value: string
  unit?: string
  tooltip?: string
}

const ReadonlyInputField = ({ value, unit, tooltip }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <StyledMcInput
      disabled
      hiddenlabel
      fit={windowSize}
      value={value}
      suffix={unit}
      title={tooltip}
    />
  )
}

export default ReadonlyInputField

const StyledMcInput = styled(McInput)`
  ::part(field) {
    background-color: var(
      --mds_core_input_disabled_background-color
    ) !important;
  }

  ::part(input) {
    font-variant: tabular-nums;
  }
`
