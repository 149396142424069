import { FuelLineType, FuelType } from '../../../../utils'
import { Performance } from '../../../../api-models'

const MAIN_ENG_FUEL_LINE_TYPES = [FuelLineType.MEMain, FuelLineType.MESecondary]
const AUX_ENG_FUEL_LINE_TYPES = [FuelLineType.AEMain, FuelLineType.AESecondary]

export const isMethanolExistsOnFuelLines = (
  fuelLineType: FuelLineType,
  engine: string,
  fuelTypeSelections?: Array<Performance.FDL.FuelTypeSelectionResponse>,
) => {
  const fuelLineTypes =
    engine === 'ME' ? MAIN_ENG_FUEL_LINE_TYPES : AUX_ENG_FUEL_LINE_TYPES

  return (
    fuelTypeSelections?.some(
      (selection) =>
        selection.fuelType === FuelType.MM &&
        ((fuelLineType === fuelLineTypes[0] &&
          selection.fuelLineType === fuelLineTypes[1]) ||
          (fuelLineType === fuelLineTypes[1] &&
            selection.fuelLineType === fuelLineTypes[0])),
    ) ?? false
  )
}
