import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { FaroRoute } from '@grafana/faro-react'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { AppContextProvider, ConnectionStatusesProvider } from './contexts'
import { displayToast, getApplicationConfig, isShoreContext } from './utils'
import { ErrorBoundary } from './commons'
import { AppLayout } from './layout'
import VesselRoutes from './routing/VesselRoutes'
import ShoreRoutes from './routing/ShoreRoutes'
import HelpRoutes from './routing/HelpRoutes'
import NetPromoterScoreModal from './components/NetPromoterScore/NetPromoterScoreModal/NetPromoterScoreModal'
import { isGandalfError } from './queries/GandalfApi/GandalfApi'
import { isMasterDataError } from './queries/MasterDataApi/MasterDataApi'

export interface routerParams {
  vesselId: string
}

const currentVesselImo = getApplicationConfig().currentVesselImo
const isShore = isShoreContext()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (!query.meta) {
        return
      }

      if (
        query.meta.errorMessage &&
        typeof query.meta.errorMessage === 'string'
      ) {
        const title =
          typeof error === 'string' ? error : 'Something went wrong...'

        return displayToast('error', title, query.meta.errorMessage)
      }

      if (
        query.meta.gandalfError &&
        typeof query.meta.gandalfError === 'string' &&
        isGandalfError(error)
      ) {
        return displayToast('error', query.meta.gandalfError, error.body.error)
      }

      if (
        query.meta.masterDataError &&
        typeof query.meta.masterDataError === 'string' &&
        isMasterDataError(error)
      ) {
        return displayToast(
          'error',
          query.meta.masterDataError,
          error.body.detail,
        )
      }
    },
  }),
})

const Routes = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <AppContextProvider>
        <ConnectionStatusesProvider>
          <QueryClientProvider client={queryClient}>
            {!isShoreContext() && <NetPromoterScoreModal />}
            <Switch>
              <FaroRoute path='/MaerskStarConnect/vessel/:vesselId'>
                <VesselRoutes />
              </FaroRoute>
              <FaroRoute path='/MaerskStarConnect/shore'>
                <ShoreRoutes />
              </FaroRoute>
              <FaroRoute path='/MaerskStarConnect/help'>
                <AppLayout>
                  <HelpRoutes />
                </AppLayout>
              </FaroRoute>
              <FaroRoute path='/MaerskStarConnect'>
                <Redirect
                  to={
                    isShore
                      ? `/MaerskStarConnect/shore/vessel-overview`
                      : `/MaerskStarConnect/vessel/${currentVesselImo}/overview`
                  }
                />
              </FaroRoute>
            </Switch>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ConnectionStatusesProvider>
      </AppContextProvider>
    </ErrorBoundary>
  </BrowserRouter>
)

export default Routes
