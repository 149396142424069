import { useContext } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { McTextarea } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../contexts'

type Props = {
  id: string
  name: string
  label: string
  placeholder?: string
  rows?: number
  height?: string
  maxlength?: number
  required?: boolean
  disabled?: boolean
}

/**
 * @description A wrapper for McTextarea that provides two-way data binding
 * for Formik form values based on `name` prop.
 */
export const FormTextArea = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField(props.name)

  const handleInputChange = ({ target }: InputEvent) => {
    const value = (target as HTMLTextAreaElement).value
    void helpers.setValue(value)
  }

  return (
    <StyledMcTextarea
      fit={windowSize}
      invalid={meta.touched && !!meta.error}
      errormessage={meta.touched ? meta.error : undefined}
      input={handleInputChange}
      value={field.value}
      {...props}
    />
  )
}

const StyledMcTextarea = styled(McTextarea)<{ height?: string }>`
  ::part(textarea) {
    resize: none;
    height: ${({ height }) => height ?? 'auto'};
  }
`
