import { useContext, useMemo, useState } from 'react'
import { McButton, McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { FuelLineType, FuelType, isShoreContext } from '../../../../utils'
import { Performance } from '../../../../api-models'
import { WindowContext } from '../../../../contexts'

type Option = {
  label: string
  value: FuelType
}

const ALL_FUEL_TYPE_OPTIONS: Array<Option> = [
  { label: FuelType[FuelType.HS], value: FuelType.HS },
  { label: FuelType[FuelType.VLS], value: FuelType.VLS },
  { label: FuelType[FuelType.ULS], value: FuelType.ULS },
  { label: FuelType[FuelType.MDO], value: FuelType.MDO },
  { label: FuelType[FuelType.MM], value: FuelType.MM },
]

type Props = {
  fuelLineType: FuelLineType
  currentlySelectedFuelType?: Performance.FuelOilStock.FuelTypeSelection
  closeHandler: () => void
  onSave: (fuelType: FuelType) => void
  uniqueFuelTypesOnboard: Array<FuelType>
}

export const FormFuelLineChange = ({
  closeHandler,
  onSave,
  uniqueFuelTypesOnboard,
}: Props) => {
  const [selectedFuelTypeOption, setSelectedFuelTypeOption] = useState<Option>()
  const disabled = isShoreContext() || !selectedFuelTypeOption?.value
  const { windowSize } = useContext(WindowContext)
  const _fit = windowSize === 'large' ? 'medium' : windowSize

  const fuelTypeOptions = useMemo(
    () =>
      ALL_FUEL_TYPE_OPTIONS.filter(({ value }) =>
        uniqueFuelTypesOnboard.includes(value),
      ),
    [uniqueFuelTypesOnboard],
  )
  const addChangeHandler = () => {
    if (selectedFuelTypeOption?.value) {
      onSave(selectedFuelTypeOption.value)
    }
  }

  const handleFuelTypeSelection = (event: CustomEvent) => {
    setSelectedFuelTypeOption(event.detail)
  }

  return (
    <>
      <McSelect
        fit={windowSize}
        label='What fuel type is currently consumed?'
        optionselected={handleFuelTypeSelection}
      >
        {fuelTypeOptions.map((option) => (
          <McOption
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </McSelect>
      <McButton
        fit={_fit}
        slot='secondaryAction'
        label='Cancel'
        appearance='neutral'
        type='button'
        click={() => closeHandler()}
      />
      <McButton
        fit={_fit}
        slot='primaryAction'
        label='Add change'
        appearance='primary'
        type='button'
        click={addChangeHandler}
        disabled={disabled}
      />
    </>
  )
}
