import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'

import CylinderCharts from './CylinderCharts/CylinderCharts'
import { recalculateResults } from '../../EngineTestReportPage.utils'
import NumberInputField from '../../Common/NumberInputField'
import CommentField from '../../Common/CommentField'
import {
  AverageCol,
  InputCol,
  TableContainer,
  ValueCol,
} from '../../EngineTestReportPage.styles'
import Legend from '../../Common/Legend'
import { formatValue, isShoreContext } from '../../../../utils'
import { WindowContext } from '../../../../contexts'

type Props = {
  isActive: boolean
}

const CylinderTab = ({ isActive }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const ctx = useFormikContext<GandalfApi.MetcReport>()
  const { data } = ctx.values

  const cylRange = useMemo(
    () => Array.from(Array(data.equipment.noOfCylinders).keys()),
    [data.equipment.noOfCylinders],
  )

  const _recalculateResults = () => {
    if (isShoreContext()) return
    return void recalculateResults(ctx)
  }

  return (
    <div slot='panel'>
      <Legend />
      <TableContainer className={`mds-table mds-table--${windowSize}`}>
        <table style={{ display: 'block', overflowX: 'auto' }}>
          <colgroup>
            <ValueCol />
            <AverageCol />
            {cylRange.map((i) => (
              <InputCol key={i} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th>Value</th>
              <th>Average</th>
              {cylRange.map((i) => (
                <th key={i}>Cyl {i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pmax [bar]</td>
              <td>{formatValue(data.result.cylinders.pmaxAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.pmax`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Pcomp [bar]</td>
              <td>{formatValue(data.result.cylinders.pcompAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.pcomp`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>MIP [bar]</td>
              <td>{formatValue(data.result.cylinders.mipAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.mip`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Exh. Gas Out. [°C]</td>
              <td>
                {formatValue(data.result.cylinders.exhaustGasTempOutAvg, 2)}
              </td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.exhaustGasTempOut`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <CylinderCharts
        isActive={isActive}
        cylinders={data.cylinders}
        result={data.result}
      />
      <CommentField label='Comment' name='data.comment' />
    </div>
  )
}

export default CylinderTab
