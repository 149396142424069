import { type FuelTypeQuantity } from '../../models'
import { fuelTypeByDisplayOrder } from '../../utils'
import { UNITS } from '../../../../utils/constants'
import { formatValue, FuelType } from '../../../../utils'
import styled, { FuelColors, type IColorScheme } from '../../../../theme'

const TotalsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const FuelTypeTotal = styled.div`
  text-align: center;
  width: 100%;
`

const Paragraph = styled.div<{
  colorScheme?: IColorScheme
}>`
  font-size: 14px;
  font-weight: bold;
  color: ${({ colorScheme }) => (colorScheme ? colorScheme.stroke : '#000000')};
`

const Metric = styled.div`
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
`

type Props = {
  fuelTypeQuantities: Array<FuelTypeQuantity>
}

export const FuelTypeTotals = ({ fuelTypeQuantities }: Props) => (
  <TotalsWrapper>
    {fuelTypeQuantities
      .filter(({ quantity }) => Boolean(quantity))
      .sort(fuelTypeByDisplayOrder)
      .map(({ fuelType, quantity }) => (
        <FuelTypeTotal key={`fuel-type-${fuelType}`}>
          <Paragraph colorScheme={FuelColors[FuelType[fuelType]]}>
            Total {FuelType[fuelType]}
          </Paragraph>
          <Metric>
            {formatValue(quantity, 3)} {UNITS.METRIC_TON}
          </Metric>
        </FuelTypeTotal>
      ))}
  </TotalsWrapper>
)
