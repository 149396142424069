import moment from 'moment'
import { Performance } from '../../api-models'
import { FUEL_TYPE_DISPLAY_ORDER } from '../../utils/constants'
import { formatValue, FuelType, FUEL_TYPES_FDL4 } from '../../utils'
import { BunkeringFormValues } from './components/bunkering/BunkeringForm.types'
import {
  FormBatch,
  InitialRobBatch,
  MergedBatchRob,
  FuelTypeQuantity,
  StockOnBoard,
} from './models'
import { BatchSelectionCommons } from './components/form-consumption-selection/FormConsumptionSelection'
import { BatchResponse } from '../../api-models/performance/fuel-oil-stock'
import {
  EStockNotificationType,
  IStockNotification,
} from '../../api-models/performance/common'
import { Option } from './hooks'

/**
 *  Checks if a common batch (Performance.FuelOilStock.BatchResponse) has a lab report
 */
const batchResponseHasLabResult = (batch: BatchSelectionCommons): boolean => {
  return !!batch?.hasLabReport
}

/**
 * Checks if a bunkered batch (Performance.FuelOilStock.BunkeredBatch) has a lab report
 */
const bunkeredBatchHasLabResult = (batch: BatchSelectionCommons): boolean => {
  if (!batch?.labReport) {
    return false
  }

  // If the marpol seal is a string with whitespaces this should be false
  if (!batch.labReport?.marpolSeal?.trim().length) {
    return false
  }

  return ['density15', 'lcv', 'water', 'ash', 'sulphur'].every(
    (key) => batch.labReport && typeof batch.labReport[key] === 'number',
  )
}

export const hasLabReport = (batch: BatchSelectionCommons) => {
  if (batch.hasOwnProperty('hasLabReport')) {
    return batchResponseHasLabResult(batch)
  } else {
    return bunkeredBatchHasLabResult(batch)
  }
}

export const hasGeneratedDisplayName = (batch: InitialRobBatch) =>
  batch.displayName ===
  generateDisplayName(
    batch.fuel.type,
    batch.fuel.isDistillate,
    `${batch.quantityPerChiefEngineer}`,
    batch.deliveryDetails.portCode,
    batch.timestamp,
  )

const getFuelType = (fuelType?: FuelType, isDistillate?: boolean): string => {
  if (!fuelType) {
    return ''
  }
  if ([FuelType.ULS, FuelType.VLS].includes(fuelType)) {
    return `${FuelType[fuelType]}${isDistillate ? 'MDO' : 'FO'}`
  }
  if (fuelType === FuelType.HS) {
    return 'HFO'
  }
  return fuelType ? FuelType[fuelType] : ''
}

export const generateDisplayName = (
  fuelType?: FuelType,
  isDistillate?: boolean,
  quantityPerChiefEngineer?: string,
  portCode?: string,
  timestamp?: string,
) => {
  let fuelName = ''
  if (fuelType) fuelName = getFuelType(fuelType, isDistillate)
  const quantity = quantityPerChiefEngineer
    ? `${formatValue(quantityPerChiefEngineer, 0)}MT`
    : ''
  const date = timestamp
    ? moment.utc(timestamp).format('DMMM').toUpperCase()
    : ''
  return [fuelName, quantity, portCode, date].filter(Boolean).join(' ')
}

export const sortBatchesByTimestampDescending = (
  a: InitialRobBatch | MergedBatchRob | BatchResponse,
  b: InitialRobBatch | MergedBatchRob | BatchResponse,
) => moment.utc(b.timestamp).valueOf() - moment.utc(a.timestamp).valueOf()

export const mapBatchestoSelectOptions = (
  batches: Performance.FuelOilStock.BatchResponse[],
  disabledIfLabReportMissing?: boolean,
): Array<Option> =>
  batches.map((b) => ({
    label: b.displayName,
    value: b.id,
    fuelType: FuelType[b.fuel.type],
    isDisabled: disabledIfLabReportMissing && !b.hasLabReport,
  }))

export const getBatchesFromFuelType = (
  batches: {
    batch: Performance.FuelOilStock.BatchResponse
    quantity: number
  }[],
  fuelType: FuelType,
): {
  batch: Performance.FuelOilStock.BatchResponse
  quantity: number
}[] => {
  return batches.filter((batch) => batch.batch.fuel.type === fuelType)
}

export const mapBatchToBunkeringFormValues = (
  batch: FormBatch,
): BunkeringFormValues => {
  return {
    fuelType: batch.fuel.type,
    fuelGrade: batch.fuel.grade,
    displayName: batch.displayName,
    bioPercentage: batch.fuel.bioPercentage,
    isDistillate: batch.fuel.isDistillate,
    timestamp: moment.utc(batch.timestamp),
    orderId: batch.deliveryDetails.orderId,
    portCode: batch.deliveryDetails.portCode,
    quantityOrdered: batch.deliveryDetails.quantityOrdered.toString(),
    quantityAgreed: batch.deliveryDetails.quantityAgreed.toString(),
    quantityPerChiefEngineer: batch.quantityPerChiefEngineer.toString(),
    marpolSeal: batch.labReport?.marpolSeal ?? '',
    density15: `${batch.labReport?.density15 ?? ''}`,
    lcv: `${batch.labReport?.lcv ?? ''}`,
    water: `${batch.labReport?.water ?? ''}`,
    ash: `${batch.labReport?.ash ?? ''}`,
    sulphur: `${batch.labReport?.sulphur ?? ''}`,
    notes: batch.notes,
  }
}

export const mapBunkeringFormValuesToBatch = (
  values: BunkeringFormValues,
): Performance.FuelOilStock.BunkeredBatch => {
  const formatValue = (val: string | null): number | null =>
    typeof val === 'number' || !!val ? +val : null

  return {
    timestamp: values.timestamp?.toISOString() ?? '',
    displayName: generateDisplayName(
      values.fuelType,
      values.isDistillate,
      values.quantityPerChiefEngineer,
      values.portCode,
      values.timestamp?.toISOString(),
    ),
    fuel: {
      grade: values.fuelGrade,
      type: values.fuelType,
      isDistillate: values.isDistillate,
      bioPercentage: values.bioPercentage,
    },
    quantityPerChiefEngineer: +values.quantityPerChiefEngineer,
    labReport: {
      marpolSeal: !!values.marpolSeal ? values.marpolSeal : null,
      density15: formatValue(values.density15),
      lcv: formatValue(values.lcv),
      water: formatValue(values.water),
      ash: formatValue(values.ash),
      sulphur: formatValue(values.sulphur),
    },
    deliveryDetails: {
      orderId: values.orderId,
      portCode: values.portCode,
      quantityOrdered: +values.quantityOrdered,
      quantityAgreed: +values.quantityAgreed,
    },
    notes: values.notes,
  }
}

export function fuelTypeByDisplayOrder(
  a: FuelTypeQuantity,
  b: FuelTypeQuantity,
) {
  return (
    FUEL_TYPE_DISPLAY_ORDER[a.fuelType] - FUEL_TYPE_DISPLAY_ORDER[b.fuelType]
  )
}

export function batchQuantitiesByDisplayOrder(
  a: Performance.FuelOilStock.BatchQuantity,
  b: Performance.FuelOilStock.BatchQuantity,
) {
  return (
    FUEL_TYPE_DISPLAY_ORDER[a.batch.type] -
    FUEL_TYPE_DISPLAY_ORDER[b.batch.type]
  )
}

export function fuelTypeNumberByDisplayOrder(a: number, b: number) {
  return FUEL_TYPE_DISPLAY_ORDER[a] - FUEL_TYPE_DISPLAY_ORDER[b]
}

export const getBatchDictionary = (
  batches: Performance.FuelOilStock.BatchResponse[],
): Record<string, Performance.FuelOilStock.BatchResponse> => {
  return batches.reduce(
    (batchesKeyValue, batch) => ({
      ...batchesKeyValue,
      [batch.id]: {
        displayName: batch.displayName,
        fuel: batch.fuel,
        timestamp: batch.timestamp,
      },
    }),
    {},
  )
}

export const getRobMarkDictionary = (
  batches: Performance.FuelOilStock.BatchRob[],
): Record<string, Performance.FuelOilStock.BatchRob> => {
  return batches.reduce(
    (batchesKeyValue, batch) => ({
      ...batchesKeyValue,
      [batch.id]: {
        id: batch.id,
        rob: batch.rob,
      },
    }),
    {},
  )
}

export const getMergedBatchRobMap = (
  batchQuantities: MergedBatchRob[],
): Map<number, MergedBatchRob[]> => {
  const fuelTypeMap = new Map<number, MergedBatchRob[]>()
  batchQuantities.forEach((batchQuantity) => {
    const key = batchQuantity.fuelType
    const collection = fuelTypeMap.get(key)
    if (!collection) {
      fuelTypeMap.set(key, [batchQuantity])
    } else {
      collection.push(batchQuantity)
    }
  })
  return fuelTypeMap
}

export const mapStockOnBoard = (
  batchQuantities: Performance.FuelOilStock.BatchQuantity[],
  fuelTypeQuantities: Performance.FuelOilStock.FuelTypeQuantity[],
): StockOnBoard => {
  const quantities = [] as FuelTypeQuantity[]
  Object.keys(FUEL_TYPES_FDL4).forEach((fuelTypeKey) => {
    quantities.push({
      fuelType: FUEL_TYPES_FDL4[fuelTypeKey],
      quantity: fuelTypeQuantities.find(
        (fuelTypeQuantity) =>
          fuelTypeQuantity.fuelType === FUEL_TYPES_FDL4[fuelTypeKey],
      )?.quantity,
    })
  })
  return {
    fuelTypeQuantities: quantities,
    batchQuantities: batchQuantities,
  }
}

export const getFilteredNotifications = (
  notifications: IStockNotification[],
  notificationType: EStockNotificationType,
) =>
  notifications
    .filter(({ type }) => type === notificationType)
    .map(({ message }) => message)

export const getFuelGradeOption = (
  fuelGrade: MasterDataApi.Common.FuelGrade,
) => {
  return {
    value: fuelGrade.data.code,
    label: fuelGrade.data.name,
    type: fuelGrade.data.fuelType,
  }
}
