// FDL < 4.0 below

/* type 0 display in position 5, type 1 in position 1, type 2 in position 3 and so on */
export const FUEL_TYPE_DISPLAY_ORDER = [5, 1, 3, 4, 2]

// FDL 4.0 below

export const FUEL_LINE_TYPES = {
  ME_MAIN: 10,
  ME_SECONDARY: 11,
  AE_MAIN: 20,
  AE_SECONDARY: 21,
  AE_MDO: 25,
  BOILER_MAIN: 30,
  BOILER_MDO: 35,
}

export const FUEL_TYPES = ['HS', 'VLS', 'ULS', 'MDO', 'MM']

export const MAIN_ENGINE_NAMES = {
  Main: 'Main engine',
  Port: 'ME port',
  Stbd: 'ME stbd',
}

// Other

export const ELECTRICAL_CONSUMERS = {
  Basic: 1,
  Reefers: 2,
  ReeferCfw: 3,
  SeaWaterPump: 4,
  ErBlowers: 5,
  CargoHoldFans: 6,
  AcUnit: 7,
  ShaftMotor1: 8,
  ShaftMotor2: 9,
  AuxBlowers1: 10,
  AuxBlowers2: 11,
  ScrubberSystem: 12,
}

export const UNITS = {
  MT_PER_HOUR: 'MT/h',
  KG_PER_HOUR: 'kg/h',
  METER: 'm',
  KNOTS: 'knots',
  KNOTS_ABR: 'kt',
  RPM: 'RPM',
  MEGA_WATT: 'MW',
  KILO_WATT: 'kW',
  KILO_WATT_HOUR: 'kWh',
  KILO_NEWTON: 'kN',
  KILO_NEWTON_METER: 'kNm',
  METRIC_TON: 'MT',
  VOLUME: 'm³',
  TEMPERATURE: '°C',
  DENSITY_FIFTEEN: 'kg/m³',
  ENGINE_LOAD: '% MCR',
  SFOC: 'g/kWh',
  GRAM_KILOWATT_HOUR: 'g/kWh',
  MT_PER_24_HOUR: 'MT/24 h',
  MASS: '%mass',
  KJ_PER_KILO: 'kJ/kg',
  PERCENTAGE: '%',
  PERCENTAGE_VOLUME: '%volume',
  KILO_GRAM_PER_CUBIC_METER: 'kg/m³',
  NAUTICAL_MILE: 'nm',
  LITER: 'ltr',
  KILOGRAM: 'kg',
  BAR: 'bar',
  MILLIBAR: 'mbar',
  MILLIMETER_H2O: 'mm H₂O',
  MILLIMETER: 'mm',
  HOURS: 'hours',
  VOLTAGE: 'V',
  AMPERE: 'A',
  COS_PHI: 'Cos ɸ',
  DEGREES: '°',
}

export const ELECTRICAL_CONSUMER_NAMES = {
  [ELECTRICAL_CONSUMERS.Basic]: 'Remaining',
  [ELECTRICAL_CONSUMERS.AcUnit]: 'A/C Unit',
  [ELECTRICAL_CONSUMERS.ErBlowers]: 'ER Blowers',
  [ELECTRICAL_CONSUMERS.ReeferCfw]: 'Reefer CFW',
  [ELECTRICAL_CONSUMERS.Reefers]: 'Reefers',
  [ELECTRICAL_CONSUMERS.SeaWaterPump]: 'Sea water pump',
  [ELECTRICAL_CONSUMERS.CargoHoldFans]: 'Cargo Hold Fans',
  [ELECTRICAL_CONSUMERS.ShaftMotor1]: 'Shaft motor port',
  [ELECTRICAL_CONSUMERS.ShaftMotor2]: 'Shaft motor stbd',
  [ELECTRICAL_CONSUMERS.AuxBlowers1]: 'Aux Blowers port',
  [ELECTRICAL_CONSUMERS.AuxBlowers2]: 'Aux Blowers stbd',
  [ELECTRICAL_CONSUMERS.ScrubberSystem]: 'Scrubber System',
}

export const ELECTRICAL_PRODUCERS = {
  AE1: 1,
  AE2: 2,
  AE3: 3,
  AE4: 4,
  AE5: 5,
  ShaftGenerator1: 6,
  ShaftGenerator2: 7,
  WHR: 8,
}

export const ELECTRICAL_PRODUCER_NAMES = {
  [ELECTRICAL_PRODUCERS.AE1]: 'AE1',
  [ELECTRICAL_PRODUCERS.AE2]: 'AE2',
  [ELECTRICAL_PRODUCERS.AE3]: 'AE3',
  [ELECTRICAL_PRODUCERS.AE4]: 'AE4',
  [ELECTRICAL_PRODUCERS.AE5]: 'AE5',
  [ELECTRICAL_PRODUCERS.ShaftGenerator1]: 'Shaft generator port',
  [ELECTRICAL_PRODUCERS.ShaftGenerator2]: 'Shaft generator stbd',
  [ELECTRICAL_PRODUCERS.WHR]: 'WHR',
}

export const VESSEL_SETTINGS = {
  Leakage: 1,
}

export const VESSEL_SETTINGS_NAMES = {
  [VESSEL_SETTINGS.Leakage]: 'Leakage deducted',
}

export const HDC_DATA_AVAILABILITY: number = 98.5
