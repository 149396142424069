import { useMemo } from 'react'

import { vesselPages as pageData } from './VesselRoutes.const'
import { pageSettings } from './VesselRoutes'
import {
  MalfunctioningEquipmentPage,
  MalfunctioningEquipmentReportPage,
} from '../pages'
import { OwnershipTypes } from '../queries/MasterDataApi/MasterDataApi.consts'
import { useVesselBasicInfo } from '../queries/MasterDataApi/MasterDataApi'

export const usePageProps = (
  imoNo: string,
  isEnabled: boolean = true,
): Array<PageProps> => {
  const vesselBasicInfo = useVesselBasicInfo(imoNo, isEnabled)
  const ownershipType = vesselBasicInfo.data?.ownershipType
  const isOwnVessel =
    ownershipType === OwnershipTypes.Own ||
    ownershipType === OwnershipTypes.BareboatCharter

  // Map over pageSettings and Filter the new array based on ownership type and restricted pages
  return useMemo(() => {
    return pageSettings
      .map((settings, idx) => ({
        name: pageData[idx].name,
        pagePath: pageData[idx].pagePath,
        ...settings,
      }))
      .filter((page) => {
        const isRestrictedPage =
          page.component === MalfunctioningEquipmentPage ||
          page.component === MalfunctioningEquipmentReportPage

        return isOwnVessel || !isRestrictedPage
      })
  }, [isOwnVessel])
}
