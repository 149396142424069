import styled from 'styled-components'

import { type TWindowSize } from '../../../../theme_new/styled'
import { FuelType } from '../../../../utils'

export const LabReportWrapper = styled.div`
  margin-top: 32px;

  > hr {
    margin-bottom: 16px;
  }
`

export const TagGroupWrapper = styled.div`
  display: flex;
  margin: -6px 0 6px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    margin: -4px 0 8px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    margin: -2px 0 10px;
  }
`

export const resolveModalHeight = (
  fuelGrade: MasterDataApi.Common.FuelGrade | undefined,
  windowSize: TWindowSize,
): `${number}px` => {
  const sizeHeights = { small: 696, medium: 792, large: 840 }
  const biofuelHeights = { small: 144, medium: 160, large: 176 }
  const otherFuelHeights = { small: 72, medium: 80, large: 88 }

  let height = sizeHeights[windowSize]

  if (fuelGrade) {
    if (fuelGrade.data.isBiofuel) {
      height += biofuelHeights[windowSize]
    } else if (fuelGrade.data.fuelType !== FuelType.MM) {
      height += otherFuelHeights[windowSize]
    }
  } else {
    height += otherFuelHeights[windowSize]
  }

  return `${height}px`
}
