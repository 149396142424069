import { useContext } from 'react'
import { McModal } from '@maersk-global/mds-react-wrapper'

import { FormFuelLineChange } from './FormFuelLineChange'
import {
  FuelLineType,
  FuelType,
  FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING,
} from '../../../../utils'

import { Performance } from '../../../../api-models'
import { WindowContext } from '../../../../contexts'
import { Heading } from '../styles'
import { HelpText } from '../../../help-texts'
import { TWindowSize } from '../../../../theme_new/styled'

const resolveModalHeight = (windowSize: TWindowSize): `${number}px` => {
  const sizeHeights = { small: 220, medium: 270, large: 320 }
  return `${sizeHeights[windowSize]}px`
}

type Props = {
  closeHandler: () => void
  currentlySelectedFuelType?: Performance.FuelOilStock.FuelTypeSelection
  fuelLineType: FuelLineType
  onSave: (fuelType: FuelType) => void
  uniqueFuelTypesOnboard: FuelType[]
}

export const FormFuelLineChangeModal = ({
  closeHandler,
  fuelLineType,
  uniqueFuelTypesOnboard,
  ...restProps
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const _fit = windowSize === 'large' ? 'medium' : windowSize
  return (
    <McModal
      fit={_fit}
      open
      width='420px'
      closed={closeHandler}
      height={resolveModalHeight(windowSize)}
    >
      <Heading slot='heading'>
        Change of {FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[fuelLineType] || ''}{' '}
        fuel type
        <HelpText textKey='consumption-selection/fuel-type' />
      </Heading>
      <FormFuelLineChange
        closeHandler={closeHandler}
        fuelLineType={fuelLineType}
        uniqueFuelTypesOnboard={uniqueFuelTypesOnboard}
        {...restProps}
      />
    </McModal>
  )
}
