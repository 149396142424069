import { useContext, useState } from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import {
  McButton,
  McModal,
  McOption,
  McSelect,
} from '@maersk-global/mds-react-wrapper'

import { recalculateResults } from '../../EngineTestReportPage.utils'
import {
  useGetFuelConsumptionBatchMonitor,
  useGetFuelOilStockBunkeredBatch,
  useGetFuelOilStockMixedBatch,
} from '../../../../queries/PerformanceApi/Papi'
import {
  BatchIndicatorType,
  BatchType,
} from '../../../../queries/PerformanceApi/Papi.consts'
import { DataPoint } from '../../../../queries/GandalfApi/GandalfApi.utils'
import { WindowContext } from '../../../../contexts'
import { isShoreContext } from '../../../../utils'

type Props = {
  open: boolean
  onClose: () => void
}

const SelectBatchModal = ({ open, onClose }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const formCtx = useFormikContext<GandalfApi.AetcReport>()
  const { imoNo, startTimestamp, endTimestamp } = formCtx.values

  const [selectedBatch, setSelectedBatch] =
    useState<Papi.FuelConsumption.Batch>()

  const getBatchMonitor = useGetFuelConsumptionBatchMonitor(
    imoNo,
    startTimestamp,
    endTimestamp!,
  )

  const getBunkeredBatch = useGetFuelOilStockBunkeredBatch(
    imoNo,
    selectedBatch && selectedBatch.type === BatchType.Bunkered
      ? selectedBatch.id
      : '',
  )

  const getMixedBatch = useGetFuelOilStockMixedBatch(
    imoNo,
    selectedBatch && selectedBatch.type === BatchType.Mixed
      ? selectedBatch.id
      : '',
  )

  const handleSelectBatch = ({
    detail,
  }: CustomEvent<{
    value: Papi.FuelConsumption.Batch
  }>) => {
    setSelectedBatch(detail.value)
  }

  const handleApplyBatch = async () => {
    if (!selectedBatch) return

    let batch: Papi.FuelOil.Stock.Batch | undefined

    if (
      selectedBatch.type === BatchType.Bunkered &&
      getBunkeredBatch.isSuccess
    ) {
      batch = getBunkeredBatch.data
    } else if (
      selectedBatch.type === BatchType.Mixed &&
      getMixedBatch.isSuccess
    ) {
      batch = getMixedBatch.data
    }

    if (!batch) {
      console.error('Batch not found')
      return
    }

    await Promise.all([
      formCtx.setFieldValue('data.general.batchId', new DataPoint(batch.id)),
      formCtx.setFieldValue(
        'data.general.batchName',
        new DataPoint(batch.displayName),
      ),
      formCtx.setFieldValue(
        'data.general.batchLcv',
        new DataPoint(batch.labReport.lcv),
      ),
    ])

    if (!isShoreContext()) {
      recalculateResults(formCtx)
    }

    onClose()
  }

  const handleClosed = () => {
    setSelectedBatch(undefined)
    onClose()
  }

  const batches = getBatchMonitor.data?.auxEngFuelLines
    .flatMap((fuelLine) => fuelLine.indicators)
    .filter((indicator) => indicator.type === BatchIndicatorType.Batch)
    .map((indicator) => indicator.batch)
    .filter((batch): batch is Papi.FuelConsumption.Batch => batch !== undefined)

  const selectedOption =
    batches?.find((batch) => batch.id === selectedBatch?.id) ??
    batches?.find(
      (batch) => batch.id === formCtx.values.data.general.batchId.value,
    )

  return (
    <McModal
      hiddenclose
      backdropcloseactiondisabled
      heading='Select Consumed Batch'
      width='720px'
      height='320px'
      open={open}
      closed={handleClosed}
    >
      <Wrapper>
        <p>
          Select the batch from which the auxiliary engine was consuming during
          the test period.
        </p>
        <McSelect
          fit={windowSize}
          label='Available batches (not including blends)'
          optionselected={handleSelectBatch}
          value={selectedOption}
        >
          {batches?.map((batch) => (
            <McOption key={batch.id} value={batch}>
              {batch.displayName}
            </McOption>
          ))}
        </McSelect>
      </Wrapper>
      <McButton
        slot='primaryAction'
        fit={windowSize}
        label='Select batch'
        disabled={!selectedBatch}
        loading={getBunkeredBatch.isFetching || getMixedBatch.isFetching}
        appearance='primary'
        click={handleApplyBatch}
      />
      <McButton
        slot='secondaryAction'
        fit={windowSize}
        label='Cancel'
        appearance='neutral'
        variant='outlined'
        dialogaction='cancel'
      />
    </McModal>
  )
}

export default SelectBatchModal

const Wrapper = styled.div`
  mc-select {
    display: block;
    margin-top: 32px;
    width: 50%;
  }
`
