import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'

import CylinderCharts from './CylinderCharts/CylinderCharts'
import { recalculateResults } from '../../EngineTestReportPage.utils'
import Legend from '../../Common/Legend'
import NumberInputField from '../../Common/NumberInputField'
import CommentField from '../../Common/CommentField'
import {
  AverageCol,
  InputCol,
  TableContainer,
  ValueCol,
} from '../../EngineTestReportPage.styles'
import { WindowContext } from '../../../../contexts'
import { formatValue, isShoreContext } from '../../../../utils'
import { UNITS } from '../../../../utils/constants'
import InfoTooltip from '../../../../components/InfoTooltip'

type Props = {
  isActive: boolean
}

const CylinderTab = ({ isActive }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const ctx = useFormikContext<GandalfApi.AetcReport>()
  const { data } = ctx.values

  const cylRange = useMemo(
    () => Array.from(Array(data.equipment.noOfCylinders).keys()),
    [data.equipment.noOfCylinders],
  )

  const _recalculateResults = () => {
    if (isShoreContext()) return
    return void recalculateResults(ctx)
  }

  return (
    <div slot='panel'>
      <Legend />
      <TableContainer className={`mds-table mds-table--${windowSize}`}>
        <table style={{ display: 'block', overflowX: 'auto' }}>
          <colgroup>
            <ValueCol />
            <AverageCol />
            {cylRange.map((i) => (
              <InputCol key={i} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th>Value</th>
              <th>Average</th>
              {cylRange.map((i) => (
                <th key={i}>Cyl {i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pmax [{UNITS.BAR}]</td>
              <td>{formatValue(data.result.cylinders.pmaxAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.pmax`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>MIP [{UNITS.BAR}]</td>
              <td>{formatValue(data.result.cylinders.mipAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.mip`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Fuel rack [{UNITS.MILLIMETER}]</td>
              <td>{formatValue(data.result.cylinders.fuelRackAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.fuelRack`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Exhaust gas temperature [{UNITS.TEMPERATURE}]</td>
              <td>{formatValue(data.result.cylinders.exhGasTempAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.exhGasTemp`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>
                <InfoTooltip
                  label={`Indicated power (measuring device) [${UNITS.KILO_WATT}]`}
                  content='Measured from Diesel Doctor or similar device'
                />
              </td>
              <td>{formatValue(data.result.cylinders.ipAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.ip`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Ignition angle [{UNITS.DEGREES}]</td>
              <td>{formatValue(data.result.cylinders.ignAngleAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.ignAngle`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Angle of peak pressure [{UNITS.DEGREES}]</td>
              <td>{formatValue(data.result.cylinders.peakPresAngleAvg, 2)}</td>
              {cylRange.map((i) => (
                <td key={i}>
                  <NumberInputField
                    name={`data.cylinders.${i}.peakPresAngle`}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <CylinderCharts
        isActive={isActive}
        cylinders={data.cylinders}
        result={data.result}
      />
      <CommentField label='Comment' name='data.comment' />
    </div>
  )
}

export default CylinderTab
