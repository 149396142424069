import { useContext, useState } from 'react'
import { sortBy } from 'lodash'

import { type Event } from '../../../api-models/hdc/events'
import { type BatchResponse } from '../../../api-models/performance/fuel-oil-stock'
import { Loading } from '../../../commons'
import FramedSection from '../../../components/form/framed-section'
import FormSection from '../../../components/form/section'
import { VesselPageContext } from '../../../contexts'
import { getBatchesById } from '../../../services/performance'
import { displayErrorModal } from '../../../utils'
import { UNITS } from '../../../utils/constants'
import { HDCReportFormValues } from '../types'
import {
  getCLOConsumptionInKg,
  getCLOFeedRate,
  getOriginalValueNote,
  mainEngineName,
} from '../utils'
import EventRow from './EventRow/EventRow'
import FormRow from './form-row'
import { type FuelTypeGroup } from './fuel-consumption-table/types'
import { HdcFuelConsumptionTable } from './fuel-consumption-table/hdc-fuel-consumption'
import { mapFuelConsumptionData } from './fuel-consumption-table/mapping'
import LeakageAndSludge from './hdc-leakage-and-sludge'
import ReadonlyElectricalConsumersTable from './readonly-electrical-consumers-table'
import ReadonlyElectricalProducersTable from './readonly-electrical-producers-table'
import ReadonlyField from './readonly-field'
import SummaryRow from './summary-row'
import ReadonlyShorePowerComponent from './ShorePower/readonly-shore-power-component'
import { ApiReportType } from '../../../api-models/hdc/report'

type Props = {
  bridgeEvents: Event[]
  engineRoomEvents: Event[]
  report: HDCReportFormValues
}

const EngineRoomSummary = ({
  bridgeEvents,
  engineRoomEvents,
  report,
}: Props) => {
  const { engineRoom } = report
  const {
    comment,
    cylindersLubeOil,
    fuelConsumption,
    mainEngines,
    numberOfReefers,
    reeferEnergy,
  } = engineRoom
  const configuration = useContext(VesselPageContext).configuration!
  const [fuelTypeGroups, setFuelTypeGroups] = useState<FuelTypeGroup[]>()

  const sortedBridgeEvents = sortBy(bridgeEvents, 'startTimestamp')

  const sortedEngineRoomEvents = sortBy(engineRoomEvents, 'startTimestamp')

  const createFuelTypeGroups = async () => {
    const batchIds = fuelConsumption.batches.map((batch) => batch.batchId)
    let papiBatches: BatchResponse[] = []
    try {
      papiBatches = await getBatchesById(`${configuration.imoNo}`, batchIds)
    } catch (error) {
      void displayErrorModal(error)
    } finally {
      const groups: FuelTypeGroup[] = mapFuelConsumptionData(
        fuelConsumption.batches,
        papiBatches,
      )
      setFuelTypeGroups(groups)
    }
  }

  if (!fuelTypeGroups && fuelConsumption.batches) {
    void createFuelTypeGroups()
  }

  return (
    <>
      <FormSection header='Propulsion'>
        {mainEngines.map((engine, idx) => (
          <FramedSection
            key={`framed-section-${idx}`}
            header={`${
              mainEngines.length > 1
                ? mainEngineName(mainEngines.length, engine.number)
                : 'Main engine'
            }`}
          >
            <SummaryRow>
              <ReadonlyField
                addon={UNITS.KILO_WATT}
                label='Average power'
                name={`main-engine-${idx}-average-power`}
                note={getOriginalValueNote(engine.averagePower)}
                value={engine.averagePower.value ?? '-'}
              />
              <ReadonlyField
                label='Running hours'
                name={`main-engine-${idx}-running-hours`}
                note={getOriginalValueNote(engine.runTime)}
                value={`${engine.runTime.hours.value ?? '-'}h ${
                  engine.runTime.minutes.value ?? '-'
                }m`}
              />
              <ReadonlyField
                addon={UNITS.RPM}
                label='Average shaft RPM'
                name={`main-engine-${idx}-average-shaft-rpm`}
                note={getOriginalValueNote(engine.averageShaftRpm)}
                value={engine.averageShaftRpm.value ?? '-'}
              />
              {engine.tcco && (
                <ReadonlyField
                  label='TCCO'
                  name={`main-engine-${idx}-tcco`}
                  note={getOriginalValueNote(engine.tcco)}
                  value={`${engine.tcco.value ? 'Yes' : 'No'}`}
                />
              )}
            </SummaryRow>
          </FramedSection>
        ))}
      </FormSection>
      <FormSection header='Electrical'>
        <FramedSection header='Electrical production'>
          <ReadonlyElectricalProducersTable report={report} />
        </FramedSection>
        {report.engineRoom.shorePowerPeriods &&
          report.reportType === ApiReportType.ALONGSIDE && (
            <FramedSection header='Shore power'>
              <ReadonlyShorePowerComponent report={report} />
            </FramedSection>
          )}
        <FramedSection header='Consumption'>
          <FormRow>
            <ReadonlyElectricalConsumersTable report={report} />
          </FormRow>
          <SummaryRow>
            <ReadonlyField
              addon={UNITS.KILO_WATT_HOUR}
              label='Reefer energy'
              name='reefer-energy'
              note={getOriginalValueNote(reeferEnergy)}
              value={reeferEnergy.value ?? '-'}
            />
            <ReadonlyField
              label='Number of reefers'
              name='number-of-reefers'
              note={getOriginalValueNote(numberOfReefers)}
              value={numberOfReefers.value ?? '-'}
            />
          </SummaryRow>
        </FramedSection>
      </FormSection>
      <FormSection header='Consumption'>
        <FramedSection header='Fuel consumption & ROB'>
          <>
            {!fuelTypeGroups ? (
              <Loading />
            ) : (
              <HdcFuelConsumptionTable
                fuelTypeGroups={fuelTypeGroups}
                tableSize='small'
              />
            )}
            <LeakageAndSludge
              leakageMass={fuelConsumption.leakageMass}
              sludge={fuelConsumption.sludge}
            />
          </>
        </FramedSection>
        <FramedSection header='Cylinder oil consumption'>
          {cylindersLubeOil.map((cylinderLubeOil, idx) => (
            <SummaryRow key={`summary-row-clo-${idx}`}>
              <ReadonlyField
                addon={UNITS.LITER}
                label='Cylinder oil consumption'
                name={`cylinder-lube-oil-${idx}-consumption-liters`}
                note={getOriginalValueNote(cylinderLubeOil.consumptionLiters)}
                value={cylinderLubeOil.consumptionLiters.value ?? '-'}
              />
              <ReadonlyField
                addon={UNITS.DENSITY_FIFTEEN}
                label='Density'
                name={`cylinder-lube-oil-${idx}-density`}
                note={getOriginalValueNote(cylinderLubeOil.density)}
                value={cylinderLubeOil.density.value ?? '-'}
              />
              <ReadonlyField
                addon={UNITS.TEMPERATURE}
                label='Average temperature'
                name={`cylinder-lube-oil-${idx}-avg-temperature`}
                note={getOriginalValueNote(cylinderLubeOil.avgTemperature)}
                value={cylinderLubeOil.avgTemperature.value ?? '-'}
              />
              <ReadonlyField
                label='TBN'
                name={`cylinder-lube-oil-${idx}-tbn`}
                value={cylinderLubeOil.totalBaseNumber.value ?? '-'}
              />
              <ReadonlyField
                label='Oil code'
                name={`cylinder-lube-oil-${idx}-oil-code`}
                note={getOriginalValueNote(cylinderLubeOil.oilCode)}
                value={cylinderLubeOil.oilCode.value ?? '-'}
              />
              <ReadonlyField
                addon={UNITS.KILOGRAM}
                label='Total CLO consumption'
                name={`cylinder-lube-oil-${idx}-consumption-kg`}
                note={getOriginalValueNote(cylinderLubeOil.consumptionKg)}
                value={getCLOConsumptionInKg(
                  cylinderLubeOil.consumptionLiters.value || 0,
                  cylinderLubeOil.avgTemperature.value || 0,
                  cylinderLubeOil.density.value || 0,
                )}
              />
              {report.reportType !== ApiReportType.ALONGSIDE && (
                <ReadonlyField
                  addon={UNITS.GRAM_KILOWATT_HOUR}
                  label={`Calculated CLO feed rate`}
                  name={`cylinder-lube-oil-${idx}-calculated-feed-rate`}
                  value={getCLOFeedRate(
                    getCLOConsumptionInKg(
                      cylindersLubeOil[idx].consumptionLiters.value || 0,
                      cylindersLubeOil[idx].avgTemperature.value || 0,
                      cylindersLubeOil[idx].density.value || 0,
                    ),
                    mainEngines[idx].averagePower.value || 0,
                    mainEngines[idx].runTime,
                  )}
                />
              )}
            </SummaryRow>
          ))}
        </FramedSection>
      </FormSection>
      <FormSection header='Events - Added from Engine room'>
        {sortedEngineRoomEvents.length ? (
          sortedEngineRoomEvents.map((event) => (
            <EventRow
              data={event}
              key={`report-event-${event.id}`}
              report={report}
            />
          ))
        ) : (
          <p>There are no events reported from the Engine Room.</p>
        )}
      </FormSection>
      <FormSection header='Events - Added from Bridge'>
        {sortedBridgeEvents.length ? (
          sortedBridgeEvents.map((event) => (
            <EventRow
              data={event}
              key={`report-event-${event.id}`}
              report={report}
            />
          ))
        ) : (
          <p>There are no events reported from the Bridge.</p>
        )}
      </FormSection>
      <FormSection header='Report comments'>
        <FramedSection header='Comments from Engine room'>
          {(comment ?? '-').split('\n').map((string) => (
            <p key={string}>{string}</p>
          ))}
        </FramedSection>
      </FormSection>
    </>
  )
}

export default EngineRoomSummary
