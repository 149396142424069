import * as yup from 'yup'

import { FuelType } from '../../../../utils'

export const validationSchema = (
  fuelGrades: Array<MasterDataApi.Common.FuelGrade>,
) =>
  yup.object().shape({
    fuelGrade: yup.string().required(required),
    fuelType: yup.number().min(1, required),
    isDistillate: yup.boolean().when('fuelType', {
      is: (value) => [FuelType.VLS, FuelType.ULS].includes(value),
      then: yup.boolean().required(required),
      otherwise: yup.boolean().notRequired(),
    }),
    portCode: yup.string().required(required),
    orderId: yup
      .string()
      .max(100, 'Must be between 1 and 100 characters long')
      .required(required),
    bioPercentage: yup
      .number()
      .nullable()
      .when('fuelGrade', {
        is: (value) =>
          fuelGrades.find((fg) => fg.data.code === value)?.data.isBiofuel,
        then: yup
          .number()
          .required(required)
          .max(100, range(0, 100))
          .positive('Must be greater than 0'),
        otherwise: yup.number().notRequired(),
      }),
    quantityOrdered: yup
      .number()
      .nullable()
      .required(required)
      .min(1, range(1, 20000))
      .max(20000, range(1, 20000)),
    quantityAgreed: yup
      .number()
      .nullable()
      .required(required)
      .min(1, range(1, 20000))
      .max(20000, range(1, 20000)),
    quantityPerChiefEngineer: yup
      .number()
      .nullable()
      .required(required)
      .min(1, range(1, 20000))
      .max(20000, range(1, 20000)),
    marpolSeal: yup
      .string()
      .max(25, 'Must be between 0 and 25 characters long')
      .notRequired(),
    timestamp: yup.date().required(required),
    notes: yup
      .string()
      .notRequired()
      .max(1000, 'Cannot be more than 1000 characters long'),
    water: yup
      .number()
      .nullable()
      .notRequired()
      .when('fuelType', {
        is: FuelType.MM,
        then: yup.number().min(0, range(0, 1)).max(1, range(0, 1)),
        otherwise: yup.number().min(0, range(0, 10)).max(10, range(0, 10)),
      }),
    ash: yup
      .number()
      .nullable()
      .notRequired()
      .when('fuelType', {
        is: FuelType.MM,
        then: yup.number().min(0, range(0, 0.15)).max(0.15, range(0, 0.15)),
        otherwise: yup.number().min(0, range(0, 10)).max(10, range(0, 10)),
      }),
    sulphur: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, range(0, 5))
      .max(5, range(0, 5)),
    lcv: yup
      .number()
      .nullable()
      .notRequired()
      .when('fuelType', {
        is: FuelType.MM,
        then: yup.number().min(0, range(0, 25000)).max(25000, range(0, 25000)),
        otherwise: yup
          .number()
          .min(37000, range(37000, 45000))
          .max(45000, range(37000, 45000)),
      }),
    density15: yup
      .number()
      .nullable()
      .notRequired()
      .min(700, range(700, 1200))
      .max(1200, range(700, 1200)),
  })

const required = 'Required'

const range = (min: number, max: number) =>
  `Must be a value between ${min} and ${max}`
