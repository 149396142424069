import React, { useContext } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { type Option } from '../hooks'
import { FuelColors } from '../../../theme'
import { WindowContext } from '../../../contexts'

type Props = {
  name: string
  options?: Array<Option>
  onChange?: (value: string) => void
  label: string
  disabled?: boolean
  placeholder?: string
  hint?: string
  optionswidth?: 'trigger' | 'auto'
}

let ft: string | null = null

const BatchSelect = ({ name, options, onChange, ...rest }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string | null>(name)

  const handleChange = async ({ detail }: CustomEvent) => {
    await helpers.setValue(detail.value)
    onChange?.(detail.value)
  }

  return (
    <McSelect
      fit={windowSize}
      value={field.value}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      optionselected={handleChange}
      {...rest}
    >
      {options &&
        options.map((o) => {
          const isNewFuelType = o.fuelType !== ft
          const theme = FuelColors[o.fuelType]
          ft = o.fuelType
          return (
            <React.Fragment key={o.value}>
              {isNewFuelType && (
                <Group {...theme}>{o.fuelType || 'Other'}</Group>
              )}
              <McOption value={o.value} disabled={o.isDisabled}>
                <span>{o.label}</span>
              </McOption>
            </React.Fragment>
          )
        })}
    </McSelect>
  )
}

export default BatchSelect

const Group = styled.small<{ fill: string; stroke: string; font: string }>`
  font-weight: bold;
  margin: 0;
  padding: 4px;
  background-color: ${({ fill }) => fill};
  color: ${({ font }) => font};
  border-top: 1px solid ${({ stroke }) => stroke};
  border-bottom: 1px solid ${({ stroke }) => stroke};
`
