import styled from 'styled-components'

import { type MergedBatch } from './MixedBatchDetails'
import MixedBatchTableRows from './MixedBatchTableRows'
import { SimpleTable } from '../../../../commons'
import { grey } from '../../../../theme'
import { formatValue } from '../../../../utils'

type Props = {
  mixedBatches?: Array<MergedBatch>
  quantityCE?: number
}

const MixedBatchTable = ({ mixedBatches, quantityCE }: Props) => {
  return (
    <Wrapper>
      <SimpleTable>
        <thead>
          <tr>
            <BatchMixedHeader>Batches mixed</BatchMixedHeader>
            <QuantityHeader>Quantity [MT]</QuantityHeader>
          </tr>
        </thead>
        <tbody>
          {mixedBatches && <MixedBatchTableRows batches={mixedBatches} />}
        </tbody>
        <tfoot>
          <FooterRow>
            <th className='total'>Total quantity according to C/E</th>
            <th>{formatValue(quantityCE, 2)}</th>
          </FooterRow>
          <Spacer />
        </tfoot>
      </SimpleTable>
    </Wrapper>
  )
}

export default MixedBatchTable

const Spacer = styled.tr`
  height: 16px;
`

const FooterRow = styled.tr`
  background: ${grey[100]};
  th {
    &:not(:first-child) {
      font-feature-settings: 'tnum' 1;
      text-align: right;
    }
  }
`

const Wrapper = styled.div`
  max-width: 400px;
  table {
    min-width: 350px;
    overflow-x: auto;
    table-layout: fixed;
    white-space: nowrap;

    width: 100%;
    font-size: 14px;
    border-collapse: separate;
    border-spacing: 0;
    tbody tr:hover {
      background-color: rgba(100, 178, 212, 0.1);
    }
    th {
      text-align: left;

      div {
        &:nth-child(2) {
          text-align: left;
          font-style: italic;
          font-weight: 500;
          width: 100%;
        }
      }
    }
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 0 8px;
      border-top: 0;
      border-left: none;
      &:last-child {
        border-right: none;
      }

      &:first-child {
        text-align: left;
      }
      text-align: right;

      &:not(:first-child) {
        font-feature-settings: 'tnum' 1;
      }
    }
  }
`

const BatchMixedHeader = styled.th`
  width: 70%;
`
const QuantityHeader = styled.th`
  width: 30%;
`
