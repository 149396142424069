import { useContext } from 'react'
import { useField } from 'formik'
import { McInput } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../contexts'

type Props = {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const InputText = ({ name, ...rest }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string | null>(name)

  const handleBlur = async (event: FocusEvent) => {
    const target = event.target as HTMLInputElement
    const newValue = target.value.trim()

    if (field.value === newValue) {
      // No change. Do nothing.
      return
    }

    // Update the formik field value.
    await helpers.setValue(newValue)
  }

  return (
    <McInput
      fit={windowSize}
      width='100'
      type='text'
      value={field.value}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      blur={handleBlur}
      autocomplete='off'
      {...rest}
    />
  )
}
