import React from 'react'
import { withRouter } from 'react-router'

import { filtersConfig, mapVesselStatusToTrimChartData } from './TrimPage.utils'
import { Wrapper } from './TrimPage.styles'
import { TrimVisualizer } from './TrimVisualizer/TrimVisualizer'
import TrimChart from './TrimChart/TrimChart'
import FilterPanel, {
  Filters,
  FiltersNotification,
  getFiltersFromQueryParams,
} from '../../features/filter'
import { TrimChartsContainer } from '../../features/trim-consumption-stw'
import { getTimestampFromVesselStatus } from '../../utils'
import { IVesselPageContext } from '../../contexts'
import { withVesselPageContext } from '../../higher-order-components'
import { ContentCard, Loading } from '../../commons'
import { ContentLayout } from '../../layout'
import { layoutSizes } from '../../theme'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

type Props = IVesselPageContext

type State = {
  filters?: Filters
}

class TrimPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      filters: getFiltersFromQueryParams(),
    }
  }

  onFiltersReset = () => {
    this.setState({ filters: undefined })
  }

  render() {
    const { vesselStatus, imoNo } = this.props
    const { filters } = this.state

    return (
      <ContentLayout header='Trim' hasFilter>
        <FiltersNotification onReset={this.onFiltersReset} />
        <TemNotifications />
        <PerformanceAlerts />
        <FilterPanel
          onChange={(filters) => this.setState({ filters })}
          config={filtersConfig}
          value={filters}
          onReset={this.onFiltersReset}
        />
        <Wrapper>
          <div className='full-components'>
            <ContentCard
              id='trim-simulator'
              title='Calm water trim simulator'
              helpTextKey='trim/trim-visualizer'
            >
              {imoNo && <TrimVisualizer imoNo={imoNo} />}
            </ContentCard>
          </div>
          <TrimChartsContainer vesselStatus={vesselStatus} filters={filters} />
          <div className='wrapped-components'>
            <ContentCard
              id='trim'
              title='Trim'
              additionalInfo={
                <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
              }
              helpTextKey='live-trim'
              width={layoutSizes.half}
            >
              {vesselStatus ? (
                <TrimChart
                  data={mapVesselStatusToTrimChartData(vesselStatus)}
                />
              ) : (
                <Loading />
              )}
            </ContentCard>
          </div>
        </Wrapper>
      </ContentLayout>
    )
  }
}

export default withRouter(withVesselPageContext(TrimPage))
