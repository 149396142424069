import { useContext, useEffect, useState } from 'react'

import MixedBatchForm from './MixedBatchForm'
import MixedBatchDetails from './MixedBatchDetails'
import { Performance } from '../../../../api-models'
import { Loading, Modal } from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'

type Props = {
  entryId?: string
  closeHandler: (refresh?: boolean) => void
}

export const MixedBatchModal = ({ closeHandler, entryId }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [mixedBatch, setMixedBatch] =
    useState<Performance.FuelOilStock.MixedBatchEntryResponse>()

  useEffect(() => {
    if (!entryId) {
      return
    }

    PerformanceApi.getMixedBatch(imoNo, entryId)
      .then(setMixedBatch)
      .catch(() =>
        displayErrorModal({
          statusText: 'Could not get mixed batch',
          message: 'Could not find the requested mixed batch',
        }),
      )
  }, [entryId, imoNo])

  if (entryId && !mixedBatch) {
    return (
      <Modal title={'Mixed batch'} visible closeHandler={closeHandler}>
        <Loading />
      </Modal>
    )
  }

  if (!mixedBatch) {
    return <MixedBatchForm closeHandler={closeHandler} />
  }

  return (
    <MixedBatchDetails mixedBatch={mixedBatch} closeHandler={closeHandler} />
  )
}
