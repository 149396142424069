import { useContext, useState } from 'react'
import { useFormikContext } from 'formik'
import { McButton } from '@maersk-global/mds-react-wrapper'

import SelectBatchModal from './SelectBatchModal'
import { recalculateResults } from '../../EngineTestReportPage.utils'
import {
  BatchSelectionTableCell,
  TableContainer,
  TableHeadingCol,
  TableValueCol,
} from '../../EngineTestReportPage.styles'
import Legend from '../../Common/Legend'
import CommentField from '../../Common/CommentField'
import NumberInputField from '../../Common/NumberInputField'
import ReadonlyInputField from '../../Common/ReadonlyInputField'
import Checkbox from '../../Common/Checkbox'
import { formatValue, isShoreContext } from '../../../../utils'
import { WindowContext } from '../../../../contexts'
import InfoTooltip from '../../../../components/InfoTooltip'
import { UNITS } from '../../../../utils/constants'

const GeneralTab = () => {
  const { windowSize } = useContext(WindowContext)
  const [selectBatchModalOpen, setSelectBatchModalOpen] = useState(false)

  const ctx = useFormikContext<GandalfApi.AetcReport>()
  const { data } = ctx.values

  const isBatchManualInput = data.general.batchId.externalSource === null
  const isBatchManuallySelected =
    isBatchManualInput && data.general.batchId.value !== null

  const _recalculateResults = () => {
    if (isShoreContext()) return
    return void recalculateResults(ctx)
  }

  return (
    <div slot='panel'>
      <Legend />
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Electrical</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InfoTooltip
                  label='Electrical energy produced'
                  content='Electrical Energy of the selected AE for Performance test.'
                />
              </td>
              <td>
                <NumberInputField
                  name={'data.general.energyProduced'}
                  unit={UNITS.KILO_WATT_HOUR}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>
                <InfoTooltip
                  label='Electrical power'
                  content='Average Electrical Power of the selected AE for Performance test.'
                />
              </td>
              <td>
                <ReadonlyInputField
                  value={formatValue(data.result.general.electricalPower, 2)}
                  unit={UNITS.KILO_WATT}
                />
              </td>
            </tr>
            <tr>
              <td>
                <InfoTooltip
                  label='Engine indicated power'
                  content='Calculated as: Electrical Power / Electrical Efficiency Reference value from Shop Test'
                />
              </td>
              <td>
                <ReadonlyInputField
                  value={formatValue(data.result.general.indicatedPower, 2)}
                  unit={UNITS.KILO_WATT}
                />
              </td>
            </tr>
            <tr>
              <td>Engine load</td>
              <td>
                <ReadonlyInputField
                  value={formatValue(data.result.general.engineLoad, 2)}
                  unit='%&nbsp;MCR'
                />
              </td>
            </tr>
            <tr>
              <td>Voltage</td>
              <td>
                <NumberInputField
                  name={'data.general.voltage'}
                  unit={UNITS.VOLTAGE}
                />
              </td>
            </tr>
            <tr>
              <td>Current</td>
              <td>
                <NumberInputField
                  name={'data.general.current'}
                  unit={UNITS.AMPERE}
                />
              </td>
            </tr>
            <tr>
              <td>Power factor</td>
              <td>
                <NumberInputField
                  name={'data.general.powerFactor'}
                  unit='Cos&nbsp;ɸ'
                />
              </td>
            </tr>
            <tr>
              <td>Engine RPM</td>
              <td>
                <NumberInputField
                  name={'data.general.engRpm'}
                  unit={UNITS.RPM}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Engine</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Engine total running hours</td>
              <td>
                <NumberInputField
                  name={'data.general.engRunHours'}
                  unit={UNITS.HOURS}
                />
              </td>
            </tr>
            <tr>
              <td>Engine room / barometric pressure</td>
              <td>
                <NumberInputField
                  name={'data.general.engRoomPres'}
                  unit='mmH₂O'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Engine room / blower inlet temperature</td>
              <td>
                <NumberInputField
                  name={'data.general.engRoomTemp'}
                  unit={UNITS.TEMPERATURE}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Scavenge air pressure</td>
              <td>
                <NumberInputField
                  name={'data.general.scavAirPres'}
                  unit={UNITS.BAR}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Scavenge air temperature</td>
              <td>
                <NumberInputField
                  name={'data.general.scavAirTemp'}
                  unit={UNITS.TEMPERATURE}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Pressure drop across air filter</td>
              <td>
                <NumberInputField
                  name={'data.general.presDropAirFilter'}
                  unit='mmH₂O'
                />
              </td>
            </tr>
            <tr>
              <td>Pressure drop across air cooler</td>
              <td>
                <NumberInputField
                  name={'data.general.presDropAirCooler'}
                  unit='mmH₂O'
                />
              </td>
            </tr>
            <tr>
              <td>Turbocharger speed</td>
              <td>
                <NumberInputField
                  name={'data.general.tcSpeed'}
                  unit={UNITS.RPM}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Exhaust gas temperature before turbocharger</td>
              <td>
                <NumberInputField
                  name={'data.general.exhGasTempBeforeTc'}
                  unit={UNITS.TEMPERATURE}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Exhaust gas temperature after turbocharger</td>
              <td>
                <NumberInputField
                  name={'data.general.exhGasTempAfterTc'}
                  unit={UNITS.TEMPERATURE}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>LT CW temperature inlet to cooler</td>
              <td>
                <NumberInputField
                  name={'data.general.cwTempBeforeCooler'}
                  unit={UNITS.TEMPERATURE}
                />
              </td>
            </tr>
            <tr>
              <td>LT CW temperature outlet from cooler</td>
              <td>
                <NumberInputField
                  name={'data.general.cwTempAfterCooler'}
                  unit={UNITS.TEMPERATURE}
                />
              </td>
            </tr>
            <tr>
              <td>Sea water temperature</td>
              <td>
                <NumberInputField
                  name={'data.general.swTemp'}
                  unit={UNITS.TEMPERATURE}
                />
              </td>
            </tr>
            <tr>
              <td>VIT actuator position (if installed)</td>
              <td>
                <NumberInputField
                  name={'data.general.vitPos'}
                  unit={UNITS.MILLIMETER}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Fuel</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InfoTooltip
                  label='Total fuel consumption'
                  content={`If 2 AEs are running in parallel, the consumption of the selected AE for Performance test can be estimated by the ratio of the AE Powers (kW), if not directly available from individual Flow meters.

                  Example: 2 AEs running in parallel at AE#1 1200 kW, AE#2 800kW, then the AE#1 consumption is estimated as 60% of the Total AE consumption (i.e. 1200/(1200+800)) in %.`}
                />
              </td>
              <td>
                <NumberInputField
                  name={'data.general.consumedMass'}
                  unit={UNITS.KILOGRAM}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Fuel batch</td>
              <BatchSelectionTableCell>
                <span>{data.general.batchName.value}</span>
                {isBatchManualInput && (
                  <>
                    {isBatchManuallySelected ? (
                      <McButton
                        key='edit'
                        fit={windowSize}
                        hiddenlabel
                        appearance='neutral'
                        icon='bars-horizontal-bullets'
                        click={() => setSelectBatchModalOpen(true)}
                      />
                    ) : (
                      <McButton
                        key='select'
                        fit={windowSize}
                        label='Select batch'
                        appearance='neutral'
                        trailingicon='bars-horizontal-bullets'
                        click={() => setSelectBatchModalOpen(true)}
                      />
                    )}
                    <SelectBatchModal
                      open={selectBatchModalOpen}
                      onClose={() => setSelectBatchModalOpen(false)}
                    />
                  </>
                )}
              </BatchSelectionTableCell>
            </tr>
            <tr>
              <td>Fuel LCV</td>
              <td>
                <NumberInputField
                  name={'data.general.batchLcv'}
                  unit={UNITS.KJ_PER_KILO}
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Fuel CCAI</td>
              <td>
                <NumberInputField name={'data.general.batchCcai'} />
              </td>
            </tr>
            <tr>
              <td>Fuel oil temperature inlet to engine</td>
              <td>
                <NumberInputField
                  name={'data.general.fuelTempInlet'}
                  unit={UNITS.TEMPERATURE}
                />
              </td>
            </tr>
            <tr>
              <td>Measuring equip. trigger angle (TDC correction)</td>
              <td>
                <NumberInputField
                  name={'data.general.tdcCorrection'}
                  unit={UNITS.DEGREES}
                />
              </td>
            </tr>
            <tr>
              <td>Active leakage on engine</td>
              <td>
                <Checkbox
                  name={'data.general.hasLeakage'}
                  label='Has leakage'
                />
              </td>
            </tr>
            <tr>
              <td>
                <InfoTooltip
                  label='SFOC measured'
                  content='Reported Total Fuel Consumption / (Engine Indicated Power  * Run period)'
                />
              </td>
              <td>
                <ReadonlyInputField
                  value={formatValue(data.result.general.sfoc, 2)}
                  unit={UNITS.SFOC}
                />
              </td>
            </tr>
            <tr>
              <td>SFOC LCV corrected to 42700 {UNITS.KJ_PER_KILO}</td>
              <td>
                <ReadonlyInputField
                  value={formatValue(data.result.general.sfocLcvCorrected, 2)}
                  unit={UNITS.SFOC}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <CommentField label='Comment' name='data.comment' />
    </div>
  )
}

export default GeneralTab
