import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { ContentCard, Loading } from '../commons'
import { IVesselPageContext } from '../contexts'
import { ElectricalConsumptionPieChart } from '../features/electrical-consumption'
import {
  CostElectricalProductionChartContainer,
  ElectricalProductionBars,
} from '../features/electrical-production'
import FilterPanel, {
  FilterConfig,
  Filters,
  FiltersNotification,
  getFiltersFromQueryParams,
} from '../features/filter'
import { withVesselPageContext } from '../higher-order-components'
import { ContentLayout } from '../layout'
import { routerParams } from '../routes'
import { layoutSizes } from '../theme'
import { getTimestampFromVesselStatus, isFeatureEnabled } from '../utils'
import TemNotificationsSwitch from '../components/TemNotificationsSwitch/TemNotificationsSwitch'
import PerformanceAlerts from '../components/PerformanceAlerts/PerformanceAlerts'

const filtersConfig: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 60,
    selections: [
      { value: 24, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: false,
  },
  numberOfDigits: {
    show: false,
    selections: [],
  },
  chartSmoothing: {
    show: false,
  },
}

interface VesselModel {
  vesselName: string
  imoNumber: string
}

interface ElectricalProductionPageProps
  extends IVesselPageContext,
    RouteComponentProps<routerParams> {
  vessel: VesselModel
}

interface ElectricalProductionPageState {
  filters?: Filters
}

class ElectricalProductionPage extends React.Component<
  ElectricalProductionPageProps,
  ElectricalProductionPageState
> {
  constructor(props: ElectricalProductionPageProps) {
    super(props)

    this.state = {
      filters: getFiltersFromQueryParams(),
    }
  }

  onFiltersReset = () => {
    this.setState({
      filters: undefined,
    })
  }

  public render() {
    const { imoNo, vesselStatus, configuration } = this.props
    const { filters } = this.state

    return (
      <ContentLayout header='Electrical production' hasFilter>
        <FiltersNotification onReset={this.onFiltersReset} />
        <FilterPanel
          onChange={(filters) =>
            this.setState({
              filters,
            })
          }
          config={filtersConfig}
          value={filters}
          onReset={this.onFiltersReset}
        />
        {imoNo && (
          <TemNotificationsSwitch
            imoNo={imoNo}
            timestamp={vesselStatus?.timestamp}
          />
        )}
        <PerformanceAlerts />
        <div className='full-component'>
          <ContentCard
            id='electrical-production-bars'
            title='Electrical production'
            width=''
            helpTextKey='electrical-balance/bars'
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
          >
            {!!vesselStatus && !!configuration && (
              <ElectricalProductionBars
                vesselConfiguration={configuration}
                auxEngs={vesselStatus.auxEngs}
                whr={vesselStatus.whr}
                hasData={vesselStatus.hasData}
                isDualEngine={configuration.hasTwoMainEngines}
                shaftGenerator1={vesselStatus.shaftGenerator1}
                shaftGenerator2={vesselStatus.shaftGenerator2}
                timestamp={vesselStatus.timestamp}
              />
            )}
            {!vesselStatus && <Loading />}
          </ContentCard>
        </div>
        {isFeatureEnabled(
          'electrical-production/electrical-production-costs',
        ) && (
          <div className='full-component'>
            <CostElectricalProductionChartContainer
              vesselStatus={vesselStatus}
              filters={filters}
            />
          </div>
        )}
        <div className='wrapped-components'>
          <ContentCard
            title='Electrical consumption'
            id='elec-consmp'
            helpTextKey='electrical-balance/consumption-dial'
            width={layoutSizes.half}
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
          >
            {!vesselStatus || !configuration ? (
              <Loading />
            ) : (
              <ElectricalConsumptionPieChart
                hasTwoMainEngines={configuration.hasTwoMainEngines}
                data={vesselStatus}
              />
            )}
          </ContentCard>
        </div>
      </ContentLayout>
    )
  }
}

export default withVesselPageContext(ElectricalProductionPage)
