import { useContext } from 'react'
import { useField } from 'formik'
import { McInput } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../contexts'

type Props = {
  name: string
  label?: string
  unit?: string
  hint?: string
  disabled?: boolean
  onBlur?: (value: number | null) => void
  onInput?: (value: number | null) => void
}

export const InputNumber = ({
  name,
  label,
  unit,
  hint,
  disabled,
  onBlur,
  onInput,
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<number | null>(name)

  const resolveNewValue = (event: UIEvent): number | null => {
    const target = event.target as HTMLInputElement
    const stringValue = target.value
    let newValue: number | null = null
    if (stringValue !== '') {
      newValue = Number(stringValue)
    }

    return newValue
  }

  /**
   * Prevents changing the value of the input field by scrolling while the
   * cursor is over it.
   */
  const handleOnWheel = (event: WheelEvent) => {
    if (document.activeElement === event.target) {
      event.preventDefault()
    }
  }

  const handleInput = (event: InputEvent) => {
    onInput?.(resolveNewValue(event))
  }

  const handleBlur = async (event: FocusEvent) => {
    const newValue = resolveNewValue(event)

    if (field.value === newValue) {
      // No change. Do nothing.
      return
    }

    // Update the formik field value.
    await helpers.setValue(newValue)

    onBlur?.(newValue)
  }

  return (
    <McInput
      fit={windowSize}
      label={label}
      hiddenlabel={!label}
      width='100'
      type='number'
      value={field.value}
      suffix={unit}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      // @ts-ignore
      onwheel={handleOnWheel}
      input={handleInput}
      blur={handleBlur}
      autocomplete='off'
      disabled={disabled}
      hint={hint}
    />
  )
}
