import styled from 'styled-components'

export const SimpleTable = styled.table`
  border-collapse: collapse;

  thead {
    background-color: var(--mds_foundations_table_header-cell_background-color);
    color: var(--mds_foundations_table_header-cell_text-color);
  }

  th,
  td {
    border: 1px solid ${(props) => props.theme.colors.grey[200]};
    padding: 8px;

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  th {
    border: none;

    &:first-child {
      border-top-left-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
      border-bottom-left-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
    }

    &:last-child {
      border-top-right-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
      border-bottom-right-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
    }
  }
`
