import { useContext } from 'react'
import moment, { type Moment } from 'moment'
import { McInputDate, McInputTime } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './InputDateTime.styles'
import { DATE_FORMAT, TIME_FORMAT } from './InputDateTime.utils'
import { WindowContext } from '../../../contexts'

type Props = {
  timestamp: Moment
  onChange: (timestamp: Moment) => void
  label?: string
}

const InputDateTimeStandAlone = ({ timestamp, onChange, label }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const handleDateInput = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement // YYYY-MM-DD

    if (value.length < DATE_FORMAT.length) {
      return
    }

    /* New Moment date with time 00:00 */
    const newTimestamp = moment.utc(value)

    /* If component already holds a value, add the time part to the new date */
    if (timestamp) {
      const currentTimestamp = moment.utc(timestamp)
      newTimestamp
        .add(currentTimestamp.hour(), 'hour')
        .add(currentTimestamp.minute(), 'minute')
    }

    onChange(newTimestamp)
  }

  const handleTimeInput = (event: InputEvent) => {
    if (!timestamp) {
      return
    }

    const { value } = event.target as HTMLInputElement // YYYY-MM-DD

    if (value.length < TIME_FORMAT.length) {
      return
    }

    const [hours, minutes] = value.split(':')
    const newTimestamp = moment.utc(timestamp).hours(+hours).minutes(+minutes)

    onChange(newTimestamp)
  }

  const _date = timestamp ? timestamp.format(DATE_FORMAT) : ''
  const _time = timestamp ? timestamp.format(TIME_FORMAT) : ''

  return (
    <Wrapper>
      <McInputDate
        fit={windowSize}
        width='100'
        hiddenlabel={!label}
        value={_date}
        input={handleDateInput}
      >
        {label && <span slot='label'>{label}</span>}
      </McInputDate>
      <McInputTime
        fit={windowSize}
        width='100'
        hiddenlabel
        minutestep={10}
        value={_time}
        input={handleTimeInput}
      />
    </Wrapper>
  )
}

export default InputDateTimeStandAlone
