import { type FormikContextType } from 'formik'

import { displayToast, doPost, getGandalfV2Url } from '../../utils'
import { isGandalfError } from '../../queries/GandalfApi/GandalfApi'

/**
 * Sets the field errors on a Formik form based on errors returned from Gandalf.
 */
export const setFieldErrors = (
  err: GandalfApi.ValidationError,
  setFieldError: (field: string, message: string | undefined) => void,
) => {
  err.body.errors.forEach((error) => {
    error.paths.forEach((path) => {
      setFieldError(`data.${path.replace(/\.\./g, '.')}`, error.message)
    })
  })
}

export const displaySuccessToast = () => {
  void displayToast(
    'success',
    'Report submitted',
    'You have successfully submitted the Engine performance test report.',
  )
}

/**
 * Fires a request to the `recalculate` Gandalf endpoint which takes the form
 * values as input and returns a report object with recalculated values. Then it
 * sets the form values to the new report object.
 */
export const recalculateResults = async (
  ctx: FormikContextType<GandalfApi.Report<any>>,
) => {
  const { values, setValues } = ctx
  const { imoNo, reportId, reportType } = values

  try {
    const newValues = await doPost(
      `${getGandalfV2Url()}/vessels/${imoNo}/reports/${reportId}/recalculate?type=${reportType}`,
      values,
    )
    setValues(newValues)
  } catch (err) {
    displayToast(
      'error',
      'Failed to update calculated values',
      isGandalfError(err) ? err.body.error : '',
    )
  }
}
