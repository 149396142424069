import { useRouteMatch } from 'react-router-dom'
import { FaroRoute } from '@grafana/faro-react'

import { ManageFavouritesPage, VesselsOverviewPage } from '../pages'
import { AppLayout } from '../layout'

export const pages: Array<PageProps> = [
  {
    name: 'Vessel overview',
    pagePath: '/vessel-overview',
    displayInOverviewMenu: true,
    icon: 'vessel-front',
    component: VesselsOverviewPage,
  },
  {
    name: 'Manage Lists',
    pagePath: '/manage-lists',
    displayInOverviewMenu: false,
    component: ManageFavouritesPage,
  },
]

const ShoreRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <AppLayout>
      {pages.map((page) => (
        <FaroRoute
          key={page.pagePath}
          path={path + page.pagePath}
          exact={page.isExact}
        >
          <page.component />
        </FaroRoute>
      ))}
    </AppLayout>
  )
}

export default ShoreRoutes
