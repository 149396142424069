import { TabStatus } from '../EngineTestReportPage.consts'

export const MetcReportTabSearchParam = 'metc-report-tab'

export enum TabIndex {
  GENERAL,
  CYLINDERS,
  TURBOCHARGERS,
  RESULTS,
}

export enum Section {
  GENERAL = 'General',
  CYLINDERS = 'Cylinders',
  TURBOCHARGERS = 'Turbochargers',
  RESULTS = 'Results',
}

export const TabIndexToSection = {
  [TabIndex.GENERAL]: Section.GENERAL.toLowerCase(),
  [TabIndex.CYLINDERS]: Section.CYLINDERS.toLowerCase(),
  [TabIndex.TURBOCHARGERS]: Section.TURBOCHARGERS.toLowerCase(),
}

export type TabsStatus = {
  [key in TabIndex]: TabStatus
}
