import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import {
  initChart,
  updateChart,
  updateDeviationChart,
} from './CylinderCharts.utils'
import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'

const PMAX_CHART_ID = 'pmax-chart'
const PMAX_DEVIATION_CHART_ID = 'pmax-deviation-chart'
const PCOMP_CHART_ID = 'pcomp-chart'
const PCOMP_DEVIATION_CHART_ID = 'pcomp-deviation-chart'
const MIP_CHART_ID = 'mip-chart'
const MIP_DEVIATION_CHART_ID = 'mip-deviation-chart'

type Props = {
  isActive: boolean
  cylinders: Array<GandalfApi.Metc.Cylinder>
  result: GandalfApi.Metc.Result
}

const CylinderCharts = ({ isActive, cylinders, result }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const pMaxChartRef = useRef<EChartsType>()
  const pMaxDeviationChartRef = useRef<EChartsType>()
  const pCompChartRef = useRef<EChartsType>()
  const pCompDeviationChartRef = useRef<EChartsType>()
  const mipChartRef = useRef<EChartsType>()
  const mipDeviationChartRef = useRef<EChartsType>()

  const pMaxMeasured = cylinders.map((c) => c.pmax.value)
  const pCompMeasured = cylinders.map((c) => c.pcomp.value)
  const mipMeasured = cylinders.map((c) => c.mip.value)

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      pMaxChartRef.current = initChart(PMAX_CHART_ID, 'Pmax [bar]', {
        'Pmax measured': false,
      })
      updateChart(
        result.cylinders.pmaxIso,
        pMaxMeasured,
        result.adjustedIsoConditions.adjustedToISOConditions.pmax,
        result.cylinders.pmaxModel,
        ['Pmax ISO corrected', 'Pmax measured'],
        pMaxChartRef.current,
      )

      pMaxDeviationChartRef.current = initChart(
        PMAX_DEVIATION_CHART_ID,
        'Pmax deviation [bar]',
      )
      updateDeviationChart(
        pMaxMeasured,
        result.cylinders.pmaxAvg,
        3,
        'Pmax deviation',
        pMaxDeviationChartRef.current,
      )

      pCompChartRef.current = initChart(PCOMP_CHART_ID, 'Pcomp [bar]', {
        'Pcomp measured': false,
      })
      updateChart(
        result.cylinders.pcompIso,
        pCompMeasured,
        result.cylinders.pcompAvg,
        result.cylinders.pcompModel,
        ['Pcomp ISO corrected', 'Pcomp measured'],
        pCompChartRef.current,
      )

      pCompDeviationChartRef.current = initChart(
        PCOMP_DEVIATION_CHART_ID,
        'Pcomp deviation [bar]',
      )
      updateDeviationChart(
        pCompMeasured,
        result.cylinders.pcompAvg,
        3,
        'Pcomp deviation',
        pCompDeviationChartRef.current,
      )

      mipChartRef.current = initChart(MIP_CHART_ID, 'MIP [bar]')
      updateChart(
        mipMeasured,
        null,
        result.cylinders.mipAvg,
        null,
        ['MIP measured'],
        mipChartRef.current,
      )

      mipDeviationChartRef.current = initChart(
        MIP_DEVIATION_CHART_ID,
        'MIP deviation [bar]',
      )
      updateDeviationChart(
        mipMeasured,
        result.cylinders.mipAvg,
        0.3,
        'MIP deviation',
        mipDeviationChartRef.current,
      )

      setIsInitialized(true)
    }, 0)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) return
    updateChart(
      result.cylinders.pmaxIso,
      pMaxMeasured,
      result.adjustedIsoConditions.adjustedToISOConditions.pmax,
      result.cylinders.pmaxModel,
      ['Pmax ISO corrected', 'Pmax measured'],
      pMaxChartRef.current,
    )
    updateDeviationChart(
      pMaxMeasured,
      result.cylinders.pmaxAvg,
      3,
      'Pmax deviation',
      pMaxDeviationChartRef.current,
    )

    updateChart(
      result.cylinders.pcompIso,
      pCompMeasured,
      result.adjustedIsoConditions.adjustedToISOConditions.pcomp,
      result.cylinders.pcompModel,
      ['Pcomp ISO corrected', 'Pcomp measured'],
      pCompChartRef.current,
    )
    updateDeviationChart(
      pCompMeasured,
      result.cylinders.pcompAvg,
      3,
      'Pcomp deviation',
      pCompDeviationChartRef.current,
    )

    updateChart(
      mipMeasured,
      null,
      result.cylinders.mipAvg,
      null,
      ['MIP measured'],
      mipChartRef.current,
    )
    updateDeviationChart(
      mipMeasured,
      result.cylinders.mipAvg,
      0.3,
      'MIP deviation',
      mipDeviationChartRef.current,
    )
  }, [pMaxMeasured, pCompMeasured, mipMeasured, result])

  return (
    <ChartsWrapper>
      <ChartWrapper id={PMAX_CHART_ID} />
      <ChartWrapper id={PMAX_DEVIATION_CHART_ID} />
      <ChartWrapper id={PCOMP_CHART_ID} />
      <ChartWrapper id={PCOMP_DEVIATION_CHART_ID} />
      <ChartWrapper id={MIP_CHART_ID} />
      <ChartWrapper id={MIP_DEVIATION_CHART_ID} />
    </ChartsWrapper>
  )
}

export default CylinderCharts
