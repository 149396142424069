import React, { useState } from 'react'
import styled from 'styled-components'

import {
  ChartContainer,
  ChartStencil,
  FancyLabel,
  NotFound,
} from '../../../../commons'
import {
  lightOrangeColorScheme,
  seaGreenColorScheme,
  whiteColorScheme,
} from '../../../../theme'
import { Performance } from '../../../../api-models'
import { UNITS } from '../../../../utils/constants'
import { BatchBurndownChart } from './BatchBurndownChart'
import { BatchBurndownSeries, ToggleInBurndownChart } from './models'
import { hasBurndownData } from './utils'

const Wrapper = styled.div`
  .legend {
    padding: 8px 0 0 16px;
    display: flex;
    .toggles {
      display: flex;
      * {
        margin: 0 8px 0 0;
      }
    }
  }
`

type Props = {
  batchBurndownSeries?: Array<BatchBurndownSeries>
  userEntriesSeries?: Array<Performance.FuelOilStock.UserEntry>
  id: string
  openWindow?: Performance.FuelOilStock.OpenWindow
  period?: Performance.Common.Period
  setInitialZoom: any
  initialZoom: any
}

export const BatchBurndownChartContainer = ({
  batchBurndownSeries,
  userEntriesSeries,
  id,
  openWindow,
  period,
  initialZoom,
  setInitialZoom,
}: Props) => {
  const [toggleInChart, setToggleInChart] = useState<ToggleInBurndownChart>({
    Events: true,
  })

  const handleToggleChange = (key: string) => {
    setToggleInChart((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  const isFetchingData = !batchBurndownSeries
  const hasBatchBurndownData = hasBurndownData(batchBurndownSeries)
  const hasNoData =
    batchBurndownSeries?.length === 0 &&
    (!userEntriesSeries || userEntriesSeries.length === 0)

  return (
    <Wrapper>
      {batchBurndownSeries && (
        <div className='legend'>
          <div className='toggles'>
            {Object.keys(toggleInChart).map((key) => (
              <FancyLabel
                key={key}
                colorScheme={seaGreenColorScheme}
                value={toggleInChart[key]}
                onChange={() => handleToggleChange(key)}
              >
                {key}
              </FancyLabel>
            ))}
            {!hasBatchBurndownData && (
              <FancyLabel colorScheme={lightOrangeColorScheme} value={true}>
                No available burndown data for this fuel type
              </FancyLabel>
            )}
          </div>
        </div>
      )}
      {isFetchingData && <ChartStencil chartType='area' />}
      {hasNoData && <NotFound text='No data available' />}

      {batchBurndownSeries && !hasNoData && period && (
        <ChartContainer
          hideSideContent={true}
          sideContentWidth={0}
          y1Label={{ name: UNITS.METRIC_TON, colorScheme: whiteColorScheme }}
        >
          <BatchBurndownChart
            toggleInChart={toggleInChart}
            id={id}
            combinedUserEntriesSeries={userEntriesSeries}
            combinedBurndownSeries={batchBurndownSeries}
            openWindow={openWindow}
            period={period}
            initialZoom={initialZoom}
            setInitialZoom={setInitialZoom}
          />
        </ChartContainer>
      )}
    </Wrapper>
  )
}
