import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import { ModelData, initChart, updateChart, TestData } from './Charts.utils'
import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'

const ISO_SFOC_CHART_ID = 'iso-sfoc-chart'
const ISO_PMAX_PCOMP_CHART_ID = 'iso-pmax-pcomp-chart'

type Props = {
  isActive: boolean
  general: GandalfApi.Metc.General
  equipment: GandalfApi.Metc.Equipment
  result: GandalfApi.Metc.Result
}

const IsoCharts = ({ isActive, general, equipment, result }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const sfocChartRef = useRef<EChartsType>()
  const pmaxPcompChartRef = useRef<EChartsType>()

  const operatingCondition = general.tccoEngaged.value
    ? equipment.shopTest.tccoOperatingCondition!
    : equipment.shopTest.defaultOperatingCondition

  const modelSfocData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.sfoc,
      name: 'SFOC shop test',
    },
  ]

  const testSfocData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.adjustedIsoConditions.adjustedToISOConditions.sfoc,
      name: 'SFOC test (ISO)',
    },
  ]

  const modelPcompPmaxData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.pcomp,
      name: 'Pcomp shop test',
    },
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.pmax,
      name: 'Pmax shop test',
    },
  ]

  const testPcompPmaxData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.adjustedIsoConditions.adjustedToISOConditions.pcomp,
      name: 'Pcomp test (ISO)',
    },
    {
      x: result.general.engineLoad,
      y: result.adjustedIsoConditions.adjustedToISOConditions.pmax,
      name: 'Pmax test (ISO)',
    },
  ]

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      sfocChartRef.current = initChart({
        id: ISO_SFOC_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'SFOC',
        yUnit: 'g/kWh',
        truncateYAxis: 20,
      })
      updateChart(modelSfocData, testSfocData, sfocChartRef.current)

      pmaxPcompChartRef.current = initChart({
        id: ISO_PMAX_PCOMP_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Pmax & Pcomp',
        yUnit: 'bar',
      })
      updateChart(
        modelPcompPmaxData,
        testPcompPmaxData,
        pmaxPcompChartRef.current,
      )
    }, 0)

    setIsInitialized(true)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) return
    updateChart(modelSfocData, testSfocData, sfocChartRef.current)
    updateChart(
      modelPcompPmaxData,
      testPcompPmaxData,
      pmaxPcompChartRef.current,
    )
  }, [result.adjustedIsoConditions])

  return (
    <ChartsWrapper>
      <ChartWrapper id={ISO_SFOC_CHART_ID} />
      <ChartWrapper id={ISO_PMAX_PCOMP_CHART_ID} />
    </ChartsWrapper>
  )
}

export default IsoCharts
