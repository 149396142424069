import { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import moment, { type Moment } from 'moment'
import { McModal } from '@maersk-global/mds-react-wrapper'

import LossForm from './LossForm'
import { resolveModalHeight } from './LossForm.styles'
import { Heading } from '../styles'
import { useBatchOptions } from '../../hooks'
import { HelpText } from '../../../help-texts'
import { type Performance } from '../../../../api-models'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../../utils'

export type LossFormValues = {
  timestamp?: Moment
  batchId: string
  destinationBatchId: string
  quantity: string
  reasonCode: string
  notes: string
}

const defaultInitialValues: LossFormValues = {
  batchId: '',
  destinationBatchId: '',
  quantity: '',
  reasonCode: '',
  notes: '',
}

const REQUIRED = 'Required'

const validationSchema = yup.object().shape({
  batchId: yup.string().required(REQUIRED),
  destinationBatchId: yup.string().required(REQUIRED),
  quantity: yup
    .number()
    .nullable()
    .required(REQUIRED)
    .moreThan(0, 'Must be higher than 0'),
  notes: yup.string().notRequired().max(1000, 'Max. 1000 characters'),
  reasonCode: yup.string().required(REQUIRED),
  timestamp: yup.date().required(REQUIRED),
})

type Props = {
  entryId?: string
  closeHandler: (refresh?: boolean) => void
}

export const LossModal = ({ entryId, closeHandler }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!
  const batchOptions = useBatchOptions(imoNo)

  const [loss, setLoss] = useState<Performance.FuelOilStock.LossEntry>()
  const [initialValues, setInitialValues] =
    useState<LossFormValues>(defaultInitialValues)
  const [didTrySubmit, setDidTrySubmit] = useState(false)
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getLoss(imoNo, entryId)
        .then(setLoss)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get loss',
            message: e.message,
          }),
        )
    }
  }, [entryId, imoNo])

  useEffect(() => {
    if (!loss) {
      return setInitialValues(defaultInitialValues)
    }

    setInitialValues({
      batchId: loss.batchId,
      destinationBatchId: loss.destinationBatchId ?? 'waste',
      quantity: loss.quantity,
      reasonCode: loss.reasonCode,
      notes: loss.notes,
      timestamp: moment(loss.timestamp),
    })
  }, [loss, imoNo])

  useEffect(() => {
    PerformanceApi.getStockOpenWindow(imoNo)
      .then(setOpenWindow)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get allowed time of loss interval',
          message: e.message,
        }),
      )
  }, [imoNo])

  const submitLoss = (
    values: LossFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true)
    const {
      batchId,
      quantity,
      reasonCode,
      notes,
      timestamp,
      destinationBatchId,
    } = values
    const lossRequestBody: Performance.FuelOilStock.Loss = {
      batchId,
      quantity,
      reasonCode,
      notes,
      destinationBatchId:
        destinationBatchId === 'waste' ? null : values.destinationBatchId,
      timestamp: timestamp?.toISOString() ?? '',
    }

    PerformanceApi.postLoss(imoNo, lossRequestBody)
      .then(() => closeHandler(true))
      .catch((e) => {
        void displayErrorModal({
          statusText: 'Could not submit loss',
          message: e.message,
        })
      })
      .finally(() => setSubmitting(false))
  }

  const deleteLoss = (
    entryId: string,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.deleteLoss(imoNo, entryId)
      .then(() => closeHandler(true))
      .catch((e) => {
        if (setSubmitting) setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  if (entryId && !loss) {
    return
  }

  const disabled = isShoreContext() || (!!loss && loss.readonly) || !!entryId

  const _fit = windowSize === 'large' ? 'medium' : windowSize

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) =>
        submitLoss(values, setSubmitting)
      }
      validationSchema={validationSchema}
      validateOnChange={didTrySubmit}
      enableReinitialize
    >
      <Form>
        <McModal
          fit={_fit}
          open
          width='1040px'
          closed={() => closeHandler()}
          height={resolveModalHeight(windowSize)}
        >
          <Heading slot='heading'>
            {loss ? 'View ' : 'Add '} loss
            <HelpText textKey='stock/loss' />
          </Heading>
          <LossForm
            loss={loss}
            disabled={disabled}
            batchOptions={batchOptions}
            onClose={closeHandler}
            openWindow={openWindow}
            onDidTrySubmit={() => setDidTrySubmit(true)}
            deleteHandler={() => {
              if (!!entryId) {
                deleteLoss(entryId, undefined)
              }
            }}
          />
        </McModal>
      </Form>
    </Formik>
  )
}
