import { useContext, useMemo } from 'react'
import { type Table } from '@tanstack/react-table'
import { McInput, McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { getEquipmentOptions } from './EngineTestFilters.utils'

import { EngineTestListModel } from '../EngineTestPage.types'
import { WindowContext } from '../../../contexts'
import { FilterWrapper } from '../../../components/Table/Table.styles'
import DateFilter from '../../ActivityLogPage/ActivityLogFilters/DateFilter/DateFilter'

type Props = {
  table: Table<EngineTestListModel>
}

const EngineTestFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const equipmentOptions = useMemo(() => getEquipmentOptions(table), [table])

  const handleEquipmentFilterChange = ({ detail }: CustomEvent) => {
    table.getColumn('equipment')?.setFilterValue(detail.value)
  }

  const handleTestNoFilterChange = (event: InputEvent) => {
    const value = (event.target as HTMLInputElement).value
    table.getColumn('reportNo')?.setFilterValue(value)
  }

  return (
    <FilterWrapper>
      <DateFilter table={table} columnId='startTimestamp' />
      <McSelect
        fit={windowSize}
        label='Equipment'
        placeholder='All'
        optionselected={handleEquipmentFilterChange}
      >
        {equipmentOptions.map((option) => (
          <McOption
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </McSelect>
      <McInput
        type='number'
        label='Test No.'
        fit={windowSize}
        input={handleTestNoFilterChange}
      />
    </FilterWrapper>
  )
}

export default EngineTestFilters
