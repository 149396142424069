import * as echarts from 'echarts/core'
import type { EChartsOption, EChartsType } from 'echarts/types/dist/shared'
import moment from 'moment'

import {
  AXIS_LABEL,
  TEXT_STYLE,
  TOOLTIP,
} from '../../../EngineTestReportPage/Common/ChartConfig'
import { MONTH_YEAR_DATE_FORMAT } from '../../../../components/formik/InputDateTime/InputDateTime.utils'
import { formatValue, calcPercentageSafely } from '../../../../utils'

export const initChart = (domId: string) => {
  const chartDom = document.getElementById(domId)

  if (!chartDom) {
    console.error(`Chart DOM element with ID '${domId}' not found`)
    return
  }

  const options: EChartsOption = {
    grid: {
      top: 0,
      left: 32,
      right: 32,
      bottom: 96,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: AXIS_LABEL,
      splitArea: { show: true },
      axisTick: { show: false },
    },
    yAxis: {
      type: 'category',
      nameGap: 24,
      axisLabel: AXIS_LABEL,
      splitArea: { show: true },
      axisTick: { show: false },
    },
    visualMap: {
      inverse: true,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      itemWidth: 32,
      itemHeight: 200,
      text: ['Least efficient', 'Most efficient'],
      formatter: '{value}%',
      textGap: 16,
      textStyle: {
        ...TEXT_STYLE,
      },
      inRange: {
        color: ['#40AB35', '#ffffff', '#B80012'],
      },
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)
  return chart
}

export const updateChart = (
  data: Array<BalrogApi.WasteData>,
  chart: EChartsType,
) => {
  // y-axis labels
  const vessels = [...new Set(data.map(({ vesselName }) => vesselName))]

  // x-axis labels
  const period = [
    ...new Set(
      data.map(({ date }) => moment(date).format(MONTH_YEAR_DATE_FORMAT)),
    ),
  ]

  let min = 100
  let max = -100

  // cell data [x, y, value]
  const _data: Array<Array<number | string>> = []
  data.forEach(
    ({ date: metricDate, vesselName, meWasteMt, meConsumptionMt }) => {
      const meWastePct = calcPercentageSafely(meWasteMt, meConsumptionMt)
      if (meWastePct === undefined) {
        return
      }
      min = Math.min(min, meWastePct)
      max = Math.max(max, meWastePct)
      const x = period.findIndex(
        (date) => date === moment(metricDate).format(MONTH_YEAR_DATE_FORMAT),
      )
      const y = vessels.findIndex((vessel) => vessel === vesselName)
      _data.push([x, y, formatValue(meWastePct, 2)])
    },
  )

  const minMax = Math.max(Math.abs(min), Math.abs(max))

  const option = {
    tooltip: {
      ...TOOLTIP,
      trigger: 'item',
      position: 'top',
      formatter: (params: any) => {
        return `${vessels[params.data[1]]}<br/>
        <span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${
          params.color
        }"></span>
        <span style="display: inline-block; width: 80px">${
          period[params.data[0]]
        }:</span> <div style="float: right;"><b>${params.data[2]}%</b></div>`
      },
    },
    xAxis: {
      data: period,
    },
    yAxis: {
      data: vessels,
    },
    series: [
      {
        type: 'heatmap',
        data: _data,
        label: {
          show: true,
          ...TEXT_STYLE,
        },
      },
    ],
    visualMap: {
      min: -minMax,
      max: minMax,
      range: [-minMax, minMax],
    },
  }

  chart.setOption(option)
  chart.resize()
}
