import { type ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { McButton, McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../contexts'

/**
 * A utility type that represents an exclusive OR (XOR) between two types.
 * It ensures that either T or U is used, but not both.
 *
 * @template T - The first type.
 * @template U - The second type.
 */
type XOR<T, U> = T | U extends object
  ? (T extends U ? never : T) | (U extends T ? never : U)
  : T | U

type Props = XOR<
  { label: string; children?: never },
  { label?: never; children: ReactNode }
> & {
  content: string
  order?: 'left' | 'right'
}

const createContent = (content: string) => {
  return content.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ))
}

const InfoTooltip = ({ children, label, content, order = 'right' }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const tooltipContent = createContent(content)

  return label ? (
    <TooltipWrapper order={order}>
      <span>{label}</span>
      <McTooltip position='top-center' appearance='inverse' width={600}>
        <McButton
          slot='trigger'
          appearance='primary'
          fit='small'
          variant='plain'
        >
          <McIcon icon='info-circle' />
        </McButton>
        {tooltipContent}
      </McTooltip>
    </TooltipWrapper>
  ) : (
    <McTooltip
      fit={windowSize}
      position='top-center'
      appearance='inverse'
      width={600}
    >
      <div slot='trigger'>{children}</div>
      {tooltipContent}
    </McTooltip>
  )
}

export default InfoTooltip

const TooltipWrapper = styled.div<{ order: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: ${({ order }) =>
    order === 'left' ? 'flex-end' : 'flex-start'};
  flex-direction: ${({ order }) => (order === 'left' ? 'row-reverse' : 'row')};

  > mc-tooltip mc-button mc-icon::part(icon) {
    fill: #9aa7b2;
  }
`
