import { useCallback, useContext } from 'react'

import { StockEntryContext } from '../../../contexts'
import {
  DebunkeringModal,
  FuelLineChangeModal,
  LossModal,
  MixedBatchModal,
  SoundingModal,
} from '..'
import {
  EntryType,
  USER_ENTRY_TYPE_TO_ENTRY_TYPE,
  UserEntryTypes,
} from '../models'
import { RobMarkInfoModal } from './rob/RobMarkInfoModal'
import { EditBatchSelectionModal } from '../../fdl-4'
import { BunkeringForm } from './bunkering/BunkeringForm'

type Props = {
  onUpdate?: () => void
}

export const EntryModals = ({ onUpdate }: Props) => {
  const {
    getFuelOilUserEntries,
    setCurrentEntry,
    state: stockEntryState,
  } = useContext(StockEntryContext)
  const { entryType, entryId } = stockEntryState
  const showBunkeringModal = entryType === EntryType.Batch
  const showMixedBatchModal = entryType === EntryType.MixedBatch
  const showSoundingModal = entryType === EntryType.Sounding
  const showLossModal = entryType === EntryType.Loss
  const showDebunkeringModal = entryType === EntryType.Debunkering
  const showRobMarkInfoModal = entryType === EntryType.RobMark
  const showBatchSelection =
    entryType?.toString() ===
    USER_ENTRY_TYPE_TO_ENTRY_TYPE[UserEntryTypes.BatchSelection]
  const showFuelLineSelection =
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[UserEntryTypes.MEMainFuelLineSelection] ||
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[
        UserEntryTypes.MESecondaryFuelLineSelection
      ] ||
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[UserEntryTypes.AEMainFuelLineSelection] ||
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[
        UserEntryTypes.AESecondaryFuelLineSelection
      ] ||
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[UserEntryTypes.AEMDOFuelLineSelection] ||
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[
        UserEntryTypes.BoilerMainFuelLineSelection
      ] ||
    entryType?.toString() ===
      USER_ENTRY_TYPE_TO_ENTRY_TYPE[UserEntryTypes.BoilerMDOFuelLineSelection]

  const onClose = useCallback(
    (refresh?: boolean) => {
      if (refresh) {
        if (onUpdate) onUpdate()
        void getFuelOilUserEntries()
      }
      setCurrentEntry(undefined, undefined)
    },
    [onUpdate, getFuelOilUserEntries, setCurrentEntry],
  )

  return (
    <>
      {showBunkeringModal && (
        <BunkeringForm entryId={entryId} closeHandler={onClose} />
      )}
      {showSoundingModal && (
        <SoundingModal entryId={entryId} closeHandler={onClose} />
      )}
      {showLossModal && <LossModal entryId={entryId} closeHandler={onClose} />}
      {showDebunkeringModal && (
        <DebunkeringModal entryId={entryId} closeHandler={onClose} />
      )}
      {showMixedBatchModal && (
        <MixedBatchModal entryId={entryId} closeHandler={onClose} />
      )}
      {showRobMarkInfoModal && (
        <RobMarkInfoModal entryId={entryId} closeHandler={onClose} />
      )}
      {showBatchSelection && (
        <EditBatchSelectionModal entryId={entryId} closeHandler={onClose} />
      )}
      {showFuelLineSelection && (
        <FuelLineChangeModal
          entryId={entryId}
          closeHandler={() => onClose()}
          submit={(_) => onClose(true)}
        />
      )}
    </>
  )
}
