import * as React from 'react'
import { useContext } from 'react'

import { ToggleInMeSfocChart, MeSfocChartActionType } from '../models'
import {
  darkGreyColorScheme,
  blackColorScheme,
  grey,
  maerskBlue,
} from '../../../theme'
import styled from 'styled-components'
import { FancyLabel } from '../../../commons'
import { VesselPageContext } from '../../../contexts'
import { useVesselConfiguration } from '../../../hooks'

const Wrapper = styled.div`
  padding: 8px 0 0 24px;
  display: flex;
  > div {
    margin: 0 8px 0 0;
  }
  #vessel-specific {
    background: transparent;
    position: relative;
    &:focus {
      outline: none;
    }
  }
  #menu {
    position: absolute;
    background: white;
    width: 100%;
    border: 1px solid #d1d3d5;
    border-radius: 4px;
    box-shadow: 2px 4px 8px 0 rgba(40, 47, 54, 0.12);
    box-sizing: border-box;
    z-index: 1;
    #menu-options {
      padding: 4px;
    }
    .menu-items {
      display: flex;
      align-items: center;
      font-size: 18px;
      span {
        padding-left: 4px;
      }
      svg {
        color: ${maerskBlue[500]};
      }
    }
    #controls {
      background-color: ${grey[100]};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 8px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #d1d3d5;
    }
  }
`

interface MeSfocLegendProps {
  toggleInChart: ToggleInMeSfocChart
  hasVesselSpecificData: boolean
  toggleReducer: Function
}

export const MeSfocLegend: React.FunctionComponent<MeSfocLegendProps> = (
  props: MeSfocLegendProps,
) => {
  const { toggleInChart, toggleReducer, hasVesselSpecificData } = props
  const { showShopTestCurve, showStableDataPointsOnly, showVesselSpecific } =
    toggleInChart
  const imoNo = useContext(VesselPageContext).imoNo!
  const configuration = useVesselConfiguration(imoNo)

  return (
    <Wrapper>
      <FancyLabel
        colorScheme={darkGreyColorScheme}
        value={!showStableDataPointsOnly}
        onChange={() =>
          toggleReducer(MeSfocChartActionType.TOGGLE_NON_STABLE_POINTS)
        }
      >
        Nonstable
      </FancyLabel>
      <FancyLabel
        colorScheme={blackColorScheme}
        value={showShopTestCurve}
        onChange={() => toggleReducer(MeSfocChartActionType.TOGGLE_SHOP_TEST)}
      >
        Shop test
      </FancyLabel>
      {hasVesselSpecificData && !configuration?.hasMainEngSecondaryFuelLine && (
        <FancyLabel
          colorScheme={blackColorScheme}
          value={showVesselSpecific}
          onChange={() =>
            toggleReducer(MeSfocChartActionType.TOGGLE_VESSEL_SPECIFIC)
          }
        >
          Vessel Specific
        </FancyLabel>
      )}
    </Wrapper>
  )
}
