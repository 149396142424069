import { useContext } from 'react'
import { useField } from 'formik'
import { McTypeahead, McIcon } from '@maersk-global/mds-react-wrapper'
import type {
  IMcTypeaheadData,
  IMcTypeaheadOptionSelectedDetail,
} from '@maersk-global/mds-components-core/mc-typeahead/types'

import { WindowContext } from '../../contexts'
import { type TWindowSize } from '../../theme_new/styled'

type Props = {
  name: string
  id: string
  label: string
  disabled?: boolean
  fit?: TWindowSize
  error?: string
  hasWarning?: boolean
  placeholder?: string
  options: Array<IMcTypeaheadData>
  initialValue?: any
  hideErrorMessage?: boolean
  onChange?: (value: string) => void
  isNumericFieldValue?: boolean
}

/**
 * @description A wrapper for McTypeahead that provides two-way data binding
 * for Formik form values based on `name` prop.
 */
export const FormTypeahead = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField(props.name)
  const {
    label,
    fit,
    options,
    initialValue,
    onChange,
    error,
    hasWarning,
    isNumericFieldValue,
    ...rest
  } = props

  const handleChange = ({
    target,
  }: CustomEvent<IMcTypeaheadOptionSelectedDetail>) => {
    const label = (target as HTMLInputElement).value
    const value = options.find((o) => o.label === label)!.value
    if (onChange) {
      onChange(value)
    } else {
      void helpers.setValue(value)
    }
  }

  const handleClear = () => {
    if (onChange) {
      onChange('')
    } else {
      void helpers.setValue('')
    }
  }

  const value =
    initialValue ??
    options.find((o) => {
      if (isNumericFieldValue) {
        return +o.value === field.value
      }
      return o.value === field.value
    })?.label ??
    ''

  return (
    <McTypeahead
      {...rest}
      fit={fit ?? windowSize}
      data={options}
      clearbutton
      optionselected={handleChange}
      clearbuttonclick={handleClear}
      value={value}
      invalid={!!error || (meta.touched && !!meta.error)}
      errormessage={
        !props.hideErrorMessage && meta.touched ? meta.error : undefined
      }
      autocomplete='off'
      highlight
      maxoptions={1000}
      optionsheight='208px'
      data-e2e={props.name}
    >
      <span slot='label'>
        {label}&nbsp;
        {hasWarning && (
          <McIcon
            className='mc-icon--warning'
            icon='exclamation-triangle-solid'
            size={windowSize === 'small' ? '20' : '24'}
          />
        )}
      </span>
    </McTypeahead>
  )
}
