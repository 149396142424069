import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment, { type Moment } from 'moment'
import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import { Heading } from '../styles'
import { StockRobNotifications } from '../StockRobNotifications'
import { FuelTypeTotals } from '../stock-on-board/FuelTypeTotals'
import { FuelTypeBatches } from '../stock-on-board/FuelTypeBatches'
import { mapStockOnBoard } from '../../utils'
import { StockOnBoard } from '../../models'
import { HelpText } from '../../../help-texts'
import { Loading } from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { displayErrorModal, getClosestTenMinute } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import InputDateTimeStandAlone from '../../../../components/formik/InputDateTime/InputDateTimeStandAlone'

const DateWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
`

type Props = {
  closeHandler: () => void
}

export const RobModal = ({ closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [stockOnBoard, setStockOnBoard] = useState<StockOnBoard>()
  const [timestamp, setTimestamp] = useState<Moment>()
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()

  useEffect(() => {
    if (timestamp) {
      setStockOnBoard(undefined)
      PerformanceApi.getRob(imoNo, timestamp.toISOString())
        .then((data) => {
          if (data.hasData) {
            setStockOnBoard(
              mapStockOnBoard(data.batchQuantities, data.fuelTypeQuantities),
            )
          } else {
            setStockOnBoard(mapStockOnBoard([], []))
          }
          setRob(data)
        })
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message: 'Could not receive remaining on board at time of loss',
          }),
        )
    }
  }, [timestamp, imoNo])

  useEffect(() => setTimestamp(getClosestTenMinute(moment().utc())), [imoNo])

  return (
    <McModal open closed={closeHandler} height='80vh' width='80vw'>
      <Heading slot='heading'>
        Get ROB at a specific time
        <HelpText textKey='stock/rob-at-specific-time' />
      </Heading>
      <StockRobNotifications rob={rob} />
      <DateWrapper>
        <InputDateTimeStandAlone
          label='Select date and time, UTC'
          timestamp={timestamp || moment.utc()}
          onChange={(timestamp: Moment) => {
            setTimestamp(timestamp)
          }}
        />
      </DateWrapper>
      {!stockOnBoard && <Loading />}
      {stockOnBoard && (
        <>
          <FuelTypeTotals
            fuelTypeQuantities={stockOnBoard.fuelTypeQuantities}
          />
          <FuelTypeBatches stockOnBoard={stockOnBoard} />
        </>
      )}
      <McButton slot='primaryAction' label='Close' click={closeHandler} />
    </McModal>
  )
}
