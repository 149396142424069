import { useContext } from 'react'
import { useField } from 'formik'
import { McTextarea } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  name: string
  label: string
}

const CommentField = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string>(props.name)

  const handleBlur = (event: FocusEvent) => {
    const target = event.target as HTMLTextAreaElement
    void helpers.setValue(target.value.trim())
  }

  return (
    <McTextarea
      {...props}
      fit={windowSize}
      style={{ display: 'block', marginTop: '32px' }}
      maxlength={1000}
      rows={5}
      invalid={!!meta.error}
      errormessage={meta.error}
      value={field.value}
      blur={handleBlur}
    />
  )
}

export default CommentField
