import { Performance } from '../../../../api-models'
import {
  BatchBurndownSeries,
  BatchBurndownSeriesLog,
  UserEntriesByFuelTypeSeries,
  BatchBurndownByFuelTypeSeries,
} from './models'
import { FUEL_TYPE_NAMES } from '../../../../utils'
import {
  maerskBlueTheme,
  purpleTheme,
  orangeTheme,
  maritimeBlueTheme,
  greenTheme,
} from '../../../../theme'

export const mapBatchBurndownByFueltypes = (
  burndown: Performance.FuelOilStock.BurndownModel,
): BatchBurndownByFuelTypeSeries => {
  const mappedBurndown: BatchBurndownByFuelTypeSeries = {
    HS: [],
    VLS: [],
    ULS: [],
    MDO: [],
    MM: [],
  }

  const fuelTypeColor = {
    1: maritimeBlueTheme.bg,
    2: maerskBlueTheme.bg,
    3: orangeTheme.bg,
    4: purpleTheme.bg,
    10: greenTheme.bg,
  }

  // map to view model
  burndown.batches.forEach((batchBurndown) => {
    const batchBurndownSeries: BatchBurndownSeries = {
      color: fuelTypeColor[batchBurndown.batch.fuel.type],
      batch: batchBurndown.batch,
      logs: batchBurndown.logs.reduce<Array<BatchBurndownSeriesLog>>(
        (acc, curr) => {
          if (curr.fromRob !== null) {
            acc.push({
              ...curr,
              value: curr.fromRob,
              displayInTooltip: false,
            })
          }

          acc.push({
            ...curr,
            value: curr.rob,
            displayInTooltip: true,
          })

          return acc
        },
        [],
      ),
    }

    mappedBurndown[FUEL_TYPE_NAMES[batchBurndown.batch.fuel.type]].push(
      batchBurndownSeries,
    )
  })

  return mappedBurndown
}

export const mapUserEntriesByFuelType = (
  burndown: Performance.FuelOilStock.BurndownModel,
): UserEntriesByFuelTypeSeries => {
  const { userEntries } = burndown
  const mappedUserEntries: UserEntriesByFuelTypeSeries = {
    HS: [],
    VLS: [],
    ULS: [],
    MDO: [],
    MM: [],
  }

  userEntries.forEach((userEntry) =>
    mappedUserEntries[FUEL_TYPE_NAMES[userEntry.fuelType]].push(userEntry),
  )
  return mappedUserEntries
}

export const hasBurndownData = (
  burndownDataSeries: Performance.FuelOilStock.BatchBurndownModel[] | undefined,
) => {
  if (!burndownDataSeries) {
    return true
  }

  if (burndownDataSeries.length === 0) {
    return false
  } else if (
    burndownDataSeries.every((burndownEntry) => burndownEntry.logs.length === 0)
  ) {
    return false
  }

  return true
}

/**
 * Converters batch burndown events to a comma separated string.
 * An empty string is returned in case no events exists or events has been filtered out.
 * Caller should therefore check length of returned string before displaying.
 *
 * @param events Array of batch burndown events.
 */
export const mapBatchBurndownEventsToString = (
  events: Performance.FuelOilStock.BatchBurndownEvent[],
): string => {
  const eventTexts: string[] = []

  events.forEach((event) => {
    switch (event) {
      case Performance.FuelOilStock.BatchBurndownEvent.NewBatch:
        eventTexts.push('New batch')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Startup:
        eventTexts.push('Startup')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Recovery:
        eventTexts.push('Recovery')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Sounding:
        eventTexts.push('Sounding')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Mixed:
        eventTexts.push('Mixed')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Loss:
        eventTexts.push('Loss')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Debunkering:
        eventTexts.push('Debunkering')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Start:
        eventTexts.push('Use start')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Stop:
        eventTexts.push('Use stop')
        break
      case Performance.FuelOilStock.BatchBurndownEvent.Error:
        eventTexts.push('Error')
        break
    }
  })

  return eventTexts.join(', ')
}
