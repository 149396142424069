export interface Configuration {
  imoNo: number
  hasTwoMainEngines: boolean
  hasMainEngSecondaryFuelLine: boolean
  flowMeterScenario: string
  fuelLineTypes: number[]
  hasOverflow: boolean
  hasManualOverflowEntry: boolean
  itPlatform: string
  isOutOfOperation: boolean
  outOfOperationReason: string
  maxSpeed: number
  mainEng1: MainEng
  mainEng2: MainEng
  whr: Whr
  shaftMotor1: Shaft
  shaftMotor2: Shaft
  shaftGenerator1: Shaft
  shaftGenerator2: Shaft
  auxEngs: AuxEng[]
  vesselClass: string
  vesselName: string
}

export interface AuxEng {
  number: number
  isInstalled: boolean
  isSmall: boolean
  mcr: number
  maxPower: number
  minLoad: number
  hasEconomizer: boolean
}

export interface MainEng {
  number: number
  isInstalled: boolean
  mcr: number
  maxSetpoint: number
  maxShaftRpm: number
  hasTcco: boolean
  tccoLimit: number
  hasAuxBlowers: boolean
  auxBlowersLimit: number
  auxBlowersPower: number
}

export interface Shaft {
  number: number
  isInstalled: boolean
  maxPower: number
  efficiency: number
}

export interface Whr {
  isInstalled: boolean
  maxPower: number
  steamTurbineLimit: number
  powerTurbineLimit: number
}
