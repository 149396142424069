import { useContext } from 'react'
import styled from 'styled-components'
import { McNotification } from '@maersk-global/mds-react-wrapper'
import { type NotificationAppearance } from '@maersk-global/mds-components-core/mc-notification/types'

import { Performance } from '../../../api-models'
import { EStockNotificationType } from '../../../api-models/performance/common'
import { WindowContext } from '../../../contexts'

type Props = {
  rob?: Performance.FuelOilStock.RobResponse
}

export const StockRobNotifications = ({ rob }: Props) => {
  const { windowSize } = useContext(WindowContext)

  if (!rob?.notifications) {
    return null
  }

  return rob.notifications.map((notification) => (
    <StyledMcNotification
      key={notification.message}
      fit={windowSize}
      body={notification.message}
      appearance={Appearance[notification.type]}
      icon={Icon[notification.type]}
    />
  ))
}

const Appearance: Record<EStockNotificationType, NotificationAppearance> = {
  [EStockNotificationType.Info]: 'info',
  [EStockNotificationType.Warning]: 'warning',
  [EStockNotificationType.Error]: 'error',
}

const Icon: Record<EStockNotificationType, string> = {
  [EStockNotificationType.Info]: 'info-circle',
  [EStockNotificationType.Warning]: 'exclamation-triangle',
  [EStockNotificationType.Error]: 'exclamation-octagon',
}

const StyledMcNotification = styled(McNotification)`
  display: block;
  margin-bottom: 16px;
`
