import { useContext, useEffect, useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { McButton, McLabel, McModal } from '@maersk-global/mds-react-wrapper'
import {
  FormTypeahead,
  InputNumber,
  FormTextArea,
} from '../../../../components/formik'

import MixedBatchTable from './MixedBatchTable'
import {
  LabReportWrapper,
  TagGroupWrapper,
} from '../bunkering/BunkeringForm.styles'
import { calculateLcvForBioFuel } from '../bunkering/Bunkering.utils'
import { Loading } from '../../../../commons'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { Performance } from '../../../../api-models'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, FuelType, isShoreContext } from '../../../../utils'
import { McTagGroup } from '../McTagGroup'
import { editMixedBatchValidationSchema } from './validation-schemas'
import {
  mapBatchResponseToMergedBatch,
  mapMixedBatchToFormValues,
  mapValuesToMixedBatchUpdateRequest,
} from './mappers'
import { fuelTypeOptions, fuelTypeSubOptions } from '../../models'
import { useFuelGrades } from '../../../../queries/MasterDataApi/MasterDataApi'
import { BatchType } from '../../../../queries/PerformanceApi/Papi.consts'
import { getFuelGradeOption } from '../../utils'
import { Column, ContentWrapper, resolveNotesHeight } from '../styles'

type Props = {
  mixedBatch: Performance.FuelOilStock.MixedBatchEntryResponse
  closeHandler: (refreshAdjustments?: boolean) => void
}

export type LabReport = {
  density15: number | null
  lcv: number | null
  water: number | null
  ash: number | null
  sulphur: number | null
  marpolSeal?: string | null
}

export type MergedBatch = {
  id: string
  quantity?: number
  displayName: string
  fuelType: number
  labReport?: LabReport
}

export type FormValues = {
  timestamp?: moment.Moment
  mixedBatches?: Array<MergedBatch>
  notes: string
  fuelType: number
  fuelGrade: string
  displayName: string
  isDistillate: boolean
  quantityPerChiefEngineer?: number
  bioPercentage: string
  density15: string
  lcv: string
  water: string
  ash: string
  sulphur: string
}

export const defaultInitialValues: FormValues = {
  timestamp: undefined,
  mixedBatches: undefined,
  notes: '',
  fuelType: 0,
  fuelGrade: '',
  displayName: '',
  isDistillate: false,
  quantityPerChiefEngineer: undefined,
  bioPercentage: '',
  density15: '',
  lcv: '',
  water: '',
  ash: '',
  sulphur: '',
}

const MixedBatchDetails = ({ mixedBatch, closeHandler }: Props) => {
  const { data: fuelGrades, isSuccess: isFuelGradesSuccess } = useFuelGrades()
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!
  const isLabReportReadonly = mixedBatch?.labReportReadonly
  const isReadOnly = mixedBatch?.readonly
  const [formInitialValues, setFormInitialValues] =
    useState<FormValues>(defaultInitialValues)
  const [calculatedLcvForBioFuel, setCalculatedLcvForBioFuel] = useState(false)
  const _fit = windowSize === 'large' ? 'medium' : windowSize

  const updateMixedBatch = (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.putMixedBatch(
      imoNo,
      mixedBatch.id,
      mapValuesToMixedBatchUpdateRequest(values, mixedBatch),
    )
      .then(() => closeHandler(true))
      .catch((e) => {
        displayErrorModal({
          statusText: 'Could not update mixed batch',
          message: e.message,
        })
      })
    setSubmitting(false)
  }

  const deleteMixedBatch = (batchId, setSubmitting) => {
    setSubmitting(true)
    PerformanceApi.deleteMixedBatch(imoNo, batchId)
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        displayErrorModal({
          statusText: 'Could not delete mixed batch',
          message: e.message,
        })
      })
  }

  const displayApiErrorAndReset = (title, message) => {
    displayErrorModal({
      statusText: title,
      message: message,
    })
    setFormInitialValues(defaultInitialValues)
  }

  useEffect(() => {
    const batchIds = mixedBatch.batches.map((batch) => batch.id)
    const mergedBatches: Array<MergedBatch> = []
    const batchRequests: Array<
      Promise<
        | Performance.FuelOilStock.BunkeredBatchResponse
        | Performance.FuelOilStock.MixedBatchEntryResponse
      >
    > = []
    PerformanceApi.getBatchesById(imoNo, batchIds)
      .then((batchResponse: Array<Performance.FuelOilStock.BatchResponse>) => {
        for (const batch of batchResponse) {
          if (batch.type === BatchType.Bunkered) {
            batchRequests.push(PerformanceApi.getBunkeredBatch(imoNo, batch.id))
          } else if (batch.type === BatchType.Mixed) {
            batchRequests.push(PerformanceApi.getMixedBatch(imoNo, batch.id))
          }
        }
        Promise.all(batchRequests)
          .then((batchRequestsRes) => {
            for (const batchReqRes of batchRequestsRes) {
              const mappedBatchRes: MergedBatch | null =
                mapBatchResponseToMergedBatch(mixedBatch, batchReqRes)
              if (mappedBatchRes) mergedBatches.push(mappedBatchRes)
            }
            setFormInitialValues(
              mapMixedBatchToFormValues(mixedBatch, mergedBatches),
            )
          })
          .catch((e) =>
            displayApiErrorAndReset(
              'Error fetching related batches',
              e.message,
            ),
          )
      })
      .catch((e) =>
        displayApiErrorAndReset('Could not fetch batches', e.message),
      )
  }, [mixedBatch, imoNo])

  const activeFuelGradeOptions = useMemo(() => {
    if (!isFuelGradesSuccess) return []
    return fuelGrades.filter((fg) => fg.data.isActive).map(getFuelGradeOption)
  }, [fuelGrades, isFuelGradesSuccess])

  return formInitialValues ? (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) =>
        updateMixedBatch(values, setSubmitting)
      }
      validationSchema={editMixedBatchValidationSchema(fuelGrades ?? [])}
    >
      {({
        values,
        isSubmitting,
        setSubmitting,
        isValidating,
        isValid,
        setFieldValue,
      }) => {
        const isSubmitDisabled =
          isShoreContext() ||
          (isLabReportReadonly && isReadOnly) ||
          isSubmitting ||
          isValidating ||
          !isValid
        const isDeleteDisabled = isShoreContext() || isReadOnly || isSubmitting

        const getFuelGradeOptions = (mixedBatches: Array<MergedBatch>) => {
          let isMethanolFuelExists = mixedBatches.some(
            (batch) => batch.fuelType === FuelType.MM,
          )
          if (isMethanolFuelExists) {
            return activeFuelGradeOptions.filter(
              (option) => option.type === FuelType.MM,
            )
          } else {
            return activeFuelGradeOptions.filter(
              (option) => option.type !== FuelType.MM,
            )
          }
        }

        const handleFuelGradeChange = (value: string | number) => {
          const fuelGrade = fuelGrades?.find((fg) => fg.data.code === value)
          void setFieldValue('fuelGrade', value)
          void setFieldValue('fuelType', fuelGrade?.data.fuelType ?? 0)
          void setFieldValue(
            'isDistillate',
            fuelGrade?.data.refinementType === 1,
          )
          void setFieldValue('lcv', '')
          void setFieldValue('bioPercentage', 0)
          setCalculatedLcvForBioFuel(false)
        }
        const handleBioPercentageChange = (value: number | null) => {
          if (value === null) {
            void setFieldValue('lcv', null)
            setCalculatedLcvForBioFuel(false)
            return
          }

          const fuelGrade = fuelGrades?.find(
            (fg) => fg.data.code === values.fuelGrade,
          )
          if (fuelGrade?.data.isBiofuel) {
            const lcv = calculateLcvForBioFuel(fuelGrade.data.fuelType, value)
            void setFieldValue('lcv', lcv)
            setCalculatedLcvForBioFuel(true)
          }
        }

        return (
          <Form>
            <McModal
              open
              fit={_fit}
              heading='Mixed batch details'
              closed={() => closeHandler()}
              width='1090px'
              height='760px'
            >
              <ContentWrapper>
                <Column>
                  <McLabel>
                    Time of mixing, UTC:{' '}
                    <strong>
                      {values.timestamp
                        ? values.timestamp.format('DD MMM YYYY HH:mm')
                        : ''}
                    </strong>
                  </McLabel>
                  <MixedBatchTable
                    mixedBatches={values.mixedBatches}
                    quantityCE={values.quantityPerChiefEngineer}
                  />
                </Column>
                <Column>
                  <FormTypeahead
                    id='fuelGrade'
                    name='fuelGrade'
                    label='Fuel grade'
                    placeholder='Type to search'
                    options={
                      values.mixedBatches
                        ? getFuelGradeOptions(values.mixedBatches)
                        : activeFuelGradeOptions
                    }
                    onChange={handleFuelGradeChange}
                    disabled={isReadOnly}
                  />
                  <McLabel fit={_fit} label='Fuel type' />
                  <TagGroupWrapper>
                    <McTagGroup name='fuelType' options={fuelTypeOptions} />
                  </TagGroupWrapper>
                  {values.fuelType !== 0 && values.fuelType !== FuelType.MM && (
                    <>
                      <McLabel
                        fit={_fit}
                        label={`Type of ${FuelType[values.fuelType]}`}
                      />
                      <TagGroupWrapper>
                        <McTagGroup
                          name='isDistillate'
                          options={fuelTypeSubOptions[values.fuelType]}
                        />
                      </TagGroupWrapper>
                    </>
                  )}
                  {(fuelGrades?.find((fg) => fg.data.code === values.fuelGrade)
                    ?.data.isBiofuel ||
                    Number(values.bioPercentage) > 0) && (
                    <InputNumber
                      label='Percentage bio'
                      name='bioPercentage'
                      unit='%mass'
                      onBlur={handleBioPercentageChange}
                      disabled={isReadOnly}
                    />
                  )}
                </Column>
                <Column>
                  <FormTextArea
                    id='notes'
                    name='notes'
                    label='Notes'
                    rows={5}
                    height={resolveNotesHeight(windowSize)}
                    maxlength={1000}
                    disabled={isReadOnly}
                  />
                </Column>
              </ContentWrapper>
              <LabReportWrapper>
                <h4>Lab report</h4>
                <hr />
                <ContentWrapper>
                  <Column>
                    <InputNumber
                      label='Density 15'
                      name='density15'
                      unit='kg/m³'
                      disabled={isLabReportReadonly}
                    />
                    <InputNumber
                      label='LCV'
                      name='lcv'
                      unit='KJ/kg'
                      disabled={isLabReportReadonly || calculatedLcvForBioFuel}
                    />
                  </Column>
                  <Column>
                    <InputNumber
                      label='Water'
                      name='water'
                      unit='%vol.'
                      disabled={isLabReportReadonly}
                    />
                    <InputNumber
                      label='Ash'
                      name='ash'
                      unit='%mass'
                      disabled={isLabReportReadonly}
                    />
                    <InputNumber
                      label='Sulphur'
                      name='sulphur'
                      unit='%mass'
                      disabled={isLabReportReadonly}
                    />
                  </Column>
                </ContentWrapper>
              </LabReportWrapper>
              <McButton
                fit={_fit}
                slot='secondaryAction'
                label='Delete'
                variant='plain'
                type='button'
                disabled={isDeleteDisabled}
                click={() => deleteMixedBatch(mixedBatch.id, setSubmitting)}
              />
              <McButton
                fit={_fit}
                slot='secondaryAction'
                label='Cancel'
                appearance='neutral'
                type='button'
                click={() => closeHandler(false)}
              />
              <McButton
                fit={_fit}
                slot='primaryAction'
                label='Save changes'
                appearance='primary'
                disabled={isSubmitDisabled}
                type='submit'
              />
            </McModal>
          </Form>
        )
      }}
    </Formik>
  ) : (
    <Loading />
  )
}

export default MixedBatchDetails
