import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import type { EngineTestListModel } from '../../pages/EngineTestPage/EngineTestPage.types'
import { useDeleteReport } from '../../queries/GandalfApi/GandalfApi'
import { ReportTypeDisplayName } from '../../queries/GandalfApi/GandalfApi.consts'
import { displayToast, isShoreContext } from '../../utils'

type Props = {
  open: boolean
  onClose: () => void
  imoNo: string
  report: EngineTestListModel | GandalfApi.MfeReport
}

const DeleteReportModal = ({ open, onClose, imoNo, report }: Props) => {
  const deleteReport = useDeleteReport(imoNo, report.reportType)

  const handleDeleteReport = async () => {
    try {
      await deleteReport.mutateAsync(report.reportId)
      void displayToast(
        'success',
        'Report deleted',
        `You have successfully deleted ${
          ReportTypeDisplayName[report.reportType]
        } - No. ${report.reportNo}.`,
      )
    } finally {
      onClose()
    }
  }

  return (
    <McModal
      hiddenclose
      heading='Delete report?'
      dimension='small'
      width='600px'
      open={open}
      closed={onClose}
    >
      <p>
        Are you sure you want to delete{' '}
        {ReportTypeDisplayName[report.reportType]} - No. {report.reportNo}?
      </p>
      <>
        <McButton
          loading={deleteReport.isLoading}
          label='Yes, delete'
          slot='primaryAction'
          appearance='error'
          dialogaction='ok'
          click={handleDeleteReport}
          disabled={isShoreContext()}
          data-e2e='delete-engine-test-report-button'
        />
        <McButton
          label='Cancel'
          slot='secondaryAction'
          appearance='neutral'
          variant='outlined'
          dialogaction='cancel'
        />
      </>
    </McModal>
  )
}

export default DeleteReportModal
