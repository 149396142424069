import { type CellContext, createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import {
  McButton,
  McIcon,
  McList,
  McListItem,
  McMenu,
  McTag,
  McTooltip,
} from '@maersk-global/mds-react-wrapper'

import type { EngineTestListModel } from './EngineTestPage.types'
import { EngineTestReportAction as Action } from './EngineTestPage.consts'
import {
  Header,
  IconCell,
  NumericCell,
  NumericHeader,
} from '../../components/Table/Table.styles'
import { ReportStateTableCell } from '../../components/Reporting/ReportStateTableCell'
import { type TWindowSize } from '../../theme_new/styled'
import {
  ReportState,
  ReportType,
} from '../../queries/GandalfApi/GandalfApi.consts'
import { dateFilter } from '../ActivityLogPage/ActivityLogFilters/DateFilter/helpers'
import { formatValue } from '../../utils'
import {
  MainEngInstanceName,
  StaticModelType,
  StaticModelTypeNames,
} from '../../queries/MasterDataApi/MasterDataApi.consts'

const columnHelper = createColumnHelper<EngineTestListModel>()

export const getColumns = (
  windowSize: TWindowSize,
  onListItemClick: (reportId: string) => (event: CustomEvent) => void,
) => {
  return [
    columnHelper.accessor('reportNo', {
      header: 'No.',
      filterFn: 'includesString',
    }),
    columnHelper.accessor('startTimestamp', {
      header: 'Report date (UTC)',
      cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY, HH:mm'),
      filterFn: dateFilter,
    }),
    columnHelper.accessor('duration', {
      header: 'Report duration',
    }),
    columnHelper.accessor('equipment', {
      header: 'Equipment',
    }),
    columnHelper.accessor('engineLoad', {
      header: () => <NumericHeader>Engine load</NumericHeader>,
      cell: (info) => {
        return (
          <NumericCell>{formatValue(info.getValue(), 1) + ' %MCR'}</NumericCell>
        )
      },
    }),
    columnHelper.accessor('sfocDeviation', {
      header: () => <NumericHeader>SFOC deviation</NumericHeader>,
      cell: (info) => {
        if (info.getValue() === null) {
          return <NumericCell>-</NumericCell>
        }
        return (
          <NumericCell>{formatValue(info.getValue(), 1) + ' %'}</NumericCell>
        )
      },
    }),
    columnHelper.display({
      id: 'warnings',
      header: 'Warnings',
      cell: ({ row }: CellContext<EngineTestListModel, any>) => {
        const wc = row.original.warningsCount

        if (wc) {
          return (
            <IconCell>
              <McTag fit={windowSize} appearance='error'>
                &nbsp;{wc}&nbsp;
              </McTag>
            </IconCell>
          )
        }

        if (row.original.reportState === ReportState.Submitted) {
          return (
            <IconCell>
              <McTag fit={windowSize} appearance='success'>
                &nbsp;OK&nbsp;
              </McTag>
            </IconCell>
          )
        }

        return <IconCell>-</IconCell>
      },
    }),
    columnHelper.accessor('comment', {
      header: () => <Header icon>Comment</Header>,
      cell: ({ row }: CellContext<EngineTestListModel, any>) => {
        if (!row.original.comment) return <IconCell>-</IconCell>

        return (
          <IconCell>
            <McTooltip
              width={400}
              fit={windowSize}
              appearance='inverse'
              position='top-center'
            >
              <McIcon
                slot='trigger'
                icon='comment'
                size={windowSize === 'small' ? '20' : '24'}
              />
              <span>{row.original.comment}</span>
            </McTooltip>
          </IconCell>
        )
      },
      enableSorting: false,
    }),
    columnHelper.accessor('reportState', {
      header: 'Status',
      cell: (info) => <ReportStateTableCell state={info.getValue()} />,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <Header icon>Actions</Header>,
      cell: ({ row }: CellContext<EngineTestListModel, any>) => {
        return (
          <IconCell>
            <McMenu fit={windowSize} position='left-top'>
              <McButton
                slot='trigger'
                icon='ellipsis-vertical'
                hiddenlabel
                label='actions'
                variant='plain'
                fit={windowSize}
              />
              <McList
                fit={windowSize}
                listchange={onListItemClick(row.original.reportId)}
              >
                <McListItem
                  fit={windowSize}
                  icon='file'
                  label={Action.ViewResults}
                />
                <McListItem
                  fit={windowSize}
                  icon='pencil'
                  label={Action.EditTest}
                />
                <McListItem
                  fit={windowSize}
                  icon='trash'
                  label={Action.Delete}
                />
              </McList>
            </McMenu>
          </IconCell>
        )
      },
    }),
  ]
}

const warningsCounter = (
  acc: number,
  curr: GandalfApi.EngineTestEfficiencyResult,
) => {
  if (curr.rangeValidation) {
    return acc + 1
  }
  return acc
}

export const getMetcWarningsCount = (
  result: GandalfApi.Metc.Result,
): number | null => {
  const meWc = Object.values(result.meEfficiency).reduce(warningsCounter, 0)
  const tcWc = Object.values(result.tcEfficiency).reduce(warningsCounter, 0)

  return meWc + tcWc || null
}

export const getAetcWarningsCount = (
  result: GandalfApi.Aetc.Result,
): number | null => {
  const keyParamsWc = Object.values(result.keyParams).reduce(warningsCounter, 0)

  return keyParamsWc || null
}

export const engineTestReportToListModel = (
  report: GandalfApi.EngineTestReport,
  isTwinMainEngine: boolean = false,
): EngineTestListModel => {
  const start = moment.utc(report.startTimestamp)
  const end = moment.utc(report.endTimestamp)
  const duration = moment.duration(end.diff(start))

  let sfocDeviation: number | null
  let warningsCount: number | null
  let equipmentName = ''
  let instanceNo = ''
  if (isMetcReport(report)) {
    sfocDeviation = report.data.result.meEfficiency.sfoc.modelDeviation
    warningsCount = getMetcWarningsCount(report.data.result)
    equipmentName = StaticModelTypeNames[StaticModelType.MainEng]
    if (isTwinMainEngine) {
      instanceNo = MainEngInstanceName[report.data.equipment.instanceNo]
    }
  } else {
    sfocDeviation = report.data.result.keyParams.sfoc.modelDeviation
    warningsCount = getAetcWarningsCount(report.data.result)
    equipmentName = StaticModelTypeNames[StaticModelType.AuxEng]
    instanceNo = `#${report.data.equipment.instanceNo}`
  }

  return {
    reportType: report.reportType,
    reportId: report.reportId,
    reportNo: report.reportNo,
    startTimestamp: report.startTimestamp,
    duration: `${duration.hours()}h ${duration.minutes()}m`,
    equipment: `${equipmentName} ${instanceNo}`,
    engineLoad: report.data.result.general.engineLoad,
    sfocDeviation: sfocDeviation,
    warningsCount: warningsCount,
    comment: report.data.comment,
    reportState: report.reportState,
  }
}

const isMetcReport = (
  report: GandalfApi.EngineTestReport,
): report is GandalfApi.MetcReport => {
  return report.reportType === ReportType.Metc
}
