import * as yup from 'yup'

import { type MixedBatch } from './MixedBatchForm'
import { FuelType } from '../../../../utils'

declare module 'yup' {
  interface ObjectSchema<T> {
    uniqueProperty(propertyName: string, message?: any): ArraySchema<T>
  }
}

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true
    }

    if (
      this.parent
        .filter((v: any) => v !== value)
        .some(
          (v: { [x: string]: any }) => v[propertyName] === value[propertyName],
        )
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      })
    }

    return true
  })
})

const REQUIRED = 'Required'

export const validationSchema = yup.object().shape({
  timestamp: yup.date().required(REQUIRED),
  mixedBatches: yup.array().of(
    yup
      .object()
      .shape({
        id: yup.string().required(REQUIRED),
        quantity: yup
          .number()
          .nullable()
          .required(REQUIRED)
          .moreThan(0, 'Must be more than 0')
          .test({
            name: 'max',
            test: function (value) {
              const currentRob = this.parent.currentRob
              /* Allow mix batches even when ROB cannot be calculated */
              if (currentRob === undefined) return true
              return value > currentRob
                ? this.createError({
                    message: `Cannot exceed batch quantity (${currentRob} MT)`,
                    path: this.path,
                  })
                : true
            },
          }),
      })
      .uniqueProperty('id', 'Batch can only be selected once'),
  ),
  notes: yup.string().notRequired().max(1000, 'Max. 1000 characters'),
})

export const editMixedBatchValidationSchema = (
  fuelGrades: Array<MasterDataApi.Common.FuelGrade>,
) =>
  yup.object().shape({
    fuelGrade: yup.string().required(required),
    fuelType: yup.number().min(1, required),
    isDistillate: yup.boolean().when('fuelType', {
      is: (value) => [FuelType.VLS, FuelType.ULS].includes(value),
      then: yup.boolean().required(required),
      otherwise: yup.boolean().notRequired(),
    }),
    bioPercentage: yup
      .number()
      .nullable()
      .when('fuelGrade', {
        is: (value) =>
          fuelGrades.find((fg) => fg.data.code === value)?.data.isBiofuel,
        then: yup
          .number()
          .required(required)
          .max(100, range(0, 100))
          .positive('Must be greater than 0'),
        otherwise: yup.number().notRequired(),
      }),
    notes: yup
      .string()
      .notRequired()
      .max(1000, 'Cannot be more than 1000 characters long'),
    water: yup
      .number()
      .nullable()
      .required(required)
      .when('fuelType', {
        is: FuelType.MM,
        then: yup.number().min(0, range(0, 1)).max(1, range(0, 1)),
        otherwise: yup.number().min(0, range(0, 10)).max(10, range(0, 10)),
      }),
    ash: yup
      .number()
      .nullable()
      .required(required)
      .when('fuelType', {
        is: FuelType.MM,
        then: yup.number().min(0, range(0, 0.15)).max(0.15, range(0, 0.15)),
        otherwise: yup.number().min(0, range(0, 10)).max(10, range(0, 10)),
      }),
    sulphur: yup
      .number()
      .nullable()
      .required(required)
      .min(0, range(0, 5))
      .max(5, range(0, 5)),
    lcv: yup
      .number()
      .nullable()
      .required(required)
      .when('fuelType', {
        is: FuelType.MM,
        then: yup.number().min(0, range(0, 25000)).max(25000, range(0, 25000)),
        otherwise: yup
          .number()
          .min(37000, range(37000, 45000))
          .max(45000, range(37000, 45000)),
      }),
    density15: yup
      .number()
      .nullable()
      .required(required)
      .min(700, range(700, 1200))
      .max(1200, range(700, 1200)),
  })

const required = 'Required'

const range = (min: number, max: number) =>
  `Must be a value between ${min} and ${max}`

export const validateMixedFuelTypes = (
  batch: MixedBatch,
  batches: Array<MixedBatch>,
) => {
  const fuelType = batch.fuel?.type

  if (!fuelType) {
    return
  }

  const fuelTypes = batches.map((batch) => batch.fuel?.type)

  if (fuelType === FuelType.MM) {
    if (fuelTypes.some((ft) => ft !== FuelType.MM)) {
      return 'Cannot mix Methanol with fuel oil'
    }
  } else {
    if (fuelTypes.some((ft) => ft === FuelType.MM)) {
      return 'Cannot mix fuel oil with Methanol'
    }
  }
}
