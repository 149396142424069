import { useContext } from 'react'

import { WindowContext } from '../../../../contexts'
import {
  TableContainer,
  TableHeadingCol,
  TableValueCol,
} from '../../EngineTestReportPage.styles'
import { formatNumberWithUnit } from '../../../../utils'
import { UNITS } from '../../../../utils/constants'
import InfoTooltip from '../../../../components/InfoTooltip'

type Props = {
  general: GandalfApi.Aetc.General
  equipment: GandalfApi.Aetc.Equipment
  result: GandalfApi.Aetc.Result
}

const OperatingConditions = ({ general, equipment, result }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <colgroup>
          <TableHeadingCol />
          <TableValueCol />
        </colgroup>
        <thead>
          <tr>
            <th>Operating conditions</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Assigned AE model in StarConnect</td>
            <td>{equipment.staticModelId}</td>
          </tr>
          <tr>
            <td>Engine total running hours</td>
            <td>
              {formatNumberWithUnit(general.engRunHours.value, 0, UNITS.HOURS)}
            </td>
          </tr>
          <tr>
            <td>
              <InfoTooltip
                label='Engine indicated power'
                content='Calculated as: Measured electrical power / Gen. efficiency'
              />
            </td>
            <td>
              <InfoTooltip
                label={formatNumberWithUnit(
                  result.general.indicatedPower,
                  2,
                  UNITS.KILO_WATT,
                )}
                content={`MCR ${equipment.mcr} ${UNITS.KILO_WATT}`}
              />
            </td>
          </tr>
          <tr>
            <td>Electrical power</td>
            <td>
              <InfoTooltip
                label={formatNumberWithUnit(
                  result.general.electricalPower,
                  2,
                  UNITS.KILO_WATT,
                )}
                content={`Max electrical power ${equipment.maxPower} ${UNITS.KILO_WATT}`}
              />
            </td>
          </tr>
          <tr>
            <td>Total indicated power (measuring device)</td>
            <td>
              {formatNumberWithUnit(result.cylinders.ipSum, 2, UNITS.KILO_WATT)}
            </td>
          </tr>
          <tr>
            <td>Avg. MIP (measuring device)</td>
            <td>
              {formatNumberWithUnit(result.cylinders.mipAvg, 2, UNITS.BAR)}
            </td>
          </tr>
          <tr>
            <td>Engine load</td>
            <td>
              {formatNumberWithUnit(
                result.general.engineLoad,
                2,
                UNITS.PERCENTAGE,
              )}
            </td>
          </tr>
          <tr>
            <td>Engine speed</td>
            <td>{formatNumberWithUnit(general.engRpm.value, 2, UNITS.RPM)}</td>
          </tr>
          <tr>
            <td>Voltage</td>
            <td>
              {formatNumberWithUnit(general.voltage.value, 2, UNITS.VOLTAGE)}
            </td>
          </tr>
          <tr>
            <td>Current</td>
            <td>
              {formatNumberWithUnit(general.current.value, 2, UNITS.AMPERE)}
            </td>
          </tr>
          <tr>
            <td>Power factor</td>
            <td>
              {formatNumberWithUnit(
                general.powerFactor.value,
                2,
                UNITS.COS_PHI,
              )}
            </td>
          </tr>
          <tr>
            <td>Sea water temperature</td>
            <td>
              {formatNumberWithUnit(general.swTemp.value, 2, UNITS.TEMPERATURE)}
            </td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default OperatingConditions
