import { useContext, useEffect, useState } from 'react'

import { Performance } from '../../../../api-models'
import { Loading } from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal } from '../../../../utils'
import { SoundingForm } from './SoundingForm'
import { FormBatchSounding, ExistingSounding } from '../../models'
import { getBatchDictionary } from '../../utils'

type Props = {
  entryId?: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const SoundingModal = ({ entryId, closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [sounding, setSounding] = useState<ExistingSounding>()
  useEffect(() => {
    if (entryId) {
      PerformanceApi.getSounding(imoNo, entryId)
        .then((soundingRes: Performance.FuelOilStock.SoundingEntry) => {
          const soundingBatchIds = soundingRes.batches.map((batch) => batch.id)

          PerformanceApi.getBatchesById(imoNo, soundingBatchIds).then((res) => {
            const batchesById = getBatchDictionary(res)
            const batchSoundings: FormBatchSounding[] = soundingRes.batches.map(
              function (batchSounding) {
                const batch = batchesById[batchSounding.id]
                return {
                  id: batchSounding.id,
                  fuelType: batch.fuel.type,
                  displayName: batch.displayName,
                  rob: batchSounding.rob,
                  adjustment: batchSounding.adjustment,
                  quantity: batchSounding.rob - batchSounding.adjustment,
                }
              },
            )

            const soundingInfo: ExistingSounding = {
              batchQuantities: batchSoundings,
              timestamp: soundingRes.timestamp,
              id: soundingRes.id,
              notes: soundingRes.notes,
              readonly: soundingRes.readonly,
            }

            setSounding(soundingInfo)
          })
        })
        .catch((e) => displayErrorModal(e))
    }
  }, [entryId, imoNo])

  if (!!entryId && !sounding) {
    return <Loading />
  }

  return (
    <SoundingForm
      imoNo={imoNo}
      sounding={sounding}
      closeHandler={closeHandler}
    />
  )
}
