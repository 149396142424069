import { useContext, useEffect, useState } from 'react'
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik'
import moment, { type Moment } from 'moment'
import { McButton, McLabel, McModal } from '@maersk-global/mds-react-wrapper'

import { Performance } from '../../../../api-models'
import { Loading } from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { postSounding } from '../../../../services/performance'
import { displayErrorModal, FuelType, isShoreContext } from '../../../../utils'
import { type ExistingSounding, type FormBatchSounding } from '../../models'
import SoundingTable from './SoundingTable'
import { castingSchema, validationSchema } from './validation-schemas'
import { StockRobNotifications } from '../StockRobNotifications'
import { WindowContext } from '../../../../contexts'
import { ContentWrapper, Heading } from '../styles'
import { HelpText } from '../../../help-texts'
import { FormInputDateTime, FormTextArea } from '../../../../components/formik'

type SoundingFormValues = {
  timestamp?: Moment
  hsValues: Array<FormBatchSounding>
  vlsValues: Array<FormBatchSounding>
  ulsValues: Array<FormBatchSounding>
  mdoValues: Array<FormBatchSounding>
  mmValues: Array<FormBatchSounding>
  notes: string
}

const defaultInitialValues: SoundingFormValues = {
  notes: '',
  hsValues: [],
  vlsValues: [],
  ulsValues: [],
  mdoValues: [],
  mmValues: [],
}

enum STEPS {
  SELECT_TIME,
  ADD_SOUNDING,
}

const mapCalculatedRobToSoundingBatch = (
  calculatedRob: Performance.FuelOilStock.RobResponse,
  fuelType: FuelType,
): Array<FormBatchSounding> =>
  calculatedRob.batchQuantities
    .filter((x) => x.batch.fuel.type === fuelType)
    .map((b) => ({
      id: b.batch.id,
      displayName: b.batch.displayName,
      fuelType: b.batch.fuel.type,
      rob: b.quantity,
      adjustment: 0,
      quantity: b.quantity,
    }))

const mapExistingSoundingBatch = (
  sounding: ExistingSounding,
  fuelType: FuelType,
): Array<FormBatchSounding> =>
  sounding.batchQuantities
    .filter((b) => b.fuelType === fuelType)
    .map((b) => ({ ...b, rob: b.rob, adjustment: b.adjustment }))

const mapSoundingFormValuesToSoundingRequest = (
  values: SoundingFormValues,
): Performance.FuelOilStock.Sounding => {
  const [timestamp, batches, notes] = [
    values.timestamp!.toISOString(),
    [
      ...values.hsValues,
      ...values.vlsValues,
      ...values.ulsValues,
      ...values.mdoValues,
      ...values.mmValues,
    ],
    values.notes,
  ]

  // Schema cast is used to force number type values instead of strings (ROB is the main culprit)
  // @ts-ignore
  return castingSchema.cast({
    timestamp,
    batches,
    notes,
  })
}

const getInitialValues = (sounding?: ExistingSounding) =>
  sounding
    ? {
        timestamp: moment.utc(sounding.timestamp),
        hsValues: mapExistingSoundingBatch(sounding, FuelType.HS),
        vlsValues: mapExistingSoundingBatch(sounding, FuelType.VLS),
        ulsValues: mapExistingSoundingBatch(sounding, FuelType.ULS),
        mdoValues: mapExistingSoundingBatch(sounding, FuelType.MDO),
        mmValues: mapExistingSoundingBatch(sounding, FuelType.MM),
        notes: sounding.notes,
      }
    : defaultInitialValues

type Props = {
  sounding?: ExistingSounding
  imoNo: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const SoundingForm = ({ sounding, closeHandler, imoNo }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const startingStep = !!sounding ? STEPS.ADD_SOUNDING : STEPS.SELECT_TIME
  const [currentStep, setCurrentStep] = useState<STEPS>(startingStep)
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const isReadMode = Boolean(sounding)
  const isShoreMode = isShoreContext()
  const isChangeAllowed = !!!sounding?.readonly
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  useEffect(() => {
    PerformanceApi.getStockOpenWindow(imoNo)
      .then(setOpenWindow)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get allowed time interval',
          message: e.message,
        }),
      )
  }, [imoNo])

  const initialValues = getInitialValues(sounding)

  const deleteSounding = (setSubmitting) => {
    if (isChangeAllowed && !isShoreMode && sounding?.id) {
      PerformanceApi.deleteSounding(imoNo, sounding?.id)
        .then(() => closeHandler(true))
        .catch((e) => {
          setSubmitting(false)
          void displayErrorModal({
            statusText: 'Could not delete sounding',
            message: e.message,
          })
        })
    }
  }

  const getCalculatedRob = (
    imoNo: string,
    timestamp: Moment,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    setFieldError: (field: string, message: string) => void,
    setFieldTouched: (field: string, isTouched: boolean) => void,
    setSubmitting: (submitting: boolean) => void,
    soundingId?: string,
  ): void => {
    setRob(undefined)
    setSubmitting(true)
    PerformanceApi.getRob(imoNo, timestamp.toISOString(), soundingId)
      .then((data: Performance.FuelOilStock.RobResponse) => {
        setRob(data)
        if (data.hasData) {
          setFieldValue(
            'hsValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.HS),
          )
          setFieldValue(
            'vlsValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.VLS),
          )
          setFieldValue(
            'ulsValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.ULS),
          )
          setFieldValue(
            'mdoValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.MDO),
          )
          setFieldValue(
            'mmValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.MM),
          )
        }
        /* setting field to touched to ensure form is validated when rob finishes fetching */
        setFieldTouched('timestamp', true)
      })
      .catch((e) => {
        if (e.statusCode === 400 && !!e.message)
          setFieldError('timestamp', e.message)
        else {
          void displayErrorModal(e)
        }
      })
      .finally(() => setSubmitting(false))
  }

  if (!openWindow) {
    return <Loading width='unset' />
  }

  const _fit = windowSize === 'large' ? 'medium' : windowSize

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values: SoundingFormValues) => {
          try {
            void validateYupSchema<SoundingFormValues>(
              values,
              validationSchema,
              true,
              {
                rob: rob,
              },
            )
          } catch (err) {
            return yupToFormErrors(err)
          }
          return {}
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!isShoreMode && currentStep === STEPS.ADD_SOUNDING) {
            postSounding(imoNo, mapSoundingFormValuesToSoundingRequest(values))
              .then(() => closeHandler(true))
              .catch((e) => {
                setSubmitting(false)
                void displayErrorModal(e)
              })
          }

          if (currentStep === STEPS.SELECT_TIME) {
            setCurrentStep(STEPS.ADD_SOUNDING)
            setSubmitting(false)
          }
        }}
      >
        {({
          isSubmitting,
          setSubmitting,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          isValid,
          isValidating,
        }) => (
          <Form>
            <McModal
              fit={_fit}
              open
              width={currentStep === STEPS.SELECT_TIME ? '428px' : '1040px'}
              height={currentStep === STEPS.SELECT_TIME ? '296px' : '80vh'}
              closed={() => closeHandler()}
            >
              <Heading slot='heading'>
                {sounding ? 'Sounding' : 'Add new sounding'}
                <HelpText textKey='stock/sounding' />
              </Heading>
              {currentStep === STEPS.SELECT_TIME && (
                <>
                  <StockRobNotifications rob={rob} />
                  <ContentWrapper>
                    <FormInputDateTime
                      name='timestamp'
                      label='Time of sounding, UTC'
                      min={moment.utc(openWindow.period.from)}
                      max={moment.utc(openWindow.period.to)}
                      passThroughMoment
                      disabled={!!sounding}
                      onChange={(value: Moment) => {
                        getCalculatedRob(
                          imoNo,
                          value,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched,
                          setSubmitting,
                          sounding?.id,
                        )
                      }}
                    />
                  </ContentWrapper>
                </>
              )}
              {currentStep === STEPS.ADD_SOUNDING && (
                <>
                  <McLabel>
                    Time of sounding, UTC:{' '}
                    <strong>
                      {values.timestamp
                        ? values.timestamp.format('DD MMM YYYY HH:mm')
                        : ''}
                    </strong>
                  </McLabel>
                  <SoundingTable
                    isShoreMode={isShoreMode}
                    readonly={isReadMode}
                  />
                  <FormTextArea
                    id='notes'
                    name='notes'
                    label='Notes'
                    rows={4}
                    maxlength={1000}
                    disabled={isShoreMode || isReadMode}
                  />
                </>
              )}
              {currentStep === STEPS.SELECT_TIME && (
                <>
                  <McButton
                    fit={_fit}
                    slot='secondaryAction'
                    label='Cancel'
                    appearance='neutral'
                    type='button'
                    click={() => closeHandler()}
                  />
                  <McButton
                    fit={_fit}
                    slot='primaryAction'
                    label='Next'
                    appearance='primary'
                    disabled={
                      !values?.timestamp ||
                      !isValid ||
                      isValidating ||
                      isSubmitting
                    }
                    type='submit'
                  />
                </>
              )}
              {currentStep === STEPS.ADD_SOUNDING && sounding && (
                <>
                  {isChangeAllowed && (
                    <McButton
                      fit={_fit}
                      slot='secondaryAction'
                      label='Delete'
                      variant='plain'
                      disabled={isSubmitting || isShoreMode}
                      type='button'
                      click={() => deleteSounding(setSubmitting)}
                    />
                  )}
                  <McButton
                    fit={_fit}
                    slot='primaryAction'
                    label='Close'
                    appearance='primary'
                    type='button'
                    click={() => closeHandler()}
                  />
                </>
              )}
              {currentStep === STEPS.ADD_SOUNDING && !sounding && (
                <>
                  <McButton
                    fit={_fit}
                    slot='secondaryAction'
                    label='Back'
                    appearance='neutral'
                    type='button'
                    click={() => setCurrentStep(STEPS.SELECT_TIME)}
                  />
                  <McButton
                    fit={_fit}
                    slot='primaryAction'
                    label='Submit Sounding'
                    appearance='primary'
                    disabled={
                      isSubmitting ||
                      !isChangeAllowed ||
                      isShoreMode ||
                      !isValid
                    }
                    type='submit'
                  />
                </>
              )}
            </McModal>
          </Form>
        )}
      </Formik>
    </>
  )
}
