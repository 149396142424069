import type { IMcTypeaheadData } from '@maersk-global/mds-components-core/mc-typeahead/types'

import { EventType } from '../../../../api-models/hdc/events'

export const getEventTypeOptions = (
  eventTypes: Array<EventType>,
  path: Array<string> = [],
): Array<IMcTypeaheadData> => {
  return eventTypes.flatMap((curr) => {
    const name = [...path, curr.name]
    if (curr.eventTypes) {
      return getEventTypeOptions(curr.eventTypes, name)
    } else if (curr.id) {
      return {
        value: curr.id.toString(), // Needs to be a string for McTypeahead data
        label: name.join(' / '),
      }
    }
    return []
  })
}
