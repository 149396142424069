import { useContext } from 'react'
import { useFormikContext, getIn } from 'formik'
import styled from 'styled-components'
import { McNotification } from '@maersk-global/mds-react-wrapper'

import { type MixedBatch } from './MixedBatchForm'
import { WindowContext } from '../../../../contexts'

type Props = {
  name: string
}

const MixWarning = ({ name }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { values, touched } = useFormikContext<any>()
  const batches = getIn(values, name)
  const isTouched = getIn(touched, name)
  const showWarning = isMixingDifferentFuelTypes(batches)

  if (!(isTouched && showWarning)) {
    return null
  }

  return (
    <StyledMcNotification
      fit={windowSize}
      appearance='warning'
      icon='exclamation-triangle'
      body={msg}
    />
  )
}

export default MixWarning

const StyledMcNotification = styled(McNotification)`
  display: block;
  margin-bottom: 24px;
  width: 100%;
`

const msg =
  'It appears you are mixing batches of different fuel types or distillates. Are you sure that is correct?'

const isMixingDifferentFuelTypes = (batches: Array<MixedBatch>) => {
  const fuelTypes = batches.reduce(
    (fuelTypes: Array<string>, batch: MixedBatch) => {
      if (batch.fuel?.type) {
        fuelTypes.push(`${batch.fuel.type + ' ' + batch.fuel.isDistillate}`)
      }
      return fuelTypes
    },
    [],
  )

  const uniqueFuelTypes = Array.from(new Set(fuelTypes))
  return uniqueFuelTypes.length > 1
}
