import { useState } from 'react'
import {
  FieldArray,
  type FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from 'formik'

import SoundingTableRow from './SoundingTableRow'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import { VolumeToMassCalculatorState } from '../../../volume-to-mass-calculator/components/VolumeToMassCalculator'
import { SimpleTable } from '../../../../commons'

import styled, {
  greenColorScheme,
  lightBlueColorScheme,
  lightOrangeColorScheme,
  mariTimeColorScheme,
  purpleColorScheme,
} from '../../../../theme'

const Spacer = styled.tr`
  height: 6px;
`

const Wrapper = styled.div`
  margin-bottom: 32px;

  table {
    table-layout: fixed;

    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    tbody tr:hover {
      background-color: var(
        --mds_foundations_table_cell_hover_background-color
      );
    }

    th {
      text-align: right;

      &.fuel-type {
        width: 40%;
        text-align: left;
      }

      &.sounding {
        width: 30%;
      }

      &.calculated,
      &.adjustment {
        width: 20%;
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
      padding: 8px;
      border-top: 0;
      border-left: none;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        text-align: left;
      }

      &:not(:first-child) {
        font-feature-settings: 'tnum' 1;
      }
    }
  }
`

const roundedValue = (val: number): number =>
  Math.round((val + Number.EPSILON) * 100) / 100

type Props = {
  isShoreMode: boolean
  readonly: boolean
}

const SoundingTable = ({ isShoreMode, readonly }: Props) => {
  const [calculatorStore, setCalculatorStore] = useState({})
  const [batchName, setBatchName] = useState<string>()
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()

  return (
    <Wrapper>
      <SimpleTable>
        <thead>
          <tr>
            <th className='fuel-type'>Fuel</th>
            <th className='calculated'>Calculated [MT]</th>
            <th className='sounding'>Sounding as per C/E [MT]</th>
            <th className='adjustment'>Adjustment [MT]</th>
          </tr>
        </thead>
        <tbody>
          <Spacer />
          <FieldArray name='hsValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={mariTimeColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='vlsValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={purpleColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='ulsValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={lightBlueColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='mdoValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={lightOrangeColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='mmValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={greenColorScheme}
              />
            )}
          </FieldArray>
        </tbody>
      </SimpleTable>

      {!!batchName && (
        <VolumeToMassCalculator
          labelText={getIn(values, `${batchName}.displayName`)}
          onCancel={() => setBatchName(undefined)}
          onClose={() => setBatchName(undefined)}
          storedState={!!batchName ? calculatorStore[batchName] : undefined}
          onSave={(totalSum: string, state: VolumeToMassCalculatorState) => {
            if (totalSum === '-' || isNaN(+totalSum) || isShoreMode) {
              setBatchName(undefined)
              return
            }
            const sum = parseFloat(totalSum)
            const quantity = getIn(values, `${batchName}.quantity`)
            const adjustment = roundedValue(sum - quantity)
            void setFieldValue(`${batchName}.rob`, sum)
            void setFieldValue(`${batchName}.adjustment`, adjustment)
            void setFieldTouched(batchName)
            setBatchName(undefined)
            setCalculatorStore({ ...calculatorStore, [batchName]: state })
          }}
        />
      )}
    </Wrapper>
  )
}

export default SoundingTable
