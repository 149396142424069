import React, { useContext } from 'react'
import { getIn, type FieldArrayRenderProps } from 'formik'
import { McButton } from '@maersk-global/mds-react-wrapper'

import styled, { type IColorScheme } from '../../../../theme'
import { SoundingWarning } from './SoundingWarning'
import { formatValue, commaAgnostic } from '../../../../utils'
import { InputNumber } from '../../../../components/formik'
import { WindowContext } from '../../../../contexts'

const SumRow = styled.tr<IColorScheme>`
  background: ${({ fill }) => fill};
  color: ${({ font }) => font};

  &:hover {
    background-color: ${({ fill }) => fill} !important;
  }

  td {
    border: none !important;
    font-weight: bold;

    &:first-child {
      border-top-left-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
      border-bottom-left-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
    }

    &:last-child {
      border-top-right-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
      border-bottom-right-radius: calc(
        var(--mds_foundations_table_border-radius) - 1px
      );
    }
  }
`

const roundedValue = (val: number): number =>
  Math.round((val + Number.EPSILON) * 100) / 100

const DISPLAY_NAME_MAPPER = {
  hsValues: 'HS',
  ulsValues: 'ULS',
  vlsValues: 'VLS',
  mdoValues: 'MDO',
  mmValues: 'MM',
}

type Props = FieldArrayRenderProps & {
  onCalcClick: (val: string) => void
  scheme: IColorScheme
  disabled: boolean
  readonly: boolean
}

const SoundingTableRow = ({
  form,
  name,
  scheme,
  onCalcClick,
  disabled,
  readonly,
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { setFieldValue } = form
  const values = getIn(form.values, name)
  const displayName = DISPLAY_NAME_MAPPER[name]

  return (
    <>
      <SumRow {...scheme}>
        <td>{displayName}</td>
        <td>
          {values
            ? formatValue(
                values.reduce((acc, cv) => acc + cv.quantity, 0),
                3,
              )
            : 0}
        </td>
        <td>
          {values
            ? formatValue(
                values.reduce(
                  (acc, cv) =>
                    acc +
                    parseFloat(
                      (typeof cv.rob === 'string'
                        ? commaAgnostic(cv.rob)
                        : cv.rob) || 0,
                    ),
                  0,
                ),
                3,
              )
            : 0}
        </td>
        <td>
          {values
            ? formatValue(
                values.reduce((acc, cv) => acc + cv.adjustment, 0),
                3,
              )
            : 0}
        </td>
      </SumRow>

      {values.length > 0 &&
        values.map((entry, index) => (
          <React.Fragment key={`name-${index}`}>
            <tr>
              <td title={entry.displayName}>{entry.displayName}</td>
              <td>{formatValue(entry.quantity, 3)}</td>
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                }}
              >
                {readonly ? (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {formatValue(entry.rob, 3)}
                  </div>
                ) : (
                  <>
                    <InputNumber
                      name={`${name}[${index}].rob`}
                      unit='MT'
                      disabled={disabled}
                      onBlur={(value) => {
                        const adjustment = roundedValue(
                          (value || 0) -
                            (parseFloat(commaAgnostic(entry.quantity)) || 0),
                        )
                        void setFieldValue(
                          `${name}[${index}].adjustment`,
                          adjustment,
                        )
                      }}
                    />
                    <div>
                      <McButton
                        fit={windowSize}
                        hiddenlabel
                        icon='calculator'
                        variant='plain'
                        click={() => onCalcClick(`${name}[${index}]`)}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                  </>
                )}
              </td>
              <td>{formatValue(entry.adjustment, 3)}</td>
            </tr>
            <SoundingWarning name={`${name}[${index}]`} />
          </React.Fragment>
        ))}
    </>
  )
}

export default SoundingTableRow
