import { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import moment, { type Moment } from 'moment'
import { isEqual } from 'lodash'
import { McButton, McError, McModal } from '@maersk-global/mds-react-wrapper'

import { isMethanolExistsOnFuelLines } from './FuelLineChangeForm.utils'
import { Loading } from '../../../../commons'
import {
  displayConfirmModal,
  displayErrorModal,
  FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING,
  FuelLineType,
  FuelType,
  isShoreContext,
} from '../../../../utils'
import { Performance } from '../../../../api-models'
import {
  StockEntryContext,
  VesselPageContext,
  WindowContext,
} from '../../../../contexts'
import * as PerformanceAPI from '../../../../services/performance'
import { UserEntryTypes } from '../../../stock-management'
import {
  Column,
  ContentWrapper,
  Heading,
} from '../../../stock-management/components/styles'
import { HelpText } from '../../../help-texts'
import { FormInputDateTime, InputSelect } from '../../../../components/formik'

type Option = {
  label: string
  value: string
}

const fuelTypeOptions: Array<Option> = [
  { label: FuelType[FuelType.HS], value: FuelType.HS.toString() },
  { label: FuelType[FuelType.VLS], value: FuelType.VLS.toString() },
  { label: FuelType[FuelType.ULS], value: FuelType.ULS.toString() },
  { label: FuelType[FuelType.MDO], value: FuelType.MDO.toString() },
  { label: FuelType[FuelType.MM], value: FuelType.MM.toString() },
]

type Props = {
  fuelLineType: FuelLineType
  currentlySelectedFuelType?: Performance.FDL.FuelTypeSelectionResponse
  batches: Performance.FuelOilStock.BatchResponse[]
  closeHandler: () => void
  submit: (fuelTypeSelection: Performance.FDL.FuelTypeSelection) => void
  entryId: string | undefined
  userEntryType?: UserEntryTypes
  readonly?: boolean
  fuelTypeSelections?: Array<Performance.FDL.FuelTypeSelectionResponse>
}

interface FuelLineChangeFormValues {
  timestamp?: Moment
  fuelType?: FuelType
}

const fuelLineChangeValidationSchema = yup.object().shape({
  fuelType: yup
    .number()
    .oneOf([
      FuelType.HS,
      FuelType.VLS,
      FuelType.ULS,
      FuelType.MDO,
      FuelType.MM,
    ]),
  timestamp: yup
    .date()
    .required('Time of fuel type changeover is required field'),
})

export const FuelLineChangeForm = ({
  closeHandler,
  fuelLineType,
  submit,
  currentlySelectedFuelType,
  batches,
  entryId,
  userEntryType,
  readonly,
  fuelTypeSelections,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { deleteEntry } = useContext(StockEntryContext)
  const { windowSize } = useContext(WindowContext)
  const _fit = windowSize === 'large' ? 'medium' : windowSize

  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  useEffect(() => {
    PerformanceAPI.getStockOpenWindow(imoNo)
      .then(setOpenWindow)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get allowed time interval',
          message: e.message,
        }),
      )
  }, [imoNo])

  if (!openWindow) {
    return <Loading width='unset' />
  }

  const getFuelTypeOptions = (values: FuelLineChangeFormValues) => {
    if (!values.timestamp) {
      return []
    }

    if (batches.length <= 0) {
      return fuelTypeOptions
    }

    const batchTypesInTimeFrame = batches
      .filter((batch) =>
        moment(batch.timestamp).isSameOrBefore(values.timestamp),
      )
      .map((batch) => batch.fuel.type)

    return fuelTypeOptions.filter((option) => {
      const isFuelTypeMm = +option.value === FuelType.MM

      const isBoilerFuelLine = [FuelLineType.BoilerMain].includes(fuelLineType)

      if (
        isFuelTypeMm &&
        (isBoilerFuelLine ||
          isMethanolExistsOnFuelLines(fuelLineType, 'ME', fuelTypeSelections) ||
          isMethanolExistsOnFuelLines(fuelLineType, 'AE', fuelTypeSelections))
      ) {
        return false
      }
      return batchTypesInTimeFrame.includes(+option.value)
    })
  }

  const handleDeleteEntry = () => {
    displayConfirmModal({
      title: '',
      message: 'Are you sure you want to delete this entry?',
    })
      .then(() =>
        deleteEntry(userEntryType!, entryId!).then(() => closeHandler()),
      )
      .catch((e) => {
        if (e !== 'cancel')
          void displayErrorModal({
            statusText: 'Something went wrong...',
            message: 'Unexpected error when trying to delete entry',
          })
      })
  }

  const initialValues = {
    timestamp: entryId
      ? moment.utc(currentlySelectedFuelType?.timestamp)
      : null,
    fuelType: fuelTypeOptions.find(
      (ft) => ft.value === currentlySelectedFuelType?.fuelType.toString(),
    )?.value,
  } as FuelLineChangeFormValues

  const disabled = isShoreContext()

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={fuelLineChangeValidationSchema}
      onSubmit={(values) =>
        values.fuelType &&
        submit({
          timestamp: values.timestamp?.toISOString() ?? '',
          fuelType: values.fuelType,
          fuelLineType,
        })
      }
    >
      {({ isSubmitting, isValid, errors, values }) => (
        <Form>
          <McModal
            fit={_fit}
            open
            width='950px'
            closed={() => closeHandler()}
            height='auto'
          >
            <Heading slot='heading'>
              Change of{' '}
              {FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[fuelLineType!] || ''} fuel
              type
              <HelpText textKey='consumption-selection/fuel-type' />
            </Heading>
            <ContentWrapper>
              <Column>
                <div>
                  <span>Latest fuel type change</span>
                  <div>
                    {currentlySelectedFuelType?.fuelType
                      ? `${moment
                          .utc(currentlySelectedFuelType.timestamp)
                          .format('DD MMM YYYY HH:mm')} - ${
                          FuelType[currentlySelectedFuelType.fuelType] || ''
                        }`
                      : 'No previous fuel type selection'}
                  </div>
                </div>
              </Column>
              <Column>
                <FormInputDateTime
                  name='timestamp'
                  label='When did you change fuel type?'
                  min={moment.utc(openWindow.period.from)}
                  max={moment.utc(openWindow.period.to)}
                  passThroughMoment
                  disabled={disabled}
                  fit={_fit}
                />
              </Column>
              <Column>
                <InputSelect
                  name='fuelType'
                  label='What fuel type did you change to?'
                  disabled={disabled}
                  options={getFuelTypeOptions(values)}
                />
              </Column>
            </ContentWrapper>
            <McError invalid={!!errors['timestamp']} errormessage='&nbsp;' />
            {userEntryType && (
              <McButton
                fit={_fit}
                slot='secondaryAction'
                label='Delete'
                variant='plain'
                type='button'
                disabled={readonly}
                click={handleDeleteEntry}
              />
            )}
            <McButton
              fit={_fit}
              slot='secondaryAction'
              label='Cancel'
              appearance='neutral'
              type='button'
              click={() => closeHandler()}
            />
            <McButton
              fit={_fit}
              slot='primaryAction'
              label='Change fuel type'
              appearance='primary'
              disabled={
                isSubmitting ||
                disabled ||
                !isValid ||
                isEqual(values, initialValues)
              }
              type='submit'
            />
          </McModal>
        </Form>
      )}
    </Formik>
  )
}
