import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import {
  displayErrorModal,
  doGet,
  getMasterDataApiShoreUrl,
  getMasterDataApiUrl,
} from '../../utils'

const STALE_TIME = 1000 * 60 * 60 * 3 // 3 hours

const keys = {
  Terminal: [{ MasterDataAccess: ['Common', 'Terminal'] }] as const,
  FuelGrade: [{ MasterDataAccess: ['Common', 'FuelGrade'] }] as const,
  VesselParameters: [
    { MasterDataAccess: ['VesselParameters', 'General', 'BasicInfo'] },
    { MasterDataAccess: ['VesselParameters', 'Machinery', 'MainEngine'] },
    { MasterDataAccess: ['VesselParameters', 'Machinery', 'AuxEngine'] },
  ] as const,
  StaticModels: [
    { MasterDataAccess: ['StaticModels'] },
    { MasterDataAccess: ['StaticModels', 'Assignments'] },
  ] as const,
  SisterVessel: [{ MasterDataAccess: ['SisterVessel'] }] as const,
  vesselsBasicInfo: () => [{ ...keys.VesselParameters[0] }] as const,
  vesselBasicInfo: (imoNo?: string) =>
    [{ ...keys.VesselParameters[0], imoNo }] as const,
  vesselMainEngine: (imoNo?: string) =>
    [{ ...keys.VesselParameters[1], imoNo }] as const,
  vesselAuxEngine: (imoNo?: string) =>
    [{ ...keys.VesselParameters[2], imoNo }] as const,
  staticModels: (ids: string) => [{ ...keys.StaticModels[0], ids }] as const,
  // prettier-ignore
  vesselStaticModels: (imoNo: number) => [{
    ...keys.StaticModels[1],
    imoNo,
  }] as const,
  // prettier-ignore
  _vesselStaticModels: (imoNo: number, types?: Array<number>) => [{
    ...keys.StaticModels[0],
    imoNo,
    types,
  }] as const,
  sisterVessel: (imoNo: number) =>
    [{ ...keys.SisterVessel[0], imoNo }] as const,
}

// region - Vessel Parameters

// region - General, Basic Info

export const useVesselsBasicInfo = () => {
  return useQuery({
    queryKey: keys.vesselsBasicInfo(),
    queryFn: getVesselsBasicInfo,
    staleTime: STALE_TIME,
  })
}

const getVesselsBasicInfo =
  (): Promise<MasterDataApi.VesselParameters.General.BasicInfo.VesselsResponse> => {
    return doGet(
      `${getMasterDataApiUrl()}/v1/vessel/parameters?names=General.BasicInfo`,
    )
  }

export const useVesselBasicInfo = (
  imoNo?: string,
  isEnabled: boolean = true,
) => {
  return useQuery({
    queryKey: keys.vesselBasicInfo(imoNo),
    queryFn: getVesselBasicInfo,
    staleTime: STALE_TIME,
    enabled: isEnabled,
  })
}

const getVesselBasicInfo = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['vesselBasicInfo']>
>): Promise<MasterDataApi.VesselParameters.General.BasicInfo.Data> => {
  const response: MasterDataApi.VesselParameters.General.BasicInfo.VesselResponse =
    await doGet(
      `${getMasterDataApiUrl()}/v1/vessel/${imoNo}/parameters?names=General.BasicInfo`,
    )

  return response.parameters['General.BasicInfo'].data
}

// endregion - General, Basic Info

// region - Machinery, Main Engine

export const useVesselMainEngines = (imoNo?: string) => {
  return useQuery({
    queryKey: keys.vesselMainEngine(imoNo),
    queryFn: getVesselMainEngines,
    onError: handleError('Main engine master data could not be acquired'),
    staleTime: STALE_TIME,
  })
}

const getVesselMainEngines = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['vesselMainEngine']>
>): Promise<MasterDataApi.VesselParameters.Machinery.MainEngine.Data> => {
  const response: MasterDataApi.VesselParameters.Machinery.MainEngine.VesselResponse =
    await doGet(
      `${getMasterDataApiUrl()}/v1/vessel/${imoNo}/parameters?names=Machinery.MainEngine`,
    )

  return response.parameters['Machinery.MainEngine'].data
}

// endregion - Machinery, Main Engine

// region - Machinery, Aux Engine

export const useVesselAuxEngines = (imoNo?: string) => {
  return useQuery({
    queryKey: keys.vesselAuxEngine(imoNo),
    queryFn: getVesselAuxEngines,
    onError: handleError('Aux engine master data could not be acquired'),
    staleTime: STALE_TIME,
  })
}

const getVesselAuxEngines = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['vesselAuxEngine']>
>): Promise<MasterDataApi.VesselParameters.Machinery.AuxEngine.Data> => {
  const response: MasterDataApi.VesselParameters.Machinery.AuxEngine.VesselResponse =
    await doGet(
      `${getMasterDataApiUrl()}/v1/vessel/${imoNo}/parameters?names=Machinery.AuxEngine`,
    )

  return response.parameters['Machinery.AuxEngine'].data
}

// endregion - Machinery, Aux Engine

// endregion - Vessel Parameters

// region - Static Models

export const useVesselStaticModelsAssignments = <StaticModelInstance>(
  imoNo: number,
) => {
  return useQuery({
    queryKey: keys.vesselStaticModels(imoNo),
    queryFn: getVesselStaticModelsAssignments<StaticModelInstance>,
    staleTime: STALE_TIME,
    enabled: true,
  })
}

const getVesselStaticModelsAssignments = async <StaticModelInstance>({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['vesselStaticModels']>
>): Promise<
  MasterDataApi.StaticModels.VesselAssignments<StaticModelInstance>
> => {
  return await doGet(
    `${getMasterDataApiUrl()}/v1/static-models/assignments/${imoNo}`,
  )
}

export const useStaticModels = <StaticModelData>(ids?: string) => {
  return useQuery({
    queryKey: keys.staticModels(ids ?? ''),
    queryFn: getStaticModels<StaticModelData>,
    enabled: !!ids,
    staleTime: STALE_TIME,
  })
}

const getStaticModels = async <StaticModelData>({
  queryKey: [{ ids }],
}: QueryFunctionContext<ReturnType<(typeof keys)['staticModels']>>): Promise<
  Array<MasterDataApi.StaticModels.StaticModel<StaticModelData>>
> => {
  return await doGet(`${getMasterDataApiUrl()}/v1/static-models?ids=${ids}`)
}

export const useVesselStaticModels = <StaticModelData>(
  imoNo: number,
  types?: Array<number>,
) => {
  return useQuery({
    queryKey: keys._vesselStaticModels(imoNo, types),
    queryFn: getVesselStaticModels<StaticModelData>,
    staleTime: STALE_TIME,
  })
}

const getVesselStaticModels = async <StaticModelData>({
  queryKey: [{ imoNo, types }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['_vesselStaticModels']>
>): Promise<MasterDataApi.StaticModels.VesselStaticModels<StaticModelData>> => {
  const typesQuery = types ? `?types=${types.join(',')}` : ''
  return await doGet(
    `${getMasterDataApiUrl()}/v1/static-models/${imoNo}${typesQuery}`,
  )
}

// endregion - Static Models

// region - List Items

const getTerminals = (): Promise<Array<MasterDataApi.Common.Terminal>> => {
  return doGet(`${getMasterDataApiUrl()}/v1/common/list/terminal`)
}

export const useTerminals = () => {
  return useQuery({
    queryKey: keys.Terminal,
    queryFn: getTerminals,
    staleTime: STALE_TIME,
  })
}

const getFuelGrades = (): Promise<Array<MasterDataApi.Common.FuelGrade>> => {
  return doGet(`${getMasterDataApiUrl()}/v1/common/list/fuel-grade`)
}

export const useFuelGrades = () => {
  return useQuery({
    queryKey: keys.FuelGrade,
    queryFn: getFuelGrades,
    staleTime: STALE_TIME,
    onError: handleError('List of fuel grades could not be acquired'),
  })
}

// endregion - List Items

// region - Sister Vessel
export const useSisterVesselInfo = (imoNo: number) => {
  return useQuery({
    queryKey: keys.sisterVessel(imoNo),
    queryFn: getSisterVesselInfo,
    staleTime: STALE_TIME,
    meta: {
      masterDataError:
        'A problem occurred retrieving sister vessel IMO numbers.',
    },
  })
}

const getSisterVesselInfo = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['sisterVessel']>
>): Promise<MasterDataApi.SisterVessel.SisterVesselResponse> => {
  return await doGet(
    `${getMasterDataApiShoreUrl()}/sister-vessel/name-imo-list/${imoNo}`,
  )
}

// endregion - Sister Vessel

const handleError = (title: string) => (error: any) => {
  let message = ''
  if (typeof error === 'string') {
    message = error
  }

  if (error.message && typeof error.message === 'string') {
    message = error.message
  }

  message = title + '. ' + message

  void displayErrorModal({ message })
}

export const isMasterDataError = (
  error: unknown,
): error is MasterDataApi.Error => {
  return (
    (error as MasterDataApi.Error).body.detail !== undefined &&
    (error as MasterDataApi.Error).body.status !== undefined &&
    (error as MasterDataApi.Error).body.title !== undefined
  )
}
