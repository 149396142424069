import styled from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

const Legend = () => (
  <Wrapper>
    <McIcon icon='exclamation-circle' />
    <Txt>Sensor data overwritten</Txt>
    <Circle />
    <Txt>Manual input</Txt>
  </Wrapper>
)

export default Legend

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -12px;
`

const Txt = styled.span`
  margin-left: 8px;
  font-size: 12px;
`

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--mds_brand_appearance_neutral_default_border-color);
  background-color: var(--mds_brand_appearance_primary_subtle_background-color);
  margin-left: 16px;
`
