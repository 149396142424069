import { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  getConsumedMassInPeriod,
  postManualCorrectionOverwrite,
} from '../../../../services/performance'
import {
  displayErrorModal,
  type FuelLineType,
  getClosestTenMinute,
  isShoreContext,
} from '../../../../utils'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { Loading } from '../../../../commons'
import TotalizerModal from '../totalizer-modal'
import * as S from './style'
import { amountPerHour } from '../../utils/fuel-consumption'
import { UNITS } from '../../../../utils/constants'
import {
  FormInputDateTime,
  FormTextArea,
  InputNumber,
  InputSelect,
} from '../../../../components/formik'
import { resolveNotesHeight } from '../../../stock-management/components/styles'

type TFuelLine = {
  label: string
  value: FuelLineType
}

type Props = {
  refreshData: Function
  fuelLineOptions: Array<TFuelLine>
  id: string
}

const FuelConsumptionOverwriteForm = ({
  refreshData,
  fuelLineOptions,
  id,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)

  const [calculatedConsumption, setCalculatedConsumption] = useState<number>()
  const [totalizerActive, setTotalizerActive] = useState(false)

  const initialValues = {
    startTimestamp: getClosestTenMinute(
      moment().subtract(10, 'minutes'),
    ).toISOString(),
    endTimestamp: getClosestTenMinute(moment()).toISOString(),
    consumedMass: null,
    reason: null,
    fuelLineType: null,
    notes: '',
  }

  const getCalculatedConsumption = async (
    startDate: string,
    endDate: string,
    fuelLineType: number | null,
  ) => {
    if (!startDate || !endDate || fuelLineType === null) {
      return null
    }
    const response = await getConsumedMassInPeriod(
      imoNo,
      { from: startDate, to: endDate },
      fuelLineType,
    )
    return response.hasData ? response.consumedMass : null
  }

  // Submit handler
  const handleSubmit = async (values, helpers) => {
    try {
      await postManualCorrectionOverwrite(imoNo, values)
      refreshData()
      helpers.resetForm()
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to overwrite',
        message: err.message,
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  type TReason = {
    value: number
    label: string
  }

  // Data loss reasons
  const reasons: Array<TReason> = [
    { value: 10, label: 'Calibrating flowmeters to zero (Constant)' },
    { value: 11, label: 'Unexpected leaks (Factor)' },
    { value: 15, label: 'Flushing (Constant)' },
    { value: 20, label: 'Inaccurate measurement (Factor)' },
    { value: 25, label: 'Defect flowmeter (Constant)' },
  ]

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    startTimestamp: Yup.date().required('Please provide a start time'),
    endTimestamp: Yup.date().required('Please provide an end time'),
    consumedMass: Yup.number()
      .nullable()
      .typeError('Please provide consumed mass')
      .required('Please provide consumed mass'),
    reason: Yup.mixed().required('Please provide a reason'),
    fuelLineType: Yup.mixed().required('Please select fuel line'),
    notes: Yup.string().max(1000, 'Limit of 1000 characters exceeded'),
  })

  const disabled = isShoreContext()

  const handleStartTimestampChange = async (val, setFieldValue, values) => {
    const newDate = val.toISOString()
    setFieldValue('startTimestamp', newDate)
    const calculatedConsumedMass = await getCalculatedConsumption(
      newDate,
      values.endTimestamp,
      values.fuelLineType,
    )
    if (calculatedConsumedMass !== null) {
      setCalculatedConsumption(calculatedConsumedMass)
      setFieldValue('consumedMass', calculatedConsumedMass)
    }
  }

  const handleEndTimestampChange = async (val, setFieldValue, values) => {
    const newDate = val.toISOString()
    setFieldValue('endTimestamp', newDate)
    const calculatedConsumedMass = await getCalculatedConsumption(
      values.startTimestamp,
      newDate,
      values.fuelLineType,
    )
    if (calculatedConsumedMass !== null) {
      setCalculatedConsumption(calculatedConsumedMass)
      setFieldValue('consumedMass', calculatedConsumedMass)
    }
  }

  const handleFuelLineChange = async (
    val: string | number,
    setFieldValue,
    values,
  ) => {
    const calculatedConsumedMass = await getCalculatedConsumption(
      values.startTimestamp,
      values.endTimestamp,
      Number(val),
    )
    if (calculatedConsumedMass !== null) {
      setCalculatedConsumption(calculatedConsumedMass)
      setFieldValue('consumedMass', calculatedConsumedMass)
    }
  }

  return (
    <>
      <S.Paragraph>
        Add your data estimation to the data shown in the above fuel line. The
        overwrite will effect the graph, and it will also be shown per fuel line
        indicated in blue.
      </S.Paragraph>

      <Formik
        key={id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <S.Container>
              {isSubmitting && (
                <S.Loading>
                  <Loading />
                </S.Loading>
              )}
              <S.ColumnLeft>
                <S.Header>Overwrite period</S.Header>
                <FormInputDateTime
                  name='startTimestamp'
                  label='Overwrite from'
                  passThroughMoment
                  disabled={disabled}
                  onChange={(val) =>
                    handleStartTimestampChange(val, setFieldValue, values)
                  }
                />
                <FormInputDateTime
                  name='endTimestamp'
                  label='Overwrite to'
                  min={moment.utc(values.startTimestamp).add(10, 'm')}
                  max={moment.utc(values.endTimestamp)}
                  passThroughMoment
                  disabled={disabled}
                  onChange={(val) =>
                    handleEndTimestampChange(val, setFieldValue, values)
                  }
                />
                <McButton
                  fit={windowSize}
                  type='button'
                  variant='outlined'
                  label='Totalizer at specific time'
                  icon='cog'
                  click={() => setTotalizerActive(true)}
                />
                <FormTextArea
                  id='notes'
                  name='notes'
                  label='Comment'
                  height={resolveNotesHeight(windowSize)}
                  rows={5}
                  maxlength={1000}
                  disabled={disabled}
                />
              </S.ColumnLeft>
              <S.ColumnRight>
                <S.Header>Consumption & Reason</S.Header>
                <InputSelect
                  name='fuelLineType'
                  label='Fuel line'
                  disabled={disabled}
                  options={fuelLineOptions}
                  onOptionSelected={(val) =>
                    handleFuelLineChange(val, setFieldValue, values)
                  }
                />
                <S.ConsumptionField>
                  <InputNumber
                    label='Est. consumption'
                    name='consumedMass'
                    unit={UNITS.METRIC_TON}
                    disabled={disabled}
                    hint={
                      calculatedConsumption !== undefined &&
                      values.consumedMass !== calculatedConsumption
                        ? `Original value was ${calculatedConsumption}`
                        : undefined
                    }
                  />
                  {!!values.consumedMass && (
                    <S.ConsumptionPerHourLabel fit={windowSize}>
                      {`${amountPerHour(
                        values.startTimestamp,
                        values.endTimestamp,
                        values.consumedMass,
                      )} MT/h`}
                    </S.ConsumptionPerHourLabel>
                  )}
                </S.ConsumptionField>
                <InputSelect
                  name='reason'
                  label='Reason'
                  disabled={disabled}
                  options={reasons}
                />
              </S.ColumnRight>
            </S.Container>
            <S.Actions>
              <McButton label='Save' appearance='primary' type='submit' />
            </S.Actions>
          </Form>
        )}
      </Formik>
      <TotalizerModal
        handleClose={() => setTotalizerActive(false)}
        visible={totalizerActive}
        timestamp={new Date().toISOString()}
      />
    </>
  )
}

export default FuelConsumptionOverwriteForm
