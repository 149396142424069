import {
  MetcReportTabSearchParam,
  TabIndex as MetcTabIndex,
} from './MetcReportPage/MetcReportPage.consts'
import {
  AetcReportTabSearchParam,
  TabIndex as AetcTabIndex,
} from './AetcReportPage/AetcReportPage.consts'
import {
  ReportState,
  ReportType,
} from '../../queries/GandalfApi/GandalfApi.consts'

export enum TabStatus {
  SUCCESS,
  ERROR,
  INDETERMINATE,
}

export const EngineTestReportTabSearchParam = {
  [ReportType.Metc]: MetcReportTabSearchParam,
  [ReportType.Aetc]: AetcReportTabSearchParam,
} as const

export const EngineTestReportTabIndex = {
  [ReportType.Metc]: {
    [ReportState.Submitted]: MetcTabIndex.RESULTS,
    [ReportState.Draft]: MetcTabIndex.GENERAL,
  },
  [ReportType.Aetc]: {
    [ReportState.Submitted]: AetcTabIndex.RESULTS,
    [ReportState.Draft]: AetcTabIndex.GENERAL,
  },
} as const
