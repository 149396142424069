import { useCallback, useMemo, useState } from 'react'
import { type EChartOption, init } from 'echarts'

import { Performance } from '../../../api-models'
import { formatValue, tooltip } from '../../../utils'
import {
  greenTheme,
  grey,
  maerskBlueTheme,
  maritimeBlueTheme,
  orangeTheme,
  purpleTheme,
} from '../../../theme'
import { Chart, FuelLineType, FuelType } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  UNITS,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'
import moment from 'moment'
import {
  convertToFuelLineUnit,
  FuelConsumptionSeriesModel,
  getDecimalsFromUnit,
  mapSeriesZero,
} from '../../fuel-consumption'
import { positionTooltip } from '../../../chart-lib'

const getConsumptionSeries = (
  fuelConsumptionData: Performance.FuelConsumption.FuelTypeMonitorResponse,
  keepOriginalValue: boolean,
): FuelConsumptionSeriesModel => {
  const acc = {
    HS: [],
    VLS: [],
    MDO: [],
    ULS: [],
    MM: [],
    meSplitLine: {
      HS: [],
      VLS: [],
      MDO: [],
      ULS: [],
      MM: [],
    },
  } as FuelConsumptionSeriesModel
  if (!fuelConsumptionData) return acc
  fuelConsumptionData?.consumptionSeries.reduce(
    (
      acc: FuelConsumptionSeriesModel,
      consumption: Performance.FuelConsumption.FuelTypeConsumptionSeries,
    ) => {
      return consumption.fuelTypes.reduce((acc, consPerFuelType) => {
        let consumption: number | null = null

        // This is being used to keep the original 0 value from the api
        // as the tool tip needs to show either a - or 0 depending on if
        // there is actual data (0) or an error (-)

        if (keepOriginalValue) {
          consumption =
            consPerFuelType.consumption! >= 0
              ? consPerFuelType.consumption
              : null
        } else {
          consumption = consPerFuelType.consumption
            ? consPerFuelType.consumption
            : null
        }

        if (consPerFuelType.fuelType === FuelType.HS) {
          acc.HS.push(consumption)
        }
        if (consPerFuelType.fuelType === FuelType.MDO) {
          acc.MDO.push(consumption)
        }
        if (consPerFuelType.fuelType === FuelType.VLS) {
          acc.VLS.push(consumption)
        }
        if (consPerFuelType.fuelType === FuelType.ULS) {
          acc.ULS.push(consumption)
        }
        if (consPerFuelType.fuelType === FuelType.MM) {
          acc.MM.push(consumption)
        }
        return acc
      }, acc)
    },
    acc,
  )
  return acc
}

type Props = {
  id: string
  timestamps: number[]
  queryPeriod: Performance.Common.Period
  fuelConsumptionData: Performance.FuelConsumption.FuelTypeMonitorResponse
}

export const FuelConsumptionOverviewChart = ({
  id,
  timestamps,
  queryPeriod,
  fuelConsumptionData,
}: Props) => {
  const tooltipConsumptionSeries: FuelConsumptionSeriesModel =
    useMemo((): FuelConsumptionSeriesModel => {
      return getConsumptionSeries(fuelConsumptionData, true)
    }, [fuelConsumptionData])

  const graphConsumptionSeries: FuelConsumptionSeriesModel =
    useMemo((): FuelConsumptionSeriesModel => {
      return getConsumptionSeries(fuelConsumptionData, false)
    }, [fuelConsumptionData])

  const getOptions = useCallback((): EChartOption | any => {
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: queryPeriod.from,
        max: queryPeriod.to,
      },
      yAxis: [
        {
          id: 'consumption',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
          position: 'left',
        },
      ],
      grid: GRID,
      toolbox: TOOLBOX_TIMELINE,
      dataZoom: [
        {
          type: 'slider',
          zoomOnMouseWheel: false,
          show: false,
        },
        DATA_ZOOM_TIMELINE,
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        transitionDuration: 0,
        position: positionTooltip,
        formatter: (params: any) => {
          // dataIndex relates to the actual index in the original arrays
          let dataIndex = params[0].dataIndex
          let labels: Array<{
            [key: string]: string | number
          }> = []
          const consumption = {
            HS:
              tooltipConsumptionSeries.HS &&
              tooltipConsumptionSeries.HS[dataIndex],
            VLS:
              tooltipConsumptionSeries.VLS &&
              tooltipConsumptionSeries.VLS[dataIndex],
            ULS:
              tooltipConsumptionSeries.ULS &&
              tooltipConsumptionSeries.ULS[dataIndex],
            MDO:
              tooltipConsumptionSeries.MDO &&
              tooltipConsumptionSeries.MDO[dataIndex],
            MM:
              tooltipConsumptionSeries.MM &&
              tooltipConsumptionSeries.MM[dataIndex],
          }
          labels.push(
            {
              label: `HS`,
              value: formatValue(
                convertToFuelLineUnit(consumption.HS, FuelLineType.MEMain),
                getDecimalsFromUnit(
                  UNITS.MT_PER_HOUR,
                  true,
                  FuelLineType.MEMain,
                ),
              ),
              unit: UNITS.MT_PER_HOUR,
            },
            {
              label: `VLS`,
              value: formatValue(
                convertToFuelLineUnit(consumption.VLS, FuelLineType.MEMain),
                getDecimalsFromUnit(
                  UNITS.MT_PER_HOUR,
                  true,
                  FuelLineType.MEMain,
                ),
              ),
              unit: UNITS.MT_PER_HOUR,
            },
            {
              label: `ULS`,
              value: formatValue(
                convertToFuelLineUnit(consumption.ULS, FuelLineType.MEMain),
                getDecimalsFromUnit(
                  UNITS.MT_PER_HOUR,
                  true,
                  FuelLineType.MEMain,
                ),
              ),
              unit: UNITS.MT_PER_HOUR,
            },
            {
              label: `MDO`,
              value: formatValue(
                convertToFuelLineUnit(consumption.MDO, FuelLineType.MEMain),
                getDecimalsFromUnit(
                  UNITS.MT_PER_HOUR,
                  true,
                  FuelLineType.MEMain,
                ),
              ),
              unit: UNITS.MT_PER_HOUR,
            },
            {
              label: `MM`,
              value: formatValue(
                convertToFuelLineUnit(consumption.MM, FuelLineType.MEMain),
                getDecimalsFromUnit(
                  UNITS.MT_PER_HOUR,
                  true,
                  FuelLineType.MEMain,
                ),
              ),
              unit: UNITS.MT_PER_HOUR,
            },
          )
          return tooltip(timestamps[dataIndex], labels)
        },
      },
      dataset: {
        source: {
          timestamps: timestamps,
          HS: mapSeriesZero(graphConsumptionSeries.HS),
          VLS: mapSeriesZero(graphConsumptionSeries.VLS),
          MDO: mapSeriesZero(graphConsumptionSeries.MDO),
          ULS: mapSeriesZero(graphConsumptionSeries.ULS),
          MM: mapSeriesZero(graphConsumptionSeries.MM),
        },
      },
      series: [
        {
          animation: false,
          xAxisIndex: 0,
          yAxisIndex: 0,
          name: 'HS',
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: maritimeBlueTheme.bg,
          },
          areaStyle: {
            color: maritimeBlueTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'HS'],
        },
        {
          animation: false,
          name: 'VLS',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: purpleTheme.bg,
          },
          areaStyle: {
            color: purpleTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'VLS'],
        },
        {
          animation: false,
          name: 'MDO',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: orangeTheme.bg,
          },
          areaStyle: {
            color: orangeTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'MDO'],
        },
        {
          animation: false,
          name: 'ULS',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: maerskBlueTheme.bg,
          },
          areaStyle: {
            color: maerskBlueTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'ULS'],
        },
        {
          animation: false,
          name: 'MM',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: greenTheme.bg,
          },
          areaStyle: {
            color: greenTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'MM'],
        },
      ],
    }
  }, [
    queryPeriod,
    timestamps,
    graphConsumptionSeries,
    tooltipConsumptionSeries,
  ])

  const [chart, setChart]: [Chart | undefined, Function] = useState()

  const refCallback = useCallback(
    (chartNode) => {
      if (chartNode !== null) {
        const chart = init(chartNode)
        chart.setOption(getOptions())
        chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
        chart.group = 'fuel-consumption-group'
        chart.on('dataZoom', () => {})
        setChart(chart)
      }
    },
    [getOptions],
  )

  const zoomOut = () => {
    const { from, to } = queryPeriod
    chart?.dispatchAction({
      type: 'dataZoom',
      batch: [
        {
          startValue: moment(from).valueOf(),
          endValue: moment(to).valueOf(),
        },
      ],
    })
  }

  return <div onDoubleClick={() => zoomOut()} id={id} ref={refCallback} />
}
