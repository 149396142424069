import { type Table } from '@tanstack/react-table'

import type { EngineTestListModel } from '../EngineTestPage.types'

export const getEquipmentOptions = (table: Table<EngineTestListModel>) => {
  return Array.from(
    new Set(table.getCoreRowModel().rows.map((row) => row.original.equipment)),
  ).map((equipment) => ({
    label: equipment,
    value: equipment,
  }))
}
