import { FormikErrors, FormikTouched, FormikValues, getIn } from 'formik'
import { sortBy } from 'lodash'
import moment, { Moment } from 'moment'
import { EventType } from '../../../api-models/hdc/events'
import {
  ApiReportState,
  ApiReportType,
  CylinderLubeOil,
  DataPoint,
  ExternalSource,
  OilCode,
  TimeDataPoint,
  UpdateBridgeReportRequest,
  UpdateCylinderLubeOil,
  UpdateEngineRoomReportRequest,
  TWarning,
  IReport,
} from '../../../api-models/hdc/report'
import { HDC_DATA_AVAILABILITY } from '../../../utils/constants/domain-constants'
import { flattenObject } from '../../../utils/format-utils'
import { formatValue, roundNumber } from '../../../utils/math-utils'
import {
  EDIT_WINDOW_PERIOD_MINUTES,
  FORM_STEP_TO_FIELDS,
  GSIS_CANAL_CODES,
} from '../constants'
import { HDCReducerState } from '../context/reducer'
import {
  BridgeFormStep,
  EngineRoomFormStep,
  ESubReport,
  FormEquipment,
  FormMainEngine,
  HDCFormType,
  HDCHoursMinutes,
  HDCReportFormValues,
} from '../types'

/**
 * prettyDuration
 * @description Returns a string with format `XXh YYm`
 * @param {Moment} from The starting timestamp where lapsed `to` should be calculated from
 * @param {Moment} to The ending timestamp that should be used to calculate lapsed value
 */
export const prettyDuration = (from: Moment, to: Moment): string => {
  const fromUtc = moment(from).utc()
  const toUtc = moment(to).utc()

  const duration = moment.duration(toUtc.diff(fromUtc))
  const days = parseInt(`${duration.asDays()}`, 10)
  const hours = parseInt(`${duration.asHours()}`, 10)
  const minutes = parseInt(`${duration.asMinutes() % 60}`, 10)

  if (days > 0) {
    return `${days}d ${hours % 24}h ${minutes}m`
  }

  return `${hours}h ${minutes}m`
}

const formatSelectedPeriod = (data: string): any => {
  const formatedDateTime = { dateValue: '', hoursValue: 0, minutesValue: 0 }
  const formatedDate = moment.utc(data)
  formatedDateTime.dateValue = formatedDate.format('DD/MM/YYYY')
  formatedDateTime.hoursValue = parseInt(formatedDate.format('HH'), 10)
  formatedDateTime.minutesValue = parseInt(formatedDate.format('mm'), 10)
  return formatedDateTime
}

/**
 * distanceAndSpeedObservations
 * @param dataPoint datapoint definition as given from backend for wether for observed distance and speed.
 * @param mainEngines Main engines data to determin the running ours that will be used for the calculation.
 */
export const distanceAndSpeedObservations = (
  dataPoint: DataPoint<number> | undefined,
  mainEngines: FormMainEngine[],
): string => {
  const mainEngine = mainEngines.reduce((prev, curr) => {
    if (
      curr.runTime.hours.value === null ||
      curr.runTime.minutes.value === null
    ) {
      return prev
    }

    if (
      prev.runTime.hours.value === null ||
      prev.runTime.minutes.value === null
    ) {
      return curr
    }

    const prevRunTime =
      prev.runTime.hours.value * 60 + (prev.runTime.minutes.value % 60)

    const currRunTime =
      curr.runTime.hours.value * 60 + (curr.runTime.minutes.value % 60)

    return prevRunTime > currRunTime ? prev : curr
  }, mainEngines[0])

  // Due to low data availability from papi this could be null from
  // the backend, so until a user fills out the Engine Room report
  // this field should be represented as null value.
  if (
    mainEngine.runTime.hours.value === null ||
    mainEngine.runTime.minutes.value === null ||
    dataPoint === undefined ||
    dataPoint?.value === null
  ) {
    return '-'
  }

  const calculatedRuntimeHours =
    mainEngine.runTime.hours.value + mainEngine.runTime.minutes.value / 60

  // Dividing the engine runtime value with 60 to turn minutes to hours
  return calculatedRuntimeHours === 0
    ? '0'
    : (dataPoint.value / calculatedRuntimeHours).toFixed(2)
}

export const getOriginalValue = (
  values: any,
  fieldName: string,
): number | string | Moment | null => {
  const externalSource: ExternalSource<number | string> = getIn(
    values,
    fieldName.replace('.value', '.externalSource'),
  )
  if (
    !externalSource ||
    (externalSource.value &&
      externalSource.dataAvailability < HDC_DATA_AVAILABILITY)
  ) {
    return null
  }
  return externalSource.value
}

export const transformHoursMinutesToDataPoint = (
  datapoint: HDCHoursMinutes,
): number => {
  const totalMinutes =
    (datapoint.hours.value || 0) * 60 + (datapoint.minutes.value || 0)
  return totalMinutes
}

/**
 * transformCylinderLubeOil
 * @description used to map the cylinder lube oil formik values to a request cylinder lube oil
 * @param {CylinderLubeOil} clo: Object that represents the cylinder lube oil consumption.
 */
const transformCylinderLubeOil = (
  clo: CylinderLubeOil,
): UpdateCylinderLubeOil => ({
  number: clo.number,
  consumptionLiters: clo.consumptionLiters.value,
  density: clo.density.value,
  avgTemperature: clo.avgTemperature.value,
  oilCode: clo.oilCode.value,
  totalBaseNumber: clo.totalBaseNumber.value,
})

/**
 * transformDataPointToHoursMinutes
 * @description transformDataPointToHoursMinutes, transform a Datapoint<number> into a entity that can collect hours and minutes.
 * @param datapoint databpoint of lapsed amount of minutes
 */
export const transformDataPointToHoursMinutes = (
  datapoint: DataPoint<number>,
): HDCHoursMinutes => {
  const initialHours =
    datapoint.value !== null ? Math.floor(datapoint.value / 60) : null
  const initialMinutes = datapoint.value !== null ? datapoint.value % 60 : null
  return {
    hours: {
      ...datapoint,
      value: initialHours,
      externalSource:
        datapoint.externalSource !== null
          ? {
              ...datapoint.externalSource,
              value: Math.floor(datapoint.externalSource.value / 60),
            }
          : null,
    },
    minutes: {
      ...datapoint,
      value: initialMinutes,
      externalSource:
        datapoint.externalSource !== null
          ? {
              ...datapoint.externalSource,
              value: datapoint.externalSource.value % 60,
            }
          : null,
    },
  }
}

/**
 * transformHoursMinutesToTotalMinutes
 * @description function that is use to calculate total time spend (in minutes), as a single unit rather than hours and minutes. Works as a counter to `transformDataPointToHoursMinutes`
 * @param hours number that represents hours within a time frame
 * @param minutes number that represents minutes within a time frame
 */
export const transformHoursMinutesToTotalMinutes = (
  time: HDCHoursMinutes,
): number => {
  if (!time.hours || !time.minutes) {
    throw new Error(
      'Could not transform hours minnutes to total, either minutes or hours are missing.',
    )
  }

  const hours = time.hours.value || 0
  const minutes = time.minutes.value || 0

  return minutes + hours * 60
}

export const timeDataPointToMomentDataPoint = (
  datapoint: TimeDataPoint<string>,
): DataPoint<Moment> => {
  const { value, externalSource } = datapoint
  return {
    ...datapoint,
    value: value ? moment(value).utc() : null,
    externalSource: externalSource
      ? {
          ...externalSource,
          value: moment.utc(externalSource.value),
        }
      : null,
  }
}

export const transformFormikReportToUpdateEngineRoomReportRequest = (
  values: HDCReportFormValues,
): UpdateEngineRoomReportRequest => {
  const { engineRoom } = values
  return {
    engineRoom: {
      shorePowerPeriods: engineRoom.shorePowerPeriods
        ? {
            ...engineRoom.shorePowerPeriods,
            numberOfPeriods: engineRoom.shorePowerPeriods.numberOfPeriods,
            periods:
              engineRoom.shorePowerPeriods.periods &&
              engineRoom.shorePowerPeriods.periods.map((period) => ({
                ...period,
                periodStart: {
                  date: period.periodStart.date,
                  energy: period.periodStart.energy,
                },
                periodEnd: {
                  date: period.periodEnd.date,
                  energy: period.periodEnd.energy,
                },
              })),
          }
        : null,
      mainEngines: engineRoom.mainEngines.map((engine: FormMainEngine) => ({
        averagePower: engine.averagePower.value || 0,
        averageShaftRpm: engine.averageShaftRpm.value || 0,
        number: engine.number,
        tcco:
          typeof engine.tcco?.value === 'boolean' ? engine.tcco?.value : null,
        runTime: transformHoursMinutesToDataPoint(engine.runTime) || 0,
      })),
      auxEngines: engineRoom.auxEngines.map((auxEng: FormEquipment) => ({
        energy: auxEng.energy.value || 0,
        number: auxEng.number,
        runTime: transformHoursMinutesToDataPoint(auxEng.runTime),
      })),
      wasteHeatRecovery: engineRoom.wasteHeatRecovery
        ? {
            energy: engineRoom.wasteHeatRecovery.energy.value || 0,
            runTime:
              transformHoursMinutesToDataPoint(
                engineRoom.wasteHeatRecovery?.runTime,
              ) || 0,
          }
        : null,
      shaftGenerators: engineRoom.shaftGenerators.map(
        (shaftGenerator: FormEquipment) => ({
          energy: shaftGenerator.energy.value || 0,
          number: shaftGenerator.number,
          runTime: transformHoursMinutesToDataPoint(shaftGenerator.runTime),
        }),
      ),
      shaftMotors: engineRoom.shaftMotors.map((shaftMotor: FormEquipment) => ({
        energy: shaftMotor.energy.value || 0,
        number: shaftMotor.number,
        runTime: transformHoursMinutesToDataPoint(shaftMotor.runTime),
      })),
      auxBlowers: engineRoom.auxBlowers.map((auxBlower: FormEquipment) => ({
        energy: auxBlower.energy.value || 0,
        number: auxBlower.number,
        runTime: transformHoursMinutesToDataPoint(auxBlower.runTime),
      })),
      scrubberSystem: engineRoom.scrubberSystem
        ? {
            energy: engineRoom.scrubberSystem.energy.value,
            runTime: transformHoursMinutesToDataPoint(
              engineRoom.scrubberSystem.runTime,
            ),
          }
        : null,
      cylindersLubeOil: engineRoom.cylindersLubeOil.map(
        transformCylinderLubeOil,
      ),
      reeferEnergy: engineRoom.reeferEnergy.value || 0,
      numberOfReefers: engineRoom.numberOfReefers.value || 0,
      comment: engineRoom.comment,
    },
  }
}

export const transformFormikReportToUpdateBridgeReportRequest = (
  values: HDCReportFormValues,
  HDCState: HDCReducerState,
): UpdateBridgeReportRequest => {
  const { bridge } = values

  return {
    bridge: {
      departureTerminal:
        bridge.departureTerminal?.code.value &&
        bridge.departureTerminal?.name.value
          ? {
              code: bridge.departureTerminal.code.value,
              name: bridge.departureTerminal.name.value,
            }
          : null,
      alternativeDepartureTerminal:
        bridge.alternativeDepartureTerminal?.code &&
        bridge.alternativeDepartureTerminal?.name
          ? {
              code: bridge.alternativeDepartureTerminal?.code,
              name: bridge.alternativeDepartureTerminal?.name,
            }
          : null,
      arrivalTerminal: isVoyageEditable(HDCState.report!, HDCState.reports!)
        ? bridge.arrivalTerminal?.code.value &&
          bridge.arrivalTerminal?.name.value
          ? {
              code: bridge.arrivalTerminal.code.value,
              name: bridge.arrivalTerminal.name.value,
            }
          : null
        : null,
      alternativeArrivalTerminal: isVoyageEditable(
        HDCState.report!,
        HDCState.reports!,
      )
        ? bridge.alternativeArrivalTerminal?.code &&
          bridge.alternativeArrivalTerminal?.name
          ? {
              code: bridge.alternativeArrivalTerminal.code,
              name: bridge.alternativeArrivalTerminal.name,
            }
          : null
        : null,
      eta: isVoyageEditable(HDCState.report!, HDCState.reports!)
        ? bridge.eta.value?.toISOString() || null
        : null,
      draughtFore: bridge.draughtFore.value || 0,
      draughtAft: bridge.draughtAft.value || 0,
      ballastWater: bridge.ballastWater.value || 0,
      distanceLogged: bridge.distanceLogged.value || 0,
      distanceObserved: bridge.distanceObserved.value || 0,
      remainingDistanceToPilotStation:
        isVoyageEditable(HDCState.report!, HDCState.reports!) ||
        bridge.remainingDistanceToPilotStation.isOverridden
          ? bridge.remainingDistanceToPilotStation.value || 0
          : null,
      waterTemperature: bridge.waterTemperature.value || 0,
      waterDepth: bridge.waterDepth.value || 0,
      comment: bridge.comment,
    },
  }
}
/**
 * transformHdcReportToFormikReport
 * @description Transform a API HDC Report to expected Formik values, pactially making Timestamps from strings to Moments
 * @param {IReport} report HDC Report
 */
export const transformHdcReportToFormikReport = (
  report: IReport,
): HDCReportFormValues => {
  const { bridge } = report
  return {
    ...report,
    periodStart: moment.utc(report.periodStart),
    periodEnd: moment.utc(report.periodEnd),
    bridge: {
      ...bridge,
      eta: timeDataPointToMomentDataPoint(bridge.eta),
      etaOriginal: timeDataPointToMomentDataPoint(bridge.etaOriginal),
      alternativeArrivalTerminal: bridge.alternativeArrivalTerminal
        ? {
            code: bridge.alternativeArrivalTerminal.code,
            name: bridge.alternativeArrivalTerminal.name,
          }
        : null,
      alternativeDepartureTerminal: bridge.alternativeDepartureTerminal
        ? {
            code: bridge.alternativeDepartureTerminal.code,
            name: bridge.alternativeDepartureTerminal.name,
          }
        : null,
    },
    engineRoom: {
      ...report.engineRoom,
      mainEngines: report.engineRoom.mainEngines.map((engine) => ({
        ...engine,
        runTime: transformDataPointToHoursMinutes(engine.runTime),
      })),
      auxEngines: report.engineRoom.auxEngines.map((engine) => ({
        ...engine,
        runTime: transformDataPointToHoursMinutes(engine.runTime),
      })),
      shorePowerPeriods:
        report.engineRoom.shorePowerPeriods === null
          ? null
          : {
              ...report.engineRoom.shorePowerPeriods,
              periods: report.engineRoom.shorePowerPeriods.periods
                ? report.engineRoom.shorePowerPeriods.periods.map((period) => ({
                    ...period,
                    periodStart: {
                      date: period.periodStart.date,
                      energy: period.periodStart.energy,
                      dateFormat: formatSelectedPeriod(period.periodStart.date),
                    },
                    periodEnd: {
                      date: period.periodEnd.date,
                      energy: period.periodEnd.energy,
                      dateFormat: formatSelectedPeriod(period.periodEnd.date),
                    },
                  }))
                : [],
            },
      shaftMotors: report.engineRoom.shaftMotors.map((motor) => ({
        ...motor,
        runTime: transformDataPointToHoursMinutes(motor.runTime),
      })),
      shaftGenerators: report.engineRoom.shaftGenerators.map((generator) => ({
        ...generator,
        runTime: transformDataPointToHoursMinutes(generator.runTime),
      })),
      auxBlowers: report.engineRoom.auxBlowers.map((blower) => ({
        ...blower,
        runTime: transformDataPointToHoursMinutes(blower.runTime),
      })),
      cylindersLubeOil: report.engineRoom.cylindersLubeOil.map((clo) => ({
        ...clo,
        oilCode:
          clo.oilCode.value !== null
            ? clo.oilCode
            : {
                ...clo.oilCode,
                value: OilCode.NORMAL,
              },
      })),
      wasteHeatRecovery:
        report.engineRoom.wasteHeatRecovery === null
          ? null
          : {
              ...report.engineRoom.wasteHeatRecovery,
              number: 0,
              runTime: transformDataPointToHoursMinutes(
                report.engineRoom.wasteHeatRecovery.runTime,
              ),
            },
      scrubberSystem:
        report.engineRoom.scrubberSystem === null
          ? null
          : {
              ...report.engineRoom.scrubberSystem,
              runTime: transformDataPointToHoursMinutes(
                report.engineRoom.scrubberSystem.runTime,
              ),
            },
    },
  }
}

/**
 * convertMinutes
 * @description Converts minutes into hours and minutes
 * @param minutes Total number of minutes
 */
export const convertMinutes = (minutes: number | null | undefined) =>
  minutes == null
    ? { hours: '-', minutes: '-' }
    : { hours: Math.floor(minutes / 60), minutes: minutes % 60 }

export const flattenFormikErrors = (errorMap: {
  [key: string]: any
}): string[] => {
  const flatErrors = flattenObject(errorMap)
  return [
    ...new Set(Object.values(flatErrors).filter((value) => !!value)),
  ] as string[]
}

export const calculateMeanDraught = (
  draughtAft: DataPoint<number>,
  draughtFore: DataPoint<number>,
): string | number => {
  if (!draughtAft.value || !draughtFore.value) {
    return '-'
  }
  return formatValue((draughtAft.value + draughtFore.value) / 2, 2, '-')
}

/**
 * getCLOConsumptionInKg
 * @description converts CLO consumption from liters to kilograms
 * @param volume actual CTO consumption [liters].
 * @param temperature average temperature [degree C].
 * @param density density at 15 degree C [kg/m³].
 */
export const getCLOConsumptionInKg = (
  volume: number = 0,
  temperature: number = 0,
  density: number = 0,
): number | '-' => {
  if (!volume || !temperature || !density) {
    return '-'
  }
  const newTemperature = temperature * 10 - 150
  const newDensity = density * 10
  let alpha = 25927.686 / newDensity / newDensity + 3.4128 / newDensity
  alpha =
    (newDensity / 10) *
    Math.exp(
      ((-alpha * newTemperature) / 10) * (1 + 0.08 * alpha * newTemperature),
    )
  return Math.round((volume / 1000) * alpha)
}

/**
 * getCLOFeedRate
 * @description calculates CLO feed rate [g/kWh].
 * @param consumption CLO consumption [kg].
 * @param power ME power [kW].
 * @param runTime ME running time [h].
 */
export const getCLOFeedRate = (
  consumption: number | '-',
  power: number = 0,
  runTime: HDCHoursMinutes,
): number | '-' => {
  if (typeof consumption !== 'number' || !consumption || !power || !runTime)
    return '-'

  const runningHours =
    (runTime.hours.value || 0) + (runTime.minutes.value || 0) / 60

  return roundNumber((consumption * 1000) / (power * runningHours), 2)
}

/**
 * getOriginalValueNote
 * @description Returns note with original value, if exists. Used in summary as an indication that value has been modified.
 * @param dataPoint Object that holds original value from the external source.
 */
export const getOriginalValueNote = (
  dataPoint:
    | DataPoint<boolean | Moment | number | string>
    | HDCHoursMinutes
    | undefined,
) => {
  const getOriginalValue = (
    dataPoint: DataPoint<boolean | Moment | number | string>,
  ): boolean | Moment | number | string | null => {
    const externalSource = dataPoint.externalSource
    if (
      !externalSource ||
      (externalSource.value &&
        externalSource.dataAvailability < HDC_DATA_AVAILABILITY)
    ) {
      return null
    }
    return externalSource.value
  }

  const renderOriginalValue = (
    originalValue: boolean | Moment | number | string | null,
  ) => {
    if (moment.isMoment(originalValue)) {
      return originalValue.format('DD MMM YYYY HH:mm')
    }
    if (typeof originalValue === 'boolean') {
      return originalValue ? 'Yes' : 'No'
    }
    return originalValue || originalValue === 0 ? originalValue : '-'
  }

  const shouldShowSensorValue = (
    value: boolean | Moment | number | string | null,
    originalValue: boolean | Moment | number | string | null,
  ) => {
    if (!originalValue && originalValue !== 0) {
      return false
    }
    return originalValue !== value
  }

  if (!dataPoint) {
    return null
  }

  if ('hours' in dataPoint) {
    const originalHours = getOriginalValue(dataPoint.hours)
    const originalMinutes = getOriginalValue(dataPoint.minutes)
    return shouldShowSensorValue(dataPoint.hours.value, originalHours) ||
      shouldShowSensorValue(dataPoint.minutes.value, originalMinutes)
      ? `Original value was: ${originalHours}h ${originalMinutes}m`
      : null
  }

  const originalValue = getOriginalValue(dataPoint)
  return shouldShowSensorValue(dataPoint.value, originalValue)
    ? `Original value was: ${renderOriginalValue(originalValue)}`
    : null
}

export const equipmentName = (
  equipmentCount: number,
  equipment: FormEquipment,
): string => {
  if (equipmentCount < 2) {
    return ''
  }

  if (equipment.number === 1) {
    return 'Port'
  } else {
    return 'Stbd'
  }
}

/**
 * mainEngineName
 * @description Small helper util to label engine names.

 * @param {number} enginesCount Count of main engines onboard the vessel
 * @param {number} engineNumber Number representing what engine is requested name for
 */
export const mainEngineName = (enginesCount: number, engineNumber: number) => {
  if (enginesCount === 1) {
    return 'Main engine'
  }

  if (engineNumber === 1) {
    return 'Main engine - Port'
  } else {
    return 'Main engine - Stbd'
  }
}

/**
 * isEventGroup
 * @description Determines wether an event type is a group that contains selectable event types.
 *
 * @param {EventType} eventType The event type object
 */
export const isEventGroup = (eventType: EventType): boolean =>
  !eventType.hasOwnProperty('id') && eventType.hasOwnProperty('eventTypes')

/**
 * getLatestReport
 * @description finds the latest HDC Report based on report `periodStart`
 * @param {IReport[]|null|undefined} reports List of Reports.
 */
export const getLatestReport = (reports?: IReport[] | null): IReport | null => {
  if (!reports || !reports.length) {
    return null
  }

  return reports.sort((a, b) =>
    new Date(a.periodStart) > new Date(b.periodStart) ? -1 : 1,
  )[0]
}

/**
 * isReportEditable
 * @description Checks if report is allowed to be edited.

 * @param {IReport} report Report to be checked.
 * @param {IReport[]} reports List of all existing reports.
 */
export const isReportEditable = (report: IReport, reports: IReport[]) => {
  const latestSubmittedReport = sortBy(reports, (r) => moment(r.periodStart))
    .reverse()
    .find((report) => isReportSubmitted(report))

  if (!latestSubmittedReport) {
    return true
  }

  const windowPeriodMinutes = moment(latestSubmittedReport.periodEnd).diff(
    moment(report.periodEnd),
    'minutes',
  )
  return windowPeriodMinutes <= EDIT_WINDOW_PERIOD_MINUTES
}

/**
 * isReportSubmitted
 * @description Checks if report has been submitted to shore.
 * In that case, both bridge and engine room subreports are either in `submit` or `edit` state.

 * @param {HDCReportFormValues | IReport} report Report to be checked.
 */
export const isReportSubmitted = (report: HDCReportFormValues | IReport) => {
  return (
    (report.bridge.state === ApiReportState.SUBMITTED ||
      report.bridge.state === ApiReportState.EDIT) &&
    (report.engineRoom.state === ApiReportState.SUBMITTED ||
      report.engineRoom.state === ApiReportState.EDIT)
  )
}

/**
 * isVoyageEditable
 * @description Checks if ETA, Destination and Remaining distance to PS should be allowed to edit
 *
 * Fields are editable for Departure, GSIS Canal, Canal coming straight after Alongside or first ever report if it has not been submitted to shore
 *
 * @param {HDCReportFormValues} report Current report
 * @param {IReport[]} reports Array of all reports
 */
export const isVoyageEditable = (
  report: HDCReportFormValues,
  reports: IReport[],
): boolean => {
  const previousReport = getPreviousReport(report.id, reports)
  return (
    !isReportSubmitted(report) &&
    (report.reportType === ApiReportType.DEPARTURE ||
      isGSISCanalReport(report, reports) ||
      (report.reportType === ApiReportType.CANAL &&
        previousReport?.reportType === ApiReportType.ALONGSIDE) ||
      !previousReport)
  )
}

/**
 * isGSISCanalReport
 * @description Checks if report is GSIS-canal
 *
 * @param {HDCReportFormValues} report Current report
 * @param {IReport[]} reports Array of all reports
 */
export const isGSISCanalReport = (
  report: HDCReportFormValues,
  reports: IReport[],
): boolean => {
  if (report.reportType !== ApiReportType.CANAL) {
    return false
  }
  const previousReport = getPreviousReport(report.id, reports)
  const arrivalTerminal =
    previousReport?.bridge.arrivalTerminal?.code.value ||
    previousReport?.bridge.alternativeArrivalTerminal?.code
  return (
    !!arrivalTerminal &&
    GSIS_CANAL_CODES.some((code) => arrivalTerminal.includes(code))
  )
}

/**
 * getFormikTouchedFieldsErrors
 * @description Returns formik errors only for touched fields.

 * @param {FormikErrors<FormikValues>} errors Formik errors object.
 * @param {FormikTouched<FormikValues>} touched Formik touched object.
 */
export const getFormikTouchedFieldsErrors = (
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>,
): FormikErrors<FormikValues> =>
  Object.keys(errors)
    .filter((fieldName) => touched[fieldName])
    .reduce((result, fieldName) => {
      result[fieldName] = errors[fieldName]
      return result
    }, {})

/**
 * getFilteredByStepErrors
 * @description Returns formik errors only for specified form step.

 * @param {FormikErrors<FormikValues>} errors Formik errors.
 * @param {HDCFormType} formType Type of the form.
 * @param {BridgeFormStep | EngineRoomFormStep} step Step to filter by.
 */
export const getFilteredByStepErrors = (
  errors: FormikErrors<FormikValues>,
  formType: HDCFormType,
  step: BridgeFormStep | EngineRoomFormStep,
): FormikErrors<FormikValues> => {
  const subReport =
    formType === HDCFormType.BRIDGE_FORM
      ? ESubReport.Bridge
      : ESubReport.EngineRoom
  return Object.keys((errors && errors[subReport]) || {})
    .filter(
      (field) =>
        FORM_STEP_TO_FIELDS[formType][step] &&
        FORM_STEP_TO_FIELDS[formType][step].includes(field),
    )
    .reduce(
      (result, field) => {
        result[subReport][field] = errors[subReport]![field]
        return result
      },
      { [subReport]: {} },
    )
}

/**
 * getFilteredByStepWarnings
 * @description Returns flattened warnings only for specified form step.

 * @param {Warning[]} warnings Warnings object.
 * @param {HDCFormType} formType Type of the form.
 * @param {BridgeFormStep | EngineRoomFormStep} step Step to filter by.
 */
export const getFilteredByStepWarnings = (
  warnings: TWarning[],
  formType: HDCFormType,
  step: BridgeFormStep | EngineRoomFormStep,
): { [path: string]: string } => {
  const targetSubReport =
    formType === HDCFormType.BRIDGE_FORM
      ? ESubReport.Bridge
      : ESubReport.EngineRoom
  return warnings.reduce((result, warning) => {
    warning.paths
      .filter((path) => {
        const [subReport, field] = path.split('.')
        if (
          [BridgeFormStep.SUMMARY, EngineRoomFormStep.SUMMARY].includes(step)
        ) {
          return subReport === targetSubReport
        }
        return (
          subReport === targetSubReport &&
          FORM_STEP_TO_FIELDS[formType][step] &&
          FORM_STEP_TO_FIELDS[formType][step].includes(field)
        )
      })
      .forEach((path) => {
        result[path] = warning.message
      })
    return result
  }, {})
}

/**
 * getCalculatedRDPS
 * @description Returns calculated remaining distance to pilot station
 *
 * Backend sets calculated value on sub-report submit.
 * Frontend visually substract observed distance from RDPS of previous report to show users the final value.
 * See when calculation should be done in shouldCalculateRDPS function
 *
 * @param {HDCReportFormValues} report Current report
 * @param {IReport[]} reports Array of all reports
 */
export const getCalculatedRDPS = (
  report: HDCReportFormValues,
  reports: IReport[],
): number | '-' => {
  const previousReport = getPreviousReport(report.id, reports)
  const rdps = previousReport?.bridge.remainingDistanceToPilotStation.value
  const distanceObserved = report.bridge.distanceObserved.value
  if (rdps === null || rdps === undefined) {
    return '-'
  }
  return roundNumber(rdps - (distanceObserved || 0), 2)
}

/**
 * getPreviousReport
 * @description Returns previous report
 *
 * @param {string} id id of current report
 * @param {IReport[]} reports Array of all reports
 */
export const getPreviousReport = (
  id: string,
  reports: IReport[],
): IReport | null => {
  const sortedReports = [...reports].sort((report, nextReport) =>
    new Date(report.periodStart) < new Date(nextReport.periodStart) ? -1 : 1,
  )
  const currentIdx = sortedReports.findIndex(
    (report) => report.id.toLowerCase() === id.toLowerCase(),
  )
  if (currentIdx === -1 || currentIdx === 0) {
    return null
  }
  return sortedReports[currentIdx - 1]
}

/**
 * shouldCalculateRDPS
 * @description Determines if remaining distance to pilot station should be calculated to visually show the user
 * how much they have left of voyage based on observed distance and RDPS from the previous report
 *
 * RDPS should be calculated for Sea or non-GSIS Canal (that is not coming straight after Alongside) reports
 * RDPS should not be calculated if report has any route change event with RDPS or RDPS was adjusted
 * @param {HDCReportFormValues} report Current report
 * @param {IReport[]} reports Array of all reports
 * @param {boolean} isRDPSOverridden Determines if RDPS has been adjusted by the user not throught the route change event
 * @param {boolean} shouldConsiderEvents Determines if route change event should be taken into account
 */
export const shouldCalculateRDPS = (
  report: HDCReportFormValues,
  reports: IReport[],
  isRDPSOverridden: boolean = false,
  shouldConsiderEvents: boolean = true,
) => {
  const previousReport = getPreviousReport(report.id, reports)
  const hasRouteChangeEvent =
    shouldConsiderEvents &&
    (report.bridge.events || [])
      .concat(report.engineRoom.events || [])
      .some((event) => !!event.metadata?.remainingDistanceToPilotStation)
  return (
    !isRDPSOverridden &&
    !!previousReport &&
    !hasRouteChangeEvent &&
    (report.reportType === ApiReportType.SEA ||
      (report.reportType === ApiReportType.CANAL &&
        !isGSISCanalReport(report, reports) &&
        !(previousReport.reportType === ApiReportType.ALONGSIDE)))
  )
}
