export enum ReportState {
  Draft = 'draft',
  Submitted = 'submitted',
  Edit = 'edit',
}

export enum ReportType {
  Metc = 'metc',
  Aetc = 'aetc',
  Mfe = 'mfe',
}

export const ReportTypeDisplayName = {
  [ReportType.Metc]: 'Engine test report',
  [ReportType.Mfe]: 'Malfunction report',
}

export enum MfeStatus {
  Ongoing = 'ongoing',
  Completed = 'completed',
  OutOfService = 'outOfService',
}

export const MetcDataFormat = {
  current: 2,
  physicalCondition: 2,
} as const

export const AetcDataFormat = {
  current: 1,
} as const

export const MfeDataFormat = {
  current: 2,
  staticModel: 2,
} as const
