import { type EquipmentOption } from './CreateEngineTestModal.types'
import {
  MainEngInstanceName,
  StaticModelType,
} from '../../../queries/MasterDataApi/MasterDataApi.consts'
import { ReportType } from '../../../queries/GandalfApi/GandalfApi.consts'

export const getEquipmentOptions = (
  staticModels: Array<
    MasterDataApi.StaticModels.StaticModelWithInstanceNumbers<MasterDataApi.StaticModels.StaticModelData>
  >,
): Array<EquipmentOption> => {
  return staticModels.flatMap((staticModel) => {
    if (staticModel.instanceNos) {
      return staticModel.instanceNos.map((instanceNo) => {
        const option: EquipmentOption = {
          value: {
            staticModelId: staticModel.id,
            instanceNo: instanceNo,
            type: staticModel.type,
          },
          label: '',
        }

        if (staticModel.type === StaticModelType.MainEng) {
          if (staticModel.instanceNos!.length === 1) {
            option.label = staticModel.data.name
          } else {
            option.label =
              MainEngInstanceName[instanceNo] + '  —  ' + staticModel.data.name
          }
        } else {
          // This assumes StaticModelType.AuxEng
          option.label = '#' + instanceNo + '  —  ' + staticModel.data.name
        }

        return option
      })
    }

    return []
  })
}

export const StaticModelTypeToReportType = {
  [StaticModelType.MainEng]: ReportType.Metc,
  [StaticModelType.AuxEng]: ReportType.Aetc,
} as const
