import {
  type CellContext,
  createColumnHelper,
  type FilterFn,
} from '@tanstack/react-table'
import moment from 'moment'
import { McTooltip } from '@maersk-global/mds-react-wrapper'

import { dateFilter } from '../ActivityLogFilters/DateFilter/helpers'
import { entryTypeFilter } from '../ActivityLogFilters/EntryTypeFilter/helpers'
import {
  NotificationState,
  NotificationStateDisplay,
} from '../../../queries/NotificationsApi/NotificationsApi.consts'
import { ActionCellButton } from '../../../components/Table/Table.styles'
import { DismissalReason } from '../../../components/TemNotifications/TemSubmitModal/TemSubmitModal.consts'
import { type TWindowSize } from '../../../theme_new/styled'
import { prettyDuration } from '../../../features/hybrid-data-collector/utils'
import { displayToast } from '../../../utils'

type Notification = NotificationsAPI.TemAdvice

const columnHelper = createColumnHelper<Notification>()

export const getColumns = (
  onDismissAdvice: (notification: NotificationsAPI.TemAdvice) => Promise<void>,
  windowSize: TWindowSize,
) => {
  return [
    columnHelper.display({
      id: 'advice',
      header: 'Advice',
      cell: ({ row }: CellContext<Notification, any>) => (
        <div
          style={{ width: '256px', maxWidth: '256px' }}
          onClick={async () => {
            await navigator.clipboard.writeText(JSON.stringify(row.original))
            void displayToast(
              'success',
              'Advice copied to clipboard',
              row.original.id,
            )
          }}
        >
          {row.original.data.description}
        </div>
      ),
    }),
    columnHelper.accessor('state', {
      cell: ({ row }) => NotificationStateDisplay[row.original.state],
      header: 'Status',
      enableSorting: false,
      filterFn: entryTypeFilter,
    }),
    columnHelper.accessor('created_at', {
      header: 'Start',
      cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY HH:mm'),
      filterFn: dateFilter,
    }),
    {
      id: 'end',
      header: 'End',
      accessorFn: (data: Notification) => {
        const notificationResponse = getLatestNotificationResponse(data)
        if (!notificationResponse) return null
        return moment.utc(notificationResponse.created_at)
      },
      cell: ({ row }: CellContext<Notification, any>) => {
        const notificationResponse = getLatestNotificationResponse(row.original)
        if (!notificationResponse) return '—'
        return moment
          .utc(notificationResponse.created_at)
          .format('DD MMM YYYY HH:mm')
      },
    },
    {
      id: 'dismissalReason',
      header: 'Reason for dismissal',
      accessorFn: (data: Notification) => {
        const notificationResponse = getLatestNotificationResponse(data)
        if (!notificationResponse) return null
        return notificationResponse.data.reason
      },
      cell: ({ row }: CellContext<Notification, any>) => {
        const notificationResponse = getLatestNotificationResponse(row.original)
        if (!notificationResponse) return '—'
        return notificationResponse.data.reason || '—'
      },
      filterFn: reasonFilterFn,
    },
    columnHelper.display({
      id: 'comment',
      header: 'Comment',
      cell: ({ row }: CellContext<Notification, any>) => {
        const notificationResponse = getLatestNotificationResponse(row.original)
        if (!notificationResponse) return '—'
        const comment = notificationResponse.data.comment

        if (!comment) return '—'

        if (comment.length < 20) {
          return comment
        }

        return (
          <McTooltip
            width={400}
            fit={windowSize}
            appearance='inverse'
            position='top-center'
          >
            <span>{`${comment.slice(0, 20)}...`}</span>
            <span slot='content'>{comment}</span>
          </McTooltip>
        )
      },
    }),
    columnHelper.display({
      id: 'action',
      header: 'Action',
      cell: ({ row }: CellContext<Notification, any>) => {
        if (
          row.original.state === NotificationState.Followed ||
          row.original.state === NotificationState.TimedOut
        ) {
          return '—'
        }

        return (
          <ActionCellButton onClick={() => onDismissAdvice(row.original)}>
            {row.original.state === NotificationState.Active
              ? 'Dismiss advice'
              : 'Edit reason'}
          </ActionCellButton>
        )
      },
    }),
    {
      id: 'duration',
      header: 'Advice duration',
      accessorFn: (data: Notification) => {
        const notificationResponse = getLatestNotificationResponse(data)
        const startTime = moment.utc(data.created_at)
        const now = moment.utc()

        if (data.state === NotificationState.Active && now.isBefore(startTime))
          return null
        if (data.state === NotificationState.Active)
          return moment.duration(now.diff(startTime)).asMilliseconds()
        if (!notificationResponse) return null
        const endTime = moment.utc(notificationResponse.created_at)
        return moment.duration(endTime.diff(startTime)).asMilliseconds()
      },
      cell: ({ row }: CellContext<Notification, any>) => {
        const notificationResponse = getLatestNotificationResponse(row.original)
        const startTime = moment.utc(row.original.created_at)
        const now = moment.utc()

        if (
          row.original.state === NotificationState.Active &&
          now.isBefore(startTime)
        )
          return '—'
        if (row.original.state === NotificationState.Active)
          return prettyDuration(startTime, now)
        if (!notificationResponse) return '—'
        const endTime = moment.utc(notificationResponse.created_at)
        return prettyDuration(startTime, endTime)
      },
    },
  ]
}

export const getLatestNotificationResponse = (notification: Notification) => {
  if (notification.notification_responses.length === 0) return undefined

  return notification.notification_responses.sort((a, b) =>
    moment.utc(b.created_at).diff(moment.utc(a.created_at)),
  )[0]
}

const reasonFilterFn: FilterFn<Notification> = (
  row,
  columnId,
  dismissalReasonKey: keyof NotificationsAPI.Tem.DismissalReason,
) => {
  const notificationResponse = getLatestNotificationResponse(row.original)
  if (!notificationResponse) return false

  return (
    notificationResponse.data.reason === DismissalReason[dismissalReasonKey]
  )
}
