import { useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment/moment'

import { PadContent } from '../../../../layout/styles'
import { useVesselAuxEngines } from '../../../../queries/MasterDataApi/MasterDataApi'
import { VesselPageContext } from '../../../../contexts'

const FORMAT = 'DD MMM YYYY, HH:mm'

type Props = {
  report: GandalfApi.AetcReport
}

const Header = ({ report }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const auxEnginesQuery = useVesselAuxEngines(imoNo)

  const end = moment.utc(report.endTimestamp)
  const start = moment.utc(report.startTimestamp)
  const duration = moment.duration(end.diff(start))
  const durationString = `${duration.hours()}h ${duration.minutes()}m`

  const isSmall = auxEnginesQuery?.data?.engines.find(
    (e) => e.number === report.data.equipment.instanceNo,
  )?.isSmall

  return (
    <PadContent>
      <Wrapper>
        <div>
          <div>Equipment</div>
          <span>Aux engine #{report.data.equipment.instanceNo}</span>
        </div>
        <div>
          <div>Engine size</div>
          <span>
            {isSmall === undefined ? '-' : isSmall ? 'Small' : 'Large'}
          </span>
        </div>
        <div>
          <div>Engine name</div>
          <span>{report.data.equipment.name}</span>
        </div>
        <div>
          <div>Start time</div>
          <span>{start.format(FORMAT)}</span>
        </div>
        <div>
          <div>End time</div>
          <span>{end.format(FORMAT)}</span>
        </div>
        <div>
          <div>Duration</div>
          <span>{durationString}</span>
        </div>
      </Wrapper>
    </PadContent>
  )
}

export default Header

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -12px;
  margin-bottom: -16px;
  padding-right: 32px;
  padding-bottom: 24px;

  > div {
    > div {
      font-weight: bold;
    }
  }
`
