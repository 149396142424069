import { useContext } from 'react'
import styled from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { TabStatus } from '../EngineTestReportPage.consts'
import { WindowContext } from '../../../contexts'

type Props = {
  tabStatus: TabStatus
}

const TabPrefix = ({ tabStatus }: Props) => {
  const { windowSize } = useContext(WindowContext)

  if (tabStatus === TabStatus.SUCCESS) {
    return (
      <McIcon
        slot='prefix'
        icon='check-circle'
        size={windowSize === 'large' ? '24' : '20'}
        color='#40AB35'
      />
    )
  }

  if (tabStatus === TabStatus.ERROR) {
    return (
      <McIcon
        slot='prefix'
        icon='exclamation-circle'
        size={windowSize === 'large' ? '24' : '20'}
        color='#B80012'
      />
    )
  }

  return <Circle slot='prefix' />
}

export default TabPrefix

/**
 * As MDS does not provide a simple circle icon, we have to create one ourselves.
 * This circle is modelled after the MDS `check-circle` icon.
 */
const Circle = styled.div`
  border: 1px solid rgb(20, 20, 20);
  border-radius: 50%;
  margin: 2px 2px 2px 2px;
  width: 16px;
  height: 16px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    margin: 2px 2px 4px 2px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    width: 20px;
    height: 20px;
    margin: 2px 2px 2px 2px;
  }
`
