import moment from 'moment'

import { AlertTypeDisplayName } from './PerformanceAlerts.consts'
import { DATE_TIME_FORMAT } from '../formik/InputDateTime/InputDateTime.utils'
import { formatValue } from '../../utils/math-utils'
import { NotificationTypes } from '../../queries/NotificationsApi/NotificationsApi.consts'
import {
  MainEngInstanceName,
  StaticModelType,
  StaticModelTypeNames,
} from '../../queries/MasterDataApi/MasterDataApi.consts'

export const getLatestNotificationResponse = (
  alert: NotificationsAPI.PerformanceAlert,
) => {
  if (alert.notification_responses.length === 0) {
    return null
  }

  return alert.notification_responses.reduce((a, b) =>
    moment(b.created_at).isAfter(a.created_at) ? b : a,
  )
}

export const getLatestMeSfocAlertsPerInstance = (
  alerts: Array<NotificationsAPI.PerformanceAlert>,
): Array<NotificationsAPI.PerformanceAlert> => {
  const result: {
    [instanceNo: number]: NotificationsAPI.PerformanceAlert
  } = alerts.reduce((acc, alert) => {
    if (acc[alert.data.instance]) {
      if (
        moment
          .utc(alert.created_at)
          .isAfter(moment.utc(acc[alert.data.instance].created_at))
      ) {
        acc[alert.data.instance] = alert
      }
    } else {
      acc[alert.data.instance] = alert
    }
    return acc
  }, {})

  return Object.values(result)
}

export const getEquipmentName = (
  notificationType: NotificationsAPI.NotificationType,
  instance?: number,
): string => {
  let equipmentStr = ''
  let instanceStr = ''

  switch (notificationType) {
    case NotificationTypes.MESFOC:
      equipmentStr = StaticModelTypeNames[StaticModelType.MainEng]
      if (instance) instanceStr = ` ${MainEngInstanceName[instance]}`
      break
  }

  return equipmentStr + instanceStr
}

export const getNotificationHeading = (
  alert: NotificationsAPI.PerformanceAlert,
  isTwinEngine: boolean,
) => {
  const equipmentName = getEquipmentName(
    alert.type,
    isTwinEngine ? alert.data.instance : undefined,
  )

  return `${equipmentName} ${getSfocStatus(alert)}`
}

export const getNotificationBody = (
  alert: NotificationsAPI.PerformanceAlert,
): string => {
  const timestamp = moment.utc(alert.created_at).format(DATE_TIME_FORMAT)
  const alertType = AlertTypeDisplayName[alert.type]
  const actualSFOC = formatValue(alert.data.engineSFOCData.actualSfocAverage, 1)
  const deviation = formatValue(
    getDeviation(
      alert.data.engineSFOCData.predictedSfoc10PercentileAverage,
      alert.data.engineSFOCData.actualSfocAverage,
    ),
    1,
  )
  const load = formatValue(alert.data.engineSFOCData.loadAverage, 1)

  let str = `${timestamp} UTC: ${alertType} (${actualSFOC} g/kWh) is deviating ${deviation}% from vessel specific model at the current ME load (${load}% MCR).`
  if (
    alert.data.estimatedWasteToPort !== null &&
    alert.data.estimatedWasteToPort > 0
  ) {
    const waste = formatValue(alert.data.estimatedWasteToPort, 2)
    const port = alert.data.portCall?.arrivalTerminalCode || 'port'
    str += ` Estimated excess fuel consumption till ${port}: ${waste} MT.`
  }

  return str
}

/**
 * Calculates how much `b` deviates from `a`.
 */
export const getDeviation = (a: number, b: number) => {
  if (a === 0) {
    return 0
  }

  return (Math.abs(a - b) / a) * 100
}

/**
 * Determines the SFOC status, based on the predicted and actual values.
 */
export const getSfocStatus = (
  alert: NotificationsAPI.PerformanceAlert,
): string => {
  const { predictedSfoc10PercentileAverage: a, actualSfocAverage: b } =
    alert.data.engineSFOCData

  return b > a ? 'SFOC high' : 'SFOC low'
}
