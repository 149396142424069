import { useRouteMatch } from 'react-router-dom'
import { FaroRoute } from '@grafana/faro-react'

import {
  LubeManagement,
  Reporting,
  TopicsOverview,
} from '../pages/help-section'

export const pages: Array<PageProps> = [
  {
    name: 'Energy help section',
    pagePath: '/lube-management',
    isExact: true,
    component: LubeManagement,
  },
  {
    name: 'Energy help section',
    pagePath: '/reporting',
    isExact: true,
    component: Reporting,
  },
  {
    name: 'Topics Overview',
    pagePath: '',
    isExact: true,
    component: TopicsOverview,
  },
]

const HelpRoutes = () => {
  const { path } = useRouteMatch()

  return pages.map((page) => (
    <FaroRoute
      key={page.pagePath}
      path={path + page.pagePath}
      exact={page.isExact}
    >
      <page.component />
    </FaroRoute>
  ))
}

export default HelpRoutes
